import React, {useState, useEffect} from 'react';
import {apiRequest} from 'services/async';
import {useTranslation} from 'react-i18next';
import {notifyApp} from 'utils/notifyWrapper';
import ScrollView from 'devextreme-react/scroll-view';
import {RoundButton} from 'components/buttons/round-button';
import {RoundInput} from "../../inputs/round-input";
import useRoundInput from 'utils/customHooks/useRoundInput';
import {SelectBox} from "devextreme-react";

import {vars} from 'utils/variables';

import './edit-merchant.scss';
import FlexiPopupComponent from "../../popup/flexi-popup";

const {SCIAPI: {PAYMENTS_SYSTEM_ID}, SUCCESS_API_CODE,} = vars;

const inputHeight = '40px';
const inputClass = 'merchant-input';

const getCheckedItems = (items) => {
	return items.filter((item) => {
		return item.IsSelected === 1;
	});
};

const EditMerchant = (props) => {
	const {
		merchant: {
			ID = '',
			Title = '',
			Name = '',
			Description = '',
			WebAddress = '',
			BUEmail = '',
			CancelURL = '',
			NotifyURL = '',
			SuccessURL = '',
			IsNew = false,
			GeneralAccountID,
		},
		cancelHandler,
		updateValue,
		updateCallback,
	} = props;

	const {t} = useTranslation();
	const [notifySignKey, setNotifySignKey] = useState('');
	const [APIKeyVal, setAPIKeyVal] = useState('');

	const [merchantName, nameInput] = useRoundInput({
		type: "text",
		name: "merchant-name",
		placeholder: "Имя SCI/API",
		initValue: Name,
		height: inputHeight,
		customClass: inputClass
	});

	const [merchantDescription, descriptionInput] = useRoundInput({
		type: "text",
		name: "merchant-description",
		placeholder: "Описание",
		initValue: Description,
		height: inputHeight,
		customClass: inputClass
	});

	const [merchantWebAddress, webAddressInput] = useRoundInput({
		type: "text",
		name: "merchant-webaddress",
		placeholder: "Адрес сайта",
		initValue: WebAddress,
		height: inputHeight,
		customClass: inputClass
	});

	const [merchantBUEmail, bueMailInput] = useRoundInput({
		type: "text",
		name: "merchant-buemail",
		placeholder: "email для отображения клиента",
		initValue: BUEmail,
		height: inputHeight,
		customClass: inputClass
	});

	const [merchantCancelURL, cancelURLInput] = useRoundInput({
		type: "text",
		name: "merchant-cancelurl",
		placeholder: "decline",
		initValue: CancelURL,
		height: inputHeight,
		customClass: inputClass
	});

	const [merchantSuccessURL, successURLInput] = useRoundInput({
		type: "text",
		name: "merchant-successurl",
		placeholder: "success",
		initValue: SuccessURL,
		height: inputHeight,
		customClass: inputClass
	});

	const [merchantNotifyURL, notifyURLInput] = useRoundInput({
		type: "text",
		name: "merchant-statusurl",
		placeholder: "status",
		initValue: NotifyURL,
		height: inputHeight,
		customClass: inputClass
	});

	const [accounts, setAccounts] = useState([]);
	const [selectedAccount, setSelectedAccount] = useState(null);
	const [paymentSystems, setPaymentSystems] = useState([]);

	useEffect(() => {
		const paymentData = ID ? {
			Filters: {
				MerchantApiProfileID: ID,
			}
		} : {};

		const fetchAccounts = async () => {
			const accountsResponse = await apiRequest({
				operation: 'Account/List',
				data: {
					Filters: {
						PaymentSystemTypeID: 0,
						AccountStatusID: 1,
						AccountTypeID: 1,
					}
				}
			});

			if (accountsResponse && accountsResponse.data.ResponseCode === SUCCESS_API_CODE) {
				const {Account} = accountsResponse.data.Response;
				setAccounts(Account);
				const selectedAccount = Account.find((item) => item.ID === GeneralAccountID);
				if (GeneralAccountID && selectedAccount) {
					setSelectedAccount(selectedAccount.ID);
				}
			} else {
				notifyApp(accountsResponse.data.ResponseText);
			}
		};

		const fetchPaymentSystems = async () => {
			const paymentsSysResponse = await apiRequest({
				operation: 'MerchantApiProfilePaymentSystemType/List',
				data: paymentData
			});

			if (paymentsSysResponse && paymentsSysResponse.data.ResponseCode === SUCCESS_API_CODE) {
				setPaymentSystems(paymentsSysResponse.data.Response.MerchantApiProfilePaymentSystemType);
			} else {
				notifyApp(paymentsSysResponse.data.ResponseText);
			}
		};

		fetchAccounts().catch((e) => {
			console.error(e);
		});
		fetchPaymentSystems().catch((e) => {
			console.error(e);
		});
	}, []);

	return (
		<div id={'edit-merchant-input'} className={'edit-merchant-wrapper'}>
			<ScrollView className={'edit-merchant-form'}>
				<div>
					<div className={'edit-merchant-inner'}>
						<div className={'edit-merchant-title'}>
							{Title}
						</div>
						<div className={'edit-merchant-inputs'}>
							{nameInput}
							{descriptionInput}
							{webAddressInput}
							{bueMailInput}
							{cancelURLInput}
							{notifyURLInput}
							{successURLInput}
							<div className={'round-input-wrapper round-selectbox'}>
								<SelectBox
									height={40}
									items={accounts}
									valueExpr={'ID'}
									displayExpr={'Code'}
									showClearButton={true}
									value={selectedAccount}
									hoverStateEnabled={false}
									focusStateEnabled={false}
									activeStateEnabled={false}
									placeholder={t('SETTINGS.RIGHT_BLOCK.SCIAPI.INCOME_ACCOUNT')}
									onValueChanged={({value}) => {
										setSelectedAccount(value);
									}}
								/>
							</div>
						</div>
					</div>
					<div className={'settings-splitter'}/>
					<div className={'settings-splitter'}/>
					<div className={'edit-merchant-inner'}>
						<div className={'edit-merchant-title'}>
							Платежные инструменты
						</div>
						<ScrollView
							direction={'horizontal'}
							scrollByContent={true}
							height={120}
						>
							<div className={'edit-merchants-payment-systems'}>
								{paymentSystems.map((system) => {
									const iconClass = PAYMENTS_SYSTEM_ID[system.PaymentSystemTypeID];
									return (
										<div
											key={system.PaymentSystemTypeID}
											className={'edit-payment-systems'}
										>
											<div className={'edit-payment-systems-head'}>
												<div className={'edit-payment-systems-name'}>{system.Name}</div>
												<i className={`payment-system-icon ${iconClass}`}/>
											</div>
											<RoundButton
												id={`payment-system-${system.PaymentSystemTypeID}`}
												text={system.IsSelected ? 'Подключено' : 'Подключить'}
												onClick={(e) => {
													let id;
													const elementAttr = e.component.instance().option('elementAttr');
													if (elementAttr && elementAttr.id) {
														const splitedId = (elementAttr.id).split('-')[2];
														if (splitedId) {
															id = parseInt(splitedId, 10);
														}
													}
													if (Number.isInteger(id)) {
														const updatedPaymentSystems = paymentSystems.map((paymentSystem) => {
															const invertedValue = paymentSystem.IsSelected === 0 ? 1 : 0;
															return id === paymentSystem.PaymentSystemTypeID ? {
																...paymentSystem,
																IsSelected: invertedValue
															} : {
																...paymentSystem,
															};
														});
														window.setTimeout(() => {
															setPaymentSystems(updatedPaymentSystems);
														}, 300);
													}
												}}
												customClass={`ps-button ${system.IsSelected ? 'white-green-border' : 'blue'}`}
												width={154}
												height={30}
											/>
										</div>
									);
								})}
							</div>
						</ScrollView>
					</div>
				</div>
			</ScrollView>
			<div className={'edit-merchants-buttons-wrapper'}>
				<div className={'edit-merchants-buttons'}>
					<RoundButton
						customClass={'edit-merchants-button'}
						width={200}
						text={t('COMMON.CANCEL')}
						onClick={() => {
							cancelHandler(null);
						}}
					/>
					<RoundButton
						customClass={'edit-merchants-button green'}
						width={200}
						text={'Применить'}
						onClick={async () => {
							let merchantApiProfile = {
								Name: merchantName,
								NotifyURL: merchantNotifyURL,
								SuccessURL: merchantSuccessURL,
								CancelURL: merchantCancelURL,
								Description: merchantDescription,
								WebAddress: merchantWebAddress,
								BUEmail: merchantBUEmail,
								GeneralAccountID: selectedAccount,
								MerchantApiProfilePaymentSystemType: getCheckedItems(paymentSystems),
							};

							if (!IsNew) {
								merchantApiProfile.ID = ID;
							}

							const response = await apiRequest({
								operation: `MerchantApiProfile/${IsNew ? 'Ins' : 'Mod'}`,
								data: {
									Params: {
										...merchantApiProfile
									}
								}
							});

							if (response.data.ResponseCode === SUCCESS_API_CODE) {
								if (IsNew) {
									const {NotifySignKey = '', APIKey = '', } = response.data.Response;
									setNotifySignKey(NotifySignKey);
									setAPIKeyVal(APIKey);
								} else {
									cancelHandler(null);
									updateCallback(!updateValue);
								}
							} else {
								notifyApp(response.data.ResponseText);
							}
						}}
					/>
				</div>
			</div>
			<FlexiPopupComponent
				title={''}
				visible={!!(notifySignKey && APIKeyVal)}
				maxWidth={500}
				handleClose={() => {
					cancelHandler(null);
					updateCallback(!updateValue);
				}}
				container={'#root'}
			>
					<div>
						<div className={'key-input-wrapper'}>
							<span>Notify sign key</span>
							<RoundInput
								name={'notify-sign-key'}
								text={notifySignKey}
								onValueChanged={({value}) => setNotifySignKey(value)}
								mode={'text'}
								height={inputHeight}
								readOnly
								customClass={`${inputClass} generate-input`}
							/>
						</div>
						<div className={'key-input-wrapper'}>
							<span>Secret api key</span>
							<RoundInput
								name={'secret-api-key'}
								text={APIKeyVal}
								onValueChanged={({value}) => setAPIKeyVal(value)}
								mode={'text'}
								height={inputHeight}
								readOnly
								customClass={`${inputClass} generate-input`}
							/>
						</div>
					</div>
			</FlexiPopupComponent>
		</div>
	);
};

export default EditMerchant;
