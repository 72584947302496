import React from 'react';
import {NumberBox, TextBox} from 'devextreme-react';
import classNames from 'classnames';
import NavigationIcons from '../../svg/navigation-icons';

import './styles.scss';

function RoundIconInput({
                          name = '',
                          disabled,
                          readOnly,
                          icon,
                          isNavigationIcons = false,
                          iconName,
                          iconFill,
                          text,
                          onValueChanged,
                          onInput = null,
                          isNewPassword = false,
                          customClass,
                          width,
                          mode = 'text',
                          height,
                          placeholder,
                          stylingMode = 'outlined',
                          inputType = 'text',
                          id,
                          children
                        }) {

  const textBoxProps = {
    name: name,
    disabled: disabled,
    readOnly: readOnly,
    placeholder: placeholder,
    value: text,
    width: width,
    mode: mode,
    onInput: onInput,
    height: height,
    stylingMode: stylingMode,
    onValueChanged: onValueChanged,
    className: classNames('round-input-wrapper_input', customClass),
  };

  if (id) {
    textBoxProps.inputAttr = {id: id};
  }

  if (isNewPassword) {
    Object.assign(textBoxProps, {
      inputAttr: {autocomplete: "new-password"}
    });
  }

  const InputComponent = inputType === 'text' ? TextBox : NumberBox;

  return (
    <div className={'round-input-wrapper'}>
      {isNavigationIcons ? (
        <NavigationIcons name={iconName} fill={iconFill}/>
      ) : (
        <i className={classNames('round-input-icon', icon)}/>
      )}
      <InputComponent
        {...textBoxProps}
      >
        {children}
      </InputComponent>
    </div>
  );
}

export default RoundIconInput;
