import React from 'react';
import {Popup} from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import OtpInputStateful from '../inputs/otp-input-stateful/otp-input-stateful';
import {useTranslation} from 'react-i18next';
import {vars} from 'utils/variables';

import './flexi-popup.scss';

const {POPUP_LOGO_FILE_NAME} = vars;

const position = {
	'my': 'center',
	'at': 'center',
	'of': '#root',
	collision: 'fit',
	boundaryOffset: '20 40',
}

const OtpPopup = ({
										height = 'auto',
										maxHeight = '80%',
										visible,
										maxWidth = 650,
										withResend = true,
										title = 'COMMON.CONFIRM_OPERATION',
										handleClose,
										infoText = '',
										sendChannelId = 1,
										onResend = () => {
										},
										onSubmit = () => {
										},
									}) => {

	const {t} = useTranslation();

	return (
		<Popup
			deferRendering
			visible={visible}
			container={'#root'}
			dragEnabled={false}
			closeOnOutsideClick={false}
			showTitle={false}
			maxWidth={maxWidth}
			height={height}
			maxHeight={maxHeight}
			onHiding={handleClose}
			position={position}
		>
			<div
				className={'flexi-popup-close'}
				onClick={handleClose}
			>
				<i className={'mdi mdi-close-circle-outline'}/>
			</div>
			<div className={'otp-popup-content'}>
				<div className={'flexi-popup-logo'}>
					<img
						src={`./images/sharpay/${POPUP_LOGO_FILE_NAME[sendChannelId]}`}
						className='wrapper-class-name-popup'
					/>
				</div>
				<div className={'flexi-popup-head'}>
					<div className={'flexi-popup-title'}>
						{t(title)}
					</div>
				</div>
				<ScrollView
					className={'popup-scrollable-content'}
					height={'100%'}
					width={'100%'}
				>
					<div className={'flexi-popup-component'}>
						<div className={'otp-popup-component'}>
							<div className={'component-subtitle'}>{infoText}</div>
							{visible ? (
								<OtpInputStateful
									withResend={withResend}
									onSubmit={onSubmit}
									onResend={onResend}
									onClosePopup={handleClose}
								/>
							) : null}
						</div>
					</div>
				</ScrollView>
			</div>
		</Popup>
	);
}

export default OtpPopup;
