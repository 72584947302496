import React from 'react';
import PropTypes from 'prop-types';
import {NumberBox, TextBox} from 'devextreme-react';
import {ReactSVG} from 'react-svg';
import classNames from 'classnames';

import './styles.scss';

function RoundInput({name = '', disabled,
											readOnly, icon, figures, text, onValueChanged, onInput = null, isNewPassword = false,
											customClass, width, mode = 'text', height, placeholder, stylingMode = 'outlined', inputType= 'text', children}) {

	const textBoxProps = {
		name: name,
		disabled: disabled,
		readOnly: readOnly,
		placeholder: placeholder,
		value: text,
		width: width,
		mode: mode,
		onInput: onInput,
		height: height,
		stylingMode: stylingMode,
		onValueChanged: onValueChanged,
		className: classNames('round-input-wrapper_input', customClass),
	};

	if (isNewPassword) {
		Object.assign(textBoxProps, {
			inputAttr: {autocomplete: "new-password"}
		});
	}

	const InputComponent = inputType === 'text' ? TextBox : NumberBox;

  const iconName = typeof figures === 'string' ? figures : 'figures';

	return (
		<div className={'round-input-wrapper'}>
			{figures ? (
				<ReactSVG
          src={`./images/${iconName}.svg`}
          className={'flexi-popup-round_input__icon'}/>
			) : (
				<i className={classNames('round-input-icon', icon)}/>
			)}
			<InputComponent
				{...textBoxProps}
			>
				{children}
			</InputComponent>
		</div>
	);
}
export default RoundInput;
RoundInput.propTypes = {
	disabled: PropTypes.bool,
	icon: PropTypes.string,
	text: PropTypes.string,
	mode: PropTypes.string,
	onValueChanged: PropTypes.func,
	customClass: PropTypes.string,
	placeholder: PropTypes.string,
};

RoundInput.defaultProps = {
	placeholder: '',
	customClass: '',
	text: '',
	mode: 'text',
	disabled: false,
	width: 'auto',
	height: '50px',
};
