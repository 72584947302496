export const actionTypes = {
	MAKE_OPERATIONS: 'operations/MAKE_TRANSACTION',
	CLEAR_OPERATIONS: 'operations/CLEAR_OPERATION',
	CHANGE_PAYMENT_STATUS: 'operations/CHANGE_PAYMENT_STATUS',
	BLOCK_RIGHT_FORM: 'operations/BLOCK_RIGHT_FORM',
	TOGGLE_BLOCK_RIGHT_FORM: 'operations/TOGGLE_BLOCK_RIGHT_FORM',
	TOGGLE_LOADER: 'operations/SHOW_LOADER',
};

export const makeOperation = ({paymentTypeId, account}) => ({
	type: actionTypes.MAKE_OPERATIONS,
	payload: {
		paymentTypeId,
		account
	}
});

export const clearOperation = () => ({
	type: actionTypes.CLEAR_OPERATIONS,
});

export const toggleLoader = (payload) => ({
	type: actionTypes.TOGGLE_LOADER,
	payload: payload
});

export const blockRightForm = () => ({
	type: actionTypes.BLOCK_RIGHT_FORM,
});

export const toggleBlockRightForm = () => ({
	type: actionTypes.TOGGLE_BLOCK_RIGHT_FORM,
});

export const changePaymentStatus = ({text}) => ({
	type: actionTypes.CHANGE_PAYMENT_STATUS,
	payload: {
		text
	}
});
