import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Button, SelectBox} from "devextreme-react";
import {RoundInput} from 'components/inputs/round-input';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import validationEngine from 'devextreme/ui/validation_engine';
import {
	Validator,
	RequiredRule,
	CompareRule,
	PatternRule,
} from 'devextreme-react/validator';
import {vars} from 'utils/variables';

import './walletto-details-settings-fields.scss';

const {
	MAIN: {
		DETAIL_WIDGET_SETTING_ID: {
			CHANGE_3D_SECURE,
			CHANGE_PIN,
			LOCK_CARD,
			UNLOCK_CARD,
		}
	},
	REG_EXP: {FOUR_NUMBERS_ONLY},
	SUCCESS_API_CODE,
	NOTIFY: {SUCCESS}
} = vars;

const source = [
	{id: CHANGE_3D_SECURE, translationKey: 'CHANGE_3D_SECURE'},
	{id: CHANGE_PIN, translationKey: 'CHANGE_PIN'},
	{id: LOCK_CARD, translationKey: 'LOCK_CARD'},
	{id: UNLOCK_CARD, translationKey: 'UNLOCK_CARD'},
];

const getSource = (t) => {
	return source.map((item) => {
		return {
			...item,
			name: t(`MAIN.ACCOUNT_DETAIL_WIDGET.${item.translationKey}`),
		}
	});
};

const initFieldValue = '';

const WallettoDetailsSettingsFields = (props) => {
	const {handleClose, cardId} = props;
	const [currentSettingID, setCurrentSettingID] = useState(null);
	const {t} = useTranslation();
	const dataSource = getSource(t);

	const [secureValue, setSecureValue] = useState(initFieldValue);
	const [secureCheckValue, setSecureCheckValue] = useState(initFieldValue);
	const [pinValue, setPinValue] = useState(initFieldValue);
	const [pinCheckValue, setPinCheckValue] = useState(initFieldValue);

	useEffect(() => {
		validationEngine.resetGroup('fields');
	}, [currentSettingID]);

	const secureComparison = () => {
		return secureValue;
	};
	const pinComparison = () => {
		return pinValue;
	};

	const getSettingFields = () => {
		switch (currentSettingID) {
			case CHANGE_3D_SECURE:
				return (
					<div className={'additional-fields'}>
						<RoundInput
							name={'secure-input'}
							placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.3D_SECURE')}
							text={secureValue}
							onValueChanged={({value}) => {
								setSecureValue(value);
							}}
							mode={'password'}
							height={50}
						>
							<Validator validationGroup={'fields'}>
								<RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
							</Validator>
						</RoundInput>
						<RoundInput
							name={'secure-check-input'}
							placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.3D_SECURE_CONFIRM')}
							text={secureCheckValue}
							onValueChanged={({value}) => {
								setSecureCheckValue(value);
							}}
							mode={'password'}
							height={50}
						>
							<Validator validationGroup={'fields'}>
								<CompareRule
									message={t('MAIN.ACCOUNT_DETAIL_WIDGET.WRONG_3D_SECURE_CONFIRM')}
									comparisonTarget={secureComparison}
								/>
							</Validator>
						</RoundInput>
					</div>
				);
			case CHANGE_PIN:
				return (
					<div className={'additional-fields'}>
						<RoundInput
							name={'pin-input'}
							placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.CARD_PIN')}
							text={pinValue}
							onValueChanged={({value}) => {
								setPinValue(value);
							}}
							mode={'password'}
							height={50}
						>
							<Validator validationGroup={'fields'}>
								<RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
								<PatternRule
									message={t('MAIN.ACCOUNT_DETAIL_WIDGET.DIGIT_ONLY')}
									pattern={FOUR_NUMBERS_ONLY}
								/>
							</Validator>
						</RoundInput>
						<RoundInput
							name={'pin-check-input'}
							placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.CARD_PIN_CONFIRM')}
							text={pinCheckValue}
							onValueChanged={({value}) => {
								setPinCheckValue(value);
							}}
							mode={'password'}
							height={50}
						>
							<Validator validationGroup={'fields'}>
								<CompareRule
									message={t('MAIN.ACCOUNT_DETAIL_WIDGET.WRONG_PIN_CONFIRM')}
									comparisonTarget={pinComparison}
								/>
							</Validator>
						</RoundInput>
					</div>
				);
			case LOCK_CARD:
			case UNLOCK_CARD:
			default:
				return null;
		}
	};

	const constructRequestData = (id) => {
		switch (id) {
			case CHANGE_3D_SECURE:
				return {
					operation: 'WallettoCard/SetStaticPassword',
					data: {
						Params: {
							WallettoCardID: cardId,
							StaticPassword: secureValue,
						}
					}
				};
			case CHANGE_PIN:
				return {
					operation: 'WallettoCard/SetPin',
					data: {
						Params: {
							WallettoCardID: cardId,
							PinCode: pinValue,
						}
					}
				};
			case LOCK_CARD:
				return {
					operation: 'WallettoCard/SoftBlock',
					data: {
						Params: {
							WallettoCardID: cardId,
						}
					}
				};
			case UNLOCK_CARD:
				return {
					operation: 'WallettoCard/SoftUnblock',
					data: {
						Params: {
							WallettoCardID: cardId,
						}
					}
				};
			default:
				return null;
		}
	};

	return (
		<div className={'detail-settings-wrapper'}>
			<SelectBox
				placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.CHOOSER')}
				dataSource={dataSource}
				valueExpr={'id'}
				displayExpr={'name'}
				value={currentSettingID}
				height={50}
				onValueChanged={({value}) => {
					if (!value) return;
					const id = parseInt(value, 10);

					if (id === CHANGE_3D_SECURE) {
						setSecureValue(initFieldValue);
						setSecureCheckValue(initFieldValue);
					} else if (id === CHANGE_PIN) {
						setPinValue(initFieldValue);
						setPinCheckValue(initFieldValue);
					}

					setCurrentSettingID(value);
				}}
			>
				<Validator validationGroup={'fields'}/>
			</SelectBox>
			{getSettingFields()}
			<div className={'popup-buttons'}>
				<Button
					id={'popup-buttons_add'}
					width={'50%'}
					height={40}
					type="default"
					stylingMode="contained"
					text={t('COMMON.CONTINUE')}
					disabled={!currentSettingID}
					onClick={async () => {
						const result = validationEngine.validateGroup('fields');

						if (result.isValid) {
							const payload = constructRequestData(currentSettingID);

							try {
                const response = await apiRequest(payload);

                if (response.data.ResponseCode === SUCCESS_API_CODE) {
                  notifyApp('Setting changed successfully', SUCCESS);
                } else {
                  notifyApp(response.data.ResponseText);
                }
              } finally {
                  if (
                    payload.operation === 'WallettoCard/SoftBlock' ||
                    payload.operation === 'WallettoCard/SoftUnblock'
                  ) {
                    window.location.reload();
                  }
              }

							handleClose();
						}
					}}
				/>
				<Button
					id={'popup-buttons_cancel'}
					width={'50%'}
					height={40}
					text={t('MAIN.ADD_ACCOUNT.CANCEL')}
					type="default"
					stylingMode="outlined"
					onClick={handleClose}
				/>
			</div>
		</div>
	);
};

export default WallettoDetailsSettingsFields;
