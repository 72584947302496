import Button from 'devextreme/ui/button';
import classNames from 'classnames';
import {vars} from 'utils/variables';

const {PAYMENT, MAIN: {ACCOUNTS: {SHARPAY_IBAN_ID}}} = vars;

const template = (index, defaultType, container, {data, column}) => {
  const {buttons, paymentTypeId, accountId} = column;
	let div = document.createElement('div');
	div.setAttribute('id', `btn-${index}-${data.Code}`);
	const {cssClass, hint, text, onClick} = (buttons[index]);

	new Button(div, {
		hint,
		text,
		// disabled: data.PaymentSystemTypeID === SHARPAY_IBAN_ID && defaultType === PAYMENT.PAYMENT_TYPE_INCOME,
		disabled: false,
		height: 40,
		width: 110,
		activeStateEnabled: false,
		focusStateEnabled: false,
		hoverStateEnabled: false,
		elementAttr: {
			'class': classNames(cssClass, {
				'selected': data.ID === accountId && paymentTypeId === defaultType
			})
		},
		onClick: () => {
			onClick({row: {data}})
		}
	});

	return div;
};

export const ConvertButton = template.bind(null, 0, PAYMENT.PAYMENT_TYPE_PAYOUT);
export const RechargeButton = template.bind(null, 1, PAYMENT.PAYMENT_TYPE_INCOME);
export const ServicesButton = template.bind(null, 2, PAYMENT.PAYMENT_SERVICE);
