import React, {useState} from 'react';
import classNames from 'classnames';
import './otp-input.scss';

const OtpInput = ({
										value = '',
										isValid = true,
										onValueChanged = (value) => {
											console.log(value);
										},
										resendCallback = () => {
											console.log('resendCallback');
										},
										onEnterKey = () => {
										},
										withFocusedState = false,
										withResend = true,
										resendText = 'Resend OTP',
										isDisabledResend = false,
										placeholder = 'OTP',
										icon = 'mdi-shield',
										validationMessage = 'Field is required',
										focusCallBack = () => {},
									}) => {
	const [isFocused, setIsFocused] = useState(false);
	const componentClass = {
		'otp-component': true,
		'with-focused-state': withFocusedState,
		'with-resend': withResend,
		'otp-component-focused': isFocused,
		'invalid': !isValid,
	};

	return (
		<div className={classNames(componentClass)}>
			<div className={'left-pocket'}>
				<i className={classNames('mdi', icon)}/>
			</div>
			<div className={'otp-input-wrapper'}>
				<input
					type={'text'}
					placeholder={placeholder}
					onKeyDown={(event) => {
						if (event.key === 'Enter') {
							onEnterKey();
						}
					}}
					onFocus={() => {
						setIsFocused(true);
						focusCallBack();
					}}
					onBlur={() => {
						setIsFocused(false);
					}}
					value={value}
					onChange={({target: {value}}) => {
						onValueChanged({
							value: value.replace(/\D/g, '').substring(0, 6),
						});
					}}
				/>
			</div>
			<div className={'right-pocket'}>
				<span
					onClick={resendCallback}
					className={classNames('resend-otp-button', {
						'resend-otp-button-disabled': isDisabledResend,
					})}
				>
					{resendText}
				</span>
				<div className={'validation-sign'}/>
			</div>
			<div className={'validation-message'}>
				{validationMessage}
			</div>
		</div>
	);
}

export default OtpInput;