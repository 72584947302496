import React, {useEffect, useState} from 'react';
import Head from './blocks/head';
import Methods from './blocks/methods';
import Fields from './blocks/fields';
import {useTranslation} from 'react-i18next';
import {blockRightForm, toggleBlockRightForm} from 'services/operations/actions';
import {connect} from 'react-redux';
import {notifyApp} from 'utils/notifyWrapper';
import {apiRequest} from 'services/async';
import {groupBy} from 'utils/functions';
import {vars} from 'utils/variables';
import BanxeRequisites from './income-fields/banxe-requisites/banxe-requisites';

const {PAYMENT: {PAYMENT_TYPE_PAYOUT, PAYMENT_SERVICE, PAYMENT_TYPE_INCOME}, MAIN: {ACCOUNTS}} = vars;

const TransactionScreen = (props) => {
  const {
    account,
    paymentTypeId,
    paymentMethodValueId,
    changePaymentMethod,
    blockRightForm,
    toggleBlockRightForm,
    isBlockedRightForm,
    accountList,
  } = props;

  const {t} = useTranslation();
  const [methods, setMethods] = useState({
    methodGroup: [],
    methodsIdToName: null,
  });

  const [selectedMethod, setSelectedMethod] = useState(null);

  useEffect(() => {
    if (methods.allowedPaymentSystem) {
      const t = methods.allowedPaymentSystem.find((item) => {
        return item.ForeignPaymentSystemTypeID === paymentMethodValueId;
      })

      if (paymentMethodValueId === -1) {
        setSelectedMethod(methods.allowedPaymentSystem[0]); // to show empty services selectbox
      } else {
        setSelectedMethod(t);
      }
    }
  }, [paymentMethodValueId]);

  useEffect(() => {
    if (methods.allowedPaymentSystem && paymentTypeId === PAYMENT_SERVICE) {
      // https://wiki.macc.com.ua/maccsystems/pages/viewpage.action?pageId=60624029
      // services flow
      if (methods.allowedPaymentSystem.length === 1) {
        // show services, right settings
        changePaymentMethod(methods.allowedPaymentSystem[0].ForeignPaymentSystemTypeID);
      } else if (methods.allowedPaymentSystem.length > 1) {
        // set payment method -1, failure settings
        // show read only services
        changePaymentMethod(-1);
      }
    }
  }, [methods]);

  const getMethodNameById = (id) => {
    return id != null ? methods.methodsIdToName[id] : null;
  };

  const getMethods = async () => {
    let params = {
      AccountID: account.ID,
      PaymentTypeID: paymentTypeId,
    };

    if (paymentTypeId === PAYMENT_SERVICE) {
      params = {
        ...params,
        PaymentTypeID: PAYMENT_TYPE_PAYOUT, // wiki.macc.com.ua/maccsystems/pages/viewpage.action?pageId=22053036
        IsService: 1,
      };
    }

    const request = await apiRequest({
      operation: 'Payment/AllowedPaymentSystem',
      data: {
        Params: params,
        Page: -1,
        Sorts: ['OrderNum'],
      },
    });

    if (request.data.ResponseCode === '000') {
      const rawResponse = request.data.Response.AllowedPaymentSystem;
      const methodsIdToName = rawResponse.reduce((obj, item) => {
        return {
          ...obj,
          [item['ForeignPaymentSystemTypeID']]: item['ForeignPaymentSystemTypeName']
        };
      }, {});

      const groupById = groupBy('ForeignPaymentSystemTypeGroupID');
      const groupedMethods = groupById(rawResponse);
      const methodGroup = Object.keys(groupedMethods).map((key) => {
        return {
          key: groupedMethods[key][0].ForeignPaymentSystemTypeGroupName,
          items: groupedMethods[key],
        };
      });
      return Promise.resolve({
        methodGroup: methodGroup,
        methodsIdToName: methodsIdToName,
        allowedPaymentSystem: rawResponse,
      });
    } else {
      throw new Error(request.data.ResponseText);
    }
  };

  const paymentType = `OPERATIONS.HEAD_OPERATION_TYPE.${paymentTypeId}`;

  useEffect(() => {
    getMethods().then((result) => {
      setMethods(result);
    }).catch((e) => {
      notifyApp(e)
    });
  }, [account, paymentTypeId]);

  return (
    <div className={'transaction-screen-wrapper'}>
      <Head
        paymentType={t(paymentType)}
        account={account}
      />
      <div className={'transaction-screen-fields'}>
        {account.PaymentSystemTypeID === ACCOUNTS.BANXE_ID && paymentTypeId === PAYMENT_TYPE_INCOME ?
          (<BanxeRequisites account={account}/>) :
          methods.methodGroup.length > 0 && paymentTypeId !== PAYMENT_SERVICE ?
            (<Methods
                methods={methods.methodGroup}
                paymentMethodValueId={paymentMethodValueId}
                paymentTypeId={paymentTypeId}
                changePaymentMethod={changePaymentMethod}
                readOnly={isBlockedRightForm}
              />
            ) : null}
        {paymentMethodValueId != null && selectedMethod ? (
          <div key={paymentMethodValueId} className={'transaction-fields-wrapper'}>
            <Fields
              accountList={accountList}
              selectedAccountCode={account.Code}
              paymentTypeId={paymentTypeId}
              paymentMethodValueId={paymentMethodValueId}
              paymentMethodValueName={getMethodNameById(paymentMethodValueId)}
              blockRightForm={blockRightForm}
              toggleBlockRightForm={toggleBlockRightForm}
              selectedMethod={selectedMethod}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    isBlockedRightForm: state.operations.isBlockedRightForm,
  }
};

const mapDispatchToProps = (dispatch) => ({
  blockRightForm: () => dispatch(blockRightForm()),
  toggleBlockRightForm: () => dispatch(toggleBlockRightForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionScreen);
