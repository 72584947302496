import React from 'react';
import {vars} from './variables';

export function customizeDateCell(props) {
  return (
    <div>
				<span>
					{props.text}
				</span>
      <br/>
      <span className={'utc'}>
					{props.data.UTC}
				</span>
    </div>
  );
}

export function customizeAmount(obj, key = 'AccountCurrencySymbol', precision) {
  let value = obj.value;
  if (precision) {
    value = value.toFixed(precision);
  }
  return `${obj.data[key]} ${value}`;
}

export function amountWithCurrencyCode(obj, key = 'CurrencyCode', precision) {
  let value = obj.value;
  let localPrecision = 2;
  const zeroAfterPoint = value.toString().split('.')[1];

  if (precision && zeroAfterPoint && zeroAfterPoint.length > 2) {
    localPrecision = precision;
  }

  value = value.toFixed(localPrecision);

  return `${value} ${obj.data[key]}`;
}

export function customizeTotalAmount(obj, key = 'ForeignCurrencySymbol', precisionKey = 'ForeignCurrencyViewPrecision') {
  let value = obj.value;
  if (precisionKey) {
    value = value.toFixed(obj.data[precisionKey]);
  }
  return <span className={'grid-col-green'}>{`${obj.data[key]} ${value}`}</span>;
}

export function customizeStatus({value, data}) {
  const {MAIN: {STATUS_COLOR_CLASS}} = vars;
  let statusClass;
  switch (data.PaymentStatusID) {
    case 4:
      statusClass = STATUS_COLOR_CLASS.GREEN;
      break;
    case 5:
    case 6:
      statusClass = STATUS_COLOR_CLASS.RED;
      break;
    default:
      statusClass = STATUS_COLOR_CLASS.ORANGE;
  }
  return <span className={'status ' + statusClass}>{value}</span>;
}

export function customizeTransactionStatus({value, data}) {
  const {MAIN: {STATUS_COLOR_CLASS}} = vars;
  let statusClass;
  switch (data.StatusID) {
    case 2:
      statusClass = STATUS_COLOR_CLASS.GREEN;
      break;
    case 3:
      statusClass = STATUS_COLOR_CLASS.RED;
      break;
    default:
      statusClass = STATUS_COLOR_CLASS.ORANGE;
  }
  return <span className={'status ' + statusClass}>{value}</span>;
}

export const customizePaymentTypeColor = ({data: {PaymentTypeID, PaymentTypeName}}) => {
  let className = '';

  if ([1, 3, 5, 7, 9].includes(PaymentTypeID)) {
    className = 'grid-col-red';
  } else if ([2, 4, 6, 8, 10].includes(PaymentTypeID)) {
    className = 'grid-col-green';
  }

  return (
    <span className={className}>
			{PaymentTypeName}
		</span>
  );
}

export const valueWithIcon = ({value, data: {PaymentSystemTypeImageUrl}}) => {
  if (!PaymentSystemTypeImageUrl) return value;

  return (
    <div className={'value-with-icon'}>
      <img height={20} src={PaymentSystemTypeImageUrl} alt='Payment System Icon'/>
      <span>{value}</span>
    </div>
  );
};