import React, {useEffect, useState} from 'react';
import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Legend,
  Series,
  Tooltip,
  ValueAxis,
  ConstantLine,
  Label,
  Connector,
} from 'devextreme-react/chart';
import {PieChart} from 'devextreme-react/pie-chart';
import {DateBox} from 'devextreme-react';
import {getChartData} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import './charts2.scss';

const PieChartComponent = ({accountId, closeChart}) => {
  const [date, setDate] = useState(new Date());
  const [pieData, setPieData] = useState(null);

  useEffect(() => {
    loadPieData().then((data) => {
      console.log('set data');
      setPieData(data);
    }).catch((error) => {
      notifyApp(error);
    });
  }, [date, accountId]);


  const loadPieData = async () => {
    const request = await getChartData(accountId, date);

    if (request.isSuccess) {
      console.log(request.response);
      return request.response;
    } else {
      throw new Error(request.error);
    }
  }

  const customizePiChartLabel = ({percentText}) => {
    return percentText;
  }

  return (
    <div className={'charts-wrapper'}>
      <div className={'chart-top-bar'}>
        <i className={'mdi mdi-close'} onClick={closeChart}/>
      </div>
      <DateBox
        displayFormat={'dd.MM.yyyy'}
        width={200}
        value={date}
        onValueChanged={({value}) => {
          setDate(value);
        }}
      />
      <div className={'charts-inner'}>
        <div className={'chart-item'}>
          <PieChart
            title={'Pie Chart'}
            dataSource={pieData}
            type={'doughnut'}
          >
            <Series
              argumentField={'PaymentStatusName'}
              valueField={'PaymentStatusID'}
            >
              <Label visible={true} customizeText={customizePiChartLabel}>
                <Connector visible={true} width={1}/>
              </Label>
            </Series>
          </PieChart>
        </div>

        <div className={'chart-item'}>
          <Chart
            title={'Bar chart'}
            dataSource={pieData}
          >
            <Series
              valueField={'Amount'}
              argumentField={'InsDate'}
              type={'bar'}
              color={'#ffaa66'}
              name={'Amount'}
            ></Series>
            <ArgumentAxis>
              <Label format="dd.MM.yyyy" />
            </ArgumentAxis>
          </Chart>
        </div>
        <div className={'chart-item'}>
          <PieChart
            dataSource={[
              {date: '12.08.2023', amount: 200},
              {date: '12.08.2023', amount: 1200},
              {date: '13.08.2023', amount: 20},
              {date: '14.08.2023', amount: 600},
              {date: '14.08.2023', amount: 800},
              {date: '14.08.2023', amount: 1200},
              {date: '17.08.2023', amount: 8},
              {date: '22.08.2023', amount: 14},
              {date: '22.08.2023', amount: 404},
            ]}

            type={'doughnut'}
          >
            <Series
              argumentField={'date'}
              valueField={'amount'}
            />
          </PieChart>
        </div>
      </div>
    </div>
  );
}

export default PieChartComponent;