import React from 'react';
import {camelToUpperSnakeCase, truncateString} from 'utils/functions';
import CopyButton from 'components/buttons/copy-button/copy-button';

const WallettoFields = ({t, rowData}) => {
	rowData.InsDate = rowData.InsDate.replace('T', ' ');

	const {
		Amount, CurrencyCode, FeeAmount, ForeignAmount, ForeignAccountCode, ForeignClientName, ForeignCurrencyCode,
		CurrencyRate, CryptoParams,
	} = rowData;

	const simpleFields = [
		'InsDate',
		'PaymentTypeName',
		'PaymentStatusName',
	];

	const extraSimpleFields = [
		'CryptoParams.BlockchainCode',
		'Mcc',
		'Rrn',
		'ApprovalCode',
		'Description',
	];

	let complexFields;
	const commonFields = {};

	// https://wiki.macc.com.ua/maccsystems/pages/viewpage.action?pageId=60623336
	if ([2, 4, 6, 8, 10].includes(rowData.PaymentTypeID)) {

		complexFields = {
			Amount: (ForeignAmount == null ? 0 : ForeignAmount).toFixed(2) + " " + ForeignCurrencyCode,
			Fee: (FeeAmount ? "~" : "") + FeeAmount.toFixed(2) + " " + ForeignCurrencyCode,
			ToReceive: Amount.toFixed(2) + " " + CurrencyCode,
		};
	} else {
		complexFields = {
			Amount: Amount.toFixed(2) + " " + CurrencyCode,
			Fee: (FeeAmount ? "~" : "") + FeeAmount.toFixed(2) + " " + ForeignCurrencyCode,
			ToTransfer: (ForeignAmount == null ? 0 : ForeignAmount).toFixed(2) + " " + ForeignCurrencyCode,
		};
	}

	if (CurrencyRate !== 1) {
		commonFields.Rate = "1 " + CurrencyCode + " = " + CurrencyRate + " " + ForeignCurrencyCode;
	}

	commonFields.TransactionId = rowData.ID;

	const complexKeys = Object.keys(complexFields);
	const commonKeys = Object.keys(commonFields);

	return (
		<div className={'payment-detail-inner'}>
			{simpleFields.map((key) => {
				return rowData[key] ? (
					<div
						key={key}
						className={'payment-detail-item'}
					>
						<span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.${camelToUpperSnakeCase(key)}`)}</span>
						<span className={'item-value'} title={rowData[key]}>{rowData[key]}</span>
					</div>
				) : null;
			})}
			<div className={'payment-detail-item'}>
				<span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.FOREIGN_CLIENT_NAME`)}</span>
				<div className={'item-value with-copy-button'}>
					<span className={'item-value'} title={ForeignClientName}>{ForeignClientName}</span>
					<CopyButton value={ForeignClientName}/>
				</div>
			</div>
			<div className={'payment-detail-item'}>
				<span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.FOREIGN_ACCOUNT_CODE`)}</span>
				<div className={'item-value with-copy-button'}>
					<span className={'item-value'} title={ForeignAccountCode}>{truncateString(ForeignAccountCode)}</span>
					<CopyButton value={ForeignAccountCode}/>
				</div>
			</div>
			{extraSimpleFields.map((key) => {
        const [outerKey, innerKey] = key.split('.');

        if (innerKey && rowData[outerKey]) {
          return rowData[outerKey][innerKey] ? (
            <div
              key={key}
              className={'payment-detail-item'}
            >
              <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.${camelToUpperSnakeCase(innerKey)}`)}</span>
              <span className={'item-value'}>{rowData[outerKey][innerKey]}</span>
            </div>
          ) : null;
        } else {
          return rowData[key] ? (
            <div
              key={key}
              className={'payment-detail-item'}
            >
              <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.${camelToUpperSnakeCase(key)}`)}</span>
              <span className={'item-value'}>{rowData[key]}</span>
            </div>
          ) : null;
        }
			})}
			{complexKeys.map((key) => {
				return (
					<div
						key={key}
						className={'payment-detail-item'}
					>
						<span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.${camelToUpperSnakeCase(key)}`)}</span>
						<span className={'item-value'} title={complexFields[key]}>{complexFields[key]}</span>
					</div>
				);
			})}
			{commonKeys.map((key) => {
				return commonFields[key] ? (
					<div
						key={key}
						className={'payment-detail-item'}
					>
						<span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.${camelToUpperSnakeCase(key)}`)}</span>
						<span className={'item-value'} title={commonFields[key]}>{commonFields[key]}</span>
					</div>
				) : null;
			})}
			{CryptoParams && CryptoParams.Txid ? (
				<div
					className={'payment-detail-item'}
				>
					<span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.BLOCKCHAIN_ID`)}</span>
					<div className={'item-value with-copy-button'}>
						<span className={'item-value'} title={CryptoParams.Txid}>{truncateString(CryptoParams.Txid)}</span>
						<CopyButton value={CryptoParams.Txid}/>
					</div>
				</div>
			) : null}
		</div>
	);
}

export default WallettoFields;