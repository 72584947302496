import React, {useState} from 'react';
import RecoveryPopupComponent from '../popup/recovery-popup';
import RecoveryInitForm from '../popup/login/recoveryInitForm';
import RecoveryConfirmForm from '../popup/login/recoveryConfirmForm';
import RecoverySuccessForm from '../popup/login/recoverySuccessForm';

const Recovery = (props) => {
  const {
    visible,
    container,
    title,
    maxWidth,
    handleClose,
  } = props;

  const [step, setStep] = useState(0);
  const [channelId, setChannelId] = useState(-1);
  const [urlid, setUrlid] = useState('');

  const increaseStep = () => {
    setStep(step + 1);
  }

  const initCallback = (response) => {
    const {
      SendChannelID,
      Urlid,
    } = response;

    setChannelId(SendChannelID);
    setUrlid(Urlid);
    increaseStep();
  }

  const renderStep = () => {
    switch (step) {
      case 0:
        return <RecoveryInitForm initCallback={initCallback}/>
      case 1:
        return <RecoveryConfirmForm nextStep={increaseStep} urlid={urlid}/>
      case 2:
        return <RecoverySuccessForm handleClose={closeAndReset}/>
    }
  }

  const closeAndReset = () => {
    setStep(0);
    handleClose();
  }

  return (
    <RecoveryPopupComponent
      visible={visible}
      container={container}
      title={title}
      maxWidth={maxWidth}
      handleClose={closeAndReset}
      channelId={channelId}
    >
      {renderStep()}
    </RecoveryPopupComponent>
  );
}

export default Recovery;