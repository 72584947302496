import React, {useEffect, useRef, useState} from 'react';
import FormInput from 'components/inputs/form-input/form-input';
import RoundButton from 'components/buttons/round-button/round-button';
import {useTranslation} from 'react-i18next';
import {conditionLinks, vars} from 'utils/variables';
import {sha512} from 'utils/functions';
import validationEngine from 'devextreme/ui/validation_engine';
import {apiRequest, getApplicationSettings, sendRegistrationOtp, sendRegistrationRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {Link} from 'react-router-dom';
import {CheckBox} from 'devextreme-react/check-box';
import Validator, {CompareRule} from 'devextreme-react/validator';
import PhoneInput from 'components/inputs/phone-input/phone-input';
import LanguageSwitcher from 'components/language-switcher/language-switcher';
import DynamicPasswordHint from 'components/dynamic-password-hint/dynamic-password-hint';

const {POPUP_LOGO_FILE_NAME, REG_EXP, NOTIFY, SUCCESS_API_CODE} = vars;

const NewRegister = (props) => {
  const {onLoginClick} = props;
  const {t} = useTranslation();
  const recaptchaRef = useRef(null);

  const [email, setEmail] = useState('');
  const [emailOtp, setEmailOtp] = useState(null);

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [referralCode, setReferralCode] = useState('');

  const [info, setInfo] = useState(null);
  const [userProfileModInfo, setUserProfileModInfo] = useState(null);

  useEffect(() => {
    if (info) {
      validationEngine.resetGroup('otpGroup');
    }
  }, [info]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=6Leo1KYnAAAAAC1q-OumYOFrUz-SaBfrxQS5io9v';
    document.body.appendChild(script);

    script.onload = () => {
      recaptchaRef.current = window.grecaptcha;
    };

    return () => {
      document.body.removeChild(script);
      recaptchaRef.current = null;
      document.querySelector('div.grecaptcha-badge').remove();
    };
  }, []);

  const comparePassword = () => {
    return password;
  };

  const checkComparison = () => {
    return true;
  }

  const sendRegistration = async () => {
    const {NOTIFY} = vars;
    const result = validationEngine.validateGroup('registrationGroup');

    if (!result.isValid) {
      notifyApp('Please fix the validation errors', NOTIFY.ERROR);
      return;
    }

    const hashedPassword = await sha512(password);

    if (hashedPassword) {
      const token = await window.grecaptcha.execute('6Leo1KYnAAAAAC1q-OumYOFrUz-SaBfrxQS5io9v', {action: 'RegistrationLogin'});
      const getParams = null;

      const request = await sendRegistrationRequest({
        phone: login,
        password: hashedPassword,
        referrerCode: referralCode,
        getParams: getParams,
        token: token,
      });

      if (request.isSuccess) {
        setInfo(request.response);
      } else {
        notifyApp(request.error, NOTIFY.ERROR);
      }
    } else {
      notifyApp('Client WebAPI error', NOTIFY.ERROR);
    }
  }

  const [smsCode, setSmsCode] = useState('');
  const [isShowEmailConfirmation, setIsShowEmailConfirmation] = useState(false);

  const triggerGTMEvent = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({'event': 'registration.gtm'});
  }

  const sendSmsCodeRequest = async () => {
    const result = validationEngine.validateGroup('otpGroup');

    if (!result.isValid) {
      notifyApp('Please fix the validation errors', NOTIFY.ERROR);
      return;
    }

    const request = await sendRegistrationOtp(smsCode);

    if (request.isSuccess) {
      triggerGTMEvent();
      setIsShowEmailConfirmation(true);
    } else {
      notifyApp(request.error, NOTIFY.ERROR);
    }
  };

  const sendEmail = async () => {
    const result = validationEngine.validateGroup('emailGroup');

    if (!result.isValid) {
      notifyApp('Please fix the validation errors');
      return;
    }

    const request = await apiRequest({
      operation: 'UserProfile/ModEmail',
      data: {
        Params: {
          Email: email,
        },
      },
    });

    if (request.data.ResponseCode === SUCCESS_API_CODE) {
      setUserProfileModInfo(request.data.Response);
    } else {
      notifyApp(request.data.ResponseText);
    }
  }

  const confirmEmail = async () => {
    const result = validationEngine.validateGroup('emailGroup');

    if (!result.isValid) {
      notifyApp('Please fix the validation errors', NOTIFY.ERROR);
      return;
    }

    const request = await apiRequest({
      operation: 'UserProfile/ConfirmFieldByCode',
      data: {
        Params: {
          Code: emailOtp,
          FieldName: 'Email',
        },
      },
    });

    if (request.data.ResponseCode === SUCCESS_API_CODE) {
      const settingsResponse = await getApplicationSettings();

      if (settingsResponse.isSuccess) {
        window.localStorage.setItem('userTypeID', settingsResponse.userTypeID);
        onLoginClick(settingsResponse.userName);
      } else {
        notifyApp(settingsResponse.error);
      }
    } else {
      notifyApp(request.data.ResponseText);
    }
  }

  const emailWizard = () => {

    return (
      <div className={'middle-block'}>
        <FormInput
          onEnterKey={sendEmail}
          label={t('COMMON.EMAIL')}
          placeholder={'example@gmail.com'}
          type={'text'}
          validation={{
            group: 'emailGroup',
            rules: [
              {type: 'required', message: t('ERROR_MSG.REQUIRED')},
              {type: 'pattern', message: 'Incorrect email address', pattern: REG_EXP.EMAIL},
            ]
          }}
          readOnly={!!userProfileModInfo}
          onValueChanged={({value}) => {
            setEmail(value);
          }}
        />
        {userProfileModInfo && (
          <>
            <FormInput
              onEnterKey={confirmEmail}
              label={t('COMMON.AUTH_CODE')}
              type={'text'}
              validation={{
                group: 'emailGroup',
                rules: [
                  {type: 'required', message: t('ERROR_MSG.REQUIRED')},
                ]
              }}
              onValueChanged={({value}) => {
                setEmailOtp(value);
              }}
            />
            <div className={'info-panel'}>
              <div className={'info-panel-icon'}>
                <img width={'60px'} src={`./images/sharpay/${POPUP_LOGO_FILE_NAME[userProfileModInfo.SendChannelID]}`}
                     alt={'popup-logo'}/>
              </div>
              <div className={'info-panel-text'}>
                {userProfileModInfo.InfoText}
              </div>
            </div>
          </>
        )}
        <RoundButton
          text={t(userProfileModInfo ? 'COMMON.SEND' : 'COMMON.ACCEPT')}
          icon={''}
          onClick={userProfileModInfo ? confirmEmail : sendEmail}
          customClass={'size14 new-button'}
        />
      </div>
    );
  }

  return (
    <div className={'adaptive-block-right'}>
      <div className={'login-form'}>
        {isShowEmailConfirmation ? (
          emailWizard()
        ) : (
          <div className={'middle-block'}>
            <p className={'form-title'}>{t('COMMON.SIGN_UP')}</p>
            <div className={'anti-autocomplete'}>
              <input type='text' name='username' autoComplete='username'/>
              <input type={'password'}/>
            </div>
            <PhoneInput
              onEnterKey={sendRegistration}
              readOnly={!!info}
              type={'phone'}
              label={t('COMMON.LOGIN_ACCOUNT_WITHOUT_CODE')}
              validation={{
                group: 'registrationGroup',
                rules: [
                  {type: 'required', message: t('ERROR_MSG.REQUIRED')},
                  {type: 'pattern', message: t('ERROR_MSG.WRONG_PHONE'), pattern: REG_EXP.PHONE_NUMBER},
                ],
              }}
              onValueChanged={({value}) => {
                setLogin(value);
              }}
            />
            {info ? (
              <>
                <FormInput
                  onEnterKey={sendSmsCodeRequest}
                  label={t('COMMON.AUTH_CODE')}
                  type={'text'}
                  validation={{
                    group: 'otpGroup',
                    rules: [
                      {type: 'required', message: t('ERROR_MSG.REQUIRED')},
                      //{type: 'pattern', message: t('ERROR_MSG.REQUIRED')},
                    ]
                  }}
                  onValueChanged={({value}) => {
                    setSmsCode(value);
                  }}
                />
                <div className={'info-panel'}>
                  <div className={'info-panel-icon'}>
                    <img width={'60px'} src={`./images/sharpay/${POPUP_LOGO_FILE_NAME[info.SendChannelID]}`}
                         alt={'popup-logo'}/>
                  </div>
                  <div className={'info-panel-text'}>
                    {info.InfoText}
                  </div>
                </div>
                <RoundButton
                  text={t('COMMON.SEND')}
                  icon={''}
                  onClick={sendSmsCodeRequest}
                  customClass={'size14 new-button'}
                />
              </>
            ) : (
              <>
                <FormInput
                  value={password}
                  onEnterKey={sendRegistration}
                  label={t('COMMON.LOGIN_PASS_PLACEHOLDER')}
                  type={'password'}
                  validation={{
                    group: 'registrationGroup',
                    rules: [
                      {type: 'required', message: t('ERROR_MSG.REQUIRED')},
                      {type: 'pattern', message: t('ERROR_MSG.NOT_ALL_CONDITIONS'), pattern: REG_EXP.PASSWORD_PATTERN},
                    ]
                  }}
                  onInput={({event}) => {
                    setPassword(event.target.value);
                  }}
                />
                <DynamicPasswordHint password={password}/>
                <FormInput
                  onEnterKey={sendRegistration}
                  label={t('COMMON.PASSWORD_CONFIRM')}
                  type={'password'}
                  validation={{
                    group: 'registrationGroup',
                    rules: [
                      {type: 'required', message: t('ERROR_MSG.REQUIRED')},
                      {
                        type: 'compare',
                        message: t('ERROR_MSG.WRONG_PASSWORD_CONFIRM'),
                        comparisonTarget: comparePassword
                      },
                    ]
                  }}
                />
                <FormInput
                  onEnterKey={sendRegistration}
                  label={t('COMMON.REFERRER_CODE')}
                  type={'text'}
                  onValueChanged={({value}) => {
                    setReferralCode(value);
                  }}
                />
                <div className={'policy-agreement'}>
                  <CheckBox
                    name={'registration-agreement-checkbox'}
                    validationMessageMode={'always'}
                    defaultValue={false}
                    text={''}
                  >
                    <Validator validationGroup={'registrationGroup'}>
                      <CompareRule message="" comparisonTarget={checkComparison}/>
                    </Validator>
                  </CheckBox>
                  <div className={'registration-agreement-text'}>
                    <span>{t('CHECKBOX.PRE_CONFIRMATION_TEXT')}</span>
                    <a
                      className={'terms-link'}
                      target={'_blank'}
                      href={conditionLinks.termsAndConditions}
                    >
                      {t('CHECKBOX.CONFIRMATION_LINK')}
                    </a>
                    <span>{t('CHECKBOX.POST_CONFIRMATION_TEXT')}</span>
                  </div>
                </div>
                <RoundButton
                  text={t('COMMON.REGISTRATION')}
                  icon={''}
                  onClick={sendRegistration}
                  customClass={'size14 new-button'}
                />
              </>
            )}
          </div>
        )}

        <div className={'bottom-block'}>
          <p className={'secondary-text'}>{t('COMMON.CHOOSE_LANG')}</p>
          <LanguageSwitcher/>
          <p className={'secondary-text'}>
            {t('COMMON.ACC_EXIST')} <Link className="link" to="/login">{t('COMMON.LOGIN')}</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default NewRegister;
