import React, {useEffect, useRef, useState} from 'react';
import DataGrid from 'devextreme-react/data-grid';
import RoundButton from '../buttons/round-button/round-button';
import {DateBox, Popover} from 'devextreme-react';
import {getExportFileName, prepareGridExport} from 'utils/functions';
import {getFilter, prepareDatesForGridExport} from 'services/dataSourceFilterUtils';
import {getDataSource} from 'services/dataSource';
import {useTranslation} from 'react-i18next';

const ExportDataGrid = React.forwardRef(({
																					 isShowExportDatePopup,
																					 closeExportDatePopup,
																					 getGridParams,
																					 filterFieldName = 'InsDate',
																					 exportCaptions
																				 }, ref) => {
	const exportRef = useRef(null);
	const emptyExportRef = useRef(null);
	const [exportDataSource, setExportDataSource] = useState(null);
	const [emptyDataSource, setEmptyDatasource] = useState(null);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const {t} = useTranslation();

	const prepareDatasource = () => {
		const gridInstance = ref.current.instance;

		const paramObj = getGridParams();

		const apiFilters = getFilter(gridInstance.getCombinedFilter(), paramObj.fieldsSettings) || {};

		paramObj.defFilter = Object.assign(paramObj.defFilter, {
			...apiFilters,
			[filterFieldName]: {
				between: prepareDatesForGridExport(startDate, endDate),
			},
		});

		const merchantsDataSource = getDataSource(paramObj);

		setExportDataSource(merchantsDataSource);
	}

	useEffect(() => {
		if (exportDataSource) {
			closeExportDatePopup();
		}
	}, [exportDataSource]);

	const onEmptyContentReady = () => {
		emptyExportRef.current.instance.exportToExcel();
	}

	const onContentReadyExport = () => {
		const exportInstance = exportRef.current.instance;

		if (exportInstance.totalCount() === 0) {
			setEmptyDatasource([
				{
					'No Data': '',
				}
			]);
		} else {
			prepareGridExport(exportInstance, ref.current.instance, exportCaptions, t);
			exportInstance.exportToExcel();
		}
	}

	const onExporting = (e) => {
		e.fileName = getExportFileName();
	}

	return (
		<div>
			{exportDataSource && (
				<DataGrid
					id={'grid-acc-statement-export'}
					visible={false}
					ref={exportRef}
					onContentReady={onContentReadyExport}
					onExporting={onExporting}
					dataSource={exportDataSource}
				/>
			)}
			{emptyDataSource && (
				<DataGrid
					id={'empty-grid-export'}
					visible={false}
					ref={emptyExportRef}
					onContentReady={onEmptyContentReady}
					onExporting={onExporting}
					dataSource={emptyDataSource}
				/>
			)}
			<div id={'calendar-wrapper'}>
				<Popover
					minWidth={270}
					target={'.dx-toolbar-after'}
					visible={isShowExportDatePopup}
					onHiding={closeExportDatePopup}
				>
					<div className={'export-fields'}>
						<div className="dx-field">
							<div className="dx-field-label">{t('COMMON.DATE_FROM')}</div>
							<div className="dx-field-value">
								<DateBox
									value={startDate}
									max={endDate}
									useMaskBehavior={true}
									displayFormat={'dd.MM.yy'}
									onValueChanged={({value}) => {
										setStartDate(value);
									}}
								/>
							</div>
						</div>
						<div className="dx-field">
							<div className="dx-field-label">{t('COMMON.DATE_TO')}</div>
							<div className="dx-field-value">
								<DateBox
									value={endDate}
									min={startDate}
									useMaskBehavior={true}
									displayFormat={'dd.MM.yy'}
									onValueChanged={({value}) => {
										setEndDate(value);
									}}
								/>
							</div>
						</div>
						<div className={'export-button'}>
							<RoundButton
								width={222}
								text={t('COMMON.EXPORT')}
								onClick={prepareDatasource}
								customClass={'blue'}
							/>
						</div>
					</div>
				</Popover>
			</div>
		</div>
	);
});

export default ExportDataGrid;