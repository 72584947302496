import React from 'react';
import {Popup} from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import {vars} from 'utils/variables';

import './flexi-popup.scss';

const {POPUP_LOGO_FILE_NAME} = vars;

class FlexiPopupComponent extends React.PureComponent {
	render() {
		const {
			container,
			position = {
				'my': 'center',
				'at': 'center',
				'of': '#login-page',
				collision: 'fit',
				boundaryOffset: '20 40',
			},
			height = 'auto',
			maxHeight = '80%',
			visible,
			channelId = 0,
			maxWidth = 500,
			title = '',
			handleClose,
			children
		} = this.props;

		return (
			<Popup
				visible={visible}
				container={container}
				dragEnabled={false}
				closeOnOutsideClick={false}
				showTitle={false}
				maxWidth={maxWidth}
				height={height}
				maxHeight={maxHeight}
				onHiding={handleClose}
				position={position}
			>
				<div
					className={'flexi-popup-close'}
					onClick={handleClose}
				>
					<i className={'mdi mdi-close-circle-outline'}/>
				</div>
				<div className={'flexi-popup-content'}>
					<div className={'flexi-popup-logo'}>
						<img
							src={`./images/sharpay/${POPUP_LOGO_FILE_NAME[channelId]}`}
							className="wrapper-class-name-popup"
						/>
					</div>
					<div className={'flexi-popup-head'}>
						<div className={'flexi-popup-title'}>
							{title}
						</div>
					</div>
					<ScrollView
						className={'popup-scrollable-content'}
						height={'100%'}
						width={'100%'}
					>
						<div className={'flexi-popup-component'}>
							{children}
						</div>
					</ScrollView>
				</div>
			</Popup>
		);
	}
}

export default FlexiPopupComponent;
