import React, {Component} from 'react';
import {capitalize} from 'utils/functions';
import {userSaveProfileField, userProfileConfirmFieldByCode} from 'services/async';
import {vars} from 'utils/variables';
import {notifyApp} from 'utils/notifyWrapper';
import SaveAndCloseButtons from './SaveAndCloseButtons';

const initialState = {
	isEdit: false,
	stateValue: null,
};

export default function withButtons(WrappedComponent) {
	return class extends Component {
		constructor(props) {
			super(props);

			this.state = initialState;

			this.el = React.createRef();

			this.onSave = this.onSave.bind(this);
			this.otpSaveButtonHandler = this.otpSaveButtonHandler.bind(this);
		}

		saveButtonHandler = async (event) => {
			const {stateValue} = this.state;

			if (event) {
				event.currentTarget.classList.add('no-touch');
			}

			if (stateValue) {
				const {name} = this.props;
				const apiName = capitalize(name);
				const params = {
					operation: `UserProfile/Mod${apiName}`,
					data: {
						Params: {
							[apiName]: stateValue
						}
					}
				};
				this.onSave(params).catch((error) => {
					notifyApp(error);
				});
			} else {
				this.closeButtonHandler();
			}
		};

		selectText = ({event}) => {
			event.target.select();
		};

		async onSave(params) {
			const input = this.el.current.instance;
			const {showOtpPopup} = this.props;
			const {NOTIFY} = vars;
			const request = await userSaveProfileField(params);

			input.off('focusIn', this.selectText);

			if (request.isSuccess) {
				showOtpPopup({
					onSubmit: async (otpCode) => {
						await this.otpSaveButtonHandler(otpCode);
					},
					onResend: this.saveButtonHandler,
					sendChannelId: request.response.SendChannelID,
					infoText: request.response.InfoText,
				});
			} else {
				const saveBtn = document.querySelector('.no-touch');

				if (saveBtn) {
					saveBtn.classList.remove('no-touch');
				}

				notifyApp(request.error, NOTIFY.ERROR);
			}
		}

		closeButtonHandler = () => {
			const input = this.el.current.instance;

			input.off('focusIn', this.selectText);

			this.setState({
				isEdit: false,
				stateValue: null,
			});
		};

		editButtonHandler = () => {
			const input = this.el.current.instance;

			input.on('focusIn', this.selectText);
			input.focus();

			this.setState({
				isEdit: true,
			});
		};

		async otpSaveButtonHandler(otpCode){
			const {name, updateState, hideOtpPopup} = this.props;
			const apiName = capitalize(name);
			const otpRequest = await userProfileConfirmFieldByCode(otpCode, apiName);

			if (otpRequest.isSuccess) {
				this.setState(initialState);
				hideOtpPopup();
				updateState();
			} else {
				notifyApp(otpRequest.error);
			}
		};

		onChange = component => {
			this.setState({
				stateValue: component.event.target.value,
			});
		};

		render() {
			const {isEdit, stateValue} = this.state;
			const {placeholder, value} = this.props;
			const actualValue = stateValue ? stateValue : value;

			return (
				<div>
					<span className={'settings-section_label'}>
						<span>{placeholder}</span>

						{isEdit ? (
							<SaveAndCloseButtons
								saveButtonHandler={this.saveButtonHandler}
								closeButtonHandler={this.closeButtonHandler}
							/>
						) : (
							<span className={'settings-section_edit'} onClick={this.editButtonHandler}>
									<i className={'mdi mdi-pencil'}/>
							</span>
						)}
					</span>
					<WrappedComponent
						ref={this.el}
						readOnly={!isEdit}
						value={actualValue}
						onValueChanged={this.onChange}
					/>
				</div>
			);
		}
	}
}
