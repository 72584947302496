import React, {useEffect, useRef, useState} from 'react';
import {Button, NumberBox, SelectBox, TextBox, Validator} from 'devextreme-react';
import {RequiredRule} from 'devextreme-react/validator';
import Loader from 'components/loader/loader';
import {notifyApp} from 'utils/notifyWrapper';
import {apiRequest, getAllowedPaymentParams, paymentConfirm, resendPaymentOtp} from 'services/async';
import {useTranslation} from 'react-i18next';
import {vars} from 'utils/variables';
import classNames from 'classnames';
import validationEngine from 'devextreme/ui/validation_engine';

const {SUCCESS_API_CODE, PAYMENT, NOTIFY} = vars;

const ServiceFields = (props) => {
  const {
    isShowLoader, paymentMethodValueId, account, onClickCancel, showOtpPopup, hideOtpPopup, changePaymentStatus
  } = props;
  const [servicesList, setServicesList] = useState(null);
  const [selectedService, setSelectedService] = useState({ID: null});
  const [amount, setAmount] = useState(null);
  const [sender, setSender] = useState(null);
  const [fee, setFee] = useState(null);
  const [foreignCurrencyID, setForeignCurrencyID] = useState({ID: null});
  const {t} = useTranslation();

  useEffect(() => {
    if (!servicesList) {
      loadServicesList().then((response) => {
        setServicesList(response);
      }).catch((error) => {
        notifyApp(error);
      });
    } else if (!servicesList && paymentMethodValueId === -1) {
      setServicesList([]);
    }
  }, [servicesList]);

  const loadServicesList = async () => {
    const allowedServicesRequest = await apiRequest({
      operation: 'Payment/AllowedServices',
      data: {
        Params: {
          PaymentSystemTypeID: paymentMethodValueId,
        }
      }
    });

    if (allowedServicesRequest.data.ResponseCode === SUCCESS_API_CODE) {
      return allowedServicesRequest.data.Response.AllowedServices;
    } else {
      throw new Error(allowedServicesRequest.data.ResponseText);
    }
  }

  const getSenderLabel = () => {
    const labelBase = 'OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.';
    return t(labelBase + (selectedService.CategoryID === 6 ? 'RECIPIENT_EMAIL' : 'RECIPIENT'));
  }

  const getSenderPlaceholder = () => {
    return `${t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.EXAMPLE')} ${selectedService.ExampleAccount}`;
  }

  const onSenderChange = ({value}) => {
    setSender(value);
  }

  const getAllowedParams = async () => {
    if (sender) {
      const request = await getAllowedPaymentParams({
          AccountID: account.ID,
          PaymentTypeID: PAYMENT.PAYMENT_TYPE_PAYOUT,
          ForeignPaymentSystemTypeID: paymentMethodValueId,
          ForeignAccountCode: sender,
        }
      );

      if (request.isSuccess) {
        // low logic code according to specification
        if (paymentMethodValueId === 20) {
          const currency = request.response.find((item) => item.ForeignCurrencyID === 840);
          setForeignCurrencyID(currency.ForeignCurrencyID);
        }
      } else {
        notifyApp(request.error);
      }
    }
  }

  useEffect(() => {
    getAllowedParams().catch((error) => {
      notifyApp(error);
    })
  }, [sender]);

  const onAmountChange = ({value}) => {
    setAmount(value);
  }

  const clearAmount = () => {
    setAmount(null);
  }

  const calculateServiceFee = async () => {
    if (sender && amount) {
      const request = await apiRequest({
        operation: 'PaymentFee/Calculate',
        data: {
          Params: {
            PaymentSystemTypeID: paymentMethodValueId,  // выбранная платежная система PaymentSystemTypeID
            PaymentTypeID: PAYMENT.PAYMENT_TYPE_PAYOUT,
            AccountID: account.ID,  // ID выбранного счета
            Amount: amount,  // = из поля "Сума віддається"
            ForeignAccountCode: sender,  // из поля "Рахунок отримувача"
            ForeignCurrencyID: foreignCurrencyID,  // Выбранная валюта ForeignCurrencyID из поля "Сума отримується"
            ServiceID: selectedService.ID,  // из поля "Сервіс"
          }
        }
      });

      if (request.data.ResponseCode === SUCCESS_API_CODE) {
        setFee(request.data.Response);
      } else {
        clearAmount(); // on fee error occurred deny create payment
        notifyApp(request.data.ResponseText);
      }
    }
  }

  useEffect(() => {
    calculateServiceFee().catch((error) => {
      notifyApp(error);
    });
  }, [amount, sender]);

  const getFeeAmount = () => {
    return fee ? fee.FeeAmount : null;
  }

  const getCreditedAmount = () => {
    return fee ? fee.ServiceAmount + ' ' + fee.ServiceCurrency : '';
  }

  const onContinue = async ({component}) => {
    const result = await validationEngine.validateGroup('service-group');

    if (result.isValid) {
      component.option('disabled', true);

      const transactionData = {
        PaymentSystemTypeID: paymentMethodValueId,
        PaymentTypeID: PAYMENT.PAYMENT_TYPE_PAYOUT,
        AccountID: account.ID,
        Amount: amount,
        ForeignAccountCode: sender,
        ForeignCurrencyID: foreignCurrencyID,
        CustomParams: {
          ServiceID: selectedService.ID,
        },
      };

      try {
        // transaction request
        const request = await apiRequest({
          operation: 'Payment/Payout',
          data: {
            Params: transactionData,
          }
        });

        if (request.data.ResponseCode === SUCCESS_API_CODE) {
          const response = request.data.Response;

          showOtpPopup({
            onSubmit: async (otpCode) => {
              const confirmRequest = await paymentConfirm({
                PaymentID: response.ID,
                Code: otpCode,
              });

              if (confirmRequest.isSuccess) {
                // done
                hideOtpPopup();
                changePaymentStatus({
                  text: t('OPERATIONS.PAYMENT_SUCCESS_TEXT')
                });
              } else {
                notifyApp(confirmRequest.error);
              }
            },
            onResend: async () => {
              const resendRequest = await resendPaymentOtp({
                PaymentID: response.ID,
              });

              if (resendRequest.isSuccess) {
                notifyApp(t('COMMON.SENT'), NOTIFY.SUCCESS);
              } else {
                notifyApp(resendRequest.error);
              }
            },
            sendChannelId: response.SendChannelID,
            infoText: response.InfoText,
          });
        } else {
          notifyApp(request.data.ResponseText);
        }

        console.log('request');
      } catch (error) {
        notifyApp(error);
      } finally {
        component.option('disabled', false);
      }
    } else {
      result.brokenRules[0].validator.focus();
    }
  }

  return (
    <div className={'services-fields'}>
      <Loader
        isShowLoader={isShowLoader}
      />
      <div className={'operations-row operations-row-services'}>
        <div className={'operations-row-inner'}>
          <div className={'operations-title'}>
            {t('OPERATIONS_PAGE.RIGHT_BLOCK.SERVICE')}
          </div>
          <div className={'operations-fields-wrapper'}>
            <div className={'method-select-wrapper'}>
              <SelectBox
                items={servicesList}
                displayExpr={'Name'}
                valueExpr={'ID'}
                value={selectedService.ID}
                name={'foreignAccountCode'}
                className={'operation-input'}
                stylingMode={'outlined'}
                onValueChanged={({value}) => {
                  const selectedValue = servicesList.find((item) => item.ID === value);
                  console.log(selectedValue);
                  setSelectedService(selectedValue);
                }}
              >
                <Validator validationGroup={'service-group'}>
                  <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                </Validator>
              </SelectBox>
            </div>
          </div>
        </div>
      </div>

      {selectedService.ID ? (
        <>
          <div className={'operations-row'}>
            <div className={'operations-row-inner'}>
              <div className={'operations-title'}>{t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.DETAILS')}</div>
              <div className={'operations-fields-wrapper'}>
                <div className={'operations-fieldset'}>
                  <div className={'operations-field'}>
                    <div className="dx-field-label">
                      {t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.SENDER')}
                    </div>
                    <div className={'dx-field-value'}>
                      <TextBox
                        className={'operation-input'}
                        defaultValue={account.Code}
                        stylingMode={'outlined'}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
                <div className={'operations-fieldset'}>
                  <div className={'operations-field'}>
                    <div
                      className={'dx-field-label'}>{`${getSenderLabel()}*`}</div>
                    <div className={'dx-field-value'}>
                      <TextBox
                        placeholder={getSenderPlaceholder()}
                        className={'operation-input'}
                        stylingMode={'outlined'}
                        value={sender}
                        onValueChanged={onSenderChange}
                      >
                        <Validator validationGroup={'service-group'}>
                          <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                        </Validator>
                      </TextBox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={'operations-row'}>
            <div className={'operations-row-inner'}>
              <div className={'operations-title'}>
                {t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.TRANSFER_AMOUNT')}
              </div>
              <div className={'operations-fields-wrapper'}>
                <div className={'operations-fieldset'}>
                  <div className={'operations-field'}>
                    <div className='dx-field-label'>
                      {`${t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.AMOUNT_PAYABLE')}*`}
                    </div>
                    <div className={'dx-field-value field-value-relative'}>
                      <span className={'field-value-currency'}>
										    {account.CurrencySymbol}
									    </span>
                      <NumberBox
                        step={0}
                        className={'operation-input'}
                        stylingMode={'outlined'}
                        value={amount}
                        onValueChanged={onAmountChange}
                      >
                        <Validator validationGroup={'service-group'}>
                          <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                        </Validator>
                      </NumberBox>
                    </div>
                  </div>
                </div>

                <div className={'operations-fieldset'}>
                  <div className={'operations-field'}>
                    <div className="dx-field-label">
                      {t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.FEE')}
                    </div>
                    <div className="dx-field-value field-value-relative">
                      <span className={classNames('field-value-currency', 'currency-read-only')}>
                        {account.CurrencySymbol}
                      </span>
                      <NumberBox
                        className={'operation-input'}
                        value={getFeeAmount()}
                        readOnly={true}
                        stylingMode={'outlined'}
                      >
                        <Validator validationGroup={'service-group'}>
                          <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                        </Validator>
                      </NumberBox>
                    </div>
                  </div>
                </div>

                <div className={'operations-fieldset'}>
                  <div className={'operations-field'}>
                    <div className="dx-field-label">
                      {t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.AMOUNT_CREDITED')}
                    </div>
                    <div className="dx-field-value">
                      <TextBox
                        className={'operation-input'}
                        value={getCreditedAmount()}
                        readOnly={true}
                        stylingMode={'outlined'}
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className={'operations-buttons'}>
            <div className={'buttons-wrapper'}>
              <Button
                width={120}
                text={t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.CANCEL')}
                type={'normal'}
                stylingMode={'contained'}
                onClick={onClickCancel}
              />
              <Button
                width={120}
                text={t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.CONTINUE')}
                type={'normal'}
                stylingMode={'contained'}
                onClick={onContinue}
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default ServiceFields;