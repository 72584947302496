import React, {useState} from 'react';
import RoundIconInput from 'components/inputs/round-input/round-icon-input';

const useRoundIconInput = ({
                             type,
                             inputType,
                             isNavigationIcons = false,
                             withTopLabel = true,
                             id,
                             iconName,
                             iconFill,
                             name,
                             placeholder,
                             initValue = '',
                             height = 40,
                             customClass,
                             validator = null,
                           }) => {
  const [value, setValue] = useState(initValue);

  const input = <RoundIconInput
    isNavigationIcons={isNavigationIcons}
    iconName={iconName}
    iconFill={iconFill}
    name={name}
    id={id}
    text={value}
    onValueChanged={({value}) => setValue(value)}
    mode={type}
    inputType={inputType}
    height={height}
    customClass={customClass}
  >
    {validator}
  </RoundIconInput>;

  const resultComponent = withTopLabel ? (
    <div>
      <label htmlFor={id} className={'inp-label'}>
        {placeholder}
      </label>
      {input}
    </div>
  ) : input;

  return [value, resultComponent];
};

export default useRoundIconInput;
