import React from 'react';
import classNames from 'classnames';
import ScrollView from 'devextreme-react/scroll-view';
import {withTranslation} from "react-i18next";

const getTranslation = (t, component) => {
	const componentKey = component.toUpperCase();
	const translationKey = 'SETTINGS.LEFT_BLOCK';
	return {
		title: t(`${translationKey}.${componentKey}.TITLE`),
		description: t(`${translationKey}.${componentKey}.DESCRIPTION`),
	};
};

const createMarkup = (text) => {
	return {__html: text};
};

const categoryList = ({active, category, onItemClick, t}) => {
	return (
		<ScrollView>
			{category.map((item) => {
				const itemClasses = {
					'active-category': item.component === active,
					'settings-category': true
				};

				const translation = getTranslation(t, item.component);

				return (
					<div
						id={item.component}
						key={item.component}
						className={classNames(itemClasses)}
						onClick={() => onItemClick(item.component)}
					>
						<div className={'settings-category_icon'}>
							<i className={item.icon} />
						</div>
						<div className={'settings-category_desc'}>
							<div className={'settings-category_title'}>
								{translation.title}
							</div>
							<div className={'settings-category_text'} dangerouslySetInnerHTML={createMarkup(translation.description)}/>
						</div>
					</div>
				);
			})}
		</ScrollView>
	);
};

export default withTranslation()(categoryList);
