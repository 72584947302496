import React, {useEffect, useState} from 'react';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {useTranslation} from 'react-i18next';
import {RoundButton} from 'components/buttons/round-button';
import EditMerchant from "./edit-merchant";
import ScrollView from 'devextreme-react/scroll-view';
import {vars} from 'utils/variables';
import '../settings.scss';
import './sciapi.scss';

const {SUCCESS_API_CODE} = vars;

const SciApi = () => {
	const {t} = useTranslation();
	const [currentMerchant, setCurrentMerchant] = useState(null);
	const [merchants, setMerchants] = useState([]);
	const [updateMerchantList, setUpdateMerchantList] = useState(false);

	useEffect(() => {
		const fetchMerchantsList = async () => {
			const response = await apiRequest({
				operation: 'MerchantApiProfile/List',
				data: {
					Page: -1,
				}
			});

			if (response.data.ResponseCode === SUCCESS_API_CODE) {
				setMerchants(response.data.Response.MerchantApiProfile);
			} else {
				notifyApp(response.data.ResponseText);
			}
		};

		fetchMerchantsList().catch((e) => {
			console.warn(e);
		});
	}, [updateMerchantList]);

	return (
		<div className={'settings-wrapper sciapi-wrapper'}>
			<div className={'settings-header'}>
				<div className={'settings-header_inner'}>
					<div className={'settings-header_text'}>
						<i className={'mdi mdi-cart-plus'}/>
						<span>{t('SETTINGS.LEFT_BLOCK.SCIAPI.TITLE')}</span>
					</div>
				</div>
			</div>
			<div className="settings-splitter"/>
			<div className={'sciapi-inner'}>
				{currentMerchant ? (
					<EditMerchant
						merchant={currentMerchant}
						cancelHandler={setCurrentMerchant}
						updateValue={updateMerchantList}
						updateCallback={setUpdateMerchantList}
					/>
				) : (
					<div className={'sciapi-inner-wrapper'}>
						<ScrollView>
							<div className={'sciapi-inner-list'}>
								{merchants.map((merchant) => {
									return (
										<div
											key={merchant.ID}
											className={'merchant-block merchant-simple'}
										>
											{merchant.Name}
											<RoundButton
												name={'edit-sci-api'}
												text={'edit'}
												icon={'mdi mdi-pencil'}
												onClick={() => {
													merchant.Title = 'Редактирование мерчанта';
													setCurrentMerchant(merchant);
												}}
												customClass={'blue size10'}
												width={'100%'}
												height={30}
											/>
										</div>
									);
								})}
							</div>
						</ScrollView>
						<div className={'add-new'}>
							<div
								onClick={() => {
									setCurrentMerchant({Title: 'Создание мерчанта', IsNew: true});
								}}
								className={'plus-button mdi mdi-plus'}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default SciApi;
