import React, {useEffect, useState} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {changeActiveScreen} from 'services/settings/actions';
import {userProfileGetList} from 'services/async';
import StartPanel from 'components/start-panel/start-panel';
import {descriptions, vars} from 'utils/variables';

import './start-verification.scss';


const {SETTINGS_SCREENS, PROFILE: {IS_PHONE_VERIFY, IS_EMAIL_VERIFY}} = vars;

const StartVerification = (props) => {
  const {t} = useTranslation();

  const pushToSettings = () => {
    const destinationId = 'settings';
    props.history.push(`/${destinationId}`);
  };

  const goToVerification = () => {
    props.changeActiveScreen({
      screen: SETTINGS_SCREENS.VERIFICATION,
    });
    pushToSettings();
  };

  const [userProfile, setUserProfile] = useState({
    vmVerificationStatusKindID: -1,
    isPhoneConfirmed: -1,
    isEmailConfirmed: -1,
  });

  const getLinkText = (vmVerificationStatusKindID = -1) => {
    switch (vmVerificationStatusKindID) {
      case 2:
        return 'MAIN.START_PANEL.START_VERIFICATION';
      case 3:
        return null;
      case 4:
        return 'MAIN.START_PANEL.CONTINUE_VERIFICATION';
      case 5:
        return null;
      default:
        return null;
    }
  }

  useEffect(() => {
    const getUserProfile = async () => {
      const request = await userProfileGetList();

      if (request.isSuccess) {
        const {
          VmVerificationStatusKindID,
          IsPhoneConfirmed,
          IsEmailConfirmed,
        } = request.responseObj;

        return setUserProfile({
          vmVerificationStatusKindID: VmVerificationStatusKindID,
          isPhoneConfirmed: IsPhoneConfirmed,
          isEmailConfirmed: IsEmailConfirmed,
        });
      }
    };

    getUserProfile().catch((e) => {
      console.error(e);
    });
  }, []);

  const buttonPartText = userProfile.isEmailConfirmed !== IS_EMAIL_VERIFY ? 'EMAIL' : 'PHONE';
  const descriptionPartText = userProfile.isEmailConfirmed !== IS_EMAIL_VERIFY ? 'EMAIL_WHOM' : 'PHONE_WHOM';

  return (
    <div
      id={'tile-view'}
      className={'start-verification'}
    >
      {userProfile.vmVerificationStatusKindID > 1 && (
        <StartPanel
          title={(userProfile.vmVerificationStatusKindID === 2 || userProfile.vmVerificationStatusKindID === 4) ?
            'MAIN.START_PANEL.TITLE' :
            ''}
          description={[
            `MAIN.START_PANEL.${descriptions[userProfile.vmVerificationStatusKindID]}`,
          ]}
          onClick={goToVerification}
          linkText={getLinkText(userProfile.vmVerificationStatusKindID)}
        />
      )}

      {(userProfile.isEmailConfirmed !== IS_EMAIL_VERIFY || userProfile.isPhoneConfirmed !== IS_PHONE_VERIFY) && (
        <div className={'verification-panel'}>
          <div className={'verification-title'}>
            {t('MAIN.CONTACT_DETAILS.TITLE')}
          </div>
          <div className={'verification-description'}>
            <span className={'verification-account'}>{t('MAIN.CONTACT_DETAILS.DESCRIPTION_START')}</span>
            <span className={'verification-account'}>{t(`MAIN.CONTACT_DETAILS.${descriptionPartText}`)}</span>
            <span className={'verification-account'}>{t('MAIN.CONTACT_DETAILS.DESCRIPTION_STOP')}</span>
          </div>
          <div
            className={'verification-start'}
            onClick={pushToSettings}
          >
            {t('MAIN.CONTACT_DETAILS.VERIFY') + t(`MAIN.CONTACT_DETAILS.${buttonPartText}`)}
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeActiveScreen: (screen) => dispatch(changeActiveScreen(screen)),
});

export default compose(withRouter, connect(null, mapDispatchToProps))(StartVerification);
