import React from 'react';
import PropTypes from 'prop-types';
import StatusScreen from './status-screen';
import TransactionScreen from './transaction-screen';
import {vars} from 'utils/variables';

const {PAYMENT} = vars;

const RightForm = (props) => {
	const {
		paymentTypeId,
		account,
		paymentMethodValueId,
		changePaymentMethod,
		status,
    accountList,
	} = props;

	return (
		<div className={'operation-form-wrapper'}>
			{paymentTypeId === PAYMENT.PAYMENT_TYPE_INIT ? (
				<StatusScreen
					text={status}
				/>
			) : (
				<TransactionScreen
          accountList={accountList}
					account={account}
					paymentTypeId={paymentTypeId}
					paymentMethodValueId={paymentMethodValueId}
					changePaymentMethod={changePaymentMethod}
				/>
			)}
		</div>
	);
};

RightForm.propTypes = {
	paymentTypeId: PropTypes.number,
};

RightForm.defaultProps = {
	paymentTypeId: 0
};

export default RightForm;
