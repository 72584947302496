import React, {useEffect, useState} from 'react';
import RoundButton from 'components/buttons/round-button/round-button';
import FormInput from 'components/inputs/form-input/form-input';
import {useTranslation} from 'react-i18next';
import {vars} from 'utils/variables';
import DynamicPasswordHint from 'components/dynamic-password-hint/dynamic-password-hint';
import validationEngine from 'devextreme/ui/validation_engine';
import {sha512} from 'utils/functions';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';

const {REG_EXP} = vars;

const RecoveryConfirmForm = (props) => {
  const {urlid, nextStep} = props;
  const {t} = useTranslation();

  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const passwordComparison = () => {
    return newPassword;
  }

  const sendConfirmRequest = async () => {
    const result = validationEngine.validateGroup('recoveryGroup');

    if (result.isValid) {
      const hashedPassword = await sha512(newPassword);

      if (hashedPassword) {
        const response = await apiRequest({
          operation: 'Login/RestorePasswordConfirm',
          data: {
            Params: {
              Code: otp,
              NewPassword: hashedPassword,
              Urlid: urlid,
            }
          }
        });

        if (response.data.ResponseCode === '000') {
          nextStep();
        } else {
          notifyApp(response.data.ResponseText);
        }
      } else {
        notifyApp('Client WebAPI error');
      }
    }
  }

	return (
		<div className={'password-recovery-component'}>
			<div className={'component-subtitle'}>{t('PASSWORD_RECOVERY_SUBTITLE')}</div>
      <FormInput
        type={'text'}
        label={t('PASSWORD_RECOVERY_OTP')}
        onValueChanged={({value}) => {
          setOtp(value);
        }}
        validation={{
          group: 'recoveryGroup',
          rules: [
            {type: 'required', message: t('ERROR_MSG.REQUIRED')},
          ]
        }}
      />
      <FormInput
        type={'password'}
        label={t('PASSWORD_RECOVERY_NEW_PASSWORD')}
        onInput={({event}) => {
          setNewPassword(event.target.value);
        }}
        validation={{
          group: 'recoveryGroup',
          rules: [
            {type: 'required', message: t('ERROR_MSG.REQUIRED')},
            {type: 'pattern', message: t('ERROR_MSG.NOT_ALL_CONDITIONS'), pattern: REG_EXP.PASSWORD_PATTERN},
          ]
        }}
      />
      <DynamicPasswordHint password={newPassword}/>
      <FormInput
        type={'password'}
        label={t('PASSWORD_RECOVERY_COMPARE_PASSWORD')}
        validation={{
          group: 'recoveryGroup',
          rules: [
            {type: 'required', message: t('ERROR_MSG.REQUIRED')},
            {type: 'compare', message: t('ERROR_MSG.WRONG_PASSWORD_CONFIRM'), comparisonTarget: passwordComparison},
          ]
        }}
      />
			<RoundButton
				name={'recovery-change-password'}
				text={t('PASSWORD_RECOVERY_CHANGE_PASSWORD')}
				icon={null}
				onClick={sendConfirmRequest}
				customClass={'size14 new-button'}
			/>
		</div>
	);
};

export default RecoveryConfirmForm;
