import {actionTypes} from './appActions';

const initialState = {
	visible: false,
	infoText: '',
	sendChannelId: 1,
};

export const appReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case actionTypes.SHOW_OTP_POPUP:
			return {
				...state,
				...payload,
				visible: true,
			}
		case actionTypes.HIDE_OTP_POPUP:
			return initialState;
		default:
			return state;
	}
}