import React from 'react';
import {LoadIndicator} from 'devextreme-react/load-indicator';

const InitLoader = () => {
	return (
		<div className={'init-loader'}>
			<LoadIndicator/>
		</div>
	);
};

export default InitLoader;