import React, {useEffect, useRef, useState} from 'react';
import ItemTemplate from 'components/operations/crypto/crypto-currency-templates/item-template';
import FieldTemplate from 'components/operations/crypto/crypto-currency-templates/field-template';
import {copyTextToClipboard} from 'utils/functions';
import {SelectBox} from 'devextreme-react';
import classNames from 'classnames/bind';
import QRCode from 'react-qr-code';
import {useTranslation} from 'react-i18next';
import {notifyApp} from 'utils/notifyWrapper';
import {apiRequest} from 'services/async';
import {vars} from 'utils/variables';
import Loader from 'components/loader/loader';

import './crypto-income-fields-hook.scss';

const {SUCCESS_API_CODE, PAYMENT: {PAYMENT_TYPE_INCOME}} = vars;

const CryptoIncomeFieldsHook = ({account, isShowLoader, toggleLoader, paymentMethodValueId}) => {
	const {t} = useTranslation();

	const [summary, setSummary] = useState(null);
	/*const [summary, setSummary] = useState({
		Address: '133j54j6k54nfdnjernr',
		Memo: 'merkjejvsdir4i54395jri',
	});*/
	const [isCopying, setIsCopying] = useState(null);
	const [checkedRadio, setCheckedRadio] = useState('address');
	const address = useRef(null);
	const memo = useRef(null);

	const [cryptoCurrency, setCryptoCurrency] = useState([]);
	const [selectedCryptoCurrency, setSelectedCryptoCurrency] = useState(null);

	console.group('SUMMARY');
	console.log(summary);
	console.groupEnd();

	const getCryptoAddress = async () => {
		//setIsLoading(true);
		console.group('get crypto address');
		console.log(selectedCryptoCurrency);
		console.log(account);
		console.groupEnd();

		const cryptoAddress = await apiRequest({
			operation: 'CryptoPayment/GetCryptoAddress',
			data: {
				Params: {
					AccountID: account.ID, //	+	Счет, который пополняется
					CurrencyID: selectedCryptoCurrency.CurrencyID, //	+	Выбранная криптовалюта
					BlockchainID: selectedCryptoCurrency.BlockchainID, //	+	Блокчейн через который выполнять платеж
				}
			}
		});

		if (cryptoAddress.data['ResponseCode'] === SUCCESS_API_CODE) {
			return Promise.resolve(cryptoAddress.data['Response']);
		} else {
			return Promise.reject(cryptoAddress.data['ResponseText']);
		}
	};

	useEffect(() => {
		if (selectedCryptoCurrency) {
			toggleLoader();
			getCryptoAddress().then((summaryResult) => {
				setSummary(summaryResult);
			}).catch((error) => {
				notifyApp(error);
				setSummary(null);
			}).finally(() => {
				toggleLoader(true);
			});
		}
	}, [selectedCryptoCurrency]);

	const onRadioChange = (event) => {
		const value = event.target.value;
		setCheckedRadio(value);
	};

	const getCryptoCurrency = async () => {
		const request = await apiRequest({
			operation: 'CryptoPayment/GetCryptoCurrency',
			data: {
				Params: {
					AccountID: account.ID, // - ID выбранного счета
					PaymentTypeID: PAYMENT_TYPE_INCOME, // - ID выбранного типа операции (1- Перевод, 2 Пополнение)
					ForeignPaymentSystemTypeID: paymentMethodValueId, // - ID выбранной платежной системы (15-Crypto)
				},
			},
		});

		if (request.data['ResponseCode'] === SUCCESS_API_CODE) {
			return Promise.resolve(request.data.Response.CryptoCurrency);
		} else {
			return Promise.reject(request.data.ResponseText);
		}
	};

	useEffect(() => {
		if (cryptoCurrency.length === 0) {
			getCryptoCurrency().then(response => {
				console.group('resolve CryptoCurrency');
				console.log(response);
				console.groupEnd();

				const cryptoCurrencies = [];

				response.forEach((item, index) => {
					if (item.DisplayBuyCurrencyRate) {
						cryptoCurrencies.push({
							...item,
							PaymentTypeID: PAYMENT_TYPE_INCOME,
							UniqID: `${item.BlockchainID}-${index}`,
							AccountCurrencySymbol: account.CurrencySymbol,
						});
					}
				});

				if (cryptoCurrencies.length > 0) {
					setCryptoCurrency(cryptoCurrencies);
				}
			}).catch((error) => {
				notifyApp(error);
				console.log(error);
			});

		}
	}, [cryptoCurrency])

	useEffect(() => {
		if (isCopying) {
			hideHint().catch((e) => {
				console.error(e);
			});
		}
	}, [isCopying]);

	const hideHint = async () => {
		const delay = new Promise((resolve => setTimeout(resolve, 2000)));
		await delay;
		setIsCopying(null);
	};

	const copyAddress = () => {
		setIsCopying('address');
		const text = address.current.textContent;
		copyTextToClipboard(text);
	};

	const copyMemo = () => {
		setIsCopying('memo');
		const text = memo.current.textContent;
		copyTextToClipboard(text);
	};

	return (
		<div>
			<Loader
				isShowLoader={isShowLoader}
			/>
			<div className={'operations-row'}>
				<div className={'operations-row-inner'}>
					<div className={'crypto-summary'}>

						<div className={'crypto-fields'}>

							<div className={'crypto-field'}>
								<div className={'crypto-label'}>{t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.CRYPTO')}</div>
								<div className={'operations-fields-wrapper'}>
									<div className={'operations-fieldset'}>
										<div className={'operations-field'}>
											<div className="dx-field-value">
												<SelectBox
													placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.CHOOSER')}
													dataSource={cryptoCurrency}
													valueExpr={'UniqID'}
													onValueChanged={({value}) => {
														const selectedItem = cryptoCurrency.find(item => item.UniqID === value);
														setSelectedCryptoCurrency(selectedItem);
													}}
													stylingMode={'outlined'}
													itemComponent={ItemTemplate}
													fieldComponent={FieldTemplate}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>

							{summary && (
								<>
									<div className={'crypto-field'}>
										<div className={'crypto-label'}>Address</div>
										<div className={'crypto-input crypto-text'}>
											<input
												checked={checkedRadio === 'address'}
												className={'crypto-radio'}
												value={'address'}
												id={'radio-1'}
												type={'radio'}
												name={'radio'}
												onChange={onRadioChange}
											/>
											<label className={'radio-label'} htmlFor="radio-1"></label>
											<div onClick={copyAddress} className={'copy-button'}>
												<span className={classNames('copy-hint', {
													'copy-hint-visible': isCopying === 'address',
												})}>{t('COMMON.COPIED')}</span>
											</div>
											<div ref={address} className={'input-text'}>
												{summary.Address}
											</div>
										</div>
									</div>
									{summary.Memo && (
										<div className={'crypto-field'}>
											<div className={'crypto-label'}>Memo / Destination tag</div>
											<div className={'crypto-input crypto-text'}>
												<input
													checked={checkedRadio === 'memo'}
													className={'crypto-radio'}
													value={'memo'}
													id={'radio-2'}
													type={'radio'}
													name={'radio'}
													onChange={onRadioChange}
												/>
												<label className={'radio-label'} htmlFor="radio-2"></label>
												<div onClick={copyMemo} className={'copy-button'}>
													<span className={classNames('copy-hint', {
														'copy-hint-visible': isCopying === 'memo',
													})}>{t('COMMON.COPIED')}</span>
												</div>
												<div ref={memo} className={'input-text'}>
													{summary.Memo}
												</div>
											</div>
										</div>
									)}
								</>
							)}

						</div>

						<div className={'crypto-qr-wrapper'}>
							{summary && (
								<div className={'crypto-qr'}>
									{checkedRadio === 'address' ? (
										<a href={`bitcoin:${summary.Address}`} target={'_blank'} rel={'noopener noreferrer'}>
											<QRCode value={`bitcoin:${summary.Address}`} size={175}/>
										</a>
									) : (
										<a href={`bitcoin:${summary.Memo}`} target={'_blank'} rel={'noopener noreferrer'}>
											<QRCode value={`bitcoin:${summary.Memo}`} size={175}/>
										</a>
									)}
								</div>
							)}
						</div>

					</div>
				</div>
			</div>
		</div>
	);
};

export default CryptoIncomeFieldsHook;
