export const CLEAR_CVV = 'CLEAR_CVV';
export const CHANGE_CVV = 'CHANGE_CVV';
export const CHANGE_CARD_NUMBER = 'CHANGE_CARD_NUMBER';

export const changeCVV = (cvvCode) => ({
	type: CHANGE_CVV,
	payload: cvvCode,
});

export const changeCardNumber = (cardNumber) => ({
	type: CHANGE_CARD_NUMBER,
	payload: cardNumber,
});

export const clearCVV = () => ({
	type: CLEAR_CVV,
});
