export const vars = {
  SUCCESS_API_CODE: '000',
  SITE_NAME: 'SharPay',
  DEFAULT_LANGUAGE: 'en',
  NOTIFY: {
    ERROR: 'error',
    SUCCESS: 'success',
  },
  ACCOUNT_LOCK_ID: 1,
  ADD_ACCOUNT: {
    WALLETTO_ACCOUNT_TYPE_ID: 3,
    PLASTIC_CARD_KIND: 1,
    VIRTUAL_CARD_KIND: 3,
  },
  GBP: 826,
  MAIN: {
    STATUS_COLOR_CLASS: {
      'GREEN': 'col-green-underline',
      'RED': 'col-red-underline',
      'ORANGE': 'col-orange-underline',
    },
    ACCOUNTS_NAME: {
      WALLETTO: 'SharPay Card',
      SHARPAY_IBAN: 'SharPay IBAN',
    },
    ACCOUNTS: {
      SHARPAY_ID: 0,
      WALLETTO_ID: 11,
      SHARPAY_IBAN_ID: 12,
      SEPA_ID: 13,
      BANXE_ID: 21,
    },
    SET_PIN_ID: {
      NOT_REQUESTED: 0,
      SUCCESS: 1,
      FAILED: 2,
    },
    WALLETTO_NOT_ACTIVATED_ID: 3,
    DETAIL_WIDGET_SETTING_ID: {
      CHANGE_3D_SECURE: 1,
      CHANGE_PIN: 2,
      LOCK_CARD: 3,
      UNLOCK_CARD: 4,
    }
  },
  PAYMENT: {
    PAYMENT_TYPE_INIT: 0,
    PAYMENT_TYPE_PAYOUT: 1,
    PAYMENT_TYPE_INCOME: 2,
    PAYMENT_SERVICE: 3,
    PAYOUT_PATH: '/operations/payout',
    INCOME_PATH: '/operations/income',
  },
  PAYMENT_STATUS: {
    IN_PROGRESS: 2,
    CONFIRMED: 4,
    ERROR: 5,
    REJECTED: 6,
  },
  OPERATION: {
    PATH: '/operations',
    INTERNAL_TRANSFERS_CODE: 0,
    IS_CHECK_OTP_CODE: 1,
    PAYMENT_PAYOUT: 'Payment/Payout',
    PAYMENT_INCOME: 'Payment/Income',
    PAYMENT_TO_CARD: 'Payment/CreateCardPayment',
    NEED_CREDIT_FORM_ID: 1,
    CRYPTO_KIND: 4,
    SWIFT_PAYMENT_TYPE: 16,
    FEE_PAYER_TYPE_OUR: 3,
    FORMATTED_CARD_NUMBER_LENGTH: 19, // 4*4 digit and 3 space
    EXPIRY_DATE_LENGTH: 5, // 2 for month 2 for year digit and 1 slash delimiter
    CRYPTO_CURRENCY_ID: 2,
    FIAT_CURRENCY_ID: 1,
    SWIFT_CODE_LENGTH: 11,
    EUR_CURRENCY: {
      Code: 'EUR',
      CurrencyTypeID: 1,
      ViewPrecision: 2,
      ID: 978,
      Symbol: '€',
    },
  },
  FIELDS: {
    DESCRIPTION: 'description',
  },
  AUTH_TYPE: {
    SIMPLE: 1,
    TWO_FACTOR: 2,
  },
  REG_EXP: {
    PASSWORD_PATTERN: /^(?=.*\d)(?=.*[~!@#$%^&*(),.?":{}|<>])(?=.*[a-z])(?=.*[A-Z]).{12,}$/,
    NUMBERS_ONLY: /^\d+$/,
    NUMBERS_WITH_SPACE_ONLY: /^(?=.*\d)[\d ]+$/,
    FOUR_NUMBERS_ONLY: /^\d{4}$/,
    THREE_NUMBERS_ONLY: /^\d{3}$/,
    LATIN_ONLY: /^[a-zA-Z\s]+$/,
    EXPIRY_DATE: /^\d{2}\/\d{2}$/,
    SPACES: / /g,
    PHONE_NUMBER: /^\+?\d+$/,
    DASHED_PHONE_NUMBER: /^[+\d\s-]+$/,
    AMOUNT: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
    EMAIL: /^[^@]+@[^@]+\.[^@]+$/,
  },
  MESSAGES: {
    NUMBERS_ONLY_TEXT: 'Value must be a number',
    ENTER_CONFIRMATION_CODE: 'Введите код подтверждения для активации аутентификации.',
  },
  METHOD_TYPE: {
    UNSPECIFIED: 0,
    EMAIL: 1,
    SMS: 2,
  },
  SETTINGS_SCREENS: {
    PROFILE: 'Profile',
    LegalInformation: 'LegalInformation',
    NOTIFICATIONS: 'Notifications',
    SECURITY: 'Security',
    SCIAPI: 'SciApi',
    CONNECTIONS: 'Connections',
    VERIFICATION: 'Verification',
  },
  PROFILE: {
    VERIFICATED_STATUS: 2,
    PERSONAL_ACCOUNT: 1,
    BUSINESS_ACCOUNT: 2,
    IS_PHONE_VERIFY: 1,
    IS_EMAIL_VERIFY: 1,
  },
  SCIAPI: {
    PAYMENTS_SYSTEM_ID: {
      0: 'sharpay',
      1: 'advcash',
      2: 'webmoney',
      3: 'connectum',
      4: 'perfect',
      5: 'billline',
      6: 'neteller',
      7: 'scrill',
      8: 'paypal',
      9: 'payeer',
      10: 'cardpay',
      11: 'sharpay-card',
    }
  },
  POPUP_LOGO_FILE_NAME: [
    'SP_80_80_3.svg',
    'push.gif',
    'phone_blue.gif',
    'sms.gif',
    'email.gif',
  ],
};

export const reasons = [
  {ID: 1, Name: 'OWN_FUNDS_TRANSFER'},
  {ID: 2, Name: 'PRIVATE_TRANSFER'},
  {ID: 3, Name: 'TOP_UP_CARD_ACCOUNT'},
  {ID: 4, Name: 'PAYMENT_FOR_SERVICES_UNDER_CONTRACT'},
  {ID: 5, Name: 'PAYMENT_FOR_SERVICES_UNDER_INVOICE'},
  {ID: 6, Name: 'PAYMENT_FOR_SERVICES_UNDER_AGREEMENT'},
  {ID: 7, Name: 'PURCHASE_OF_GOODS_UNDER_CONTRACT'},
  {ID: 8, Name: 'PURCHASE_OF_GOODS_UNDER_INVOICE'},
  {ID: 9, Name: 'PURCHASE_OF_GOODS_UNDER_AGREEMENT'},
  {ID: 10, Name: 'RENTAL_PAYMENT_UNDER_CONTRACT'},
  {ID: 11, Name: 'RENTAL_PAYMENT_UNDER_INVOICE'},
  {ID: 12, Name: 'RENTAL_PAYMENT_UNDER_AGREEMENT'},
  {ID: 13, Name: 'FAMILY_TRANSFER'},
  {ID: 14, Name: 'TAX_PAYMENT'},
  {ID: 15, Name: 'REIMBURSEMENT_OF_EXPENSES'},
];

export const apiDateFormats = {
  dateFormat: 'YYYY-MM-DD',
  dateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
}

export const dateFormats = {
  apiDateFormat: 'YYYY-MM-DD',
  apiDateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
  viewDateFormat: 'YYYY.MM.DD',
  viewDateTimeFormat: 'YYYY.MM.DD, HH:mm',
}

export const progressColor = {
  EMAIL: '#4cd964',
  PHONE: '#9013fe',
  PERSON: '#ffc951',
  PERSON_LIVING: '#ff0085',
};

export const languages = [
  {
    name: 'en',
    imgSrc: '/images/flags/gb.svg',
  },
  {
    name: 'ru',
    imgSrc: '/images/flags/ru.svg',
  },
  {
    name: 'uk',
    imgSrc: '/images/flags/ua.svg',
  },
];

const {MAIN: {ACCOUNTS: {SHARPAY_IBAN_ID, SEPA_ID, BANXE_ID}}} = vars;

export const specialPaymentMethodValueIds = [SHARPAY_IBAN_ID, SEPA_ID, BANXE_ID]; // IBAN & SEPA & BANXE

export const conditionLinks = {
  docHost: 'https://sharpay.net/docs/',
  termsAndConditions: 'https://sharpay.net/docs/Terms_and_Conditions_eng.pdf',
};

export const verificationStatus = {
  VERIFIED: 2,
  DECLINED: 3,
  NEED_EXTRA_DOCS: 4,
  DECLINED_AFTER_VERIFIED: 5,
  NEED_APPROVAL_FROM_BACKOFFICE: 6,
  IN_PROGRESS: 7,
  NEED_EXTRA_DOCS_AFTER_VERIFIED: 8,
  IN_PROCESS_AFTER_VERIFIED: 9,
}

export const descriptions = [
  '',
  '',
  'DESC_1',
  'DESC_2',
  'DESC_3',
  'DESC_4',
];

export const countries = [
  {
    "id": "1",
    "name_en": "Jersey",
    "name_uk": "Джерсі",
    "name_ru": "Джерси",
    "alpha_2": "JE",
    "alpha_3": "JEY",
    "numeric": "832",
    "phone_code": "44",
    "language_id": "40",
    "display_code": "+44"
  },
  {
    "id": "2",
    "name_en": "Ukraine",
    "name_uk": "Україна",
    "name_ru": "Украина",
    "alpha_2": "UA",
    "alpha_3": "UKR",
    "numeric": "804",
    "phone_code": "380",
    "language_id": "169",
    "display_code": "+380"
  },
  {
    "id": "3",
    "name_en": "Russia",
    "name_uk": "Росія",
    "name_ru": "Россия",
    "alpha_2": "RU",
    "alpha_3": "RUS",
    "numeric": "643",
    "phone_code": "7",
    "language_id": "133",
    "display_code": "+7"
  },
  {
    "id": "4",
    "name_en": "Mexico",
    "name_uk": "Мексика",
    "name_ru": "Мексика",
    "alpha_2": "MX",
    "alpha_3": "MEX",
    "numeric": "484",
    "phone_code": "52",
    "language_id": "40",
    "display_code": "+52"
  },
  {
    "id": "5",
    "name_en": "Belize",
    "name_uk": "Беліз",
    "name_ru": "Белиз",
    "alpha_2": "BZ",
    "alpha_3": "BLZ",
    "numeric": "84",
    "phone_code": "501",
    "language_id": "40",
    "display_code": "+501"
  },
  {
    "id": "6",
    "name_en": "Aruba",
    "name_uk": "Аруба",
    "name_ru": "Аруба",
    "alpha_2": "AW",
    "alpha_3": "ABW",
    "numeric": "533",
    "phone_code": "297",
    "language_id": "40",
    "display_code": "+297"
  },
  {
    "id": "7",
    "name_en": "Butane",
    "name_uk": "Бутан",
    "name_ru": "Бутан",
    "alpha_2": "BT",
    "alpha_3": "BTN",
    "numeric": "64",
    "phone_code": "975",
    "language_id": "40",
    "display_code": "+975"
  },
  {
    "id": "8",
    "name_en": "Denmark",
    "name_uk": "Данія",
    "name_ru": "Дания",
    "alpha_2": "DK",
    "alpha_3": "DNK",
    "numeric": "208",
    "phone_code": "45",
    "language_id": "40",
    "display_code": "+45"
  },
  {
    "id": "9",
    "name_en": "Mongolia",
    "name_uk": "Монголія",
    "name_ru": "Монголия",
    "alpha_2": "MN",
    "alpha_3": "MNG",
    "numeric": "496",
    "phone_code": "976",
    "language_id": "40",
    "display_code": "+976"
  },
  {
    "id": "10",
    "name_en": "Monaco",
    "name_uk": "Монако",
    "name_ru": "Монако",
    "alpha_2": "MC",
    "alpha_3": "MCO",
    "numeric": "492",
    "phone_code": "377",
    "language_id": "40",
    "display_code": "+377"
  },
  {
    "id": "11",
    "name_en": "Haiti",
    "name_uk": "Гаїті",
    "name_ru": "Гаити",
    "alpha_2": "HT",
    "alpha_3": "HTI",
    "numeric": "332",
    "phone_code": "509",
    "language_id": "40",
    "display_code": "+509"
  },
  {
    "id": "12",
    "name_en": "Ireland",
    "name_uk": "Ірландія",
    "name_ru": "Ирландия",
    "alpha_2": "IE",
    "alpha_3": "IRL",
    "numeric": "372",
    "phone_code": "353",
    "language_id": "40",
    "display_code": "+353"
  },
  {
    "id": "13",
    "name_en": "Mali",
    "name_uk": "Малі",
    "name_ru": "Мали",
    "alpha_2": "ML",
    "alpha_3": "MLI",
    "numeric": "466",
    "phone_code": "223",
    "language_id": "40",
    "display_code": "+223"
  },
  {
    "id": "14",
    "name_en": "Congo, Democratic Republic",
    "name_uk": "Конго, демократична республiка",
    "name_ru": "Конго, демократическая республика",
    "alpha_2": "CG",
    "alpha_3": "COG",
    "numeric": "178",
    "phone_code": "242",
    "language_id": "40",
    "display_code": "+242"
  },
  {
    "id": "15",
    "name_en": "Guam",
    "name_uk": "Гуам",
    "name_ru": "Гуам",
    "alpha_2": "GU",
    "alpha_3": "GUM",
    "numeric": "316",
    "phone_code": "670",
    "language_id": "40",
    "display_code": "+670"
  },
  {
    "id": "16",
    "name_en": "Angola",
    "name_uk": "Ангола",
    "name_ru": "Ангола",
    "alpha_2": "AO",
    "alpha_3": "AGO",
    "numeric": "24",
    "phone_code": "244",
    "language_id": "40",
    "display_code": "+244"
  },
  {
    "id": "17",
    "name_en": "Cuba",
    "name_uk": "Куба",
    "name_ru": "Куба",
    "alpha_2": "CU",
    "alpha_3": "CUB",
    "numeric": "192",
    "phone_code": "53",
    "language_id": "40",
    "display_code": "+53"
  },
  {
    "id": "18",
    "name_en": "Laos",
    "name_uk": "Лаос",
    "name_ru": "Лаос",
    "alpha_2": "LA",
    "alpha_3": "LAO",
    "numeric": "418",
    "phone_code": "856",
    "language_id": "40",
    "display_code": "+856"
  },
  {
    "id": "19",
    "name_en": "Libya",
    "name_uk": "Лівія",
    "name_ru": "Ливия",
    "alpha_2": "LY",
    "alpha_3": "LBY",
    "numeric": "434",
    "phone_code": "218",
    "language_id": "40",
    "display_code": "+218"
  },
  {
    "id": "20",
    "name_en": "Ghana",
    "name_uk": "Гана",
    "name_ru": "Гана",
    "alpha_2": "GH",
    "alpha_3": "GHA",
    "numeric": "288",
    "phone_code": "233",
    "language_id": "40",
    "display_code": "+233"
  },
  {
    "id": "21",
    "name_en": "Gambia",
    "name_uk": "Гамбія",
    "name_ru": "Гамбия",
    "alpha_2": "GM",
    "alpha_3": "GMB",
    "numeric": "270",
    "phone_code": "220",
    "language_id": "40",
    "display_code": "+220"
  },
  {
    "id": "22",
    "name_en": "Belgium",
    "name_uk": "Бельгія",
    "name_ru": "Бельгия",
    "alpha_2": "BE",
    "alpha_3": "BEL",
    "numeric": "56",
    "phone_code": "32",
    "language_id": "40",
    "display_code": "+32"
  },
  {
    "id": "23",
    "name_en": "Benin",
    "name_uk": "Бенін",
    "name_ru": "Бенин",
    "alpha_2": "BJ",
    "alpha_3": "BEN",
    "numeric": "204",
    "phone_code": "229",
    "language_id": "40",
    "display_code": "+229"
  },
  {
    "id": "24",
    "name_en": "Djibouti",
    "name_uk": "Джібуті",
    "name_ru": "Джибути",
    "alpha_2": "DJ",
    "alpha_3": "DJI",
    "numeric": "262",
    "phone_code": "253",
    "language_id": "40",
    "display_code": "+253"
  },
  {
    "id": "25",
    "name_en": "Cyprus",
    "name_uk": "Кіпр",
    "name_ru": "Кипр",
    "alpha_2": "CY",
    "alpha_3": "CYP",
    "numeric": "196",
    "phone_code": "357",
    "language_id": "40",
    "display_code": "+357"
  },
  {
    "id": "26",
    "name_en": "Moldova",
    "name_uk": "Молдавія",
    "name_ru": "Молдавия",
    "alpha_2": "MD",
    "alpha_3": "MDA",
    "numeric": "498",
    "phone_code": "373",
    "language_id": "40",
    "display_code": "+373"
  },
  {
    "id": "27",
    "name_en": "Latvia",
    "name_uk": "Латвія",
    "name_ru": "Латвия",
    "alpha_2": "LV",
    "alpha_3": "LVA",
    "numeric": "428",
    "phone_code": "371",
    "language_id": "40",
    "display_code": "+371"
  },
  {
    "id": "28",
    "name_en": "Italy",
    "name_uk": "Італія",
    "name_ru": "Италия",
    "alpha_2": "IT",
    "alpha_3": "ITA",
    "numeric": "380",
    "phone_code": "39",
    "language_id": "40",
    "display_code": "+39"
  },
  {
    "id": "29",
    "name_en": "China",
    "name_uk": "Китай",
    "name_ru": "Китай",
    "alpha_2": "CN",
    "alpha_3": "CHN",
    "numeric": "156",
    "phone_code": "86",
    "language_id": "40",
    "display_code": "+86"
  },
  {
    "id": "30",
    "name_en": "Zambia",
    "name_uk": "Замбія",
    "name_ru": "Замбия",
    "alpha_2": "ZM",
    "alpha_3": "ZMB",
    "numeric": "894",
    "phone_code": "260",
    "language_id": "40",
    "display_code": "+260"
  },
  {
    "id": "31",
    "name_en": "Guinea",
    "name_uk": "Гвінея",
    "name_ru": "Гвинея",
    "alpha_2": "GN",
    "alpha_3": "GIN",
    "numeric": "324",
    "phone_code": "224",
    "language_id": "40",
    "display_code": "+224"
  },
  {
    "id": "32",
    "name_en": "Macau",
    "name_uk": "Макао",
    "name_ru": "Макао",
    "alpha_2": "MO",
    "alpha_3": "MAC",
    "numeric": "446",
    "phone_code": "853",
    "language_id": "40",
    "display_code": "+853"
  },
  {
    "id": "33",
    "name_en": "India india",
    "name_uk": "Індія",
    "name_ru": "Индия Индия",
    "alpha_2": "IN",
    "alpha_3": "IND",
    "numeric": "356",
    "phone_code": "91",
    "language_id": "40",
    "display_code": "+91"
  },
  {
    "id": "34",
    "name_en": "Kenya",
    "name_uk": "Кенія",
    "name_ru": "Кения",
    "alpha_2": "KE",
    "alpha_3": "KEN",
    "numeric": "404",
    "phone_code": "254",
    "language_id": "40",
    "display_code": "+254"
  },
  {
    "id": "35",
    "name_en": "Malawi",
    "name_uk": "Малаві",
    "name_ru": "Малави",
    "alpha_2": "MW",
    "alpha_3": "MWI",
    "numeric": "454",
    "phone_code": "265",
    "language_id": "40",
    "display_code": "+265"
  },
  {
    "id": "36",
    "name_en": "Guyana",
    "name_uk": "Гайана",
    "name_ru": "Гайана",
    "alpha_2": "GY",
    "alpha_3": "GUY",
    "numeric": "328",
    "phone_code": "592",
    "language_id": "40",
    "display_code": "+592"
  },
  {
    "id": "37",
    "name_en": "Yemen",
    "name_uk": "Ємен",
    "name_ru": "Йемен",
    "alpha_2": "YE",
    "alpha_3": "YEM",
    "numeric": "887",
    "phone_code": "967",
    "language_id": "40",
    "display_code": "+967"
  },
  {
    "id": "38",
    "name_en": "Qatar",
    "name_uk": "Катар",
    "name_ru": "Катар",
    "alpha_2": "QA",
    "alpha_3": "QAT",
    "numeric": "634",
    "phone_code": "974",
    "language_id": "40",
    "display_code": "+974"
  },
  {
    "id": "39",
    "name_en": "Malaysia",
    "name_uk": "Малайзія",
    "name_ru": "Малайзия",
    "alpha_2": "MY",
    "alpha_3": "MYS",
    "numeric": "458",
    "phone_code": "60",
    "language_id": "40",
    "display_code": "+60"
  },
  {
    "id": "40",
    "name_en": "Comoros",
    "name_uk": "Комори",
    "name_ru": "Коморы",
    "alpha_2": "KM",
    "alpha_3": "COM",
    "numeric": "174",
    "phone_code": "269",
    "language_id": "40",
    "display_code": "+269"
  },
  {
    "id": "41",
    "name_en": "Mauritius",
    "name_uk": "Маврикій",
    "name_ru": "Маврикий",
    "alpha_2": "MU",
    "alpha_3": "MUS",
    "numeric": "480",
    "phone_code": "230",
    "language_id": "40",
    "display_code": "+230"
  },
  {
    "id": "42",
    "name_en": "Myanmar",
    "name_uk": "М'янма",
    "name_ru": "Мьянма",
    "alpha_2": "MM",
    "alpha_3": "MMR",
    "numeric": "104",
    "phone_code": "95",
    "language_id": "40",
    "display_code": "+95"
  },
  {
    "id": "43",
    "name_en": "Germany",
    "name_uk": "Німеччина",
    "name_ru": "Германия",
    "alpha_2": "DE",
    "alpha_3": "DEU",
    "numeric": "276",
    "phone_code": "49",
    "language_id": "40",
    "display_code": "+49"
  },
  {
    "id": "44",
    "name_en": "Nauru",
    "name_uk": "Науру",
    "name_ru": "Науру",
    "alpha_2": "NR",
    "alpha_3": "NRU",
    "numeric": "520",
    "phone_code": "674",
    "language_id": "40",
    "display_code": "+674"
  },
  {
    "id": "45",
    "name_en": "Nepal",
    "name_uk": "Непал",
    "name_ru": "Непал",
    "alpha_2": "NP",
    "alpha_3": "NPL",
    "numeric": "524",
    "phone_code": "977",
    "language_id": "40",
    "display_code": "+977"
  },
  {
    "id": "46",
    "name_en": "Vatican",
    "name_uk": "Ватикан",
    "name_ru": "Ватикан",
    "alpha_2": "VA",
    "alpha_3": "VAT",
    "numeric": "336",
    "phone_code": "379",
    "language_id": "40",
    "display_code": "+379"
  },
  {
    "id": "47",
    "name_en": "Uganda",
    "name_uk": "Уганда",
    "name_ru": "Уганда",
    "alpha_2": "UG",
    "alpha_3": "UGA",
    "numeric": "800",
    "phone_code": "256",
    "language_id": "40",
    "display_code": "+256"
  },
  {
    "id": "48",
    "name_en": "Estonia",
    "name_uk": "Естонія",
    "name_ru": "Эстония",
    "alpha_2": "EE",
    "alpha_3": "EST",
    "numeric": "233",
    "phone_code": "372",
    "language_id": "40",
    "display_code": "+372"
  },
  {
    "id": "49",
    "name_en": "Singapore",
    "name_uk": "Сінгапур",
    "name_ru": "Сингапур",
    "alpha_2": "SG",
    "alpha_3": "SGP",
    "numeric": "702",
    "phone_code": "65",
    "language_id": "40",
    "display_code": "+65"
  },
  {
    "id": "50",
    "name_en": "Jamaica",
    "name_uk": "Ямайка",
    "name_ru": "Ямайка",
    "alpha_2": "JM",
    "alpha_3": "JAM",
    "numeric": "388",
    "phone_code": "1876",
    "language_id": "40",
    "display_code": "+1876"
  },
  {
    "id": "51",
    "name_en": "Niger",
    "name_uk": "Нігер",
    "name_ru": "Нигер",
    "alpha_2": "NE",
    "alpha_3": "NER",
    "numeric": "562",
    "phone_code": "227",
    "language_id": "40",
    "display_code": "+227"
  },
  {
    "id": "52",
    "name_en": "Nicaragua",
    "name_uk": "Нікарагуа",
    "name_ru": "Никарагуа",
    "alpha_2": "NI",
    "alpha_3": "NIC",
    "numeric": "558",
    "phone_code": "505",
    "language_id": "40",
    "display_code": "+505"
  },
  {
    "id": "53",
    "name_en": "Ethiopia",
    "name_uk": "Ефіопія",
    "name_ru": "Эфиопия",
    "alpha_2": "ET",
    "alpha_3": "ETH",
    "numeric": "231",
    "phone_code": "251",
    "language_id": "40",
    "display_code": "+251"
  },
  {
    "id": "54",
    "name_en": "Serbia",
    "name_uk": "Сербія",
    "name_ru": "Сербия",
    "alpha_2": "RS",
    "alpha_3": "SRB",
    "numeric": "688",
    "phone_code": "381",
    "language_id": "40",
    "display_code": "+381"
  },
  {
    "id": "55",
    "name_en": "Gabon",
    "name_uk": "Габон",
    "name_ru": "Габон",
    "alpha_2": "GA",
    "alpha_3": "GAB",
    "numeric": "266",
    "phone_code": "241",
    "language_id": "40",
    "display_code": "+241"
  },
  {
    "id": "56",
    "name_en": "Turkey",
    "name_uk": "Туреччина",
    "name_ru": "Турция",
    "alpha_2": "TR",
    "alpha_3": "TUR",
    "numeric": "792",
    "phone_code": "90",
    "language_id": "40",
    "display_code": "+90"
  },
  {
    "id": "57",
    "name_en": "Sudan",
    "name_uk": "Судан",
    "name_ru": "Судан",
    "alpha_2": "SD",
    "alpha_3": "SDN",
    "numeric": "729",
    "phone_code": "249",
    "language_id": "40",
    "display_code": "+249"
  },
  {
    "id": "58",
    "name_en": "Bahamas",
    "name_uk": "Багамські острови",
    "name_ru": "Багамские Острова",
    "alpha_2": "BS",
    "alpha_3": "BHS",
    "numeric": "44",
    "phone_code": "1242",
    "language_id": "40",
    "display_code": "+1242"
  },
  {
    "id": "59",
    "name_en": "Niue",
    "name_uk": "Ніуе",
    "name_ru": "Ниуэ",
    "alpha_2": "NU",
    "alpha_3": "NIU",
    "numeric": "570",
    "phone_code": "683",
    "language_id": "40",
    "display_code": "+683"
  },
  {
    "id": "60",
    "name_en": "Togo",
    "name_uk": "того",
    "name_ru": "Того",
    "alpha_2": "TG",
    "alpha_3": "TGO",
    "numeric": "768",
    "phone_code": "228",
    "language_id": "40",
    "display_code": "+228"
  },
  {
    "id": "61",
    "name_en": "Croatia",
    "name_uk": "Хорватія",
    "name_ru": "Хорватия",
    "alpha_2": "HR",
    "alpha_3": "HRV",
    "numeric": "191",
    "phone_code": "385",
    "language_id": "40",
    "display_code": "+385"
  },
  {
    "id": "62",
    "name_en": "Bahrain",
    "name_uk": "Бахрейн",
    "name_ru": "Бахрейн",
    "alpha_2": "BH",
    "alpha_3": "BHR",
    "numeric": "48",
    "phone_code": "973",
    "language_id": "40",
    "display_code": "+973"
  },
  {
    "id": "63",
    "name_en": "Thailand",
    "name_uk": "Таїланд",
    "name_ru": "Таиланд",
    "alpha_2": "TH",
    "alpha_3": "THA",
    "numeric": "764",
    "phone_code": "66",
    "language_id": "40",
    "display_code": "+66"
  },
  {
    "id": "64",
    "name_en": "Somalia",
    "name_uk": "Сомалі",
    "name_ru": "Сомали",
    "alpha_2": "SO",
    "alpha_3": "SOM",
    "numeric": "706",
    "phone_code": "252",
    "language_id": "40",
    "display_code": "+252"
  },
  {
    "id": "65",
    "name_en": "Palau",
    "name_uk": "Палау",
    "name_ru": "Палау",
    "alpha_2": "PW",
    "alpha_3": "PLW",
    "numeric": "585",
    "phone_code": "6809",
    "language_id": "40",
    "display_code": "+6809"
  },
  {
    "id": "66",
    "name_en": "Tokelau",
    "name_uk": "Токелау",
    "name_ru": "Токелау",
    "alpha_2": "TK",
    "alpha_3": "TKL",
    "numeric": "772",
    "phone_code": "690",
    "language_id": "40",
    "display_code": "+690"
  },
  {
    "id": "67",
    "name_en": "France",
    "name_uk": "Франція",
    "name_ru": "Франция",
    "alpha_2": "FR",
    "alpha_3": "FRA",
    "numeric": "250",
    "phone_code": "33",
    "language_id": "40",
    "display_code": "+33"
  },
  {
    "id": "68",
    "name_en": "Finland",
    "name_uk": "Фінляндія",
    "name_ru": "Финляндия",
    "alpha_2": "FI",
    "alpha_3": "FIN",
    "numeric": "246",
    "phone_code": "358",
    "language_id": "40",
    "display_code": "+358"
  },
  {
    "id": "69",
    "name_en": "Israel",
    "name_uk": "Ізраїль",
    "name_ru": "Израиль",
    "alpha_2": "IL",
    "alpha_3": "ISR",
    "numeric": "376",
    "phone_code": "972",
    "language_id": "40",
    "display_code": "+972"
  },
  {
    "id": "70",
    "name_en": "Sri Lanka",
    "name_uk": "Шрі Ланка",
    "name_ru": "Шри-Ланка",
    "alpha_2": "LK",
    "alpha_3": "LKA",
    "numeric": "144",
    "phone_code": "94",
    "language_id": "40",
    "display_code": "+94"
  },
  {
    "id": "71",
    "name_en": "Samoa",
    "name_uk": "Самоа",
    "name_ru": "Самоа",
    "alpha_2": "WS",
    "alpha_3": "WSM",
    "numeric": "882",
    "phone_code": "685",
    "language_id": "40",
    "display_code": "+685"
  },
  {
    "id": "72",
    "name_en": "Senegal",
    "name_uk": "Сенегал",
    "name_ru": "Сенегал",
    "alpha_2": "SN",
    "alpha_3": "SEN",
    "numeric": "686",
    "phone_code": "221",
    "language_id": "40",
    "display_code": "+221"
  },
  {
    "id": "73",
    "name_en": "Esvatini",
    "name_uk": "Есватіні",
    "name_ru": "Эсватини",
    "alpha_2": "SZ",
    "alpha_3": "SWZ",
    "numeric": "748",
    "phone_code": "268",
    "language_id": "40",
    "display_code": "+268"
  },
  {
    "id": "74",
    "name_en": "Bermuda",
    "name_uk": "Бермуди",
    "name_ru": "Бермуды",
    "alpha_2": "BM",
    "alpha_3": "BMU",
    "numeric": "60",
    "phone_code": "1441",
    "language_id": "40",
    "display_code": "+1441"
  },
  {
    "id": "75",
    "name_en": "Slovenia",
    "name_uk": "Словенія",
    "name_ru": "Словения",
    "alpha_2": "SI",
    "alpha_3": "SVN",
    "numeric": "705",
    "phone_code": "386",
    "language_id": "40",
    "display_code": "+386"
  },
  {
    "id": "76",
    "name_en": "Republic of China",
    "name_uk": "китайська Республіка",
    "name_ru": "Китайская Республика",
    "alpha_2": "TW",
    "alpha_3": "TWN",
    "numeric": "158",
    "phone_code": "886",
    "language_id": "40",
    "display_code": "+886"
  },
  {
    "id": "77",
    "name_en": "Chile",
    "name_uk": "Чилі",
    "name_ru": "Чили",
    "alpha_2": "CL",
    "alpha_3": "CHL",
    "numeric": "152",
    "phone_code": "56",
    "language_id": "40",
    "display_code": "+56"
  },
  {
    "id": "78",
    "name_en": "USA",
    "name_uk": "США",
    "name_ru": "США",
    "alpha_2": "US",
    "alpha_3": "USA",
    "numeric": "840",
    "phone_code": "1",
    "language_id": "40",
    "display_code": "+1"
  },
  {
    "id": "79",
    "name_en": "Fiji",
    "name_uk": "Фіджі",
    "name_ru": "Фиджи",
    "alpha_2": "FJ",
    "alpha_3": "FJI",
    "numeric": "242",
    "phone_code": "679",
    "language_id": "40",
    "display_code": "+679"
  },
  {
    "id": "80",
    "name_en": "Tuvalu",
    "name_uk": "Тувалу",
    "name_ru": "Тувалу",
    "alpha_2": "TV",
    "alpha_3": "TUV",
    "numeric": "798",
    "phone_code": "688",
    "language_id": "40",
    "display_code": "+688"
  },
  {
    "id": "81",
    "name_en": "Norway",
    "name_uk": "Норвегія",
    "name_ru": "Норвегия",
    "alpha_2": "NO",
    "alpha_3": "NOR",
    "numeric": "578",
    "phone_code": "47",
    "language_id": "40",
    "display_code": "+47"
  },
  {
    "id": "82",
    "name_en": "Rwanda",
    "name_uk": "Руанда",
    "name_ru": "Руанда",
    "alpha_2": "RW",
    "alpha_3": "RWA",
    "numeric": "646",
    "phone_code": "250",
    "language_id": "40",
    "display_code": "+250"
  },
  {
    "id": "83",
    "name_en": "Chad",
    "name_uk": "Чад",
    "name_ru": "Чад",
    "alpha_2": "TD",
    "alpha_3": "TCD",
    "numeric": "148",
    "phone_code": "235",
    "language_id": "40",
    "display_code": "+235"
  },
  {
    "id": "84",
    "name_en": "Isle Of Man",
    "name_uk": "острів Мен",
    "name_ru": "Остров Мэн",
    "alpha_2": "IM",
    "alpha_3": "IMN",
    "numeric": "833",
    "phone_code": "44",
    "language_id": "40",
    "display_code": "+44"
  },
  {
    "id": "85",
    "name_en": "Oman",
    "name_uk": "Оман",
    "name_ru": "Оман",
    "alpha_2": "OM",
    "alpha_3": "OMN",
    "numeric": "512",
    "phone_code": "968",
    "language_id": "40",
    "display_code": "+968"
  },
  {
    "id": "86",
    "name_en": "Panama",
    "name_uk": "Панама",
    "name_ru": "Панама",
    "alpha_2": "PA",
    "alpha_3": "PAN",
    "numeric": "591",
    "phone_code": "507",
    "language_id": "40",
    "display_code": "+507"
  },
  {
    "id": "87",
    "name_en": "Peru",
    "name_uk": "Перу",
    "name_ru": "Перу",
    "alpha_2": "PE",
    "alpha_3": "PER",
    "numeric": "604",
    "phone_code": "51",
    "language_id": "40",
    "display_code": "+51"
  },
  {
    "id": "88",
    "name_en": "Reunion",
    "name_uk": "Реюньйон",
    "name_ru": "Реюньон",
    "alpha_2": "RE",
    "alpha_3": "REU",
    "numeric": "638",
    "phone_code": "262",
    "language_id": "40",
    "display_code": "+262"
  },
  {
    "id": "89",
    "name_en": "Ecuador",
    "name_uk": "Еквадор",
    "name_ru": "Эквадор",
    "alpha_2": "EC",
    "alpha_3": "ECU",
    "numeric": "218",
    "phone_code": "593",
    "language_id": "40",
    "display_code": "+593"
  },
  {
    "id": "90",
    "name_en": "Kyrgyzstan",
    "name_uk": "Киргизія",
    "name_ru": "Киргизия",
    "alpha_2": "KG",
    "alpha_3": "KGZ",
    "numeric": "417",
    "phone_code": "996",
    "language_id": "40",
    "display_code": "+996"
  },
  {
    "id": "91",
    "name_en": "Iran",
    "name_uk": "Іран",
    "name_ru": "Иран",
    "alpha_2": "IR",
    "alpha_3": "IRN",
    "numeric": "364",
    "phone_code": "98",
    "language_id": "40",
    "display_code": "+98"
  },
  {
    "id": "92",
    "name_en": "Seychelles",
    "name_uk": "Сейшельські острови",
    "name_ru": "Сейшельские Острова",
    "alpha_2": "SC",
    "alpha_3": "SYC",
    "numeric": "690",
    "phone_code": "248",
    "language_id": "40",
    "display_code": "+248"
  },
  {
    "id": "93",
    "name_en": "Morocco",
    "name_uk": "Марокко",
    "name_ru": "Марокко",
    "alpha_2": "MA",
    "alpha_3": "MAR",
    "numeric": "504",
    "phone_code": "212",
    "language_id": "40",
    "display_code": "+212"
  },
  {
    "id": "94",
    "name_en": "Cape Verde",
    "name_uk": "Кабо-Верде",
    "name_ru": "Кабо-Верде",
    "alpha_2": "CV",
    "alpha_3": "CPV",
    "numeric": "132",
    "phone_code": "238",
    "language_id": "40",
    "display_code": "+238"
  },
  {
    "id": "95",
    "name_en": "Dominica",
    "name_uk": "Домініка",
    "name_ru": "Доминика",
    "alpha_2": "DM",
    "alpha_3": "DMA",
    "numeric": "212",
    "phone_code": "1767",
    "language_id": "40",
    "display_code": "+1767"
  },
  {
    "id": "96",
    "name_en": "Cayman islands",
    "name_uk": "Кайманові острови",
    "name_ru": "Острова Кайман",
    "alpha_2": "KY",
    "alpha_3": "CYM",
    "numeric": "136",
    "phone_code": "1345",
    "language_id": "40",
    "display_code": "+1345"
  },
  {
    "id": "97",
    "name_en": "Zimbabwe",
    "name_uk": "Зімбабве",
    "name_ru": "Зимбабве",
    "alpha_2": "ZW",
    "alpha_3": "ZWE",
    "numeric": "716",
    "phone_code": "263",
    "language_id": "40",
    "display_code": "+263"
  },
  {
    "id": "98",
    "name_en": "Saudi Arabia",
    "name_uk": "Саудівська Аравія",
    "name_ru": "Саудовская Аравия",
    "alpha_2": "SA",
    "alpha_3": "SAU",
    "numeric": "682",
    "phone_code": "966",
    "language_id": "40",
    "display_code": "+966"
  },
  {
    "id": "99",
    "name_en": "Grenada",
    "name_uk": "Гренада",
    "name_ru": "Гренада",
    "alpha_2": "GD",
    "alpha_3": "GRD",
    "numeric": "308",
    "phone_code": "1473",
    "language_id": "40",
    "display_code": "+1473"
  },
  {
    "id": "100",
    "name_en": "Montserrat",
    "name_uk": "Монтсеррат",
    "name_ru": "Монтсеррат",
    "alpha_2": "MS",
    "alpha_3": "MSR",
    "numeric": "500",
    "phone_code": "1664",
    "language_id": "40",
    "display_code": "+1664"
  },
  {
    "id": "101",
    "name_en": "Malta",
    "name_uk": "Мальта",
    "name_ru": "Мальта",
    "alpha_2": "MT",
    "alpha_3": "MLT",
    "numeric": "470",
    "phone_code": "356",
    "language_id": "40",
    "display_code": "+356"
  },
  {
    "id": "102",
    "name_en": "Tajikistan",
    "name_uk": "Таджикистан",
    "name_ru": "Таджикистан",
    "alpha_2": "TJ",
    "alpha_3": "TJK",
    "numeric": "762",
    "phone_code": "992",
    "language_id": "40",
    "display_code": "+992"
  },
  {
    "id": "103",
    "name_en": "Uruguay",
    "name_uk": "Уругвай",
    "name_ru": "Уругвай",
    "alpha_2": "UY",
    "alpha_3": "URY",
    "numeric": "858",
    "phone_code": "598",
    "language_id": "40",
    "display_code": "+598"
  },
  {
    "id": "104",
    "name_en": "Kazakhstan",
    "name_uk": "Казахстан",
    "name_ru": "Казахстан",
    "alpha_2": "KZ",
    "alpha_3": "KAZ",
    "numeric": "398",
    "phone_code": "7",
    "language_id": "40",
    "display_code": "+7"
  },
  {
    "id": "105",
    "name_en": "Tunisia",
    "name_uk": "Туніс",
    "name_ru": "Тунис",
    "alpha_2": "TN",
    "alpha_3": "TUN",
    "numeric": "788",
    "phone_code": "216",
    "language_id": "40",
    "display_code": "+216"
  },
  {
    "id": "106",
    "name_en": "Canada",
    "name_uk": "Канада",
    "name_ru": "Канада",
    "alpha_2": "CA",
    "alpha_3": "CAN",
    "numeric": "124",
    "phone_code": "1",
    "language_id": "40",
    "display_code": "+1"
  },
  {
    "id": "107",
    "name_en": "East Timor",
    "name_uk": "Східний Тимор",
    "name_ru": "Восточный Тимор",
    "alpha_2": "TL",
    "alpha_3": "TLS",
    "numeric": "626",
    "phone_code": "670",
    "language_id": "40",
    "display_code": "+670"
  },
  {
    "id": "108",
    "name_en": "Anguilla",
    "name_uk": "Ангілья",
    "name_ru": "Ангилья",
    "alpha_2": "AI",
    "alpha_3": "AIA",
    "numeric": "660",
    "phone_code": "1264",
    "language_id": "40",
    "display_code": "+1264"
  },
  {
    "id": "109",
    "name_en": "Tonga",
    "name_uk": "Тонга",
    "name_ru": "Тонга",
    "alpha_2": "TO",
    "alpha_3": "TON",
    "numeric": "776",
    "phone_code": "676",
    "language_id": "40",
    "display_code": "+676"
  },
  {
    "id": "110",
    "name_en": "Indonesia",
    "name_uk": "Індонезія",
    "name_ru": "Индонезия",
    "alpha_2": "ID",
    "alpha_3": "IDN",
    "numeric": "360",
    "phone_code": "62",
    "language_id": "40",
    "display_code": "+62"
  },
  {
    "id": "111",
    "name_en": "Sint martin",
    "name_uk": "Сінт-Мартен",
    "name_ru": "Синт-Мартен",
    "alpha_2": "SX",
    "alpha_3": "SXM",
    "numeric": "534",
    "phone_code": "1721",
    "language_id": "40",
    "display_code": "+1721"
  },
  {
    "id": "112",
    "name_en": "Mozambique",
    "name_uk": "Мозамбік",
    "name_ru": "Мозамбик",
    "alpha_2": "MZ",
    "alpha_3": "MOZ",
    "numeric": "508",
    "phone_code": "258",
    "language_id": "40",
    "display_code": "+258"
  },
  {
    "id": "113",
    "name_en": "Bulgaria",
    "name_uk": "Болгарія",
    "name_ru": "Болгария",
    "alpha_2": "BG",
    "alpha_3": "BGR",
    "numeric": "100",
    "phone_code": "359",
    "language_id": "40",
    "display_code": "+359"
  },
  {
    "id": "114",
    "name_en": "Jordan",
    "name_uk": "Йорданія",
    "name_ru": "Иордания",
    "alpha_2": "JO",
    "alpha_3": "JOR",
    "numeric": "400",
    "phone_code": "962",
    "language_id": "40",
    "display_code": "+962"
  },
  {
    "id": "115",
    "name_en": "Greece",
    "name_uk": "Греція",
    "name_ru": "Греция",
    "alpha_2": "GR",
    "alpha_3": "GRC",
    "numeric": "300",
    "phone_code": "30",
    "language_id": "40",
    "display_code": "+30"
  },
  {
    "id": "116",
    "name_en": "Liberia",
    "name_uk": "Ліберія",
    "name_ru": "Либерия",
    "alpha_2": "LR",
    "alpha_3": "LBR",
    "numeric": "430",
    "phone_code": "231",
    "language_id": "40",
    "display_code": "+231"
  },
  {
    "id": "117",
    "name_en": "Slovakia",
    "name_uk": "Словаччина",
    "name_ru": "Словакия",
    "alpha_2": "SK",
    "alpha_3": "SVK",
    "numeric": "703",
    "phone_code": "421",
    "language_id": "40",
    "display_code": "+421"
  },
  {
    "id": "118",
    "name_en": "Costa Rica",
    "name_uk": "Коста-Ріка",
    "name_ru": "Коста-Рика",
    "alpha_2": "CR",
    "alpha_3": "CRI",
    "numeric": "188",
    "phone_code": "506",
    "language_id": "40",
    "display_code": "+506"
  },
  {
    "id": "119",
    "name_en": "Romania",
    "name_uk": "Румунія",
    "name_ru": "Румыния",
    "alpha_2": "RO",
    "alpha_3": "ROU",
    "numeric": "642",
    "phone_code": "40",
    "language_id": "40",
    "display_code": "+40"
  },
  {
    "id": "120",
    "name_en": "Namibia",
    "name_uk": "Намібія",
    "name_ru": "Намибия",
    "alpha_2": "NA",
    "alpha_3": "NAM",
    "numeric": "516",
    "phone_code": "264",
    "language_id": "40",
    "display_code": "+264"
  },
  {
    "id": "121",
    "name_en": "Lesotho",
    "name_uk": "Лесото",
    "name_ru": "Лесото",
    "alpha_2": "LS",
    "alpha_3": "LSO",
    "numeric": "426",
    "phone_code": "266",
    "language_id": "40",
    "display_code": "+266"
  },
  {
    "id": "122",
    "name_en": "North Macedonia",
    "name_uk": "Північна Македонія",
    "name_ru": "Северная Македония",
    "alpha_2": "MK",
    "alpha_3": "MKD",
    "numeric": "807",
    "phone_code": "389",
    "language_id": "40",
    "display_code": "+389"
  },
  {
    "id": "123",
    "name_en": "Hong Kong",
    "name_uk": "Гонконг",
    "name_ru": "Гонконг",
    "alpha_2": "HK",
    "alpha_3": "HKG",
    "numeric": "344",
    "phone_code": "852",
    "language_id": "40",
    "display_code": "+852"
  },
  {
    "id": "124",
    "name_en": "Lebanon",
    "name_uk": "Ліван",
    "name_ru": "Ливан",
    "alpha_2": "LB",
    "alpha_3": "LBN",
    "numeric": "422",
    "phone_code": "961",
    "language_id": "40",
    "display_code": "+961"
  },
  {
    "id": "125",
    "name_en": "Nigeria",
    "name_uk": "Нігерія",
    "name_ru": "Нигерия",
    "alpha_2": "NG",
    "alpha_3": "NGA",
    "numeric": "566",
    "phone_code": "234",
    "language_id": "40",
    "display_code": "+234"
  },
  {
    "id": "126",
    "name_en": "Tanzania",
    "name_uk": "Танзанія",
    "name_ru": "Танзания",
    "alpha_2": "TZ",
    "alpha_3": "TZA",
    "numeric": "834",
    "phone_code": "255",
    "language_id": "40",
    "display_code": "+255"
  },
  {
    "id": "127",
    "name_en": "Trinidad and Tobago",
    "name_uk": "Трінідад і Тобаго",
    "name_ru": "Тринидад и Тобаго",
    "alpha_2": "TT",
    "alpha_3": "TTO",
    "numeric": "780",
    "phone_code": "1868",
    "language_id": "40",
    "display_code": "+1868"
  },
  {
    "id": "128",
    "name_en": "Guatemala",
    "name_uk": "Гватемала",
    "name_ru": "Гватемала",
    "alpha_2": "GT",
    "alpha_3": "GTM",
    "numeric": "320",
    "phone_code": "502",
    "language_id": "40",
    "display_code": "+502"
  },
  {
    "id": "129",
    "name_en": "Pakistan",
    "name_uk": "Пакистан",
    "name_ru": "Пакистан",
    "alpha_2": "PK",
    "alpha_3": "PAK",
    "numeric": "586",
    "phone_code": "92",
    "language_id": "40",
    "display_code": "+92"
  },
  {
    "id": "130",
    "name_en": "Cote d’Ivoire",
    "name_uk": "Кот-д'Івуар",
    "name_ru": "Кот-д’Ивуар",
    "alpha_2": "CI",
    "alpha_3": "CIV",
    "numeric": "384",
    "phone_code": "225",
    "language_id": "40",
    "display_code": "+225"
  },
  {
    "id": "131",
    "name_en": "Japan",
    "name_uk": "Японія",
    "name_ru": "Япония",
    "alpha_2": "JP",
    "alpha_3": "JPN",
    "numeric": "392",
    "phone_code": "81",
    "language_id": "40",
    "display_code": "+81"
  },
  {
    "id": "132",
    "name_en": "Azerbaijan",
    "name_uk": "Азербайджан",
    "name_ru": "Азербайджан",
    "alpha_2": "AZ",
    "alpha_3": "AZE",
    "numeric": "31",
    "phone_code": "994",
    "language_id": "40",
    "display_code": "+994"
  },
  {
    "id": "133",
    "name_en": "Gibraltar",
    "name_uk": "Гібралтар",
    "name_ru": "Гибралтар",
    "alpha_2": "GI",
    "alpha_3": "GIB",
    "numeric": "292",
    "phone_code": "350",
    "language_id": "40",
    "display_code": "+350"
  },
  {
    "id": "134",
    "name_en": "SADR",
    "name_uk": "Садр",
    "name_ru": "САДР",
    "alpha_2": "EH",
    "alpha_3": "ESH",
    "numeric": "732",
    "phone_code": "212",
    "language_id": "40",
    "display_code": "+212"
  },
  {
    "id": "135",
    "name_en": "Martinique",
    "name_uk": "Мартинюк",
    "name_ru": "Мартиника",
    "alpha_2": "MQ",
    "alpha_3": "MTQ",
    "numeric": "474",
    "phone_code": "596",
    "language_id": "40",
    "display_code": "+596"
  },
  {
    "id": "136",
    "name_en": "Uzbekistan",
    "name_uk": "Узбекистан",
    "name_ru": "Узбекистан",
    "alpha_2": "UZ",
    "alpha_3": "UZB",
    "numeric": "860",
    "phone_code": "998",
    "language_id": "40",
    "display_code": "+998"
  },
  {
    "id": "137",
    "name_en": "Hungary",
    "name_uk": "Угорщина",
    "name_ru": "Венгрия",
    "alpha_2": "HU",
    "alpha_3": "HUN",
    "numeric": "348",
    "phone_code": "36",
    "language_id": "40",
    "display_code": "+36"
  },
  {
    "id": "138",
    "name_en": "Luxembourg",
    "name_uk": "Люксембург",
    "name_ru": "Люксембург",
    "alpha_2": "LU",
    "alpha_3": "LUX",
    "numeric": "442",
    "phone_code": "352",
    "language_id": "40",
    "display_code": "+352"
  },
  {
    "id": "139",
    "name_en": "Philippines",
    "name_uk": "Філіппіни",
    "name_ru": "Филиппины",
    "alpha_2": "PH",
    "alpha_3": "PHL",
    "numeric": "608",
    "phone_code": "63",
    "language_id": "40",
    "display_code": "+63"
  },
  {
    "id": "140",
    "name_en": "Brazil",
    "name_uk": "Бразилія",
    "name_ru": "Бразилия",
    "alpha_2": "BR",
    "alpha_3": "BRA",
    "numeric": "76",
    "phone_code": "55",
    "language_id": "40",
    "display_code": "+55"
  },
  {
    "id": "141",
    "name_en": "Spain",
    "name_uk": "Іспанія",
    "name_ru": "Испания",
    "alpha_2": "ES",
    "alpha_3": "ESP",
    "numeric": "724",
    "phone_code": "34",
    "language_id": "40",
    "display_code": "+34"
  },
  {
    "id": "142",
    "name_en": "Wallis and Futuna",
    "name_uk": "Уолліс і Футуна",
    "name_ru": "Уоллис и Футуна",
    "alpha_2": "WF",
    "alpha_3": "WLF",
    "numeric": "876",
    "phone_code": "681",
    "language_id": "40",
    "display_code": "+681"
  },
  {
    "id": "143",
    "name_en": "Austria",
    "name_uk": "Австрія",
    "name_ru": "Австрия",
    "alpha_2": "AT",
    "alpha_3": "AUT",
    "numeric": "40",
    "phone_code": "43",
    "language_id": "40",
    "display_code": "+43"
  },
  {
    "id": "144",
    "name_en": "Mauritania",
    "name_uk": "Мавританія",
    "name_ru": "Мавритания",
    "alpha_2": "MR",
    "alpha_3": "MRT",
    "numeric": "478",
    "phone_code": "222",
    "language_id": "40",
    "display_code": "+222"
  },
  {
    "id": "145",
    "name_en": "Afghanistan",
    "name_uk": "Афганістан",
    "name_ru": "Афганистан",
    "alpha_2": "AF",
    "alpha_3": "AFG",
    "numeric": "4",
    "phone_code": "93",
    "language_id": "40",
    "display_code": "+93"
  },
  {
    "id": "146",
    "name_en": "Lithuania",
    "name_uk": "Литва",
    "name_ru": "Литва",
    "alpha_2": "LT",
    "alpha_3": "LTU",
    "numeric": "440",
    "phone_code": "370",
    "language_id": "40",
    "display_code": "+370"
  },
  {
    "id": "147",
    "name_en": "Belarus",
    "name_uk": "Білорусь",
    "name_ru": "Белоруссия",
    "alpha_2": "BY",
    "alpha_3": "BLR",
    "numeric": "112",
    "phone_code": "375",
    "language_id": "40",
    "display_code": "+375"
  },
  {
    "id": "148",
    "name_en": "Switzerland",
    "name_uk": "Швейцарія",
    "name_ru": "Швейцария",
    "alpha_2": "CH",
    "alpha_3": "CHE",
    "numeric": "756",
    "phone_code": "41",
    "language_id": "40",
    "display_code": "+41"
  },
  {
    "id": "149",
    "name_en": "New caledonia",
    "name_uk": "Нова Каледонія",
    "name_ru": "Новая Каледония",
    "alpha_2": "NC",
    "alpha_3": "NCL",
    "numeric": "540",
    "phone_code": "687",
    "language_id": "40",
    "display_code": "+687"
  },
  {
    "id": "150",
    "name_en": "Curacao",
    "name_uk": "Кюрасао",
    "name_ru": "Кюрасао",
    "alpha_2": "CW",
    "alpha_3": "CUW",
    "numeric": "531",
    "phone_code": "599",
    "language_id": "40",
    "display_code": "+599"
  },
  {
    "id": "151",
    "name_en": "Faroe Islands",
    "name_uk": "Фарери",
    "name_ru": "Фареры",
    "alpha_2": "FO",
    "alpha_3": "FRO",
    "numeric": "234",
    "phone_code": "298",
    "language_id": "40",
    "display_code": "+298"
  },
  {
    "id": "152",
    "name_en": "Turkmenistan",
    "name_uk": "Туркменія",
    "name_ru": "Туркмения",
    "alpha_2": "TM",
    "alpha_3": "TKM",
    "numeric": "795",
    "phone_code": "993",
    "language_id": "40",
    "display_code": "+993"
  },
  {
    "id": "153",
    "name_en": "Bolivia",
    "name_uk": "Болівія",
    "name_ru": "Боливия",
    "alpha_2": "BO",
    "alpha_3": "BOL",
    "numeric": "68",
    "phone_code": "591",
    "language_id": "40",
    "display_code": "+591"
  },
  {
    "id": "154",
    "name_en": "Papua New Guinea",
    "name_uk": "Папуа Нова Гвінея",
    "name_ru": "Папуа — Новая Гвинея",
    "alpha_2": "PG",
    "alpha_3": "PNG",
    "numeric": "598",
    "phone_code": "675",
    "language_id": "40",
    "display_code": "+675"
  },
  {
    "id": "155",
    "name_en": "Solomon islands",
    "name_uk": "Соломонові Острови",
    "name_ru": "Соломоновы Острова",
    "alpha_2": "SB",
    "alpha_3": "SLB",
    "numeric": "90",
    "phone_code": "677",
    "language_id": "40",
    "display_code": "+677"
  },
  {
    "id": "156",
    "name_en": "United Kingdom",
    "name_uk": "Великобританія",
    "name_ru": "Великобритания",
    "alpha_2": "GB",
    "alpha_3": "GBR",
    "numeric": "826",
    "phone_code": "44",
    "language_id": "40",
    "display_code": "+44"
  },
  {
    "id": "157",
    "name_en": "Madagascar",
    "name_uk": "Мадагаскар",
    "name_ru": "Мадагаскар",
    "alpha_2": "MG",
    "alpha_3": "MDG",
    "numeric": "450",
    "phone_code": "261",
    "language_id": "40",
    "display_code": "+261"
  },
  {
    "id": "158",
    "name_en": "Equatorial Guinea",
    "name_uk": "Екваторіальна Гвінея",
    "name_ru": "Экваториальная Гвинея",
    "alpha_2": "GQ",
    "alpha_3": "GNQ",
    "numeric": "226",
    "phone_code": "240",
    "language_id": "40",
    "display_code": "+240"
  },
  {
    "id": "159",
    "name_en": "Cameroon",
    "name_uk": "Камерун",
    "name_ru": "Камерун",
    "alpha_2": "CM",
    "alpha_3": "CMR",
    "numeric": "120",
    "phone_code": "237",
    "language_id": "40",
    "display_code": "+237"
  },
  {
    "id": "160",
    "name_en": "Brunei",
    "name_uk": "Бруней",
    "name_ru": "Бруней",
    "alpha_2": "BN",
    "alpha_3": "BRN",
    "numeric": "96",
    "phone_code": "673",
    "language_id": "40",
    "display_code": "+673"
  },
  {
    "id": "161",
    "name_en": "Serbia and Montenegro",
    "name_uk": "Сербія і Чорногорія",
    "name_ru": "Сербия и Черногория",
    "alpha_2": "ME",
    "alpha_3": "MNE",
    "numeric": "499",
    "phone_code": "381",
    "language_id": "40",
    "display_code": "+381"
  },
  {
    "id": "162",
    "name_en": "Sierra leone",
    "name_uk": "Сьєрра-Леоне",
    "name_ru": "Сьерра-Леоне",
    "alpha_2": "SL",
    "alpha_3": "SLE",
    "numeric": "694",
    "phone_code": "232",
    "language_id": "40",
    "display_code": "+232"
  },
  {
    "id": "163",
    "name_en": "Salvador",
    "name_uk": "Сальвадор",
    "name_ru": "Сальвадор",
    "alpha_2": "SV",
    "alpha_3": "SLV",
    "numeric": "222",
    "phone_code": "503",
    "language_id": "40",
    "display_code": "+503"
  },
  {
    "id": "164",
    "name_en": "New Zealand",
    "name_uk": "Нова Зеландія",
    "name_ru": "Новая Зеландия",
    "alpha_2": "NZ",
    "alpha_3": "NZL",
    "numeric": "554",
    "phone_code": "64",
    "language_id": "40",
    "display_code": "+64"
  },
  {
    "id": "165",
    "name_en": "Syria",
    "name_uk": "Сирія",
    "name_ru": "Сирия",
    "alpha_2": "SY",
    "alpha_3": "SYR",
    "numeric": "760",
    "phone_code": "963",
    "language_id": "40",
    "display_code": "+963"
  },
  {
    "id": "166",
    "name_en": "Guiana",
    "name_uk": "Гвіана",
    "name_ru": "Гвиана",
    "alpha_2": "GF",
    "alpha_3": "GUF",
    "numeric": "254",
    "phone_code": "594",
    "language_id": "40",
    "display_code": "+594"
  },
  {
    "id": "167",
    "name_en": "Australia",
    "name_uk": "Австралія",
    "name_ru": "Австралия",
    "alpha_2": "AU",
    "alpha_3": "AUS",
    "numeric": "36",
    "phone_code": "61",
    "language_id": "40",
    "display_code": "+61"
  },
  {
    "id": "168",
    "name_en": "Andorra",
    "name_uk": "Андорра",
    "name_ru": "Андорра",
    "alpha_2": "AD",
    "alpha_3": "AND",
    "numeric": "20",
    "phone_code": "376",
    "language_id": "40",
    "display_code": "+376"
  },
  {
    "id": "169",
    "name_en": "Venezuela",
    "name_uk": "Венесуела",
    "name_ru": "Венесуэла",
    "alpha_2": "VE",
    "alpha_3": "VEN",
    "numeric": "862",
    "phone_code": "58",
    "language_id": "40",
    "display_code": "+58"
  },
  {
    "id": "170",
    "name_en": "UAE",
    "name_uk": "ОАЕ",
    "name_ru": "ОАЭ",
    "alpha_2": "AE",
    "alpha_3": "ARE",
    "numeric": "784",
    "phone_code": "971",
    "language_id": "40",
    "display_code": "+971"
  },
  {
    "id": "171",
    "name_en": "San marino",
    "name_uk": "Сан-Марино",
    "name_ru": "Сан-Марино",
    "alpha_2": "SM",
    "alpha_3": "SMR",
    "numeric": "674",
    "phone_code": "378",
    "language_id": "40",
    "display_code": "+378"
  },
  {
    "id": "172",
    "name_en": "Guinea bissau",
    "name_uk": "Гвінея-Бісау",
    "name_ru": "Гвинея-Бисау",
    "alpha_2": "GW",
    "alpha_3": "GNB",
    "numeric": "624",
    "phone_code": "245",
    "language_id": "40",
    "display_code": "+245"
  },
  {
    "id": "173",
    "name_en": "Bangladesh",
    "name_uk": "Бангладеш",
    "name_ru": "Бангладеш",
    "alpha_2": "BD",
    "alpha_3": "BGD",
    "numeric": "50",
    "phone_code": "880",
    "language_id": "40",
    "display_code": "+880"
  },
  {
    "id": "174",
    "name_en": "Botswana",
    "name_uk": "Ботсвана",
    "name_ru": "Ботсвана",
    "alpha_2": "BW",
    "alpha_3": "BWA",
    "numeric": "72",
    "phone_code": "267",
    "language_id": "40",
    "display_code": "+267"
  },
  {
    "id": "175",
    "name_en": "Albania",
    "name_uk": "Албанія",
    "name_ru": "Албания",
    "alpha_2": "AL",
    "alpha_3": "ALB",
    "numeric": "8",
    "phone_code": "355",
    "language_id": "40",
    "display_code": "+355"
  },
  {
    "id": "176",
    "name_en": "Greenland",
    "name_uk": "Гренландія",
    "name_ru": "Гренландия",
    "alpha_2": "GL",
    "alpha_3": "GRL",
    "numeric": "304",
    "phone_code": "299",
    "language_id": "40",
    "display_code": "+299"
  },
  {
    "id": "177",
    "name_en": "South Sudan",
    "name_uk": "південний Судан",
    "name_ru": "Южный Судан",
    "alpha_2": "SS",
    "alpha_3": "SSD",
    "numeric": "728",
    "phone_code": "211",
    "language_id": "40",
    "display_code": "+211"
  },
  {
    "id": "178",
    "name_en": "DPRK",
    "name_uk": "КНДР",
    "name_ru": "КНДР",
    "alpha_2": "KP",
    "alpha_3": "PRK",
    "numeric": "408",
    "phone_code": "850",
    "language_id": "40",
    "display_code": "+850"
  },
  {
    "id": "179",
    "name_en": "Norfolk Island",
    "name_uk": "острів Норфолк",
    "name_ru": "Остров Норфолк",
    "alpha_2": "NF",
    "alpha_3": "NFK",
    "numeric": "574",
    "phone_code": "672",
    "language_id": "40",
    "display_code": "+672"
  },
  {
    "id": "180",
    "name_en": "Virgin Islands (USA)",
    "name_uk": "Віргінські Острови (США)",
    "name_ru": "Виргинские Острова (США)",
    "alpha_2": "VI",
    "alpha_3": "VIR",
    "numeric": "850",
    "phone_code": "1340",
    "language_id": "40",
    "display_code": "+1340"
  },
  {
    "id": "181",
    "name_en": "Honduras",
    "name_uk": "Гондурас",
    "name_ru": "Гондурас",
    "alpha_2": "HN",
    "alpha_3": "HND",
    "numeric": "340",
    "phone_code": "504",
    "language_id": "40",
    "display_code": "+504"
  },
  {
    "id": "182",
    "name_en": "Dominican Republic",
    "name_uk": "Домініканська республіка",
    "name_ru": "Доминиканская Республика",
    "alpha_2": "DO",
    "alpha_3": "DOM",
    "numeric": "214",
    "phone_code": "1809",
    "language_id": "40",
    "display_code": "+1809"
  },
  {
    "id": "183",
    "name_en": "Guadeloupe",
    "name_uk": "Гваделупа",
    "name_ru": "Гваделупа",
    "alpha_2": "GP",
    "alpha_3": "GLP",
    "numeric": "312",
    "phone_code": "590",
    "language_id": "40",
    "display_code": "+590"
  },
  {
    "id": "184",
    "name_en": "Kuwait",
    "name_uk": "Кувейт",
    "name_ru": "Кувейт",
    "alpha_2": "KW",
    "alpha_3": "KWT",
    "numeric": "414",
    "phone_code": "965",
    "language_id": "40",
    "display_code": "+965"
  },
  {
    "id": "185",
    "name_en": "CAR",
    "name_uk": "ЦАР",
    "name_ru": "ЦАР",
    "alpha_2": "CF",
    "alpha_3": "CAF",
    "numeric": "140",
    "phone_code": "236",
    "language_id": "40",
    "display_code": "+236"
  },
  {
    "id": "186",
    "name_en": "Northern Mariana Islands",
    "name_uk": "Північні Маріанські острови",
    "name_ru": "Северные Марианские Острова",
    "alpha_2": "MP",
    "alpha_3": "MNP",
    "numeric": "580",
    "phone_code": "1670",
    "language_id": "40",
    "display_code": "+1670"
  },
  {
    "id": "187",
    "name_en": "Czech",
    "name_uk": "Чехія",
    "name_ru": "Чехия",
    "alpha_2": "CZ",
    "alpha_3": "CZE",
    "numeric": "203",
    "phone_code": "420",
    "language_id": "40",
    "display_code": "+420"
  },
  {
    "id": "188",
    "name_en": "Netherlands",
    "name_uk": "Нідерланди",
    "name_ru": "Нидерланды",
    "alpha_2": "NL",
    "alpha_3": "NLD",
    "numeric": "528",
    "phone_code": "31",
    "language_id": "40",
    "display_code": "+31"
  },
  {
    "id": "189",
    "name_en": "Virgin Islands (UK)",
    "name_uk": "Віргінські Острови (Великобританія)",
    "name_ru": "Виргинские Острова (Великобритания)",
    "alpha_2": "VG",
    "alpha_3": "VGB",
    "numeric": "92",
    "phone_code": "1284",
    "language_id": "40",
    "display_code": "+1284"
  },
  {
    "id": "190",
    "name_en": "Sao Tome and Principe",
    "name_uk": "Сан-Томе і Прінсіпі",
    "name_ru": "Сан-Томе и Принсипи",
    "alpha_2": "ST",
    "alpha_3": "STP",
    "numeric": "678",
    "phone_code": "239",
    "language_id": "40",
    "display_code": "+239"
  },
  {
    "id": "191",
    "name_en": "Burundi",
    "name_uk": "Бурунді",
    "name_ru": "Бурунди",
    "alpha_2": "BI",
    "alpha_3": "BDI",
    "numeric": "108",
    "phone_code": "257",
    "language_id": "40",
    "display_code": "+257"
  },
  {
    "id": "192",
    "name_en": "Suriname",
    "name_uk": "Суринам",
    "name_ru": "Суринам",
    "alpha_2": "SR",
    "alpha_3": "SUR",
    "numeric": "740",
    "phone_code": "597",
    "language_id": "40",
    "display_code": "+597"
  },
  {
    "id": "193",
    "name_en": "Cook Islands",
    "name_uk": "Острови Кука",
    "name_ru": "Острова Кука",
    "alpha_2": "CK",
    "alpha_3": "COK",
    "numeric": "184",
    "phone_code": "682",
    "language_id": "40",
    "display_code": "+682"
  },
  {
    "id": "194",
    "name_en": "Islands of St. Helena, Ascension and Tristan da Cunha",
    "name_uk": "Острови Святої Єлени, Вознесіння і Трістан-да-Кунья",
    "name_ru": "Острова Святой Елены, Вознесения и Тристан-да-Кунья",
    "alpha_2": "SH",
    "alpha_3": "SHN",
    "numeric": "654",
    "phone_code": "290",
    "language_id": "40",
    "display_code": "+290"
  },
  {
    "id": "195",
    "name_en": "Saint Kitts and Nevis",
    "name_uk": "Сент-Кітс і Невіс",
    "name_ru": "Сент-Китс и Невис",
    "alpha_2": "KN",
    "alpha_3": "KNA",
    "numeric": "659",
    "phone_code": "1869",
    "language_id": "40",
    "display_code": "+1869"
  },
  {
    "id": "196",
    "name_en": "Falkland Islands",
    "name_uk": "Фолклендські острови",
    "name_ru": "Фолклендские острова",
    "alpha_2": "FK",
    "alpha_3": "FLK",
    "numeric": "238",
    "phone_code": "500",
    "language_id": "40",
    "display_code": "+500"
  },
  {
    "id": "197",
    "name_en": "Vanuatu",
    "name_uk": "Вануату",
    "name_ru": "Вануату",
    "alpha_2": "VU",
    "alpha_3": "VUT",
    "numeric": "548",
    "phone_code": "678",
    "language_id": "40",
    "display_code": "+678"
  },
  {
    "id": "198",
    "name_en": "The Republic of Korea",
    "name_uk": "Республіка Корея",
    "name_ru": "Республика Корея",
    "alpha_2": "KR",
    "alpha_3": "KOR",
    "numeric": "410",
    "phone_code": "82",
    "language_id": "40",
    "display_code": "+82"
  },
  {
    "id": "199",
    "name_en": "South Africa",
    "name_uk": "ПАР",
    "name_ru": "ЮАР",
    "alpha_2": "ZA",
    "alpha_3": "ZAF",
    "numeric": "710",
    "phone_code": "27",
    "language_id": "40",
    "display_code": "+27"
  },
  {
    "id": "200",
    "name_en": "Vietnam",
    "name_uk": "В'єтнам",
    "name_ru": "Вьетнам",
    "alpha_2": "VN",
    "alpha_3": "VNM",
    "numeric": "704",
    "phone_code": "84",
    "language_id": "40",
    "display_code": "+84"
  },
  {
    "id": "201",
    "name_en": "DR Congo",
    "name_uk": "ДР Конго",
    "name_ru": "ДР Конго",
    "alpha_2": "CD",
    "alpha_3": "COD",
    "numeric": "180",
    "phone_code": "243",
    "language_id": "40",
    "display_code": "+243"
  },
  {
    "id": "202",
    "name_en": "Egypt",
    "name_uk": "Єгипет",
    "name_ru": "Египет",
    "alpha_2": "EG",
    "alpha_3": "EGY",
    "numeric": "818",
    "phone_code": "20",
    "language_id": "40",
    "display_code": "+20"
  },
  {
    "id": "203",
    "name_en": "Kiribati",
    "name_uk": "Кірібаті",
    "name_ru": "Кирибати",
    "alpha_2": "KI",
    "alpha_3": "KIR",
    "numeric": "296",
    "phone_code": "686",
    "language_id": "40",
    "display_code": "+686"
  },
  {
    "id": "204",
    "name_en": "Puerto rico",
    "name_uk": "Пуерто-Ріко",
    "name_ru": "Пуэрто-Рико",
    "alpha_2": "PR",
    "alpha_3": "PRI",
    "numeric": "630",
    "phone_code": "1787",
    "language_id": "40",
    "display_code": "+1787"
  },
  {
    "id": "205",
    "name_en": "Eritrea",
    "name_uk": "Еритрея",
    "name_ru": "Эритрея",
    "alpha_2": "ER",
    "alpha_3": "ERI",
    "numeric": "232",
    "phone_code": "291",
    "language_id": "40",
    "display_code": "+291"
  },
  {
    "id": "206",
    "name_en": "French polynesia",
    "name_uk": "Французька Полінезія",
    "name_ru": "Французская Полинезия",
    "alpha_2": "PF",
    "alpha_3": "PYF",
    "numeric": "258",
    "phone_code": "689",
    "language_id": "40",
    "display_code": "+689"
  },
  {
    "id": "207",
    "name_en": "Turks and Caicos Islands",
    "name_uk": "Теркс і Кайкос",
    "name_ru": "Теркс и Кайкос",
    "alpha_2": "TC",
    "alpha_3": "TCA",
    "numeric": "796",
    "phone_code": "1649",
    "language_id": "40",
    "display_code": "+1649"
  },
  {
    "id": "208",
    "name_en": "Paraguay",
    "name_uk": "Парагвай",
    "name_ru": "Парагвай",
    "alpha_2": "PY",
    "alpha_3": "PRY",
    "numeric": "600",
    "phone_code": "595",
    "language_id": "40",
    "display_code": "+595"
  },
  {
    "id": "209",
    "name_en": "Poland",
    "name_uk": "Польща",
    "name_ru": "Польша",
    "alpha_2": "PL",
    "alpha_3": "POL",
    "numeric": "616",
    "phone_code": "48",
    "language_id": "40",
    "display_code": "+48"
  },
  {
    "id": "210",
    "name_en": "Saint Vincent and the Grenadines",
    "name_uk": "Сент-Вінсент і Гренадіни",
    "name_ru": "Сент-Винсент и Гренадины",
    "alpha_2": "VC",
    "alpha_3": "VCT",
    "numeric": "670",
    "phone_code": "1784",
    "language_id": "40",
    "display_code": "+1784"
  },
  {
    "id": "211",
    "name_en": "State of Palestine",
    "name_uk": "держава Палестина",
    "name_ru": "Государство Палестина",
    "alpha_2": "PS",
    "alpha_3": "PSE",
    "numeric": "275",
    "phone_code": "970",
    "language_id": "40",
    "display_code": "+970"
  },
  {
    "id": "212",
    "name_en": "Svalbard and Jan Mayen",
    "name_uk": "Шпiцберген i Ян Майен",
    "name_ru": "Шпицберген и Ян Майен",
    "alpha_2": "SJ",
    "alpha_3": "SJM",
    "numeric": "744",
    "phone_code": "0",
    "language_id": "40",
    "display_code": "+0"
  },
  {
    "id": "213",
    "name_en": "Algeria",
    "name_uk": "Алжир",
    "name_ru": "Алжир",
    "alpha_2": "DZ",
    "alpha_3": "DZA",
    "numeric": "12",
    "phone_code": "213",
    "language_id": "40",
    "display_code": "+213"
  },
  {
    "id": "214",
    "name_en": "Barbados",
    "name_uk": "Барбадос",
    "name_ru": "Барбадос",
    "alpha_2": "BB",
    "alpha_3": "BRB",
    "numeric": "52",
    "phone_code": "1246",
    "language_id": "40",
    "display_code": "+1246"
  },
  {
    "id": "215",
    "name_en": "Burkina Faso",
    "name_uk": "Буркіна-Фасо",
    "name_ru": "Буркина-Фасо",
    "alpha_2": "BF",
    "alpha_3": "BFA",
    "numeric": "854",
    "phone_code": "226",
    "language_id": "40",
    "display_code": "+226"
  },
  {
    "id": "216",
    "name_en": "Colombia",
    "name_uk": "Колумбія",
    "name_ru": "Колумбия",
    "alpha_2": "CO",
    "alpha_3": "COL",
    "numeric": "170",
    "phone_code": "57",
    "language_id": "40",
    "display_code": "+57"
  },
  {
    "id": "217",
    "name_en": "Iceland",
    "name_uk": "Ісландія",
    "name_ru": "Исландия",
    "alpha_2": "IS",
    "alpha_3": "ISL",
    "numeric": "352",
    "phone_code": "354",
    "language_id": "40",
    "display_code": "+354"
  },
  {
    "id": "218",
    "name_en": "Maldives",
    "name_uk": "Мальдіви",
    "name_ru": "Мальдивы",
    "alpha_2": "MV",
    "alpha_3": "MDV",
    "numeric": "462",
    "phone_code": "960",
    "language_id": "40",
    "display_code": "+960"
  },
  {
    "id": "219",
    "name_en": "Marshall Islands",
    "name_uk": "Маршаллові Острови",
    "name_ru": "Маршалловы Острова",
    "alpha_2": "MH",
    "alpha_3": "MHL",
    "numeric": "584",
    "phone_code": "692",
    "language_id": "40",
    "display_code": "+692"
  },
  {
    "id": "220",
    "name_en": "Micronesia",
    "name_uk": "Мікронезія",
    "name_ru": "Микронезия",
    "alpha_2": "FM",
    "alpha_3": "FSM",
    "numeric": "583",
    "phone_code": "691",
    "language_id": "40",
    "display_code": "+691"
  },
  {
    "id": "221",
    "name_en": "Pitcairn Islands",
    "name_uk": "Острови Піткерн",
    "name_ru": "Острова Питкэрн",
    "alpha_2": "PN",
    "alpha_3": "PCN",
    "numeric": "612",
    "phone_code": "64",
    "language_id": "40",
    "display_code": "+64"
  },
  {
    "id": "222",
    "name_en": "Saint Pierre and Miquelon",
    "name_uk": "Сен-П'єр і Мікелон",
    "name_ru": "Сен-Пьер и Микелон",
    "alpha_2": "PM",
    "alpha_3": "SPM",
    "numeric": "666",
    "phone_code": "508",
    "language_id": "40",
    "display_code": "+508"
  },
  {
    "id": "223",
    "name_en": "Sweden",
    "name_uk": "Швеція",
    "name_ru": "Швеция",
    "alpha_2": "SE",
    "alpha_3": "SWE",
    "numeric": "752",
    "phone_code": "46",
    "language_id": "40",
    "display_code": "+46"
  },
  {
    "id": "224",
    "name_en": "American Samoa",
    "name_uk": "американське Самоа",
    "name_ru": "Американское Самоа",
    "alpha_2": "AS",
    "alpha_3": "ASM",
    "numeric": "16",
    "phone_code": "1684",
    "language_id": "40",
    "display_code": "+1684"
  },
  {
    "id": "225",
    "name_en": "Antigua and Barbuda",
    "name_uk": "Антигуа і Барбуда",
    "name_ru": "Антигуа и Барбуда",
    "alpha_2": "AG",
    "alpha_3": "ATG",
    "numeric": "28",
    "phone_code": "1268",
    "language_id": "40",
    "display_code": "+1268"
  },
  {
    "id": "226",
    "name_en": "Argentina",
    "name_uk": "Аргентина",
    "name_ru": "Аргентина",
    "alpha_2": "AR",
    "alpha_3": "ARG",
    "numeric": "32",
    "phone_code": "54",
    "language_id": "40",
    "display_code": "+54"
  },
  {
    "id": "227",
    "name_en": "Armenia",
    "name_uk": "Вірменія",
    "name_ru": "Армения",
    "alpha_2": "AM",
    "alpha_3": "ARM",
    "numeric": "51",
    "phone_code": "374",
    "language_id": "40",
    "display_code": "+374"
  },
  {
    "id": "228",
    "name_en": "Cambodia",
    "name_uk": "Камбоджа",
    "name_ru": "Камбоджа",
    "alpha_2": "KH",
    "alpha_3": "KHM",
    "numeric": "116",
    "phone_code": "855",
    "language_id": "40",
    "display_code": "+855"
  },
  {
    "id": "229",
    "name_en": "Bonaire, St. Eustatius and Saba",
    "name_uk": "Нідерландські Карибські острови",
    "name_ru": "Бонэйр, Синт-Эстатиус и Саба",
    "alpha_2": "BQ",
    "alpha_3": "BES",
    "numeric": "535",
    "phone_code": "599",
    "language_id": "40",
    "display_code": "+599"
  },
  {
    "id": "230",
    "name_en": "Portugal",
    "name_uk": "Португалія",
    "name_ru": "Португалия",
    "alpha_2": "PT",
    "alpha_3": "PRT",
    "numeric": "620",
    "phone_code": "351",
    "language_id": "40",
    "display_code": "+351"
  },
  {
    "id": "231",
    "name_en": "Saint lucia",
    "name_uk": "Сент-Люсія",
    "name_ru": "Сент-Люсия",
    "alpha_2": "LC",
    "alpha_3": "LCA",
    "numeric": "662",
    "phone_code": "1758",
    "language_id": "40",
    "display_code": "+1758"
  },
  {
    "id": "232",
    "name_en": "Bosnia and Herzegovina",
    "name_uk": "Боснія і Герцеговина",
    "name_ru": "Босния и Герцеговина",
    "alpha_2": "BA",
    "alpha_3": "BIH",
    "numeric": "70",
    "phone_code": "387",
    "language_id": "40",
    "display_code": "+387"
  },
  {
    "id": "233",
    "name_en": "Iraq",
    "name_uk": "Ірак",
    "name_ru": "Ирак",
    "alpha_2": "IQ",
    "alpha_3": "IRQ",
    "numeric": "368",
    "phone_code": "964",
    "language_id": "40",
    "display_code": "+964"
  },
  {
    "id": "234",
    "name_en": "Liechtenstein",
    "name_uk": "Ліхтенштейн",
    "name_ru": "Лихтенштейн",
    "alpha_2": "LI",
    "alpha_3": "LIE",
    "numeric": "438",
    "phone_code": "423",
    "language_id": "40",
    "display_code": "+423"
  },
  {
    "id": "235",
    "name_en": "Georgia",
    "name_uk": "Грузія",
    "name_ru": "Грузия",
    "alpha_2": "GE",
    "alpha_3": "GEO",
    "numeric": "268",
    "phone_code": "995",
    "language_id": "40",
    "display_code": "+995"
  }
];