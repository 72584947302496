const initialState = {
  currencies: null,
  loading: false,
  error: null,
};

export const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_CURRENCIES_START':
      return {...state, loading: true};
    case 'FETCH_CURRENCIES_SUCCESS':
      return {...state, currencies: action.payload, loading: false};
    case 'FETCH_CURRENCIES_FAILURE':
      return {...state, error: action.payload, loading: false};
    default:
      return state;
  }
};