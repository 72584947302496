import React from 'react';
import {useTranslation} from 'react-i18next';
import {SelectBox} from 'devextreme-react';
import ItemTemplate from './method-select-templates/item-template';
import FieldTemplate from './method-select-templates/field-template';
import GroupTemplate from './method-select-templates/group-template';

const Methods = (props) => {
	const {t} = useTranslation();
	const {methods, paymentMethodValueId, paymentTypeId, changePaymentMethod, readOnly} = props;

	return (
		<div className={'operations-row'}>
			<div className={'operations-row-inner'}>
				<div className={'operations-title'}>{t('OPERATIONS.PAYMENT_METHODS')}</div>
				<div className={'operations-fields-wrapper'}>
					<div className={'method-select-wrapper'}>
						<SelectBox
							grouped
							value={paymentMethodValueId}
							items={methods}
							readOnly={readOnly}
							stylingMode={'outlined'}
							placeholder={t('COMMON.SELECT_BOX')}
							displayExpr={'ForeignPaymentSystemTypeName'}
							valueExpr={'ForeignPaymentSystemTypeID'}
							onValueChanged={({value}) => {
								changePaymentMethod(value);
							}}
							itemComponent={ItemTemplate}
							fieldComponent={FieldTemplate}
							groupRender={(props) => {
								return (
									<GroupTemplate paymentTypeId={paymentTypeId} data={props}/>
								);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Methods;
