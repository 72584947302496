import React from 'react';
import {useTranslation} from 'react-i18next';
import {copyTextToClipboard} from 'utils/functions';
import {notifyApp} from 'utils/notifyWrapper';
import {vars} from 'utils/variables';
import CopyIcon from '../../svg/copy-icon';

const {NOTIFY} = vars;

const CopyButton = ({value}) => {
	const {t} = useTranslation();

	return (
		<div className={'copy-button-wrapper'}>
			<div
				onClick={() => {
					notifyApp(t('COMMON.COPIED'), NOTIFY.SUCCESS);
					copyTextToClipboard(value);
				}}
				className={'copy-button'}
			>
        <CopyIcon/>
			</div>
		</div>
	);
}

export default CopyButton;