import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {parseMultilineText} from 'utils/functions';
import {CheckBox} from 'devextreme-react';
import Validator, {CompareRule} from 'devextreme-react/validator';
import {Button} from 'devextreme-react/button';
import {createBanxeAccount} from 'services/async';
import {useHistory} from 'react-router-dom';
import {notifyApp} from 'utils/notifyWrapper';
import {vars} from 'utils/variables';

const {SETTINGS_SCREENS: {VERIFICATION}} = vars;

const BanxeAccountFields = (props) => {
  const {userProfile, handleClose, showOtpPopup, hideOptPopup, changeActiveScreen} = props;
  const history = useHistory();
  const {t} = useTranslation();
  const [agreement, setAgreement] = useState(false);

  const agreementCompare = () => {
    return true;
  }

  const createAccount = async () => {
    try {
      const request = await createBanxeAccount();

      if (request.isSuccess) {
        return request.response;
      } else {
        throw new Error(request.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  const goToVerification = () => {
    const destinationId = 'settings';
    changeActiveScreen({
      screen: VERIFICATION,
      isBanxe: true,
    });
    history.push(`/${destinationId}`);
  }

  return (
    <div className={'banxe-fields'}>
      <div className={'notice'}>
        {parseMultilineText(t('MAIN.ADD_ACCOUNT.NOTICE')).map((line, index) => {
          return (
            <p key={`line-${index}`}>{line}</p>
          );
        })}
      </div>
      <div className={'dx-field add-walletto-checkbox'}>
        <CheckBox
          name={'registration-agreement-checkbox'}
          id={'agreement-checkbox-text'}
          validationMessageMode={'always'}
          defaultValue={false}
          value={agreement}
          text={''}
          onValueChanged={({value}) => {
            setAgreement(value);
          }}
        >
          <Validator validationGroup={'registrationGroup'}>
            <CompareRule
              message={''}
              comparisonTarget={agreementCompare}
            />
          </Validator>
        </CheckBox>
        <div>
          <span>{t('MAIN.ADD_ACCOUNT.CONFIRM_CHECKBOX_PRE')}</span>
          <a
            className={'terms-link'}
            target={'_blank'}
            href={userProfile.BanxeTermsUrl}
          >
            {t('MAIN.ADD_ACCOUNT.CONFIRM_CHECKBOX_LINK')}
          </a>
          <span>{t('MAIN.ADD_ACCOUNT.CONFIRM_CHECKBOX_POST')}</span>
        </div>
      </div>
      <div className={'popup-buttons'}>
        <Button
          id={'popup-buttons_add'}
          width={'50%'}
          height={40}
          text={t('MAIN.ADD_ACCOUNT.ADD')}
          type={'default'}
          stylingMode={'contained'}
          disabled={!agreement}
          onClick={({component}) => {
            component.option('disabled', true);

            createAccount().then((response) => {
              showOtpPopup({
                onResend: createAccount,
                onSubmit: async (otpCode) => {
                  const confirmRequest = await createBanxeAccount(otpCode);

                  if (confirmRequest.isSuccess) {
                    // push to settings
                    hideOptPopup();
                    goToVerification();
                  } else {
                    notifyApp(confirmRequest.error);
                  }
                },
                sendChannelId: response.SendChannelID,
                infoText: response.InfoText,
              });
            }).catch((error) => {
              notifyApp(error);
            }).finally( () => {
              setTimeout(() => {
                component.option('disabled', false);
              }, 500);
            });
          }}
        />
        <Button
          id={'popup-buttons_cancel'}
          width={'50%'}
          height={40}
          text={t('MAIN.ADD_ACCOUNT.CANCEL')}
          type={'default'}
          stylingMode={'outlined'}
          onClick={handleClose}
        />
      </div>
    </div>
  );
}

export default BanxeAccountFields;

