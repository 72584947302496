import React from 'react';
import '../settings.scss';
import {TextBox} from 'devextreme-react';
import {userProfileGetList} from 'services/async';
import {vars} from "utils/variables";
import {notifyApp} from "utils/notifyWrapper";
import {splitDateTimeToDate} from "utils/functions";
import ScrollView from 'devextreme-react/scroll-view';
import {withTranslation} from 'react-i18next';

class BusinessProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userProfile: {
				BirthCountry: '',
				BirthDate: '',
				Email: '',
				LivingCity: '',
				LivingCountry: '',
				LivingPostalIndex: '',
				LivingStreet: '',
				Name: '',
				PayoutConfirmType: '',
				Phone: '',
				Surname: '',
			}
		}
	}

	async componentDidMount() {
		const {NOTIFY} = vars;
		const response = await userProfileGetList();

		if (response.isSuccess) {
			this.setState({
				userProfile: response.response.UserProfile[0]
			});
		} else {
			notifyApp(response.error, NOTIFY.ERROR);
		}
	}

	render() {
		const {PROFILE} = vars;
		const {t} = this.props;
		const {userProfile} = this.state;
		return (
			<ScrollView>
				<div className={'settings-wrapper'}>
					<div className={'settings-header'}>
						<div className={'settings-header_inner'}>
							<div className={'settings-header_text'}>
								<i className={'mdi mdi-account-circle-outline'}/>
								<span>{t('SETTINGS.LEFT_BLOCK.PROFILE.TITLE')}</span>
							</div>
							{userProfile.VmVerificationStatusID === PROFILE.VERIFICATED_STATUS && (
								<div className={'settings-header_text'}>
									<i className={'settings-status_icon'}/>
									{t('SETTINGS.RIGHT_BLOCK.PROFILE.VERIFIED')}
								</div>
							)}
						</div>
					</div>
					<div className="settings-splitter"/>
					<div className={'settings-fields'}>
						<div className={'settings-fields_column'}>
							<span className={'settings-section_name'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.COMPANY_DETAILS')}</span>
							<div className={'settings-section_inputs'}>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.COMPANY_NAME')}</span>
									<TextBox readOnly value={userProfile.BUcompanyName}/>
								</div>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.REGISTRATION_NUMBER')}</span>
									<TextBox readOnly value={userProfile.BUregNumber}/>
								</div>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.COUNTRY_OF_INCORPORATION')}</span>
									<TextBox readOnly value={userProfile.BUregCountry}/>
								</div>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.INCORPORATION_DATE')}</span>
									<TextBox readOnly value={userProfile.BUdateOfReg}/>
								</div>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.BUSINESS_CATEGORY')}</span>
									<TextBox readOnly value={userProfile.BUcategory}/>
								</div>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.EMAIL')}</span>
									<TextBox readOnly value={userProfile.BUemail}/>
								</div>

							</div>
						</div>
						<div className={'settings-fields_column'}>
							<span className={'settings-section_name'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.DIRECTOR_PERSONAL_DETAILS')}</span>
							<div className={'settings-section_inputs'}>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.FIRST_NAME')}</span>
									<TextBox readOnly value={userProfile.Name}/>
								</div>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.LAST_NAME')}</span>
									<TextBox readOnly value={userProfile.Surname}/>
								</div>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.DATE_OF_BIRTH')}</span>
									<TextBox readOnly value={splitDateTimeToDate(userProfile.BirthDate)}/>
								</div>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.PLACE_OF_BIRTH')}</span>
									<TextBox readOnly value={userProfile.BUHEADcountryOfBirth}/>
								</div>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.EMAIL')}</span>
									<TextBox readOnly value={userProfile.Email}/>
								</div>
							</div>
						</div>
					</div>
					<div className="settings-splitter"/>
					<div className={'settings-fields'}>
						<div className={'settings-fields_column'}>
							<span className={'settings-section_name'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.REGISTERED_OFFICE')}</span>
							<div className={'settings-section_inputs'}>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.STREET')}</span>
									<TextBox readOnly value={userProfile.BUstreet}/>
								</div>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.CITY')}</span>
									<TextBox readOnly value={userProfile.BUtown}/>
								</div>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.COUNTRY')}</span>
									<TextBox readOnly value={userProfile.BUcountry}/>
								</div>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.ZIP_CODE')}</span>
									<TextBox readOnly value={userProfile.BUpostCode}/>
								</div>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.PHONE')}</span>
									<TextBox readOnly value={userProfile.BUphone}/>
								</div>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.ADDITIONAL_PHONE')}</span>
									<TextBox readOnly value={userProfile.BUphone2}/>
								</div>
							</div>
						</div>
						<div className={'settings-fields_column'}>
							<span className={'settings-section_name'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.DIRECTOR_ADDRESS')}</span>
							<div className={'settings-section_inputs'}>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.STREET')}</span>
									<TextBox readOnly value={userProfile.BUHEADstreet}/>
								</div>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.CITY')}</span>
									<TextBox readOnly value={userProfile.BUHEADtown}/>
								</div>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.COUNTRY')}</span>
									<TextBox readOnly value={userProfile.BUHEADcountry}/>
								</div>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.ZIP_CODE')}</span>
									<TextBox readOnly value={userProfile.BUHEADpostCode}/>
								</div>
								<div className={'settings-section_edit'}>
									<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.PHONE')}</span>
									<TextBox readOnly value={userProfile.BUHEADphone}/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ScrollView>
		);
	}
}

export default withTranslation()(BusinessProfile);
