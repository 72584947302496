import React from 'react';

const ArrowIcon = ({width = 10, height = 8, fill = '#0066FF'}) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M1.175 0.912476L5 4.72914L8.825 0.912476L10 2.08748L5 7.08748L0 2.08748L1.175 0.912476Z" fill={fill}/>
    </svg>
  );
}

export default ArrowIcon;