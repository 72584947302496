import {CLEAR_CVV, CHANGE_CVV, CHANGE_CARD_NUMBER} from './actions';

const initialState = {
	cvvCode: null,
	cardNumber: null,
};

export const mainReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case CHANGE_CARD_NUMBER:
			return {
				...state,
				cvvCode: null,
				cardNumber: payload.cardNumber,
			};
		case CHANGE_CVV:
			return {
				...state,
				cvvCode: payload.cvvCode,
				cardNumber: null,
			};
		case CLEAR_CVV:
			return initialState;
		default:
			return state;
	}
};
