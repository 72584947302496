import React, {useState, useEffect} from 'react';
import ArrowIcon from '../svg/arrow-icon';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import LockIcon from '../svg/lock-icon';

const AccountWidget = ({accountData, onDetailClick, isDetailOpen}) => {
  const {t} = useTranslation();
  const {
    currency,
    balance,
    account,
  } = accountData;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if(!isDetailOpen) {
      setIsOpen(false);
    }
  }, [isDetailOpen]);

  return (
    <div className={'account-widget'}>
      <div className={'account-row'}>
        <div className={'account-row-group'}>
          {account.IsLock ? (
            <LockIcon/>
          ) : null}
          <span className={'account-currency'}>{`${currency.Code} ${currency.Symbol}`}</span>
          <span>{t('MAIN.ACCOUNT_DETAIL_WIDGET.BALANCE')}</span>
        </div>
        <div className={'account-row-group'}>
          <span>{balance}</span>
          <span className={classNames('chevron', {
            up: isOpen && isDetailOpen,
          })} onClick={(event) => {
            setIsOpen(!isOpen);
            onDetailClick(event, accountData);
          }}>
            <ArrowIcon/>
          </span>
        </div>
      </div>
    </div>
  );
}

export default AccountWidget;