import React, {useMemo, useRef, useState} from 'react';
import {Button as TextBoxButton, TextBox} from 'devextreme-react/text-box';
import {CompareRule, CustomRule, PatternRule, RequiredRule, Validator} from 'devextreme-react/validator';
import './form-input.scss';

const FormInput = ({
                     value = '',
                     readOnly = false,
                     onInput = () => {
                     },
                     onEnterKey = () => {
                     },
                     onValueChanged = () => {
                     },
                     label,
                     placeholder = label,
                     type = 'text', validation = {group: 'none', rules: []}
                   }) => {
  const [inputType, setInputType] = useState(type);
  const [errorMessage, setErrorMessage] = useState('');
  const textBoxRef = useRef(null);

  // Handle validation status updates
  const handleValidation = (e) => {
    if (!e.isValid) {
      setErrorMessage(e.brokenRule.message);
    } else {
      setErrorMessage('');
    }
  };

  const passwordButton = useMemo(
    () => ({
      icon: inputType === 'password'
        ? '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M2.72891 12.7464C2.02064 11.8262 1.6665 11.3661 1.6665 9.99998C1.6665 8.63383 2.02064 8.17375 2.72891 7.25359C4.14314 5.41628 6.51493 3.33331 9.99984 3.33331C13.4847 3.33331 15.8565 5.41628 17.2708 7.25359C17.979 8.17375 18.3332 8.63383 18.3332 9.99998C18.3332 11.3661 17.979 11.8262 17.2708 12.7464C15.8565 14.5837 13.4847 16.6666 9.99984 16.6666C6.51493 16.6666 4.14314 14.5837 2.72891 12.7464Z" stroke="#1D1F20" stroke-width="1.5"/>\n' +
        '<path d="M12.5 10C12.5 11.3807 11.3807 12.5 10 12.5C8.61929 12.5 7.5 11.3807 7.5 10C7.5 8.61929 8.61929 7.5 10 7.5C11.3807 7.5 12.5 8.61929 12.5 10Z" stroke="#1D1F20" stroke-width="1.5"/>\n' +
        '</svg>'
        : '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<g clip-path="url(#clip0_2918_1258)">\n' +
        '<path d="M2.72891 12.7464C2.02064 11.8262 1.6665 11.3662 1.6665 10C1.6665 8.63386 2.02064 8.17378 2.72891 7.25362C4.14314 5.41631 6.51493 3.33334 9.99984 3.33334C13.4847 3.33334 15.8565 5.41631 17.2708 7.25362C17.979 8.17378 18.3332 8.63386 18.3332 10C18.3332 11.3662 17.979 11.8262 17.2708 12.7464C15.8565 14.5837 13.4847 16.6667 9.99984 16.6667C6.51493 16.6667 4.14314 14.5837 2.72891 12.7464Z" stroke="#1D1F20" stroke-width="1.5"/>\n' +
        '<path d="M12.5 10C12.5 11.3807 11.3807 12.5 10 12.5C8.61929 12.5 7.5 11.3807 7.5 10C7.5 8.61929 8.61929 7.5 10 7.5C11.3807 7.5 12.5 8.61929 12.5 10Z" stroke="#1D1F20" stroke-width="1.5"/>\n' +
        '<path d="M18.3332 1.66666L1.6665 18.3333" stroke="#1D1F20" stroke-width="1.5" stroke-linecap="round"/>\n' +
        '</g>\n' +
        '<defs>\n' +
        '<clipPath id="clip0_2918_1258">\n' +
        '<rect width="20" height="20" fill="white"/>\n' +
        '</clipPath>\n' +
        '</defs>\n' +
        '</svg>',
      /*width: 30,
      height: 30,*/
      focusStateEnabled: false,
      hoverStateEnabled: false,
      onClick: () => {
        setInputType((prevPasswordMode) => (prevPasswordMode === 'text' ? 'password' : 'text'));
      },
    }),
    [inputType, setInputType],
  );

  return (
    <div className={'form-input'}>
      <label className={'form-input-label'}>{label}</label>
      <TextBox
        value={value}
        readOnly={readOnly}
        onEnterKey={onEnterKey}
        ref={textBoxRef}
        mode={inputType}
        placeholder={placeholder}
        stylingMode={'outlined'}
        height={36}
        onValueChanged={onValueChanged}
        onInput={onInput}
      >
        {type === 'password' && (
          <TextBoxButton
            location={'after'}
            name={'password'}
            options={passwordButton}
          />
        )}
        <Validator onValidated={handleValidation} validationGroup={validation.group}>
          {validation.rules.map((rule, index) => {
            switch (rule.type) {
              case 'required':
                return <RequiredRule key={index} message={rule.message}/>;
              case 'pattern':
                return <PatternRule key={index} pattern={rule.pattern} message={rule.message}/>;
              case 'custom':
                return <CustomRule key={index} validationCallback={rule.validationCallback} message={rule.message}/>;
              case 'compare':
                return <CompareRule key={index} message={rule.message} comparisonTarget={rule.comparisonTarget}/>
              default:
                return null;
            }
          })}
        </Validator>
      </TextBox>
      {errorMessage && (
        <div className={'error-message'}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};
export default FormInput;