import {
  createStore,
  applyMiddleware,
  combineReducers,
  compose
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';
import {settingsReducer} from 'services/settings/reducer';
import {operationsReducer} from 'services/operations/reducer';
import {mainReducer} from 'services/main/reducer';
import {appReducer} from 'services/app/appReducer';
import {currencyReducer} from 'services/currency/currencyReducer';

const rootReducer = combineReducers({
	app: appReducer,
	main: mainReducer,
	operations: operationsReducer,
	settings: settingsReducer,
	currency: currencyReducer,
});

const loggerMiddleware = createLogger({
  duration: true,
  collapsed: true,
  colors: {
    title: () => '#139BFE',
    prevState: () => '#1c5faf',
    action: () => '#149945',
    nextState: () => '#A47104',
    error: () => '#ff0005'
  }
});

const devtools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
let composeEnhancers = compose;

const middleWares = [thunkMiddleware];

if (process.env.NODE_ENV !== 'production') {
  middleWares.push(loggerMiddleware);
  composeEnhancers = devtools ? devtools : compose;
}

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleWares))
);

export default store;
