import React from 'react';
import {Button, TextBox} from 'devextreme-react';
import validationEngine from 'devextreme/ui/validation_engine'
import Validator, {CompareRule, PatternRule, RequiredRule} from 'devextreme-react/validator';
import {CheckBox} from 'devextreme-react/check-box';
import {notifyApp} from 'utils/notifyWrapper';
import {apiRequest, getApplicationSettings, sendRegistrationOtp, sendRegistrationRequest} from 'services/async';
import OtpInput from '../../inputs/otp-input/otp-input';
import {conditionLinks, vars} from 'utils/variables';
import {withRouter} from 'react-router';
import {parseGetParams, sha512} from 'utils/functions';

import Loader from 'components/loader/loader';
import {withTranslation} from "react-i18next";

const {REG_EXP, NOTIFY, SUCCESS_API_CODE} = vars;

class Registration extends React.Component {
  constructor(props) {
    super(props);

    const getParams = parseGetParams(props.params);
    const referrerCode = getParams ? getParams['ref_code'] : null;

    this.state = {
      phone: '',
      email: '',
      password: '',
      otpCode: null,
      smsCode: '',
      isSmsCode: false,
      isOtpCode: false,
      isShowEmailConfirmation: false,
      isShowLoader: false,
      getParams: getParams,
      referrerCode: referrerCode,
      validationResult: true,
      validationOtpResult: true,
    };
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=6Leo1KYnAAAAAC1q-OumYOFrUz-SaBfrxQS5io9v';
    document.body.appendChild(script);
  }

  render() {
    const {phone, password, smsCode, isSmsCode, isShowLoader, isShowEmailConfirmation} = this.state;
    const {t} = this.props;
    return (
      <>
        <Loader
          isShowLoader={isShowLoader}
        />
        {isShowEmailConfirmation ? (
          <div>{this.emailWizardField()}</div>
        ) : (
          <div>
            <div className={'anti-autocomplete'}>
              <input/>
              <input type={'password'}/>
            </div>
            <div className={'dx-field'}>
              <i className={'mdi mdi-account-circle login-page-icon'}/>
              <TextBox
                name={'registration-phone'}
                height={50}
                value={phone}
                onValueChanged={this.onPhoneChanged}
                readOnly={isSmsCode}
                placeholder={t('COMMON.LOGIN_PHONE_ACCOUNT_PLACEHOLDER')}
                width={'100%'}
                onEnterKey={this.validateFields}
              >
                <Validator validationGroup={'registrationFieldsGroup'}>
                  <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                  <PatternRule
                    message={t('ERROR_MSG.WRONG_PHONE')}
                    pattern={REG_EXP.PHONE_NUMBER}
                  />
                </Validator>
              </TextBox>
            </div>
            {isSmsCode ? this.smsCodeFields(phone, smsCode) : this.registrationFields(password)}
          </div>
        )}
      </>
    );
  }

  validateFields = ({component}) => {
    const {isSmsCode, smsCode} = this.state;
    const result = validationEngine.validateGroup('registrationFieldsGroup');

    if (isSmsCode && !smsCode) {
      this.setState({
        validationResult: false,
      });

      if (result.isValid) {
        this.setState({
          isShowLoader: true,
        }, this.sendSmsCodeRequest);
      }

      return;
    }

    if (result.isValid) {
      this.setState({
        isShowLoader: true,
      }, isSmsCode ? this.sendSmsCodeRequest : this.sendRegistration);
    }
  };

  smsCodeFields(phone, smsCode) {
    const {validationResult} = this.state;
    const {t} = this.props;

    return (
      <>
        <div className={'dx-field'}>
          <OtpInput
            withFocusedState
            withResend={false}
            value={smsCode}
            placeholder={t('COMMON.SMS_CODE')}
            onValueChanged={this.onSmsCodeChanged}
            onEnterKey={this.validateFields}
            validationMessage={t('ERROR_MSG.REQUIRED')}
            isValid={validationResult}
            focusCallBack={() => {
              this.setState({
                validationResult: true,
              });
            }}
          />
        </div>
        <div className={'dx-field custom-login'}>
          <Button
            elementAttr={{name: 'registration-otp-submit'}}
            type={'default'}
            text={t('COMMON.SEND')}
            onClick={this.validateFields}
            width={'100%'}
          />
        </div>
      </>
    );
  }

  triggerGTMEvent = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({'event': 'registration.gtm'});
  }

  registrationFields = (password) => {
    const {t} = this.props;
    const {referrerCode} = this.state;
    return (
      <>
        <div className={'dx-field registration-input'}>
          <i className={'mdi mdi-lock-outline login-page-icon'}/>
          <TextBox
            name={'registration-password'}
            height={50}
            mode={'password'}
            value={password}
            onValueChanged={this.onPasswordChanged}
            placeholder={t('COMMON.LOGIN_PASS_PLACEHOLDER')}
            width={'100%'}
            onEnterKey={this.validateFields}
          >
            <Validator validationGroup={'registrationFieldsGroup'}>
              <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
              <PatternRule
                message={t('ERROR_MSG.WRONG_SYMBOLS')}
                pattern={REG_EXP.PASSWORD_PATTERN}
              />
            </Validator>
          </TextBox>
        </div>
        <div className={'dx-field'}>
          <i className={'mdi mdi-lock-outline login-page-icon'}/>
          <TextBox
            name={'registration-confirm-password'}
            height={50}
            mode={'password'}
            placeholder={t('COMMON.PASSWORD_CONFIRM')}
            width={'100%'}
            onEnterKey={this.validateFields}
          >
            <Validator validationGroup={'registrationFieldsGroup'}>
              <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
              <CompareRule message={t('ERROR_MSG.WRONG_PASSWORD_CONFIRM')}
                           comparisonTarget={this.passwordComparison}/>
            </Validator>
          </TextBox>
        </div>
        <div className={'dx-field'}>
          <i className={'mdi mdi-account-multiple login-page-icon'}/>
          <TextBox
            name={'registration-referrer_code'}
            height={50}
            mode={'text'}
            placeholder={t('COMMON.REFERRER_CODE')}
            width={'100%'}
            onEnterKey={this.validateFields}
            value={referrerCode}
            onValueChanged={this.onReferrerCodeChanged}
          />
        </div>
        <div className={'dx-field flex-field'}>
          <CheckBox
            name={'registration-agreement-checkbox'}
            id={'agreement-checkbox-text'}
            validationMessageMode={'always'}
            defaultValue={false}
            text={''}
          >
            <Validator validationGroup={'registrationFieldsGroup'}>
              <CompareRule message=""
                           comparisonTarget={this.checkComparison}/>
            </Validator>
          </CheckBox>
          <div className={'registration-agreement-text'}>
            <span>{t('CHECKBOX.PRE_CONFIRMATION_TEXT')}</span>
            <a
              className={'terms-link'}
              target={'_blank'}
              href={conditionLinks.termsAndConditions}
            >
              {t('CHECKBOX.CONFIRMATION_LINK')}
            </a>
            <span>{t('CHECKBOX.POST_CONFIRMATION_TEXT')}</span>
          </div>
        </div>
        <div className={'dx-field custom-login'}>
          <Button
            elementAttr={{name: 'registration-submit'}}
            type={'default'}
            text={t('COMMON.REGISTRATION')}
            onClick={this.validateFields}
            width={'100%'}
          />
        </div>
      </>
    );
  };

  emailWizardField = () => {
    const {email, otpCode, isOtpCode, validationOtpResult} = this.state;
    const {t} = this.props;

    return (
      <div>
        <div className={'dx-field'}>
          <i className={'mdi mdi-email-outline login-page-icon'}/>
          <TextBox
            name={'registration-email'}
            height={50}
            value={email}
            onValueChanged={this.onEmailChanged}
            readOnly={isOtpCode}
            placeholder={t('COMMON.EMAIL')}
            width={'100%'}
            onEnterKey={this.validateEmailGroup}
          >
            <Validator validationGroup={'registrationEmailFieldsGroup'}>
              <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
            </Validator>
          </TextBox>
        </div>
        {isOtpCode && (
          <div className={'dx-field'}>
            <OtpInput
              withFocusedState
              withResend={false}
              value={otpCode}
              placeholder={t('COMMON.OTP_OR_SMS')}
              onValueChanged={this.onOtpCodeChanged}
              onEnterKey={this.validateEmailGroup}
              validationMessage={t('ERROR_MSG.REQUIRED')}
              isValid={validationOtpResult}
              focusCallBack={() => {
                this.setState({
                  validationOtpResult: true,
                });
              }}
            />
          </div>
        )}
        <div className={'dx-field custom-login'}>
          <Button
            elementAttr={{name: 'registration-submit'}}
            type={'default'}
            text={t(isOtpCode ? 'COMMON.SEND' : 'COMMON.ACCEPT')}
            onClick={isOtpCode ? this.sendOtpCodeRequest : this.validateEmailGroup}
            width={'100%'}
          />
        </div>
      </div>
    );
  };

  validateEmailGroup = () => {
    const {isOtpCode, otpCode} = this.state;

    const result = validationEngine.validateGroup('registrationEmailFieldsGroup');

    if (isOtpCode && !otpCode) {
      this.setState({
        validationOtpResult: false,
      });

      if (result.isValid) {
        this.setState({
          isShowLoader: true,
        }, this.sendOtpCodeRequest);
      }

      return;
    }

    if (result.isValid) {
      this.setState({
        isShowLoader: true,
      }, isOtpCode ? this.sendOtpCodeRequest : this.sendModifyEmail);
    }
  };

  sendModifyEmail = async () => {
    const {email} = this.state;

    const request = await apiRequest({
      operation: 'UserProfile/ModEmail',
      data: {
        Params: {
          Email: email,
        },
      },
    });

    if (request.data.ResponseCode === SUCCESS_API_CODE) {
      this.setState({
        isShowLoader: false,
        isOtpCode: true,
      });
    } else {
      notifyApp(request.data.ResponseText);
      this.setState({
        isShowLoader: false,
      });
    }
  }

  sendOtpCodeRequest = async () => {
    const {otpCode} = this.state;

    const request = await apiRequest({
      operation: 'UserProfile/ConfirmFieldByCode',
      data: {
        Params: {
          Code: otpCode,
          FieldName: "Email",
        },
      },
    });

    if (request.data.ResponseCode === SUCCESS_API_CODE) {
      const settingsResponse = await getApplicationSettings();

      if (settingsResponse.isSuccess) {
        window.localStorage.setItem('userTypeID', settingsResponse.userTypeID);
        this.props.logIn(settingsResponse.userName);
      } else {
        notifyApp(settingsResponse.error);
      }
    } else {
      notifyApp(request.data.ResponseText);
    }
  }

  passwordComparison = () => {
    return this.state.password;
  };

  checkComparison() {
    return true;
  }

  onEmailChanged = (e) => {
    this.setState({
      email: e.value
    });
  };

  onPhoneChanged = (e) => {
    this.setState({
      phone: e.value
    });
  };

  onSmsCodeChanged = ({value}) => {
    this.setState({
      smsCode: value,
    });
  };

  onOtpCodeChanged = ({value}) => {
    this.setState({
      otpCode: value,
    });
  };

  onPasswordChanged = (e) => {
    this.setState({
      password: e.value
    });
  };

  onReferrerCodeChanged = (e) => {
    this.setState({
      referrerCode: e.value
    });
  };

  sendSmsCodeRequest = async () => {
    const {smsCode} = this.state;

    const response = await sendRegistrationOtp(smsCode);

    if (response.isSuccess) {
      this.triggerGTMEvent();
      this.setState({
        isShowEmailConfirmation: true,
      });
    } else {
      notifyApp(response.error, NOTIFY.ERROR);
    }

    this.setState({
      isShowLoader: false,
    });
  };

  sendRegistration = async () => {
    const {NOTIFY} = vars;
    const {phone, password, referrerCode, getParams} = this.state;
    const {setInfo} = this.props;
    const hashedPassword = await sha512(password);

    if (hashedPassword) {
      const token = await window.grecaptcha.execute('6Leo1KYnAAAAAC1q-OumYOFrUz-SaBfrxQS5io9v', {action: 'RegistrationLogin'});

      const request = await sendRegistrationRequest({
        phone, hashedPassword, referrerCode, getParams, token,
      });

      if (request.isSuccess) {
        this.setState({
          isSmsCode: true,
          isShowLoader: false,
        }, request.infoText ? () => {
          setInfo(request.response);
        } : null);
      } else {
        notifyApp(request.error, NOTIFY.ERROR);
      }
    } else {
      notifyApp('Client WebAPI error', NOTIFY.ERROR);
    }

    this.setState({
      isShowLoader: false,
    });
  };
}

const TranslatedRegistration = withTranslation()(Registration);

export default withRouter(TranslatedRegistration);
