import React from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Button} from 'devextreme-react/button';
import {vars} from 'utils/variables';
import {makeOperation} from 'services/operations/actions';
import {useTranslation} from 'react-i18next';

const {OPERATION, PAYMENT, MAIN: {ACCOUNTS: {SHARPAY_IBAN_ID}}, ACCOUNT_LOCK_ID} = vars;

const SharpayDetails = ({accountData, makeOperation}) => {

  const {t} = useTranslation();
  const history = useHistory();

  const onAccountDetailButtonClick = (type) => {
    const payload = {
      paymentTypeId: type,
      account: accountData.account,
    };

    makeOperation(payload);
    history.push(OPERATION.PATH);
  };

  return (
    <div>
      <div className={'tile-acc-wrap'}>
        <div className={'tile-text tile-text-detail'}>
          <div className={'tile-acc-bold tile-acc-label'}>{t('MAIN.ACCOUNT_DETAIL_WIDGET.ACCOUNT_NUMBER')}</div>
          <div className={'tile-acc-num'}>{accountData.accountNumber}</div>
        </div>
      </div>
      <div className={'buttons-container'}>
        <Button
          height={60}
          text={t('MAIN.ACCOUNT_DETAIL_WIDGET.PAYOUT')}
          stylingMode={'text'}
          className={'blue'}
          focusStateEnabled={false}
          onClick={() => {
            onAccountDetailButtonClick(PAYMENT.PAYMENT_TYPE_PAYOUT);
          }}
        />
        <div className={'divider'}/>
        <Button
          disabled={accountData.PaymentSystemTypeID === SHARPAY_IBAN_ID}
          height={60}
          text={t('MAIN.ACCOUNT_DETAIL_WIDGET.INCOME')}
          stylingMode={'text'}
          className={'green'}
          focusStateEnabled={false}
          onClick={() => {
            onAccountDetailButtonClick(PAYMENT.PAYMENT_TYPE_INCOME);
          }}
        />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  makeOperation: (payload) => dispatch(makeOperation(payload)),
});

export default connect(null, mapDispatchToProps)(SharpayDetails);