import React from 'react';
import {Popup} from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';

import './app-popup.scss';

const AppPopup = ({
										container,
										position = {
											'my': 'center',
											'at': 'center',
											'of': '#login-page',
											collision: 'fit',
											boundaryOffset: '20 40',
										},
										height = 'auto',
										maxHeight = '80%',
										visible,
										maxWidth = 500,
										title = '',
										handleClose,
										icon,
										children
									}) => {
	return (
		<Popup
			visible={visible}
			container={container}
			dragEnabled={false}
			closeOnOutsideClick={false}
			showTitle={false}
			maxWidth={maxWidth}
			height={height}
			maxHeight={maxHeight}
			onHiding={handleClose}
			position={position}
		>
			<div
				className={'flexi-popup-close'}
				onClick={handleClose}
			>
				<i className={'mdi mdi-close-circle-outline'}/>
			</div>
			<div className={'flexi-popup-content app-popup'}>
				<div className={'flexi-popup-logo'}>
					{icon}
				</div>
				<div className={'flexi-popup-head'}>
					<div className={'flexi-popup-title'}>
						{title}
					</div>
				</div>
				<ScrollView
					className={'popup-scrollable-content'}
					height={'100%'}
					width={'100%'}
				>
					<div className={'flexi-popup-component'}>
						{children}
					</div>
				</ScrollView>
			</div>
		</Popup>
	);
}

export default AppPopup;
