import React from 'react';
import {valueWithPrecision} from 'utils/functions';
import {useTranslation} from 'react-i18next';
import './item-detail-widget.scss';

const ItemDetailWidget = ({coords: {left}, data, onItemDetailClick}) => {
  const {t} = useTranslation();

  const style = {
    position: 'absolute',
    top: 78,
    left: Math.round(left - 304),
  }

  return (
    <div className={'account-detail-wrapper'} onClick={() => {
      onItemDetailClick(null);
    }}>
      <div
        style={style}
        className={'account-detail bottom-gap'}
        onClick={(event) => {
          if (event.type === 'click') {
            event.stopPropagation();
          }
        }}
      >
        <div className={'inner-top'}>
          <div className={'close-cursor'} onClick={() => {
            onItemDetailClick(null);
          }}></div>
        </div>
        <div className={'item-detail-wrap'}>
          <span className={'item-text-bold'}>{t('TRANSACTIONS.DEFAULT_ACCOUNT')}</span>
          <span className={'item-text-bold'}>
					  {valueWithPrecision(data.DefaultAccountBalance, data.DefaultAccountCurrencyViewPrecision)}
				  </span>
        </div>
        <div className={'item-detail-wrap'}>
          <span className={'item-text-bold'}>{t('TRANSACTIONS.ROLLING_ACCOUNT')}</span>
          <span className={'item-text-bold'}>
						  {valueWithPrecision(data.RollingAccountBalance, data.DefaultAccountCurrencyViewPrecision)}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ItemDetailWidget;