import React from 'react';
import {useTranslation} from 'react-i18next';

import './start-panel.scss';

const StartPanel = ({title = '', description = [], onClick = () => {}, linkText = ''}) => {
	const {t} = useTranslation();

	return (
		<div className={'start-wrapper'}>
			<div className={'start-panel'}>
				<div className={'start-title'}>
					{t(title)}
				</div>
				<div className={'start-description'}>
					{description.map((line, index) => {
						return (
							<div key={index}>
								<span style={{ whiteSpace: 'pre-line' }} className={'start-account'}>{t(line)}</span>
								{index === description.length - 1 && (
									<br/>
								)}
							</div>
						);
					})}
				</div>
        {linkText ? (
          <div
            className={'start-link'}
            onClick={onClick}
          >
            {t(linkText)}
          </div>
        ) : null}
			</div>
			<br/>
		</div>
	);
};

export default StartPanel;
