import React, {useEffect, useState} from 'react';
import {LoadIndicator} from 'devextreme-react/load-indicator';
import ScrollView from 'devextreme-react/scroll-view';
import {notifyApp} from 'utils/notifyWrapper';
import {useTranslation} from 'react-i18next';
import {apiRequest} from 'services/async';
import {vars} from 'utils/variables';
import '../settings.scss';
import '../legal-information/legal-information.scss';

const {SUCCESS_API_CODE} = vars;

const LegalInformation = () => {
	const {t, i18n} = useTranslation();
	const [sections, setSections] = useState(null);

	useEffect(() => {
		loadSections(i18n.language).catch((e) => {
			console.log(e);
		});
	}, [i18n.language]);

	const loadSections = async (requestLanguage) => {
		const infoRequest = await apiRequest({
			operation: 'GeneralInfo/List',
			data: {},
			requestLanguage: requestLanguage,
		});

		if (infoRequest.data.ResponseCode === SUCCESS_API_CODE) {
			const infoList = infoRequest.data.Response.GeneralInfo;

			if(Array.isArray(infoList)) {
				infoList.sort((a, b) => {
					return a.OrderNum - b.OrderNum;
				});
				setSections(infoList);
			}
		} else {
			notifyApp(infoRequest.data.ResponseText);
		}
	};

	return <div className={'settings-wrapper legal-wrapper'}>
		<div className={'settings-header'}>
			<div className={'settings-header_inner'}>
				<div className={'settings-header_text'}>
					<i className={'mdi mdi-bank'}/>
					<span>{t('SETTINGS.LEFT_BLOCK.LEGALINFORMATION.TITLE')}</span>
				</div>
			</div>
		</div>
		<div className="settings-splitter"/>
		<div className={'legal-inner'}>
			{sections ? (
				<div className={'legal-section-wrapper'}>
					<ScrollView>
						{sections.map((item) => {
							return (
								<div key={`section-${item.OrderNum}`}>
									<div className={'legal-section'}>
										<div className={'legal-title'}>{item.Title}</div>
										<div className={'legal-subtitle'}>{item.Subtitle}</div>
										<div className={'legal-content'}>{item.Description}</div>
										<a href={item.LinkUrl} className={'legal-link'}>{item.LinkText}</a>
									</div>
									<div className="settings-splitter"/>
								</div>
							);
						})}
					</ScrollView>
				</div>
			) : (
				<div className={'load-wrapper'}>
					<LoadIndicator/>
				</div>
			)}
		</div>
	</div>;
};

export default LegalInformation;
