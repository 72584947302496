import React from 'react';
import {TextBox} from "devextreme-react";

const ElementWithFlag = (itemData) => {
	return itemData && (
		<div className={'language-flag-wrapper'}>
			<img
				className={'language-flag'}
				src={itemData.imgSrc}
				width={20}
				alt=""/>
			<span
				className={'language-name'}
			>
				<TextBox
					readOnly
					value={itemData.name}
				/>
			</span>
		</div>
	);
};


export default ElementWithFlag;
