import React from 'react';
import {vars} from 'utils/variables';

const {MAIN: {ACCOUNTS}} = vars;

const Head = props => {
  const {paymentType, account} = props;
  return (
    <div className={'top'}>
      <div className={'head-string'}>
        <span className={'text-bold'}>{paymentType}</span>
        {account.PaymentSystemTypeID === ACCOUNTS.WALLETTO_ID ? account.CardNumber : account.AccountNumber}
      </div>
    </div>
  );
};

export default Head;