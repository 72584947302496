import React from 'react';
import {useTranslation} from 'react-i18next';
import {NumberBox, SelectBox, Validator} from 'devextreme-react';
import {PatternRule} from 'devextreme-react/validator';
import {vars} from 'utils/variables';

const {REG_EXP} = vars;

const CurrencyAmount = (props) => {
	const {t} = useTranslation();
	const {amountChanged, currencyChanged, value, fee, account, currencyState} = props;

	return (
		// amount callback
		// currency rate string
		// selected currency callback
		<div className={'operations-fieldset'}>
			<div className={'operations-field-full operations-field'}>
				<div className="operations-field-label dx-field-label">
					{`${t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.AMOUNT_PAYABLE')}*`}
				</div>
				<div className="dx-field-value field-value-relative">
					<div className={'operation-selectbox-currency'}>
						<div className={'operation-selectbox_input'}>
							<div className={'operation-selectbox_left'}>
								<div className={'operation-total-amount'}>
									{currencyState.selectedCurrency.Symbol}
									<NumberBox
										width={'100%'}
										height={40}
										className={'operation-total-amount-input'}
										name={'currencyAmount'}
										value={value}
										onValueChanged={(e) => {
											amountChanged(e);
										}}
									>
										<Validator validationGroup={'gr1'}>
											<PatternRule message={t('ERROR_MSG.WRONG_AMOUNT')} pattern={REG_EXP.AMOUNT}/>
										</Validator>
									</NumberBox>
								</div>
								{fee.CurrencyRate ? (
									<div className={'operation-fee-detail'}>
										{`${currencyState.selectedCurrency.Symbol} 1.00 = ${account.CurrencySymbol} ${fee.CurrencyRate.toFixed(5)}`}
									</div>
								) : null}
							</div>
						</div>

						{currencyState.selectedCurrency ? (
							<div className={'operation-selectbox-wrapper'}>
							<span className={'operation-selectbox-currency_icon'}>
								{currencyState.selectedCurrency.Symbol}
							</span>
								<SelectBox
									placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.CHOOSER')}
									disabled={false}
									className={'operation-selectbox'}
									height={40}
									items={currencyState.currencies}
									displayExpr={'Code'}
									valueExpr={'ID'}
									value={currencyState.selectedCurrency.ID}
									onValueChanged={({value}) => {
										currencyChanged(value);
									}}
								/>
							</div>
						) : null}

					</div>
				</div>
			</div>
		</div>
	);
}

export default CurrencyAmount;