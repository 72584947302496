import React, {useEffect, useState} from 'react';
import Loader from 'components/loader/loader';
import {Button, CheckBox, NumberBox, SelectBox, TextBox} from 'devextreme-react';
import {CustomRule, PatternRule, RequiredRule, StringLengthRule, Validator} from 'devextreme-react/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import {useTranslation} from 'react-i18next';
import {notifyApp} from 'utils/notifyWrapper';
import {apiRequest, makeTransaction, paymentConfirm, resendPaymentOtp} from 'services/async';
import classNames from 'classnames';
import {reasons, vars} from 'utils/variables';

const {
  MAIN: {ACCOUNTS},
  SUCCESS_API_CODE,
  PAYMENT: {PAYMENT_TYPE_PAYOUT},
  OPERATION: {FEE_PAYER_TYPE_OUR, IS_CHECK_OTP_CODE, PAYMENT_PAYOUT, SWIFT_CODE_LENGTH},
  REG_EXP,
  NOTIFY: {SUCCESS}
} = vars;

const textBoxProps = {
  className: 'operation-input',
  stylingMode: 'outlined',
};

const buttonProp = {
  width: 120,
  type: 'normal',
  stylingMode: 'contained',
};

const dictionaryBuffer = {};

const swiftCodeValidationCallback = ({value}) => {
  if (!value) return false;
  return value.length <= SWIFT_CODE_LENGTH;
}

const bankFieldName = 'Name';
const swiftFieldName = 'Swift';
const transferTemplateFieldName = 'ForeignAccountCode';

const SwiftFields = (props) => {
  const {
    isShowLoader,
    paymentMethodValueId,
    account,
    isBlockedRightForm,
    clickCancel,
    changePaymentStatus,
    showOtpPopup,
    hideOtpPopup,
  } = props;
  const {t} = useTranslation();

  const checkBalance = ({value}) => {
    return account.Balance >= value;
  };

  const [fields, setFields] = useState({
    AccountID: account.Code,
    ForeignClientName: null,
    ForeignClientCode: null,
    Reason: null,
    DocumentNo: null,
    ForeignClientAddress: null,
    ForeignClientTown: null,
    ForeignClientCountry: null,

    ForeignAccountCode: null,
    ForeignBankName: null,
    ForeignBankSwift: null,
    ForeignBankAddress: null,
    ForeignBankCountry: null,
    AdditionalInfo: null,

    CorrBankName: null,
    CorrBankAddress: null,
    CorrBankSwift: null,
    CorrBankAccountCode: null,
    Amount: null,
    ForeignCurrencyID: account.CurrencyID,
    Description: null,

    local: {
      feeAmount: null,
      amountReceivable: null,
      isAdditionalInfo: 0,
      lastForeignBankCountry: null,
      lastAmount: -1,
    }
  });

  const [dictionaries, setDictionaries] = useState({
    Country: [],
    PaymentBankTransferTemplate: [],
    Bank: [],
    FeePayerType: [],
  });

  const [payerType, setPayerType] = useState(FEE_PAYER_TYPE_OUR);
  const [payment, setPayment] = useState(null);

  useEffect(() => {
    if (payment) {
      if (payment.IsCheckOtp === IS_CHECK_OTP_CODE) {
        showOtpPopup({
          onResend: async () => {
            const resendRequest = await resendPaymentOtp({
              PaymentID: payment.ID,
            });

            if (resendRequest.isSuccess) {
              notifyApp(t('COMMON.SENT'), SUCCESS);
            } else {
              notifyApp(resendRequest.error);
            }
          },
          onSubmit: async (otpCode) => {
            const confirmRequest = await paymentConfirm({
              PaymentID: payment.ID,
              Code: otpCode,
            });

            if (confirmRequest.isSuccess) {
              // done
              hideOtpPopup();
              changePaymentStatus({
                text: t('OPERATIONS.PAYMENT_SUCCESS_TEXT')
              });
            } else {
              notifyApp(confirmRequest.error);
            }
          },
          sendChannelId: payment.SendChannelID,
          infoText: payment.InfoText,
        });
      } else {
        changePaymentStatus({
          text: t('OPERATIONS.PAYMENT_SUCCESS_TEXT')
        });
      }
    }
  }, [payment]);

  useEffect(() => {
    calculateTransferFee().catch((errorText) => {
      notifyApp(errorText);
    });
  }, [payerType]);

  useEffect(() => {
    loadDictionaries().catch((error) => {
      notifyApp(error.message);
    }).finally(() => {
      setDictionaries(dictionaryBuffer);
    });
  }, []);

  const loadDictionaries = async () => {
    const dictionariesResponse = await Promise.allSettled([
      fetchDictionary('Country', 'Name'),
      fetchDictionary('PaymentBankTransferTemplate', 'ForeignAccountCode'),
      fetchDictionary('Bank', 'Name'),
      fetchDictionary('FeePayerType'),
    ]);

    dictionariesResponse.forEach((dictionary) => {
      if (dictionary.status === 'rejected') {
        throw new Error(dictionary.reason);
      }
    });
  }

  useEffect(() => {
    console.log(fields);
    if (
      dictionaries.Country.length > 0 &&
      fields.ForeignBankCountry &&
      fields.ForeignBankCountry !== fields.local.lastForeignBankCountry
    ) {

      const countryItem = dictionaries.Country.find((item) => {
        return item.Code === fields.ForeignBankCountry;
      });

      setFields({
        ...fields,
        local: {
          ...fields.local,
          isAdditionalInfo: countryItem ? countryItem.IsAdditionalInfo : 0,
          lastForeignBankCountry: fields.ForeignBankCountry,
        }
      });
    }

    if (fields.Amount && fields.Amount !== fields.local.lastAmount) {
      calculateTransferFee().catch((errorText) => {
        notifyApp(errorText);
      });
    }
  }, [fields]);

  const calculateTransferFee = async () => {
    const {Amount, ForeignAccountCode} = fields;

    if (!(Amount && ForeignAccountCode)) return;

    const objectName = 'PaymentFee';
    const params = {
      PaymentSystemTypeID: paymentMethodValueId,
      PaymentTypeID: PAYMENT_TYPE_PAYOUT,
      AccountID: account.ID,
      Amount: fields.Amount,
      ForeignAccountCode: fields.ForeignAccountCode,
      ForeignCurrencyID: fields.ForeignCurrencyID,
      FeePayerTypeID: payerType,
    };

    const paymentFeeRequest = await apiRequest({
      operation: `${objectName}/Calculate`,
      data: {
        Params: params
      }
    });

    if (paymentFeeRequest.data.ResponseCode === SUCCESS_API_CODE) {
      const {FeeAmount, ToCurrencyAmount} = paymentFeeRequest.data.Response;
      return setFields({
        ...fields,
        local: {
          ...fields.local,
          feeAmount: FeeAmount,
          amountReceivable: ToCurrencyAmount,
          lastAmount: fields.Amount,
        }
      });
    } else {
      throw new Error(paymentFeeRequest.data.ResponseText);
    }
  }

  const fetchDictionary = async (objectName, sortField) => {
    let data = {
      Page: -1
    };

    if (sortField) {
      data = Object.assign(data, {Sorts: [sortField]});
    }

    const request = await apiRequest({
      operation: `${objectName}/List`,
      data: data,
    });

    if (request.data.ResponseCode === SUCCESS_API_CODE) {
      dictionaryBuffer[objectName] = request.data.Response[objectName];
    } else {
      return Promise.reject(request.data.ResponseText);
    }
  }

  const onContinue = async ({component}) => {
    const result = await validationEngine.validateGroup('gr2');

    if (result.isValid) {
      component.option('disabled', true);

      let customParams = null;

      try {
        const transactionParams = {
          ...fields,
          AccountID: account.ID,
          FeePayerTypeID: payerType,
          PaymentSystemTypeID: paymentMethodValueId,
          PaymentTypeID: PAYMENT_TYPE_PAYOUT,
          operation: PAYMENT_PAYOUT,
        };

        if (account.PaymentSystemTypeID === ACCOUNTS.BANXE_ID) {
          if (isChecked) {
            customParams = {
              ForeignCompanyName: transactionParams.ForeignClientName,
            }
          } else {
            const [firstName, ...other] = transactionParams.ForeignClientName.split(' ');

            customParams = {
              ForeignFirstName: firstName,
              ForeignLastName: other.join(' ') || '',
            }
          }

          customParams.Reason = fields.Reason;
          customParams.DocumentNo = fields.DocumentNo;

          delete transactionParams.ForeignClientName;
          delete transactionParams.Reason;
          delete transactionParams.DocumentNo;
        }

        delete transactionParams.local;

        const request = await makeTransaction(transactionParams, customParams);

        if (request.isSuccess) {
          setPayment(request.response);
        } else {
          notifyApp(request.error);
        }
      } catch (error) {
        notifyApp(error);
      } finally {
        component.option('disabled', false);
      }
    } else {
      // focus first broken field
      result.brokenRules[0].validator.focus();
    }
  }

  const onClickCancel = () => {
    if (payment) {
      setPayment(null);
    } else {
      clickCancel();
    }
  }

  const onCustomItemCreating = (args, itemList = [], {
    indexKey = 'ID',
    valueKey = 'Name',
  }) => {
    if (!args.text) {
      args.customItem = null;
      return;
    }

    const {text} = args;

    const newId = itemList.length > 0 ? itemList.at(-1)[indexKey] + 1 : 1;
    let newItem = itemList.length > 0 ? {...itemList[0]} : {};

    for (const prop in newItem) {
      if (prop === indexKey) {
        newItem[indexKey] = newId;
      } else if (prop === valueKey) {
        newItem[valueKey] = text.trim();
      } else {
        newItem[prop] = null;
      }
    }

    const itemInDataSource = itemList.find((item) => item[valueKey] === newItem[valueKey]);

    if (itemInDataSource) {
      args.customItem = itemInDataSource;
    } else {
      args.customItem = newItem;
      // add item to select
      //itemList.push(newItem);
    }
  }

  const [isChecked, setIsChecked] = useState(false);

  console.group('account');
  console.log(account);
  console.groupEnd();

  return (
    <div className={'payout-fields swift-payout-fields'}>
      <Loader
        isShowLoader={isShowLoader}
      />
      {/*.currency-read-only*/}
      <div className={'operations-row'}>
        <div className={'operations-row-inner'}>
          <div className={'operations-subtitle'}>
            {`${t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.SWIFT_TRANSFER')}`}
          </div>
          <div className={'operations-fields-wrapper'}>
            <div className={'operations-fieldset smooth-top'}>
              <div className={'operations-field'}>
                <div className='dx-field-label'>{`${t('FIELDS.BENEFICIARY_NAME')}`}</div>
                <div className='dx-field-value'>
                  <TextBox
                    {...textBoxProps}
                    value={fields.ForeignClientName}
                    readOnly={isBlockedRightForm}
                    onValueChanged={({value}) => {
                      setFields({
                        ...fields,
                        ForeignClientName: value,
                      });
                    }}
                    // ForeignClientName
                  >
                    <Validator validationGroup={'gr2'}>
                      <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                    </Validator>
                  </TextBox>
                </div>
              </div>
            </div>
            <div className={'operations-fieldset smooth-center'}>
              <div className={'operations-field'}>
                <div className={'dx-field-label'}></div>
                <div className={'dx-field checkbox-field'}>
                  <CheckBox
                    id={'company-payment'}
                    validationMessageMode={'always'}
                    value={isChecked}
                    text={''}
                    onValueChanged={({value}) => {
                      setIsChecked(value);
                    }}
                  />
                  <div className={'checkbox-text'}>
                    <span>{t('FIELDS.COMPANY_PAYMENT')}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={'operations-fieldset smooth-bottom'}>
              <div className={'operations-field'}>
                <div className='dx-field-label'>{`${t('FIELDS.BENEFICIARY_CODE')}`}</div>
                <div className='dx-field-value'>
                  <TextBox
                    {...textBoxProps}
                    value={fields.ForeignClientCode}
                    readOnly={isBlockedRightForm}
                    onValueChanged={({value}) => {
                      setFields({
                        ...fields,
                        ForeignClientCode: value,
                      });
                    }}
                    // ForeignClientCode
                  />
                </div>
              </div>
            </div>
            <div className={'operations-fieldset'}>
              <div className={'operations-field'}>
                <div className='dx-field-label'>{`${t('FIELDS.BENEFICIARY_ADDRESS')}`}</div>
                <div className='dx-field-value'>
                  <TextBox
                    {...textBoxProps}
                    value={fields.ForeignClientAddress}
                    readOnly={isBlockedRightForm}
                    onValueChanged={({value}) => {
                      setFields({
                        ...fields,
                        ForeignClientAddress: value,
                      });
                    }}
                    // ForeignClientAddress
                  >
                    <Validator validationGroup={'gr2'}>
                      <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                    </Validator>
                  </TextBox>
                </div>
              </div>
            </div>
            <div className={'operations-fieldset'}>
              <div className={'operations-field'}>
                <div className='dx-field-label'>{`${t('FIELDS.TOWN')}`}</div>
                <div className='dx-field-value'>
                  <TextBox
                    {...textBoxProps}
                    value={fields.ForeignClientTown}
                    readOnly={isBlockedRightForm}
                    onValueChanged={({value}) => {
                      setFields({
                        ...fields,
                        ForeignClientTown: value,
                      });
                    }}
                    // ForeignClientTown
                  >
                    <Validator validationGroup={'gr2'}>
                      <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                    </Validator>
                  </TextBox>
                </div>
              </div>
            </div>
            <div className={'operations-fieldset'}>
              <div className={'operations-field'}>
                <div className='dx-field-label'>{`${t('FIELDS.COUNTRY')}`}</div>
                <div className='dx-field-value'>
                  <SelectBox
                    searchEnabled
                    placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.CHOOSER')}
                    value={fields.ForeignClientCountry}
                    readOnly={isBlockedRightForm}
                    dataSource={dictionaries.Country}
                    displayExpr={'ViewName'}
                    valueExpr={'Code'}
                    searchExpr={'ViewName'}
                    onValueChanged={({value}) => {
                      setFields({
                        ...fields,
                        ForeignClientCountry: value,
                      });
                    }}
                    stylingMode={'outlined'}
                    // ForeignClientCountry
                  >
                    <Validator validationGroup={'gr2'}>
                      <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                    </Validator>
                  </SelectBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'operations-row'}>
        <div className={'operations-row-inner'}>
          <div className={'operations-fields-wrapper'}>
            <div className={'operations-fieldset'}>
              <div className={'operations-field'}>
                <div className='dx-field-label'>{`${t('FIELDS.BENEFICIARY_ACCOUNT_IBAN')}`}</div>
                <div className='dx-field-value'>
                  {dictionaries.PaymentBankTransferTemplate.length === 0 ? (
                    <TextBox
                      {...textBoxProps}
                      readOnly={isBlockedRightForm}
                      onValueChanged={({value}) => {
                        setFields({
                          ...fields,
                          ForeignAccountCode: value,
                        });
                      }}
                    >
                      <Validator validationGroup={'gr2'}>
                        <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                      </Validator>
                    </TextBox>
                  ) : (
                    <SelectBox
                      searchEnabled
                      placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.CHOOSER')}
                      readOnly={isBlockedRightForm}
                      dataSource={dictionaries.PaymentBankTransferTemplate}
                      displayExpr={transferTemplateFieldName}
                      valueExpr={transferTemplateFieldName}
                      searchExpr={transferTemplateFieldName}
                      onValueChanged={({value}) => {
                        const itemInDataSource = dictionaries.PaymentBankTransferTemplate.find(
                          (item) => item[transferTemplateFieldName] === value
                        );

                        if (itemInDataSource) {
                          const {
                            ForeignAccountCode,
                            ForeignClientName,
                            ForeignClientCode,
                            ForeignClientAddress,
                            ForeignClientTown,
                            ForeignClientCountry,
                            ForeignBankName,
                            ForeignBankSwift,
                            ForeignBankAddress,
                            ForeignBankCountry,
                            AdditionalInfo,
                            CorrBankName,
                            CorrBankSwift,
                            CorrBankAddress,
                            CorrBankCountry,
                            CorrBankAccountCode,
                          } = itemInDataSource;

                          setFields({
                            ...fields,
                            ForeignAccountCode,
                            ForeignClientName,
                            ForeignClientCode,
                            ForeignClientAddress,
                            ForeignClientTown,
                            ForeignClientCountry,
                            ForeignBankName,
                            ForeignBankSwift,
                            ForeignBankAddress,
                            ForeignBankCountry,
                            AdditionalInfo,
                            CorrBankName,
                            CorrBankSwift,
                            CorrBankAddress,
                            CorrBankCountry,
                            CorrBankAccountCode,
                          });
                        } else {
                          setFields({
                            ...fields,
                            ForeignAccountCode: value,
                          });
                        }
                      }}
                      stylingMode={'outlined'}
                      onCustomItemCreating={(args) => {
                        return onCustomItemCreating(args, dictionaries.PaymentBankTransferTemplate, {
                          indexKey: 'PaymentID',
                          valueKey: transferTemplateFieldName,
                        });
                      }}
                      acceptCustomValue
                      // ForeignAccountCode
                    >
                      <Validator validationGroup={'gr2'}>
                        <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                      </Validator>
                    </SelectBox>
                  )}
                </div>
              </div>
            </div>
            <div className={'operations-fieldset'}>
              <div className={'operations-field'}>
                <div className='dx-field-label'>{`${t('FIELDS.BENEFICIARY_BANK')}`}</div>
                <div className='dx-field-value'>
                  {dictionaries.Bank.length === 0 ? (
                    <TextBox
                      {...textBoxProps}
                      readOnly={isBlockedRightForm}
                      value={fields.ForeignBankName}
                      onValueChanged={({value}) => {
                        setFields({
                          ...fields,
                          ForeignBankName: value,
                        });
                      }}
                      // ForeignBankName
                    />
                  ) : (
                    <SelectBox
                      searchEnabled
                      placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.CHOOSER')}
                      value={fields.ForeignBankName}
                      readOnly={isBlockedRightForm}
                      dataSource={dictionaries.Bank}
                      displayExpr={bankFieldName}
                      valueExpr={bankFieldName}
                      searchExpr={bankFieldName}
                      onValueChanged={({value}) => {
                        const itemInDataSource = dictionaries.Bank.find((item) => item[bankFieldName] === value);

                        if (itemInDataSource) {
                          const {Name, Swift, Address, Country} = itemInDataSource;

                          setFields({
                            ...fields,
                            ForeignBankName: Name,
                            ForeignBankSwift: Swift,
                            ForeignBankAddress: Address,
                            ForeignBankCountry: Country,
                          });
                        } else {
                          setFields({
                            ...fields,
                            ForeignBankName: value,
                          });
                        }
                      }}
                      stylingMode={'outlined'}
                      onCustomItemCreating={(args) => {
                        return onCustomItemCreating(args, dictionaries.Bank, {
                          indexKey: 'ID',
                          valueKey: bankFieldName,
                        });
                      }}
                      acceptCustomValue
                      // ForeignBankName
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={'operations-fieldset'}>
              <div className={'operations-field'}>
                <div className='dx-field-label'>{`${t('FIELDS.FOREIGN_BANK_SWIFT')}`}</div>
                <div className='dx-field-value'>
                  {dictionaries.Bank.length === 0 ? (
                    <TextBox
                      {...textBoxProps}
                      readOnly={isBlockedRightForm}
                      value={fields.ForeignBankSwift}
                      onValueChanged={({value}) => {
                        setFields({
                          ...fields,
                          ForeignBankSwift: value,
                        });
                      }}
                      // ForeignBankSwift
                    >
                      <Validator validationGroup={'gr2'}>
                        <StringLengthRule max={SWIFT_CODE_LENGTH} message={''}/>
                      </Validator>
                    </TextBox>
                  ) : (
                    <SelectBox
                      searchEnabled
                      placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.CHOOSER')}
                      value={fields.ForeignBankSwift}
                      readOnly={isBlockedRightForm}
                      dataSource={dictionaries.Bank}
                      displayExpr={swiftFieldName}
                      valueExpr={swiftFieldName}
                      searchExpr={swiftFieldName}
                      onValueChanged={({value}) => {
                        const itemInDataSource = dictionaries.Bank.find((item) => item[swiftFieldName] === value);

                        if (itemInDataSource) {
                          const {Name, Swift, Address, Country} = itemInDataSource;

                          setFields({
                            ...fields,
                            ForeignBankName: Name,
                            ForeignBankSwift: Swift,
                            ForeignBankAddress: Address,
                            ForeignBankCountry: Country,
                          });
                        } else {
                          setFields({
                            ...fields,
                            ForeignBankSwift: value,
                          });
                        }
                      }}
                      stylingMode={'outlined'}
                      onCustomItemCreating={(args) => {
                        return onCustomItemCreating(args, dictionaries.Bank, {
                          indexKey: 'ID',
                          valueKey: 'Swift',
                        });
                      }}
                      acceptCustomValue
                      // ForeignBankSwift
                    >
                      <Validator validationGroup={'gr2'}>
                        <CustomRule validationCallback={swiftCodeValidationCallback} message={''}/>
                      </Validator>
                    </SelectBox>
                  )}
                </div>
              </div>
            </div>
            <div className={'operations-fieldset'}>
              <div className={'operations-field'}>
                <div className='dx-field-label'>{`${t('FIELDS.BANK_ADDRESS')}`}</div>
                <div className='dx-field-value'>
                  <TextBox
                    {...textBoxProps}
                    readOnly={isBlockedRightForm}
                    value={fields.ForeignBankAddress}
                    onValueChanged={({value}) => {
                      setFields({
                        ...fields,
                        ForeignBankAddress: value,
                      });
                    }}
                    // ForeignBankAddress
                  />
                </div>
              </div>
            </div>
            <div className={'operations-fieldset'}>
              <div className={'operations-field'}>
                <div className='dx-field-label'>{`${t('FIELDS.BANK_COUNTRY')}`}</div>
                <div className='dx-field-value'>
                  <SelectBox
                    searchEnabled
                    placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.CHOOSER')}
                    value={fields.ForeignBankCountry}
                    readOnly={isBlockedRightForm}
                    dataSource={dictionaries.Country}
                    displayExpr={'ViewName'}
                    valueExpr={'Code'}
                    searchExpr={'ViewName'}
                    onValueChanged={({value}) => {
                      setFields({
                        ...fields,
                        ForeignBankCountry: value,
                      });
                    }}
                    stylingMode={'outlined'}
                    // ForeignBankCountry
                  />
                </div>
              </div>
            </div>
            {fields.ForeignBankCountry && fields.local.isAdditionalInfo === 1 ? (
              <div className={'operations-fieldset'}>
                <div className={'operations-field'}>
                  <div className='dx-field-label'>{`${t('FIELDS.ADDITIONAL_INFORMATION')}`}</div>
                  <div className='dx-field-value'>
                    <TextBox
                      {...textBoxProps}
                      value={fields.AdditionalInfo}
                      readOnly={isBlockedRightForm}
                      placeholder={'Routing number/ABA/Sort Code/BSB/IFSC/CLABE'}
                      onValueChanged={({value}) => {
                        setFields({
                          ...fields,
                          AdditionalInfo: value,
                        });
                      }}
                      // AdditionalInfo
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={'operations-row'}>
        <div className={'operations-row-inner'}>
          <div className={'operations-fields-wrapper'}>
            <div className={'operations-fieldset'}>
              <div className={'operations-field'}>
                <div className='dx-field-label'>{`${t('FIELDS.CORRESPONDENT_BANK')}`}</div>
                <div className='dx-field-value'>
                  {dictionaries.Bank.length === 0 ? (
                    <TextBox
                      {...textBoxProps}
                      readOnly={isBlockedRightForm}
                      value={fields.CorrBankName}
                      onValueChanged={({value}) => {
                        setFields({
                          ...fields,
                          CorrBankName: value,
                        });
                      }}
                      // CorrBankName
                    />
                  ) : (
                    <SelectBox
                      searchEnabled
                      placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.CHOOSER')}
                      readOnly={isBlockedRightForm}
                      dataSource={dictionaries.Bank}
                      value={fields.CorrBankName}
                      displayExpr={bankFieldName}
                      valueExpr={bankFieldName}
                      searchExpr={bankFieldName}
                      onValueChanged={({value}) => {
                        const itemInDataSource = dictionaries.Bank.find((item) => item[bankFieldName] === value);

                        if (itemInDataSource) {
                          const {Name, Swift, Address, Country} = itemInDataSource;

                          setFields({
                            ...fields,
                            CorrBankName: Name,
                            CorrBankSwift: Swift,
                            CorrBankAddress: Address,
                            CorrBankCountry: Country,
                          });
                        } else {
                          setFields({
                            ...fields,
                            CorrBankName: value,
                          });
                        }
                      }}
                      stylingMode={'outlined'}
                      onCustomItemCreating={(args) => {
                        return onCustomItemCreating(args, dictionaries.Bank, {
                          indexKey: 'ID',
                          valueKey: bankFieldName,
                        });
                      }}
                      acceptCustomValue
                      // CorrBankName
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={'operations-fieldset'}>
              <div className={'operations-field'}>
                <div className='dx-field-label'>{`${t('FIELDS.CORRESPONDENT_SWIFT_CODE')}`}</div>
                <div className='dx-field-value'>
                  {dictionaries.Country.length === 0 ? (
                    <TextBox
                      {...textBoxProps}
                      readOnly={isBlockedRightForm}
                      value={fields.CorrBankSwift}
                      onValueChanged={({value}) => {
                        setFields({
                          ...fields,
                          CorrBankSwift: value,
                        });
                      }}
                      // CorrBankSwift
                    >
                      {account.PaymentSystemTypeID !== ACCOUNTS.BANXE_ID ? (
                        <Validator validationGroup={'gr2'}>
                          <StringLengthRule max={SWIFT_CODE_LENGTH} message={''}/>
                        </Validator>
                      ) : null}
                    </TextBox>
                  ) : (
                    <SelectBox
                      searchEnabled
                      placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.CHOOSER')}
                      value={fields.CorrBankSwift}
                      readOnly={isBlockedRightForm}
                      dataSource={dictionaries.Bank}
                      displayExpr={swiftFieldName}
                      valueExpr={swiftFieldName}
                      searchExpr={swiftFieldName}
                      onValueChanged={({value}) => {
                        const itemInDataSource = dictionaries.Bank.find((item) => item[swiftFieldName] === value);

                        if (itemInDataSource) {
                          const {Name, Swift, Address, Country} = itemInDataSource;

                          setFields({
                            ...fields,
                            CorrBankName: Name,
                            CorrBankSwift: Swift,
                            CorrBankAddress: Address,
                            CorrBankCountry: Country,
                          });
                        } else {
                          setFields({
                            ...fields,
                            CorrBankSwift: value,
                          });
                        }
                      }}
                      stylingMode={'outlined'}
                      onCustomItemCreating={(args) => {
                        return onCustomItemCreating(args, dictionaries.Bank, {
                          indexKey: 'ID',
                          valueKey: 'Swift',
                        });
                      }}
                      acceptCustomValue
                      // CorrBankSwift
                    >
                      {account.PaymentSystemTypeID !== ACCOUNTS.BANXE_ID ? (
                        <Validator validationGroup={'gr2'}>
                          <CustomRule validationCallback={swiftCodeValidationCallback} message={''}/>
                        </Validator>
                      ) : null}
                    </SelectBox>
                  )}
                </div>
              </div>
            </div>
            <div className={'operations-fieldset'}>
              <div className={'operations-field'}>
                <div className='dx-field-label'>{`${t('FIELDS.CORR_BANK_ADDRESS')}`}</div>
                <div className='dx-field-value'>
                  <TextBox
                    {...textBoxProps}
                    readOnly={isBlockedRightForm}
                    value={fields.CorrBankAddress}
                    onValueChanged={({value}) => {
                      setFields({
                        ...fields,
                        CorrBankAddress: value,
                      });
                    }}
                    // CorrBankAddress
                  />
                </div>
              </div>
            </div>
            <div className={'operations-fieldset'}>
              <div className={'operations-field'}>
                <div className='dx-field-label'>{`${t('FIELDS.CORR_BANK_COUNTRY')}`}</div>
                <div className='dx-field-value'>
                  <SelectBox
                    searchEnabled
                    placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.CHOOSER')}
                    value={fields.CorrBankCountry}
                    readOnly={isBlockedRightForm}
                    dataSource={dictionaries.Country}
                    displayExpr={'ViewName'}
                    valueExpr={'Code'}
                    searchExpr={'ViewName'}
                    onValueChanged={({value}) => {
                      setFields({
                        ...fields,
                        CorrBankCountry: value,
                      });
                    }}
                    stylingMode={'outlined'}
                    // CorrBankCountry
                  />
                </div>
              </div>
            </div>
            <div className={'operations-fieldset'}>
              <div className={'operations-field'}>
                <div className='dx-field-label'>{`${t('FIELDS.CORRESPONDENT_ACCOUNT')}`}</div>
                <div className='dx-field-value'>
                  <TextBox
                    {...textBoxProps}
                    value={fields.CorrBankAccountCode}
                    readOnly={isBlockedRightForm}
                    onValueChanged={({value}) => {
                      setFields({
                        ...fields,
                        CorrBankAccountCode: value,
                      });
                    }}
                    // CorrBankAccountCode
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={'operations-row'}>
        <div className={'operations-row-inner'}>
          <div className={'operations-fields-wrapper'}>
            <div className={'operations-fieldset'}>
              <div className={'operations-field'}>
                <div className='dx-field-label'>{`${t('FIELDS.AMOUNT_PAYABLE')}`}</div>
                <div className='dx-field-value field-value-relative'>
									<span className={classNames('field-value-currency', {
                    'currency-read-only': isBlockedRightForm,
                  })}>
										{account.CurrencySymbol}
									</span>
                  <NumberBox
                    {...textBoxProps}
                    value={fields.Amount}
                    readOnly={isBlockedRightForm}
                    onValueChanged={({value}) => {
                      setFields({
                        ...fields,
                        Amount: value,
                      });
                    }}
                    // Amount
                  >
                    <Validator validationGroup={'gr2'}>
                      <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                      <PatternRule message={t('ERROR_MSG.DIGIT_GRATER_NULL_ONLY')} pattern={REG_EXP.AMOUNT}/>
                      <CustomRule
                        message={t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.INSUFFICIENT_BALANCE')}
                        validationCallback={checkBalance}
                      />
                    </Validator>
                  </NumberBox>
                </div>
              </div>
            </div>
            <div className={'operations-fieldset'}>
              <div className={'operations-field'}>
                <div className='dx-field-label'>{`${t('FIELDS.FEE')}`}</div>
                <div className='dx-field-value field-value-relative'>
									<span className={'field-value-currency currency-read-only'}>
										{account.CurrencySymbol}
									</span>
                  <div className={'with-select-wrapper'}>
                    <NumberBox
                      {...textBoxProps}
                      readOnly={true}
                      value={fields.local.feeAmount}
                      // local feeAmount
                    />
                    <SelectBox
                      placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.CHOOSER')}
                      value={payerType}
                      readOnly={isBlockedRightForm}
                      dataSource={dictionaries.FeePayerType}
                      valueExpr={'ID'}
                      displayExpr={'Code'}
                      stylingMode={'outlined'}
                      onValueChanged={({value}) => {
                        setPayerType(value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={'operations-fieldset'}>
              <div className={'operations-field'}>
                <div className='dx-field-label'>{`${t('FIELDS.AMOUNT_RECEIVABLE')}`}</div>
                <div className='dx-field-value field-value-relative'>
									<span className={'field-value-currency currency-read-only'}>
										{account.CurrencySymbol}
									</span>
                  <div className={'with-select-wrapper'}>
                    <NumberBox
                      {...textBoxProps}
                      readOnly={true}
                      value={fields.local.amountReceivable}
                      // AMOUNT_RECEIVABLE
                    />
                    <div className={'select-currency-wrapper'}>
											<span className={'operation-selectbox-currency_icon'}>
												{account.CurrencySymbol}
											</span>
                      <SelectBox
                        dataSource={[{id: 1, text: account.CurrencyCode}]}
                        readOnly={isBlockedRightForm}
                        defaultValue={1}
                        displayExpr={'text'}
                        valueExpr={'id'}
                        stylingMode={'outlined'}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={'operations-row'}>
        <div className={'operations-row-inner'}>
          <div className={'operations-fieldset'}>
            <div className={'operations-field'}>
              <div className='dx-field-label'>{`${t('FIELDS.PURPOSE_OF_PAYMENT')}`}</div>
              <div className='dx-field-value'>
                <TextBox
                  {...textBoxProps}
                  placeholder={t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.DESCRIPTION_FIELD')}
                  readOnly={isBlockedRightForm}
                  onValueChanged={({value}) => {
                    setFields({
                      ...fields,
                      Description: value,
                    });
                  }}
                  // Description
                />
              </div>
            </div>
          </div>

          {account.PaymentSystemTypeID === ACCOUNTS.BANXE_ID && paymentMethodValueId !== ACCOUNTS.BANXE_ID ? (
            <>
              <div className={'operations-fieldset smooth-bottom'}>
                <div className={'operations-field'}>
                  <div className='dx-field-label'>{'Reason*'}</div>
                  <div className='dx-field-value'>
                    <SelectBox
                      items={reasons}
                      displayExpr={'Name'}
                      valueExpr={'Name'}
                      value={fields.Reason}
                      className={'operation-input'}
                      stylingMode={'outlined'}
                      onValueChanged={({value}) => {
                        setFields({
                          ...fields,
                          Reason: value,
                        });
                      }}
                    >
                      <Validator validationGroup={'gr2'}>
                        <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                      </Validator>
                    </SelectBox>
                  </div>
                </div>
              </div>
              <div className={'operations-fieldset smooth-bottom'}>
                <div className={'operations-field'}>
                  <div className='dx-field-label'>{`${t('FIELDS.DOCUMENT_NO')}${isChecked ? '*' : ''}`}</div>
                  <div className='dx-field-value'>
                    <TextBox
                      {...textBoxProps}
                      value={fields.DocumentNo}
                      readOnly={isBlockedRightForm}
                      onValueChanged={({value}) => {
                        setFields({
                          ...fields,
                          DocumentNo: value,
                        });
                      }}
                      // DocumentNo
                    >
                      <Validator validationGroup={'gr2'}>
                        {isChecked ? (
                          <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                        ) : null}
                      </Validator>
                    </TextBox>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>

      <div className={'operations-buttons'}>
        <div className={'buttons-wrapper'}>
          <Button
            {...buttonProp}
            text={t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.CANCEL')}
            onClick={onClickCancel}
          />
          <Button
            {...buttonProp}
            text={t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.CONTINUE')}
            onClick={onContinue}
          />
        </div>
      </div>
    </div>
  );
}

export default SwiftFields;