import React, {useEffect, useState} from 'react';
import {Popup} from 'devextreme-react/popup';
import PopupSelectBox from './popup-selectbox';
import {useTranslation} from 'react-i18next';
import AddCardFields from './main/addCardFields';
import SharpayAccountFields from './main/sharpayAccountFields';
import IbanAccountFields from './main/ibanAccountFields';
import BanxeAccountFields from './main/banxeAccountFields';
import {vars} from 'utils/variables';
import ScrollView from 'devextreme-react/scroll-view';
import {apiRequest, userProfileGetList} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {hideOtpPopup, showOtpPopup} from 'services/app/appActions';
import {changeActiveScreen} from 'services/settings/actions';
import {connect} from 'react-redux';
import './add-account-popup.scss';

const {SUCCESS_API_CODE} = vars;

const parsePaymentSystemId = (value) => {
  if (!value) return null;
  const num = Number(value.substring(0, value.indexOf('-')));
  return !isNaN(num) ? num : null;
}

const popupPosition = {
  'my': 'top',
  'at': 'top',
  'of': '#acc-toolbar',
  collision: 'fit',
  boundaryOffset: '20 40',
};

const SHARPAY_ID = 0;
const WALLETTO_ID = 11;
const IBAN_ID = 12;
const BANXE_ID = 21;

const getPopupTitle = (id, t) => {
  switch (id) {
    case WALLETTO_ID:
      return t('MAIN.ADD_ACCOUNT.NEW_SHARPAY');
    case IBAN_ID:
      return t('MAIN.ADD_ACCOUNT.NEW_SHARPAY_IBAN');
    case SHARPAY_ID:
    default:
      return t('MAIN.ADD_ACCOUNT.NEW');
  }
};

const PopupFieldsComponent = (props) => {
  if (!props.paymentSystemValue) return null;

  const {
    paymentSystemValue,
    handleClose,
    successHandler,
    allowedCreateMatrix,
    userProfile,
    showOtpPopup,
    hideOtpPopup,
    changeActiveScreen,
    rawMatrix,
    accountList,
  } = props;
  const paymentSystemId = parsePaymentSystemId(paymentSystemValue);
  const paymentSystem = allowedCreateMatrix.find((item) => item.id === paymentSystemValue);
  const currencyList = isNaN(parseInt(paymentSystemId)) ? [] : Object.values(paymentSystem.gateCurrencyPair);
  const uniqueKeys = [];
  const paymentSystemMatrix = [];

  console.log('paymentSystemValue');
  console.log(paymentSystemValue);

  rawMatrix.forEach((item) => {
    const key = `${item.CurrencyID}-${item.PayGateTokenID}-${item.PaymentSystemSubTypeID}`;

    if (item.PaymentSystemTypeID === paymentSystemId && !uniqueKeys.includes(key)) {
      uniqueKeys.push(key);
      paymentSystemMatrix.push({
        ...item,
        ID: key,
      });
    }

    if (item.PaymentSystemTypeID === IBAN_ID && item.WallettoCardTypes && !uniqueKeys.includes(key)) {
      uniqueKeys.push(key);
      paymentSystemMatrix.push({
        ...item,
        ID: key,
      });
    }
  });

  const fieldProps = {
    currencies: currencyList,
    currencyValue: currencyList.length === 1 ? currencyList[0].gateCurrencyPairId : null,
    handleClose: handleClose,
    successHandler: successHandler,
    userProfile: userProfile,
    showOtpPopup: showOtpPopup,
    hideOptPopup: hideOtpPopup,
    changeActiveScreen: changeActiveScreen,
    paymentSystemId: paymentSystemId,
    paymentSystemMatrix: paymentSystemMatrix,
  };

  switch (paymentSystemId) {
    case SHARPAY_ID:
      return (
        <SharpayAccountFields
          {...fieldProps}
          key={paymentSystem.id}
        />
      );
    case IBAN_ID:
      return (
        <IbanAccountFields {...fieldProps}/> // systemTypeId - 12
      );
    case BANXE_ID:
      return (
        <BanxeAccountFields {...fieldProps}/>
      );
    default:
      const filteredAccounts = accountList.filter((item) => {
        return item.PaymentSystemTypeID === IBAN_ID && item.AccountStatusID === 1;
      });

      return (
        <AddCardFields accountList={filteredAccounts}  {...fieldProps}/>
      );
  }
};

const AddAccountPopup = (props) => {
  const {t} = useTranslation();
  const [paymentSystemValue, setPaymentSystemValue] = useState(null);
  const [allowedCreateMatrix, setAllowedCreateMatrix] = useState(null);
  const [rawMatrix, setRawMatrix] = useState(null);
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    if (!allowedCreateMatrix) {
      getAllowedCreateMatrix().then((response) => {
        const allowedPaymentSystems = {};

        if (Array.isArray(response)) {
          response.forEach((item) => {
            const {
              CurrencyID,
              CurrencyCode,
              CurrencyTypeID,
              PayGateTokenID,
              PaymentSystemTypeID,
            } = item;
            if (CurrencyTypeID === 2) {
              item.PaymentSystemTypeName = item.PaymentSystemTypeName + ' Crypto';
            }

            const systemNameId = `${item.PaymentSystemTypeID}-${CurrencyTypeID}`;
            const gateCurrencyPairId = `${CurrencyID}-${PayGateTokenID ?? -1}`;
            const uniqId = `${systemNameId}-${gateCurrencyPairId}`;

            if (allowedPaymentSystems[systemNameId]) {
              if (!allowedPaymentSystems[systemNameId].gateCurrencyPair[gateCurrencyPairId]) {
                allowedPaymentSystems[systemNameId].gateCurrencyPair[gateCurrencyPairId] = {
                  name: CurrencyCode,
                  gateTokenId: PayGateTokenID,
                  currencyId: CurrencyID,
                  gateCurrencyPairId: gateCurrencyPairId,
                }
              }
            } else {
              allowedPaymentSystems[systemNameId] = {
                accountType: PaymentSystemTypeID,
                id: uniqId,
                name: item.PaymentSystemTypeName + '/' + item.CurrencyCode + '/' + item.PayGateTokenDisplayName,
                gateCurrencyPair: {
                  [gateCurrencyPairId]: {
                    name: CurrencyCode,
                    gateTokenId: PayGateTokenID,
                    currencyId: CurrencyID,
                    gateCurrencyPairId: gateCurrencyPairId,
                  },
                },
              }
            }
          });
        }

        const values = Object.values(allowedPaymentSystems);

        if (response.some(obj => obj.hasOwnProperty('WallettoCardTypes'))) {
          values.push({
            accountType: null,
            id: 'new-card',
            name: 'SP Card',
            gateCurrencyPair: null,
          });
        }

        setAllowedCreateMatrix(values);
        setRawMatrix(response);
        return {a: values, b: response};
      }).then(async ({a, b}) => {
        const request = await userProfileGetList();

        console.group('allowedCreateMatrix');
        console.log(a);
        console.groupEnd();

        console.group('raw');
        console.log(b);
        console.groupEnd();

        if (request.isSuccess) {
          setUserProfile(request.responseObj);
        } else {
          notifyApp(request.error);
        }
      }).catch((e) => {
        notifyApp(e);
      });
    }
  }, [allowedCreateMatrix]);

  const getAllowedCreateMatrix = async () => {
    const response = await apiRequest({
      operation: 'Account/AllowedCreateMatrix',
      data: {
        Params: {
          IsBanxe: 1,
        }
      },
    });

    if (response.data.ResponseCode === SUCCESS_API_CODE) {
      return response.data.Response.AllowedCreateMatrix;
    } else {
      throw new Error(response.data.ResponseText);
    }
  }

  const paymentSystemChangeHandler = ({value}) => {
    setPaymentSystemValue(value);
  };

  return (
    <Popup
      visible={props.visible}
      container={'#root'}
      dragEnabled={false}
      closeOnOutsideClick={false}
      showTitle={false}
      maxWidth={500}
      height={'auto'}
      maxHeight={'80%'}
      position={popupPosition}
      onHiding={props.handleClose}
    >
      <div
        className={'flexi-popup-close'}
        onClick={props.handleClose}
      >
        <i className={'mdi mdi-close-circle-outline'}/>
      </div>
      <div className={'flexi-popup-content'}>
        <div className={'flexi-popup-logo'}>
          <img width={29} src={'/images/icons/card-plus.svg'} alt=''/>
        </div>
        <div className={'flexi-popup-head'}>
          <div className={'flexi-popup-title'}>
            {getPopupTitle(parsePaymentSystemId(paymentSystemValue), t)}
          </div>
        </div>
        <ScrollView
          className={'popup-scrollable-content'}
          height={'100%'}
          width={'100%'}
        >
          <div className={'flexi-popup-selects'}>
            <PopupSelectBox
              placeholder={t('MAIN.ADD_ACCOUNT.ACCOUNT_TYPE')}
              items={allowedCreateMatrix}
              value={paymentSystemValue}
              changeHandler={paymentSystemChangeHandler}
              displayExpr={'name'}
              valueExpr={'id'}
            />
            <PopupFieldsComponent
              paymentSystemValue={paymentSystemValue}
              allowedCreateMatrix={allowedCreateMatrix}
              handleClose={props.handleClose}
              successHandler={props.successHandler}
              userProfile={userProfile}
              showOtpPopup={props.showOtpPopup}
              changeActiveScreen={props.changeActiveScreen}
              hideOtpPopup={props.hideOtpPopup}
              rawMatrix={rawMatrix}
              accountList={props.accountList}
            />
          </div>
        </ScrollView>
      </div>
    </Popup>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    showOtpPopup: (payload) => dispatch(showOtpPopup(payload)),
    hideOtpPopup: () => dispatch(hideOtpPopup()),
    changeActiveScreen: (screen) => dispatch(changeActiveScreen(screen)),
  }
}

export default connect(null, mapDispatchToProps)(AddAccountPopup);
