import React from 'react';

const EyeIcon = ({open = false, width = 20, height= 14,  fill = '#0066FF'}) => {
  const d = open ?
    "M9.99967 2.41667C13.158 2.41667 15.9747 4.19167 17.3497 7C15.9747 9.80833 13.158 11.5833 9.99967 11.5833C6.84134 11.5833 4.02467 9.80833 2.64967 7C4.02467 4.19167 6.84134 2.41667 9.99967 2.41667ZM9.99967 0.75C5.83301 0.75 2.27467 3.34167 0.833008 7C2.27467 10.6583 5.83301 13.25 9.99967 13.25C14.1663 13.25 17.7247 10.6583 19.1663 7C17.7247 3.34167 14.1663 0.75 9.99967 0.75ZM9.99967 4.91667C11.1497 4.91667 12.083 5.85 12.083 7C12.083 8.15 11.1497 9.08333 9.99967 9.08333C8.84968 9.08333 7.91634 8.15 7.91634 7C7.91634 5.85 8.84968 4.91667 9.99967 4.91667ZM9.99967 3.25C7.93301 3.25 6.24967 4.93333 6.24967 7C6.24967 9.06667 7.93301 10.75 9.99967 10.75C12.0663 10.75 13.7497 9.06667 13.7497 7C13.7497 4.93333 12.0663 3.25 9.99967 3.25Z" :
    "M9.99967 2.97915C13.158 2.97915 15.9747 4.75415 17.3497 7.56248C16.858 8.57915 16.1663 9.45415 15.3413 10.1625L16.5163 11.3375C17.6747 10.3125 18.5913 9.02915 19.1663 7.56248C17.7247 3.90415 14.1663 1.31248 9.99967 1.31248C8.94134 1.31248 7.92467 1.47915 6.96634 1.78748L8.34134 3.16248C8.88301 3.05415 9.43301 2.97915 9.99967 2.97915ZM9.10801 3.92915L10.833 5.65415C11.308 5.86248 11.6913 6.24581 11.8997 6.72081L13.6247 8.44581C13.6913 8.16248 13.7413 7.86248 13.7413 7.55415C13.7497 5.48748 12.0663 3.81248 9.99967 3.81248C9.69134 3.81248 9.39968 3.85415 9.10801 3.92915ZM1.67467 1.20415L3.90801 3.43748C2.54967 4.50415 1.47467 5.92081 0.833008 7.56248C2.27467 11.2208 5.83301 13.8125 9.99967 13.8125C11.2663 13.8125 12.483 13.5708 13.5997 13.1291L16.4497 15.9791L17.6247 14.8041L2.84967 0.020813L1.67467 1.20415ZM7.92467 7.45415L10.0997 9.62915C10.0663 9.63748 10.033 9.64581 9.99967 9.64581C8.84968 9.64581 7.91634 8.71248 7.91634 7.56248C7.91634 7.52081 7.92467 7.49581 7.92467 7.45415ZM5.09134 4.62081L6.54967 6.07915C6.35801 6.53748 6.24967 7.03748 6.24967 7.56248C6.24967 9.62915 7.93301 11.3125 9.99967 11.3125C10.5247 11.3125 11.0247 11.2041 11.4747 11.0125L12.2913 11.8291C11.558 12.0291 10.7913 12.1458 9.99967 12.1458C6.84134 12.1458 4.02467 10.3708 2.64967 7.56248C3.23301 6.37081 4.08301 5.38748 5.09134 4.62081Z";

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d={d} fill={fill}/>
    </svg>
  );
}

export default EyeIcon;