import dictionaryFilter from "./dictionaryFilter";
export {makeCalculateFilterExpression} from './makeCalculateFilterExpression';

/**
 * @typedef {object} DictionaryFilter
 * @property {string} object
 * @property {string | function} displayName
 * @property {string} keyName
 * @property {any[]} value
 * @property {string[]} [additionalCols]
 * @property {function} onValueChanged
 * @property {number} parentWidth
 */

/**
 * Returns an HTMLElement (an editor) for a data grid filter row
 * @param {object} props
 * @param {'dictionary' | 'calendar'} props.type
 * @param {DictionaryFilter} props.options
 * @returns {HTMLElement} - a filter element which must be appended to a container
 */
export function createFilter(props) {
	switch (props.type) {
		case "dictionary":
			return dictionaryFilter(props.options);
		case "calendar":
		default:
			return document.createElement('div');
	}
}
