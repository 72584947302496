import React from 'react';
import 'App.scss';

export default class UnderConstruction extends React.PureComponent {
	render() {
		return (
			<div className={'under-construction-wrapper'}>
				<div className={'under-construction'}>
					<img src="/images/under.png" alt=""/>
				</div>
			</div>
		);
	}
}
