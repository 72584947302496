import React from 'react';
import {useTranslation} from 'react-i18next';
import SuccessStatusScreen from './success-status-screen';

const StatusScreen = (props) => {
	const {text} = props;
	const {t} = useTranslation();

	return text === 'OPERATIONS.RIGHT_START_TEXT' ? (
		<div className={'blank-block'}>
			<div className={'blank-block-logo-wrapper'}>
				<img width={120} src={'./images/icons/wallet-green.svg'} alt={t('OPERATIONS.RIGHT_START_TEXT')}/>
			</div>
			<div>
					<span className={'blank-block-text'}>
						{t(text)}
					</span>
			</div>
		</div>
	) : (
		<SuccessStatusScreen text={text}/>
	);
};

export default StatusScreen;
