import React, {useEffect, useRef, useState} from 'react';
import {vars} from 'utils/variables';
import AccountWidget from '../account/account-widget';

const {
  MAIN: {ACCOUNTS: {WALLETTO_ID, SHARPAY_IBAN_ID, SHARPAY_ID}},
  OPERATION: {EUR_CURRENCY}
} = vars;

const HorizontalBar = ({
                         accountsMap, accountGroupID, onDetailClick, onAccountClick, currencies, selectedAccountId, isDetailOpen
                       }) => {
  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('wheel', (e) => {
        e.preventDefault();
        container.scrollLeft += e.deltaY;
      }, {passive: false});
    }
  }, []);

  return (
    <div className={'horizontal-bar-widget'}>
      <div
        className='bar-widget'
        ref={scrollContainerRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <div className={'account-inner'}>
          {accountsMap.get(accountGroupID).map((account) => {
            const accountId = account.PaymentSystemTypeID === WALLETTO_ID ? account.AccountID : account.ID;
            const currencyId = account.PaymentSystemTypeID === WALLETTO_ID ? account.AccountCurrencyID : account.CurrencyID;
            const balance = account.PaymentSystemTypeID === WALLETTO_ID ? account.AccountBalance : account.Balance;

            const currency = currencies.find((currency) => currency.ID === currencyId) || EUR_CURRENCY;
            const accountData = {
              currency: currency,
              balance: balance.toFixed(currency.ViewPrecision),
              accountId: accountId,
              paymentSystemTypeId: account.PaymentSystemTypeID,
              accountNumber: account.AccountNumber,
              cardNumber: account.CardNumber,
              expiry: account.Expiry,
              account: account,
            }

            const className = accountId === selectedAccountId ? 'account-item selected' : 'account-item';

            return (
              <div className={className} key={accountId} onClick={() => {
                if (accountId !== selectedAccountId) {
                  onAccountClick({accountId: accountId});
                }
              }}>
                <AccountWidget accountData={accountData} onDetailClick={onDetailClick} isDetailOpen={isDetailOpen}/>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default HorizontalBar;