import React from 'react';
import {vars} from 'utils/variables';
import Profile from './profile/profile';
import LegalInformation from './legal-information/legal-information';
import BusinessProfile from './profile/business-profile';
import SciApi from './sciapi/sciapi';
import Security from './security/security';
import Verification from './verification/verification';
import Invite from './invite/invite';

const {PROFILE: {PERSONAL_ACCOUNT, BUSINESS_ACCOUNT}} = vars;

const screenMaker = ({activeScreen, isBanxe}) => {
	switch (activeScreen) {
		case 'Profile':
			const userTypeId = parseInt(window.localStorage.getItem('userTypeID')) || PERSONAL_ACCOUNT;
			return userTypeId === BUSINESS_ACCOUNT ? <BusinessProfile/> : <Profile/>;
		case 'LegalInformation':
			return <LegalInformation/>;
		case 'SciApi':
			return <SciApi/>;
		case 'Security':
			return <Security/>;
		case 'Verification':
			return <Verification isBanxe={isBanxe}/>;
		case 'Invite':
			return <Invite/>;
		default:
			return null;
	}
};

export default screenMaker;
