import React from 'react';

const LockIcon = ({width = 14, height= 18,  fill = 'red'}) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M11.167 16.5H2.83366C1.91283 16.5 1.16699 15.7542 1.16699 14.8333V8.16667C1.16699 7.24583 1.91283 6.5 2.83366 6.5H11.167C12.0878 6.5 12.8337 7.24583 12.8337 8.16667V14.8333C12.8337 15.7542 12.0878 16.5 11.167 16.5Z"
            stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M3.66699 6.5V4.83333V4.83333C3.66699 2.9925 5.15949 1.5 7.00033 1.5V1.5C8.84116 1.5 10.3337 2.9925 10.3337 4.83333V4.83333V6.5"
        stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.5 9.83335H4.5" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.5 13.1667H4.5" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default LockIcon;