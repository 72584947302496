import TagBox from 'devextreme/ui/tag_box';
import Popup from 'devextreme/ui/popup';
import {getDicDataSource} from "../../services/dictionaryDataSource";
import {valueToArray} from "../functions";

/** Returns a filter element for a data grid filter row.
 * @param {DictionaryFilter} options
 * @returns {HTMLElement}
 */
export default function dictionaryFilter(options) {
	const div = document.createElement('div');
	const {placeholder = 'Select...'} = options;

	new TagBox(div, {
		placeholder: placeholder,
		searchEnabled: typeof options.displayName === 'string',
		showClearButton: true,
		showSelectionControls: true,
		maxDisplayedTags: 3,
		showMultiTagOnly: false,
		applyValueMode: 'useButtons',
		displayExpr: options.displayName,
		valueExpr: options.keyName,
		value: options.value,
		popupWidthExtension: options.parentWidth < 140 ? 140 - options.parentWidth: 0,
		tagTemplate: function(tagData) {
			let text = typeof tagData === 'string' ?
				tagData :
				typeof options.displayName === 'function' ?
					options.displayName(tagData) :
					tagData[options.displayName];

			const div = document.createElement('div');
			div.className = "dx-tag-content";
			div.style.maxWidth = '100%';
			div.style.paddingRight = '27px'; //уменьшил паддинг - был 32
			div.style.display = 'flex';

			//38 - подобранное число. Размер dx-tag-remove-button (24) + на глаз
			div.innerHTML = `
				<span style="text-overflow: ellipsis;width: ${options.parentWidth - 38}px;display: inline-block;overflow: hidden;" title="${text}">${text}</span>
				<div class="dx-tag-remove-button"/>
			`;

			return div;
		},
		onValueChanged: ({value}) => {
			options.onValueChanged({
				value: valueToArray(value)
			})
		},
		onOpened: ({element}) => {
			let popup = Popup.getInstance(element.querySelector('.dx-popup.dx-widget'));

			function changeButton(item, icon, className) {
				item.text = undefined;
				item.shortcut = undefined;
				item.widget = 'dxButton';
				item.options.icon = icon;
				item.options.text = undefined;
				item.options.elementAttr = {
					'class': className,
				};
			}

			const [ok, cancel] = popup.option('toolbarItems');

			changeButton(ok, 'mdi mdi-check-bold', 'popup-filter-ok');
			changeButton(cancel, 'mdi mdi-close-thick', 'popup-filter-close');

			ok.options.elementAttr.style = 'margin-right: 8px;'

			popup.option('toolbarItems', [ok, cancel]);
		},
		dataSource: getDicDataSource({
			dictDisplayFld: options.displayName,
			keyField: options.keyName,
			dictObj: options.object,
			additionalCols: options.additionalCols,
			sorts: options.sorts,
		}),
	});

	return div;
}
