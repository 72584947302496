import React from 'react';

const NavigationIcons = ({name, fill= '#000'}) => {
  let d;

  switch (name) {
    case 'person':
      d = "M10 4.375C11.0312 4.375 11.875 5.21875 11.875 6.25C11.875 7.28125 11.0312 8.125 10 8.125C8.96875 8.125 8.125 7.28125 8.125 6.25C8.125 5.21875 8.96875 4.375 10 4.375ZM10 13.75C12.5312 13.75 15.4375 14.9594 15.625 15.625H4.375C4.59063 14.95 7.47813 13.75 10 13.75ZM10 2.5C7.92812 2.5 6.25 4.17812 6.25 6.25C6.25 8.32188 7.92812 10 10 10C12.0719 10 13.75 8.32188 13.75 6.25C13.75 4.17812 12.0719 2.5 10 2.5ZM10 11.875C7.49687 11.875 2.5 13.1313 2.5 15.625V17.5H17.5V15.625C17.5 13.1313 12.5031 11.875 10 11.875Z";
      break;
    case 'phone':
      d = "M5.45 4.16667C5.5 4.90833 5.625 5.63333 5.825 6.325L4.825 7.325C4.48333 6.325 4.26667 5.26667 4.19167 4.16667H5.45ZM13.6667 14.1833C14.375 14.3833 15.1 14.5083 15.8333 14.5583V15.8C14.7333 15.725 13.675 15.5083 12.6667 15.175L13.6667 14.1833ZM6.25 2.5H3.33333C2.875 2.5 2.5 2.875 2.5 3.33333C2.5 11.1583 8.84167 17.5 16.6667 17.5C17.125 17.5 17.5 17.125 17.5 16.6667V13.7583C17.5 13.3 17.125 12.925 16.6667 12.925C15.6333 12.925 14.625 12.7583 13.6917 12.45C13.6083 12.4167 13.5167 12.4083 13.4333 12.4083C13.2167 12.4083 13.0083 12.4917 12.8417 12.65L11.0083 14.4833C8.65 13.275 6.71667 11.35 5.51667 8.99167L7.35 7.15833C7.58333 6.925 7.65 6.6 7.55833 6.30833C7.25 5.375 7.08333 4.375 7.08333 3.33333C7.08333 2.875 6.70833 2.5 6.25 2.5Z";
      break;
    case 'email':
      d = "M18.3337 4.99998C18.3337 4.08331 17.5837 3.33331 16.667 3.33331H3.33366C2.41699 3.33331 1.66699 4.08331 1.66699 4.99998V15C1.66699 15.9166 2.41699 16.6666 3.33366 16.6666H16.667C17.5837 16.6666 18.3337 15.9166 18.3337 15V4.99998ZM16.667 4.99998L10.0003 9.15831L3.33366 4.99998H16.667ZM16.667 15H3.33366V6.66665L10.0003 10.8333L16.667 6.66665V15Z";
      break;
    default:
      d = "M9 0L3.5 9h11L9 0zm0 3.84L10.93 7H7.06L9 3.84zM14.5 11c-2.49 0-4.5 2.01-4.5 4.5s2.01 4.5 4.5 4.5 4.5-2.01 4.5-4.5-2.01-4.5-4.5-4.5zm0 7a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5zM0 19.5h8v-8H0v8zm2-6h4v4H2v-4z";
  }

  return (
    <span className={'flexi-popup-round_input__icon'}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d={d}
        fill={fill}/>
    </svg>
    </span>
  );
}

export default NavigationIcons;