import React from 'react';

const CardActivation = ({width = 26, height = 28}) => {
	return (
		<svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1 7.66667H25" stroke="#171717" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M1 14.3333V5C1 2.79086 2.79086 1 5 1H21C23.2091 1 25 2.79086 25 5V15.6667C25 17.8758 23.2091 19.6667 21 19.6667H17" stroke="#171717" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M19.6665 14.3334H15.6665" stroke="#171717" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M6.33333 26.9999C6.33333 24.0544 3.94552 21.6666 1 21.6666C3.94552 21.6666 6.33333 19.2788 6.33333 16.3333C6.33333 19.2788 8.72115 21.6666 11.6667 21.6666C8.72115 21.6666 6.33333 24.0544 6.33333 26.9999Z" stroke="#171717" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	);
}

export default CardActivation;