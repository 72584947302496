import React from 'react';
import {getGroupIcon} from 'utils/functions';
import {vars} from 'utils/variables';

const {PAYMENT: {PAYMENT_TYPE_INCOME}} = vars;

const GroupTemplate = ({data, paymentTypeId}) => {
	if (!data) {
		return null;
	}

	const {
		key,
		items,
	} = data;

	return (
		<div className={`group-wrapper ${paymentTypeId === PAYMENT_TYPE_INCOME ? 'group-wrapper-income' : ''}`}>
			<i className={getGroupIcon(items[0].ForeignPaymentSystemTypeGroupID)}></i>
			<div className={'group-text'}>
				{key}
			</div>
		</div>
	);
};

export default GroupTemplate;


