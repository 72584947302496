import React, {useState, useEffect} from 'react';
import ArrowIcon from 'components/svg/arrow-icon';
import classNames from 'classnames';

const ItemWidget = ({data, onItemDetailClick, isDetailOpen}) => {
  const {
    DefaultAccountCurrencyCode,
    DefaultAccountCurrencySymbol,
    Name,
  } = data;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if(!isDetailOpen) {
      setIsOpen(false);
    }
  }, [isDetailOpen]);

  return (
    <div className={'account-widget'}>
      <div className={'account-row'}>
        <div className={'account-row-group'}>
          <span className={'account-currency'}>{`${DefaultAccountCurrencyCode} ${DefaultAccountCurrencySymbol}`}</span>
          <span>{Name}</span>
        </div>
        <div className={'account-row-group'}>
          <span className={classNames('chevron', {
            up: isOpen && isDetailOpen,
          })} onClick={(event) => {
            setIsOpen(!isOpen);
            onItemDetailClick(event, data);
          }}>
            <ArrowIcon/>
          </span>
        </div>
      </div>
    </div>
  );
}

export default ItemWidget;