import React from 'react';
import {getAccountsList} from 'services/async';
import RightForm from 'components/operations';
import AccountDataGrid from 'components/operations/account-datagrid';
import {connect} from 'react-redux';
import {clearOperation, makeOperation} from 'services/operations/actions';
import {notifyApp} from 'utils/notifyWrapper';

import './operations.scss';

const initialState = {
	accountList: null,
	paymentMethodValueId: null,
};

class NewOperations extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
	}

	async componentDidMount() {
		const account = await getAccountsList();

		if (account.isSuccess) {
			this.setState({
				accountList: account.list,
			})
		} else {
			notifyApp(account.error);
		}
	}

	componentWillUnmount() {
		this.props.clearOperation();
	}

	changePaymentMethod = (paymentMethodValueId) => {
		this.setState({
			paymentMethodValueId: paymentMethodValueId,
		});
	};

	operationButtonHandler = (paymentTypeId, row) => {
    const {account} = this.props;

    if (account.ID !== row.ID || paymentTypeId !== this.props.paymentTypeId) {
      this.setState({
        paymentMethodValueId: null,
      }, () => {
        this.props.makeOperation({
          paymentTypeId: paymentTypeId,
          account: row,
        });
      });
    }
	};

	render() {
		const {accountList, paymentMethodValueId} = this.state;
		const {account, paymentTypeId, status} = this.props;

		return (
			<div id={'operation-wrapper'} className={'animated-page'}>
				<div
					className={'operation-block'}
					id={'operation-table'}
				>
					<AccountDataGrid
						accountList={accountList}
						account={account}
						paymentTypeId={paymentTypeId}
						operationButtonHandler={this.operationButtonHandler}
					/>
				</div>
				<div
					className={'operation-block'}
					id={'operation-form'}
				>
					<RightForm
            accountList={accountList}
						paymentTypeId={paymentTypeId}
						account={account}
						paymentMethodValueId={paymentMethodValueId}
						changePaymentMethod={this.changePaymentMethod}
						status={status}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		paymentTypeId: state.operations.paymentTypeId,
		account: state.operations.account,
		status: state.operations.status,
	}
};

const mapDispatchToProps = (dispatch) => ({
	makeOperation: (payload) => dispatch(makeOperation(payload)),
	clearOperation: () => dispatch(clearOperation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewOperations);
