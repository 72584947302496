import {apiRequest} from 'services/async';
import {vars} from 'utils/variables';

const {SUCCESS_API_CODE} = vars;

export const fetchCurrencies = () => {
  const objectName = 'Currency';

  return async (dispatch) => {
    dispatch({type: 'FETCH_CURRENCIES_START'});

    try {
      const response = await apiRequest({
        operation: `${objectName}/List`,
        data: {
          Page: -1,
        }
      });

      if (response.data.ResponseCode === SUCCESS_API_CODE) {
        dispatch({type: 'FETCH_CURRENCIES_SUCCESS', payload: response.data.Response[objectName]});
      } else {
        throw new Error(response.data.ResponseText || 'Failed to fetch currencies');
      }
    } catch (error) {
      dispatch({type: 'FETCH_CURRENCIES_FAILURE', payload: error.message});
    }
  };
};