import React from "react";
import {Tabs, Button} from 'devextreme-react';
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';

import './styles.scss';
import RoundButton from '../../../components/buttons/round-button/round-button';

const Accounts = (props) => {
	const {t} = useTranslation();
	const {selectedIndex, dataSource, addButtonClickHandler, onTabsSelectionChanged} = props;

	return (
		<div
			id={'acc-toolbar'}
			className={'acc-top-nav'}
		>
      <RoundButton
        text={t('MAIN.ADD_ACCOUNT.OPEN_ACCOUNT')}
        customClass={'custom-add-btn'}
        onClick={addButtonClickHandler}
        height={28}
      />
			<Tabs
				id={'accounts'}
				dataSource={dataSource}
				selectedIndex={selectedIndex}
				scrollByContent={true}
				showNavButtons={false}
				height={40}
				noDataText={t('MAIN.PLUG_ACCOUNTS_TABS.NO_DATA')}
				onOptionChanged={onTabsSelectionChanged}
			/>
		</div>
	);
};

Accounts.propTypes = {
	dataSource: PropTypes.array,
	selectedIndex: PropTypes.number,
	addButtonClickHandler: PropTypes.func.isRequired,
};

export default Accounts;
