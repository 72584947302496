import {
	Operations,
	Settings,
	NewMain,
	NewTransactions,
} from './pages';
import {vars} from 'utils/variables';

const {PROFILE: {PERSONAL_ACCOUNT, BUSINESS_ACCOUNT}} = vars;

export const registrationRoute = '/registration';

export const routes = [
	{
		id: 'main',
		path: '/main',
		exact: true,
		component: NewMain,
		translationKey: 'HOME',
		url: '/main',
		enabledUserTypes: [PERSONAL_ACCOUNT, BUSINESS_ACCOUNT],
	},
	{
		id: 'transactions',
		path: '/transactions/',
		exact: true,
		component: NewTransactions,
		translationKey: 'TRANSACTIONS',
		url: '/transactions',
		enabledUserTypes: [BUSINESS_ACCOUNT],
	},
	{
		id: 'operations',
		path: '/operations/:operation?',
		exact: false,
		component: Operations,
		translationKey: 'OPERATIONS',
		url: '/operations',
		enabledUserTypes: [PERSONAL_ACCOUNT, BUSINESS_ACCOUNT],
	},
	/*{
		id: 'statement',
		path: '/statement',
		exact: true,
		component: UnderConstruction,
		translationKey: 'STATEMENTS',
		url: '/statement',
	},
	{
		id: 'analytics',
		path: '/analytics',
		exact: true,
		component: UnderConstruction,
		translationKey: 'ANALYTICS',
		url: '/analytics',
	},
	{
		id: 'service-payment',
		path: '/service-payment',
		exact: true,
		component: UnderConstruction,
		translationKey: 'SERVICES',
		url: '/service-payment',
	},
	{
		id: 'helpdesk',
		path: '/helpdesk',
		exact: true,
		component: UnderConstruction,
		translationKey: 'SUPPORT',
		url: '/helpdesk',
	},*/
	{
		id: 'settings',
		path: '/settings',
		exact: true,
		component: Settings,
		translationKey: 'SETTINGS',
		url: '/settings',
		enabledUserTypes: [PERSONAL_ACCOUNT, BUSINESS_ACCOUNT],
	},
];
