import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';

import {vars} from 'utils/variables';

const {DEFAULT_LANGUAGE} = vars;

i18next
	.use(initReactI18next)
	.use(HttpApi)
	.use(LanguageDetector)
	.init({
		detection: {
			order: ['localStorage', 'sessionStorage'],
			lookupLocalStorage: 'language'
		},
		backend: {
			loadPath: `/locales/{{lng}}/{{ns}}.json?v=${process.env.REACT_APP_VERSION}`
		},
		fallbackLng: DEFAULT_LANGUAGE,
		saveMissingTo: 'fallback',
		interpolation: {
			escapeValue: false,
		},
		debug: process.env.NODE_ENV,
	});

export default i18next;
