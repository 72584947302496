import DataSource from 'devextreme/data/data_source';
import axios from 'axios';
import {prepareRequestOptions} from './async';
import {notifyApp} from '../utils/notifyWrapper';
import {vars} from '../utils/variables';
import {getFilter} from "./dataSourceFilterUtils";

const {NOTIFY, SUCCESS_API_CODE} = vars;

/**
 * @param {string} key
 * @param {string[]} columns
 * @param {string} object
 * @param {object} defFilter
 * @param {object} [fieldsSettings]
 * @return {DevExpress.data.DataSource}
 */
export const getDataSource = ({
																key,
																columns,
																object,
																defFilter = {},
																fieldsSettings,
																sorts,
																modifier = null,
															}) => {
	return new DataSource({
		key,
		load: async ({isLoadingAll, take, skip, filter}) => {
			let page = (skip + take) / take || -1;

			let apiFilter = fieldsSettings ? getFilter(filter, fieldsSettings) || {} : {};
			let requestObj = {
				Filters: {
					...defFilter,
					...apiFilter
				},
				//TODO
				// 	Sorts: {},
				Page: isLoadingAll ? -1 : page,
				RowsPerPage: take,
				Columns: columns,
			};

			if (sorts) {
				requestObj.Sorts = sorts;
			}

			if (!Object.keys(requestObj.Filters).length) {
				delete requestObj.Filters;
			}

			const response = await axios(prepareRequestOptions({operation: `${object}/List`, data: requestObj}));

			if (response.data.ResponseCode === SUCCESS_API_CODE) {
				const result = response.data.Response[object];
				return modifier ? modifier(result) : result;
			}

			notifyApp(response.data.ResponseText, NOTIFY.ERROR);
			return [];
		},
	});
};
