import React from 'react';

const CardSettings = ({size = 29}) => {
	return (
		<svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.56697 8.42866C7.66242 8.5241 7.69097 8.66763 7.63932 8.79233C7.58767 8.91704 7.46598 8.99834 7.33101 8.99834C7.19603 8.99834 7.07435 8.91704 7.0227 8.79233C6.97104 8.66763 6.9996 8.5241 7.09504 8.42866C7.22549 8.29865 7.43652 8.29865 7.56697 8.42866" stroke="#171717" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M14.2359 8.42866C14.3314 8.5241 14.3599 8.66763 14.3083 8.79233C14.2566 8.91704 14.1349 8.99834 14 8.99834C13.865 8.99834 13.7433 8.91704 13.6916 8.79233C13.64 8.66763 13.6685 8.5241 13.764 8.42866C13.8944 8.29865 14.1055 8.29865 14.2359 8.42866" stroke="#171717" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M20.9058 8.42866C21.0013 8.5241 21.0298 8.66763 20.9782 8.79233C20.9265 8.91704 20.8048 8.99834 20.6699 8.99834C20.5349 8.99834 20.4132 8.91704 20.3616 8.79233C20.3099 8.66763 20.3385 8.5241 20.4339 8.42866C20.5644 8.29865 20.7754 8.29865 20.9058 8.42866" stroke="#171717" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M11.3328 15.334H4.66338C3.95579 15.3342 3.27711 15.0532 2.77676 14.5528C2.27642 14.0525 1.99561 13.3738 1.99561 12.6662V4.6629C1.99561 3.9553 2.27642 3.27662 2.77676 2.77628C3.27711 2.27593 3.95579 1.99492 4.66338 1.99512H23.3378C24.0454 1.99492 24.7241 2.27593 25.2244 2.77628C25.7248 3.27662 26.0056 3.9553 26.0056 4.6629V8.66456" stroke="#171717" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M16.1919 20.2469L13.086 23.3593C12.8177 23.6298 12.6668 23.9953 12.666 24.3763V25.9003C12.666 26.6949 13.3101 27.339 14.1047 27.339H15.6351C16.0171 27.3395 16.3833 27.1871 16.6522 26.9158L19.7516 23.8099C20.3193 24.0086 20.9156 24.1135 21.517 24.1205C24.0148 24.1616 26.2511 22.5792 27.0432 20.21C27.8354 17.8409 27.0007 15.2316 24.9806 13.7621C22.9604 12.2926 20.2209 12.3019 18.2108 13.785C16.2007 15.2682 15.3837 17.8831 16.1919 20.2469V20.2469Z" stroke="#171717" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M21.5175 18.3213C21.4254 18.3213 21.3508 18.3959 21.3508 18.488C21.3508 18.5801 21.4254 18.6547 21.5175 18.6547C21.6096 18.6547 21.6843 18.5801 21.6843 18.488C21.6843 18.3959 21.6096 18.3213 21.5175 18.3213V18.3213" stroke="#171717" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	);
}

export default CardSettings;