import React from 'react';
import {useTranslation} from 'react-i18next';

const DynamicPasswordHint = (props) => {
  const {password} = props;
  const {t} = useTranslation();

  const renderPasswordHint = () => {
    const currentPassword = password || '';
    const hints = [
      {text: t('HINT.MIN_OCCURS'), condition: currentPassword.length >= 12},
      {text: t('HINT.NEED_NUMBER'), condition: /\d/.test(currentPassword)},
      {text: t('HINT.NEED_SPECIAL'), condition: /[~!@#$%^&*(),.?":{}|<>]/.test(currentPassword)},
      {text: t('HINT.NEED_LOWERCASE'), condition: /[a-z]/.test(currentPassword)},
      {text: t('HINT.NEED_UPPERCASE'), condition: /[A-Z]/.test(currentPassword)},
    ];

    return hints.map((hint, index) => (
      <span key={index} style={{textDecoration: hint.condition ? 'line-through' : 'none'}}>
        {hint.text}
        {index < hints.length - 1 && ', '}
      </span>
    ));
  }

  return (
    <div className={'interactive-hint'}>
      {renderPasswordHint()}
    </div>
  );
}

export default DynamicPasswordHint;