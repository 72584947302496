import notify from 'devextreme/ui/notify';
import {vars} from 'utils/variables';

const {NOTIFY} = vars;

export const notifyApp = (text, type = NOTIFY.ERROR) => {
	return notify({
		message: text,
		position: {
			my: 'center top',
			at: 'center top'
		}
	}, type, 3000);
};
