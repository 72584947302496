import React, {useEffect, useRef, useState} from 'react';
import DataGrid, {
  Column,
  ColumnChooser,
  FilterRow,
  Paging,
  RemoteOperations,
  Scrolling,
  Sorting,
} from 'devextreme-react/data-grid';
import {customizeTransactionStatus, valueWithIcon} from 'utils/customize-table-cell';
import {Popover} from 'devextreme-react/popover';
import {DateBox} from 'devextreme-react';
import RoundButton from 'components/buttons/round-button/round-button';
import CreateOrderPopup from 'components/popup/create-order-popup';
import {fileDateTextFormat, formatDateToApi} from 'services/dataSourceFilterUtils';
import {getTransactions} from 'services/requestConsts';
import {getDataSource} from 'services/dataSource';
import {apiRequest, merchantGetOrderUrl} from 'services/async';
import {vars} from 'utils/variables';
import TransactionDetails from './transaction-details';
import {notifyApp} from 'utils/notifyWrapper';
import {Template} from 'devextreme-react/core/template';
import TransactionGroup from './tabs/transaction-group';
import {useTranslation} from 'react-i18next';
import {createFilter, makeCalculateFilterExpression} from 'utils/customFilters';
import {copyTextToClipboard, getExportFileName, valueToArray} from 'utils/functions';
import {ButtonGroup} from 'devextreme-react/button-group';
import ItemDetailWidget from './tabs/item-detail-widget';

import './newtransactions.scss';

const {SUCCESS_API_CODE, NOTIFY: {SUCCESS}} = vars;
const today = new Date();

const innerContextButtons = [
  {icon: 'mdi mdi-dots-horizontal', action: 'details', title: 'TRANSACTIONS.SHOW_DETAILS'},
  {icon: 'mdi mdi-content-copy', action: 'copy', title: 'TRANSACTIONS.COPY_LINK'},
  {icon: 'mdi mdi-check', action: 'confirm', title: 'TRANSACTIONS.CONFIRM'},
  {icon: 'mdi mdi-close', action: 'cancel', title: 'TRANSACTIONS.CANCEL'},
];

const toggleContextMenu = () => {
  const ctxWrapper = document.getElementById('ctx-wrapper');
  const ctxMenu = document.getElementById('ctx-menu');

  ctxWrapper.classList.toggle('visible');
  ctxMenu.classList.toggle('visible');
}

const CustomButtonGroupCell = ({row, handleActionClick}) => {
  const {t} = useTranslation();
  const {MerchantOrderID, MerchantOrderTypeID, PaymentStatusID} = row.data;

  const handleClick = ({element}) => {
    const ctxMenu = document.getElementById('ctx-menu');
    const rect = element.getBoundingClientRect();

    innerContextButtons.forEach((btn, index) => {
      const btnElement = document.getElementById(btn.action);

      if(btn.action === 'details') {
        if (MerchantOrderID) {
          btnElement.classList.remove('disabled');
        } else {
          btnElement.classList.add('disabled');
        }
      }

      if (btn.action === 'copy') {
        if (MerchantOrderID && MerchantOrderTypeID === 2) {
          btnElement.classList.remove('disabled');
        } else {
          btnElement.classList.add('disabled');
        }
      }

      if (btn.action === 'confirm' || btn.action === 'cancel') {
        if (PaymentStatusID === 8) {
          btnElement.classList.remove('disabled');
        } else {
          btnElement.classList.add('disabled');
        }
      }

      btnElement.onclick = () => {
        toggleContextMenu();
        handleActionClick(btn.action, row.data);
      }
    });

    ctxMenu.style.top = (rect.top - 56) + 'px';
    ctxMenu.style.left = (rect.left - 120) + 'px';

    toggleContextMenu();
  }

  const buttons = [
    {
      id: 'actions',
      icon: 'mdi mdi-dots-horizontal',
      hint: t('TRANSACTIONS.ACTION'),
    },
  ];

  return (
    <div className={'button-group-wrapper'}>
      <ButtonGroup
        activeStateEnabled={false}
        items={buttons}
        keyExpr='id'
        stylingMode='outlined'
        onItemClick={handleClick}
      />
    </div>
  );
};

const NewTransactions = () => {
  const [transactions, setTransactions] = useState(null);
  const [list, setList] = useState([]);
  const [selectedId, setSelectedId] = useState(-1);
  const [isShowExportDatePopup, setIsShowExportDatePopup] = useState(false);
  const [exportDataSource, setExportDataSource] = useState([]);
  const [startDate, setStartDate] = useState({
    value: today,
    text: fileDateTextFormat(today),
  });
  const [endDate, setEndDate] = useState({
    value: today,
    text: fileDateTextFormat(today),
  });
  const [enableExport, setEnableExport] = useState(false);

  const {t} = useTranslation();

  const filterOptions = {
    PaymentSystemTypeName: {
      type: 'dictionary',
      filterOperations: [],
      calculateFilterExpression: makeCalculateFilterExpression('PaymentSystemTypeID'),
      options: {
        object: 'PaymentSystemType',
        displayName: 'Name',
        keyName: 'ID',
        value: undefined,
        sorts: 'Name',
        onValueChanged: () => {
        },
      }
    },
    Status: {
      type: 'dictionary',
      filterOperations: [],
      calculateFilterExpression: makeCalculateFilterExpression('StatusID'),
      options: {
        object: 'MerchantOrderStatus',
        displayName: 'Name',
        keyName: 'ID',
        value: undefined,
        onValueChanged: () => {
        },
      }
    },
  };

  const onEditorPreparing = (e) => {
    const {dataField, editorElement, parentType} = e;

    if (filterOptions.hasOwnProperty(dataField) && parentType === 'filterRow') {
      const settings = filterOptions[dataField];
      e.cancel = true;

      editorElement.appendChild(createFilter({
        ...settings,
        options: {
          ...settings.options,
          placeholder: t('MAIN.PAYMENT_HISTORY_TABLE.TABLE_TOPBAR.COLUMN_CHOOSER_SELECT'),
          parentWidth: editorElement.clientWidth,
          value: valueToArray(e.value),
          onValueChanged: e.editorOptions.onValueChanged
        }
      }));
    }
  };
  const getMerchantList = async () => {
    const merchantRequest = await apiRequest({
      operation: 'MerchantApiProfile/List',
      data: {
        Filters: {
          MerchantApiProfileStatusID: 1
        }
      }
    });

    if (merchantRequest.data.ResponseCode === SUCCESS_API_CODE) {
      const response = merchantRequest.data.Response.MerchantApiProfile;
      setList(response);
      setSelectedId(response[0].ID);
    } else {
      notifyApp(merchantRequest.data.ResponseText);
    }
  };

  useEffect(() => {
    getMerchantList().catch((e) => {
      console.error(e);
    });
  }, []);

  useEffect(() => {
    if (list.length > 0) {
      const transactions = getDataSource(getTransactions(list[0].ID));
      setTransactions(transactions);
    }
  }, [list]);

  useEffect(() => {
    if (selectedId > 0) {
      const transactions = getDataSource(getTransactions(selectedId));
      setTransactions(transactions);
    }
  }, [selectedId]);

  const withCurrencySymbol = ({value, data}) => {
    if (!value) return '';

    let val = value;
    if (data['CurrencyViewPrecision']) {
      val = val.toFixed(data['CurrencyViewPrecision']);
    }
    return `${data.CurrencySymbol} ${val}`;
  };

  const dataGridRef = useRef(null);
  const dataGridRefExport = useRef(null);
  const [showColumnChooser, setShowColumnChooser] = useState(false);

  useEffect(() => {
    if (showColumnChooser) {
      dataGridRef.current.instance.showColumnChooser();
    } else {
      dataGridRef.current.instance.hideColumnChooser();
    }
  }, [showColumnChooser]);

  const [activeRow, setActiveRow] = useState(null);

  const onDetailsHiding = () => {
    setActiveRow(null);
  }
  const [showFilter, setShowFilter] = useState(false);
  const [order, setOrder] = useState({
    showOrderForm: false,
    redirectUrl: null,
  });

  const handleActionClick = async (action, data) => {
    switch (action) {
      case 'details':
        setActiveRow(data);
        break;
      case 'copy':
        const request = await merchantGetOrderUrl(data.MerchantOrderID);

        if (request.isSuccess) {
          notifyApp(t('COMMON.COPIED'), SUCCESS);
          copyTextToClipboard(request.response);
        } else {
          notifyApp(request.error);
        }
        break;
      case 'confirm':
        operationButtonHandler(data.PaymentID, 'Transaction/Confirm').catch((e) => {
          console.error(e);
        });
        break;
      case 'cancel':
        operationButtonHandler(data.PaymentID, 'Transaction/Cancel').catch((e) => {
          console.error(e);
        });
        break;
      default:
        return null;
    }
  }

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'customToolbar'
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: t('COMMON.EXPORT_TO_EXCEL'),
          icon: 'xlsxfile',
          onClick: () => {
            setIsShowExportDatePopup(!isShowExportDatePopup);
          }
        }
      }, {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'mdi mdi-view-column',
          onClick: () => {
            setShowColumnChooser(!showColumnChooser);
          }
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'mdi mdi-filter-variant',
          onClick: () => {
            setShowFilter(!showFilter);
          }
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          text: t('TRANSACTIONS.CREATE_ORDER'),
          hint: t('TRANSACTIONS.CREATE_ORDER'),
          stylingMode: 'outlined',
          elementAttr: {class: 'round-toolbar-button'},
          onClick: () => {
            setOrder({
              showOrderForm: true,
              redirectUrl: null,
            });
          }
        }
      },
    );
  };

  const toolbarItemCustomize = () => {
    return (
      <div className={'recent-operation'}>
				<span className={'recent-operation-text'}>
					{t('MAIN.PAYMENT_HISTORY_TABLE.TABLE_TOPBAR.RECENT_OPERATIONS')}
				</span>
      </div>
    );
  };

  const exportDataGrid = async () => {
    const paramObj = getTransactions();

    paramObj.defFilter = {
      MerchantApiProfileID: selectedId,
      Date: {
        between: [formatDateToApi(startDate.value), formatDateToApi(endDate.value)],
      },
    };

    const exportDataSource = getDataSource(paramObj);

    setExportDataSource(exportDataSource);
    setEnableExport(true);
  }

  const makeExcelReport = () => {
    if (enableExport) {
      const exportRef = dataGridRefExport.current;
      exportRef.instance.exportToExcel();
      setEnableExport(false);
    }
  }

  const onExporting = (e) => {
    e.fileName = getExportFileName();
  }

  const selectedDetailID = selectedId > 0 ? selectedId : -1;

  const toggleCreateOrderPopup = () => {
    setOrder({
      showOrderForm: !order.showOrderForm,
      redirectUrl: null
    });
  }

  const operationButtonHandler = async (paymentId, operation) => {
    const transactionRequest = await apiRequest({
      operation: operation,
      data: {
        Params: {
          PaymentID: paymentId,
        },
      }
    });

    if (transactionRequest.data.ResponseCode === SUCCESS_API_CODE) {
      dataGridRef.current.instance.refresh();
    } else {
      notifyApp(transactionRequest.data['ResponseText']);
    }
  };

  const transactionAmount = ({value, data}) => {
    if (!value) return '';

    let val = value;
    if (data['CurrencyViewPrecision']) {
      val = val.toFixed(data['CurrencyViewPrecision']);
    }

    if (data['MerchantOrderTypeID'] === 1) {
      val = '- ' + val;
    }

    const className = data['MerchantOrderTypeID'] === 2 ? 'grid-col-green' : '';

    return (
      <div className={className}>
        {val}
      </div>
    );
  };

  const [coords, setCoords] = useState(null);
  const [data, setData] = useState(null);

  const onItemDetailClick = (event, data) => {
    if (event) {
      const coords = event.target.getBoundingClientRect();

      setCoords(coords);
      setData(data);
    } else {
      setCoords(null);
    }
  }

  return (
    <div
      style={{
        height: '100%',
      }}
      className={'animated-page'}
    >
      {coords ? (
        <ItemDetailWidget
          data={data}
          coords={coords}
          onItemDetailClick={onItemDetailClick}/>
      ) : null}
      <TransactionDetails
        t={t}
        activeRow={activeRow}
        visible={!!activeRow}
        onHiding={onDetailsHiding}
      />
      <div
        style={{
          height: '100%',
          position: 'relative',
        }}
      >
        <div id={'transaction-wrapper'} style={{height: '100%'}} className={'grid-acc-wrapper'}>
          <div key={+order.showOrderForm}>
            <CreateOrderPopup
              order={order}
              hide={toggleCreateOrderPopup}
              merchantId={selectedId}
            />
          </div>
          <TransactionGroup
            items={list}
            selectedId={selectedDetailID}
            onItemClick={({selectedItemId}) => {
              setSelectedId(selectedItemId);
            }}
            onItemDetailClick={onItemDetailClick}
            isDetailOpen={!!coords}
          />
          <div id={'ctx-menu'}>
            <div className={'ctx-menu'}>
              {innerContextButtons.map((ctxMenuItem, index) => {
                return (
                  <div key={ctxMenuItem.action} id={ctxMenuItem.action} className={'column-operation-button'}>
                    <i className={`${ctxMenuItem.icon} ctx-blue-item`}></i>
                    <span>
										{t(ctxMenuItem.title)}
									</span>
                  </div>
                );
              })}
            </div>
          </div>
          <DataGrid
            ref={dataGridRef}
            id={'merchant-accounts'}
            alignment={'center'}
            dataSource={transactions}
            hoverStateEnabled={false}
            showBorders={false}
            focusedRowEnabled={false}
            height={'calc(100% - 76px)'}
            columnHidingEnabled={false}
            onToolbarPreparing={onToolbarPreparing}
            onEditorPreparing={onEditorPreparing}
            keyExpr={'ID'}
          >
            <ColumnChooser
              title={t('MAIN.PAYMENT_HISTORY_TABLE.TABLE_TOPBAR.COLUMN_CHOOSER_TITLE')}
              emptyPanelText={t('MAIN.PAYMENT_HISTORY_TABLE.TABLE_TOPBAR.COLUMN_CHOOSER_DESCRIPTION')}
            />
            <RemoteOperations
              paging
              filtering
            />
            <Sorting mode={'none'}/>
            <Scrolling
              mode={'infinite'}
              showScrollbar='onHover'
            />
            <Paging enabled defaultPageSize={50}/>
            <FilterRow visible={showFilter}/>

            <Column
              caption={t('TRANSACTIONS.ORDER_ID')}
              dataField={'MerchantOrderID'}
              width={100}
            />
            <Column
              caption={t('TRANSACTIONS.DATE')}
              dataField={'SortDate'}
              alignment={'left'}
              dataType={'date'}
              format={'dd.MM.yy, HH:mm'}
              width={135}
            />
            <Column
              caption={t('TRANSACTIONS.AMOUNT')}
              dataField={'Amount'}
              alignment={'right'}
              cellRender={transactionAmount}
              width={170}
            />
            <Column
              caption={''}
              dataField={'CurrencyCode'}
              alignment={'left'}
              allowFiltering={false}
              allowHeaderFiltering={false}
              allowSorting={false}
              width={75}
            />
            <Column
              caption={t('TRANSACTIONS.STATUS')}
              dataField={'Status'}
              alignment={'left'}
              filterOperations={filterOptions['Status'].filterOperations}
              calculateFilterExpression={filterOptions['Status'].calculateFilterExpression}
              cellRender={customizeTransactionStatus}
              width={180}
            />
            <Column
              caption={t('TRANSACTIONS.ORDER')}
              dataField={'ExternalOrderID'}
              alignment={'left'}
              width={120}
            />
            <Column
              caption={t('TRANSACTIONS.DESCRIPTION')}
              dataField={'Description'}
              alignment={'left'}
              //minWidth={700}
            />
            <Column
              caption={t('TRANSACTIONS.CUSTOMER')}
              dataField={'Email'}
              alignment={'left'}
              width={250}
            />
            <Column
              type={'buttons'}
              allowReordering={false}
              alignment={'right'}
              cellRender={(row) => {
                return <CustomButtonGroupCell
                  row={row}
                  handleActionClick={handleActionClick}
                />
              }}
            />

            <Template name={'customToolbar'} render={toolbarItemCustomize}/>
          </DataGrid>
          <DataGrid
            ref={dataGridRefExport}
            id={'grid-acc-statement-export'}
            visible={true}
            onContentReady={makeExcelReport}
            onExporting={onExporting}
            dataSource={exportDataSource}
          >
            <Column
              caption={t('TRANSACTIONS.DATE')}
              alignment={'left'}
              dataField={'Date'}
              width={95}
              hidingPriority={27}
              allowHiding={false}
            />
            <Column
              caption={t('MAIN.PAYMENT_HISTORY_TABLE.COLUMNS.APPROVE_DATE')}
              dataField={'ApproveDate'}
            />
            <Column
              caption={t('TRANSACTIONS.ORDER_ID')}
              alignment={'left'}
              dataField={'MerchantOrderID'}
              width={90}
              hidingPriority={26}
              allowHiding={false}
            />
            <Column
              caption={t('TRANSACTIONS.ORDER_TYPE')}
              alignment={'left'}
              dataField={'MerchantOrderTypeName'}
              hidingPriority={24}
              allowHiding={false}
            />
            <Column
              caption={t('TRANSACTIONS.ORDER')}
              alignment={'right'}
              dataField={'ExternalOrderID'}
              width={100}
              hidingPriority={25}
              allowHiding={false}
            />
            <Column
              caption={t('TRANSACTIONS.AMOUNT')}
              alignment={'right'}
              dataField={'Amount'}
              cellRender={withCurrencySymbol}
              hidingPriority={23}
              allowHiding={false}
            />
            <Column
              caption={t('TRANSACTIONS.ORDER_CURRENCY')}
              alignment={'right'}
              dataField={'CurrencyCode'}
              hidingPriority={23}
              allowHiding={false}
            />
            <Column
              caption={t('TRANSACTIONS.STATUS')}
              alignment={'left'}
              dataField={'Status'}
              filterOperations={filterOptions['Status'].filterOperations}
              calculateFilterExpression={filterOptions['Status'].calculateFilterExpression}
              cellRender={customizeTransactionStatus}
              width={108}
              hidingPriority={22}
              allowHiding={false}
            />
            <Column
              caption={t('TRANSACTIONS.DESCRIPTION')}
              alignment={'left'}
              dataField={'Description'}
              hidingPriority={21}
              allowHiding={false}
              minWidth={750}
            />
            <Column
              caption={t('TRANSACTIONS.CUSTOMER')}
              alignment={'left'}
              dataField={'Email'}
              hidingPriority={20}
              allowHiding={false}
              width={250}
            />
            <Column
              caption={t('TRANSACTIONS.PAID')}
              alignment={'right'}
              dataField={'PaymentAmount'}
              cellRender={withCurrencySymbol}
              hidingPriority={19}
            />
            <Column
              caption={t('TRANSACTIONS.FEE')}
              alignment={'right'}
              dataField={'Fee'}
              cellRender={withCurrencySymbol}
              hidingPriority={18}
            />
            <Column
              caption={t('TRANSACTIONS.HOLD')}
              alignment={'right'}
              dataField={'Hold'}
              cellRender={withCurrencySymbol}
              hidingPriority={17}
            />
            <Column
              caption={t('TRANSACTIONS.RECEIVABLE')}
              alignment={'right'}
              dataField={'Receivable'}
              cellRender={withCurrencySymbol}
              hidingPriority={16}
            />
            <Column
              caption={t('TRANSACTIONS.CURRENCY_RATE')}
              alignment={'left'}
              dataField={'CurrencyRate'}
              cellRender={({value, data}) => {
                return data['CurrencyViewPrecision'] && value ? value.toFixed(data['CurrencyViewPrecision']) : value;
              }}
              hidingPriority={15}
            />
            <Column
              caption={'CurrencyPaid'}
              dataField={'ForeignCurrencyCode'}
            />
            <Column
              caption={t('TRANSACTIONS.PAYMENT_ID')}
              alignment={'left'}
              dataField={'PaymentID'}
              hidingPriority={14}
            />
            <Column
              caption={t('TRANSACTIONS.PAYMENT_DATE')}
              alignment={'left'}
              dataField={'PaymentDate'}
              dataType={'date'}
              format={'dd.MM.yy, HH:mm'}
              hidingPriority={13}
            />
            <Column
              caption={t('TRANSACTIONS.PAYMENT_STATUS')}
              alignment={'left'}
              dataField={'PaymentStatusName'}
              hidingPriority={12}
            />
            <Column
              caption={t('TRANSACTIONS.PAYGATE_TRAN_ID')}
              alignment={'left'}
              dataField={'PayGatePaymentID'}
              hidingPriority={11}
            />
            <Column
              caption={t('TRANSACTIONS.PAYMENT_METHOD')}
              alignment={'left'}
              dataField={'PaymentSystemTypeName'}
              filterOperations={filterOptions['PaymentSystemTypeName'].filterOperations}
              calculateFilterExpression={filterOptions['PaymentSystemTypeName'].calculateFilterExpression}
              hidingPriority={10}
              cellRender={valueWithIcon}
            />
            <Column
              caption={t('TRANSACTIONS.CARD_ACCOUNT')}
              alignment={'left'}
              dataField={'ForeignAccountCode'}
              hidingPriority={9}
            />
            <Column
              caption={t('TRANSACTIONS.HOLDER')}
              alignment={'left'}
              dataField={'ForeignClientName'}
              hidingPriority={8}
            />
            <Column
              caption={t('TRANSACTIONS.PHONE_NUMBER')}
              alignment={'center'}
              dataField={'PhoneNumber'}
              hidingPriority={6}
            />
            <Column
              caption={t('TRANSACTIONS.NAME')}
              alignment={'left'}
              dataField={'CustomerFirstName'}
              hidingPriority={5}
            />
            <Column
              caption={t('TRANSACTIONS.LAST_NAME')}
              alignment={'left'}
              dataField={'CustomerLastName'}
              hidingPriority={4}
            />
            <Column
              caption={t('TRANSACTIONS.TAX_ID')}
              alignment={'left'}
              dataField={'CustomerPersonalId'}
              hidingPriority={3}
            />
            <Column
              caption={t('TRANSACTIONS.COUNTRY')}
              alignment={'left'}
              dataField={'CustomerBillingCountry'}
              hidingPriority={2}
            />
            <Column
              caption={t('TRANSACTIONS.CITY')}
              alignment={'left'}
              dataField={'CustomerBillingCity'}
              hidingPriority={1}
            />
            <Column
              caption={t('TRANSACTIONS.ADDRESS')}
              alignment={'left'}
              dataField={'CustomerBillingAddress'}
              hidingPriority={0}
            />
          </DataGrid>
          <div id={'calendar-wrapper'}>
            <Popover
              minWidth={270}
              target={'.dx-toolbar-after'}
              visible={isShowExportDatePopup}
              onHiding={() => {
                setIsShowExportDatePopup(false);
              }}
            >
              <div className={'export-fields'}>
                <div className="dx-field">
                  <div className="dx-field-label">{t('COMMON.DATE_FROM')}</div>
                  <div className="dx-field-value">
                    <DateBox
                      value={startDate.value}
                      max={endDate.value}
                      useMaskBehavior={true}
                      displayFormat={'dd.MM.yy'}
                      onValueChanged={({value}) => {
                        setStartDate({
                          value: value,
                          text: fileDateTextFormat(value),
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="dx-field">
                  <div className="dx-field-label">{t('COMMON.DATE_TO')}</div>
                  <div className="dx-field-value">
                    <DateBox
                      value={endDate.value}
                      min={startDate.value}
                      useMaskBehavior={true}
                      displayFormat={'dd.MM.yy'}
                      onValueChanged={({value}) => {
                        setEndDate({
                          value: value,
                          text: fileDateTextFormat(value),
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={'export-button'}>
                  <RoundButton
                    width={222}
                    text={t('COMMON.EXPORT')}
                    onClick={exportDataGrid}
                    customClass={'blue'}
                  />
                </div>
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTransactions;
