import React from 'react';
import CircleProgress from "components/cirlce-progress/cirlce-progress";
import {progressColor} from 'utils/variables';

const getPercent = (type, progress) => {
	if (type === 'person') {
		switch (progress) {
			case 2:
				return 100;
			case 4:
				return 50;
			default:
				return 0;
		}
	}

	return progress * 100;
};

export default class VerificationProgressBar extends React.PureComponent{
	render() {
		const {type, progress, title, description} = this.props;

		return (
			<div className={'verification-progress-item'}>
				<div className={'progress'}>
					<CircleProgress
						color={progressColor[type.toUpperCase()]}
						radius={25}
						stroke={8}
						progress={getPercent(type, progress)}
					/>
				</div>
				<div className={'progress-text'}>
					<span className={'progress-text-title'}>{title}</span>
					<span className={'progress-text-description'}>{description}</span>
				</div>
			</div>
		);
	}
}