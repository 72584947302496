import React from 'react';
import {NumberBox} from 'devextreme-react';
import classNames from 'classnames';

import './currency-input.scss';

const CurrencyInput = (props) => {
	const {label = '', currency = '', inputProps, children} = props;

	return (
		<div className={classNames('operations-fieldset currency-input-wrapper', {
			'read-only': inputProps.readOnly,
		})}>
			<div className={'operations-field'}>
				<div className='dx-field-label'>{label}</div>
				<div className='dx-field-value currency-input'>
					<div className={'currency-input-value-wrapper'}>
						<span className={'currency-input-value'}>
							{currency}
						</span>
					</div>
					<NumberBox
						{...inputProps}
						step={0}
						validationMessageMode={'always'}
						className={'operation-input'}
						stylingMode={'outlined'}
						hoverStateEnabled={false}
					>
						{children}
					</NumberBox>
				</div>
			</div>
		</div>
	);
}

export default CurrencyInput;