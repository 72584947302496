import React from 'react';

const CopyIcon = ({fill= '#007AFF'}) => {

  return (
    <svg width="14" height="17" viewBox="0 0 14 17" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.316.412H1.474C.664.412 0 1.066 0 1.866v10.182h1.474V1.866h8.842V.412zM9.579 3.32H4.42c-.81 0-1.466.654-1.466 1.454l-.008 10.182c0 .8.656 1.455 1.467 1.455h8.112c.81 0 1.474-.655 1.474-1.455V7.684L9.579 3.321zM4.42 14.957V4.775h4.421v3.637h3.684v6.545H4.421z"
        fill={fill} fillRule="nonzero"/>
    </svg>
  );
}

export default CopyIcon;