import {vars} from '../utils/variables';

const {MAIN: {ACCOUNTS: {SHARPAY_ID, WALLETTO_ID, SHARPAY_IBAN_ID, BANXE_ID}}} = vars;

export const accounts = [
	{
		icon: 'images/sharpay/SP_80_80_1.svg',
		text: 'SharPay',
		id: 5,
	},
];

export const accountIcon = {
	[SHARPAY_ID]: {
		icon: process.env.REACT_APP_BRAND === 's3' ? 'images/sharpay/SP_80_80_1.svg' : 'images/icons/wallet.svg',
	},
	[WALLETTO_ID]: {
		icon: 'images/sharpay/card.svg',
	},
	[SHARPAY_IBAN_ID]: {
		icon: 'images/sharpay/iban.svg',
	},
	[BANXE_ID]: {
		icon: 'images/sharpay/iban.svg',
	},
};
