import React from 'react';
import classNames from 'classnames';
import {Button, NumberBox, TextBox, Validator} from 'devextreme-react';
import {PatternRule, RequiredRule} from 'devextreme-react/validator';
import {vars} from 'utils/variables';
import {calculateFee, getAllowedPaymentParams, getCurrencyList, makeTransaction} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {withTranslation} from 'react-i18next';
import Loader from 'components/loader/loader';
import {constructMinMaxString, creditCardFormat, expiryDateFormat} from 'utils/functions';
import CurrencyAmount from '../blocks/currency-amount';

const {NOTIFY, PAYMENT, OPERATION, REG_EXP} = vars;

const initialState = {
  amount: null,
  toCurrencyAmount: null,
  fee: {
    Amount: null,
    ToCurrencyAmount: null,
    CurrencyRate: null,
    TotalAmount: null,
    FeeAmount: null,
  },
  card: '',
  cardHolder: '',
  expire: '',
  cvvCode: '',
  currencyState: {
    currencies: [],
    selectedCurrency: OPERATION.EUR_CURRENCY,
  },
};

class IncomeFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    this.prepareAllowedCurrencies();
  }

  componentDidUpdate(prevProps) {
    if (this.props.paymentMethodValueId !== prevProps.paymentMethodValueId) {
      this.prepareAllowedCurrencies();
    }
  }

  prepareAllowedCurrencies = () => {
    const {account, selectedMethod} = this.props;

    this.getCurrencies().then(async (currencyList) => {
      const request = await getAllowedPaymentParams({
        AccountID: account.ID,
        PaymentTypeID: PAYMENT.PAYMENT_TYPE_INCOME,
        ForeignPaymentSystemTypeID: selectedMethod.ForeignPaymentSystemTypeID,
        ForeignAccountCode: -1,
      });

      if (request.isSuccess) {
        if (request.response.length > 0) {
          const allowedCurrencyIds = request.response.map((item) => {
            return item.ForeignCurrencyID;
          });

          const limitedCurrencyList = currencyList.filter((currency) => {
            return allowedCurrencyIds.includes(currency.ID);
          });

          if (Array.isArray(limitedCurrencyList) && limitedCurrencyList.length > 0) {
            this.setState({
              allowedPaymentParams: request.response,
              currencyState: {
                currencies: limitedCurrencyList,
                selectedCurrency: limitedCurrencyList[0],
              },
            });
          }
        } else {
          throw new Error('Allowed currency not specified');
        }
      } else {
        throw new Error(request.error);
      }
    }).catch((errorText) => {
      notifyApp(errorText);
    })
  }

  getCurrencies = async () => {
    const response = await getCurrencyList();

    if (response.isSuccess) {
      return response.list;
    } else {
      throw new Error(response.error);
    }
  };

  amountChanged = ({value}) => {
    if (!value) return;

    this.setState({
      amount: value,
    }, value > 0 ? this.calculateAmountFee : null);
  };

  currencyChanged = (value) => {
    const {currencyState} = this.state;
    const selectedCurrency = currencyState.currencies.find((item) => item.ID === value);

    this.setState({
      currencyState: {
        ...currencyState,
        selectedCurrency: selectedCurrency,
      }
    }, this.calculateAmountFee);
  };

  calculateAmountFee = async () => {
    const {account, paymentMethodValueId, selectedMethod} = this.props;
    const {amount, currencyState} = this.state;

    if (amount) {
      const requestData = {
        amount: null,
        toCurrencyAmount: amount,  // field how much from amount payable
        accountId: account.ID, // from account object
        paymentSystemTypeID: paymentMethodValueId, // field radio-selectbox
        paymentTypeID: PAYMENT.PAYMENT_TYPE_INCOME, // payout or income
      };

      if (selectedMethod.ForeignPaymentSystemTypeGroupID === 2 || selectedMethod.ForeignPaymentSystemTypeGroupID === 3) {
        Object.assign(requestData, {currency: currencyState.selectedCurrency.ID});
      }

      const request = await calculateFee(requestData);

      if (request.isSuccess) {
        this.setState({
          fee: request.response
        });
      } else {
        notifyApp(`Fee calculation error: ${request.error}`, NOTIFY.ERROR);
      }
    }
  };

  externalTransaction = async ({validationGroup, component}) => {
    const {amount, fee, card, cardHolder, expire, cvvCode, currencyState} = this.state;
    const amountValue = amount ? amount : fee.Amount;
    const {paymentMethodValueId, account, changePaymentStatus, toggleLoader, t, selectedMethod} = this.props;
    const result = await validationGroup.validate();

    if (result.isValid) {
      component.option('disabled', true);
      toggleLoader();
      let data;

      if (selectedMethod.ForeignPaymentSystemTypeIsShowCardForm === OPERATION.NEED_CREDIT_FORM_ID) {
        const date = new Date();
        const thousandDatePart = date.getFullYear().toString().substring(0, 2);
        const cardExpireDate = expire.replace('/', thousandDatePart);

        data = {
          PaymentSystemTypeID: paymentMethodValueId,
          AccountID: account.ID, // - счет который пополняем
          CardNumber: card.replaceAll(' ', ''), // - Номер карты
          CardHolderName: cardHolder, // - Имя владельца
          CardCode: cvvCode, // - свв
          CardExpireDate: cardExpireDate,  // - Срок действия карты - MMYYYY
          ForeignAmount: fee.ToCurrencyAmount,
          BrowserTimezone: date.getTimezoneOffset(),
          BrowserWindowHeight: window.innerHeight,
          BrowserScreenWidth: window.screen.width,
          BrowserWindowWidth: window.innerWidth,
          BrowserUserAgent: window.navigator.userAgent,
          BrowserLanguage: window.navigator.language,
          BrowserJavaEnabled: false,
          BrowserScreenHeight: window.screen.height,
          BrowserAcceptHeader: 'application/json, text/plain, */*',
          BrowserColorDepth: window.screen.colorDepth,
          operation: OPERATION.PAYMENT_TO_CARD
        };

        if (selectedMethod.ForeignPaymentSystemTypeGroupID === 2 || selectedMethod.ForeignPaymentSystemTypeGroupID === 3) {
          Object.assign(data, {
            ForeignCurrencyID: currencyState.selectedCurrency.ID, // from new currency field
          });
        }
      } else {
        data = {
          PaymentSystemTypeID: paymentMethodValueId,
          AccountID: account.ID,
          ForeignAmount: amountValue,
          ForeignCurrencyID: currencyState.selectedCurrency.ID, // from new currency field
          operation: OPERATION.PAYMENT_INCOME
        };
      }

      try {
        const request = await makeTransaction(data);

        if (request.isSuccess) {
          if (request.response) {
            if (request.response.RedirectUrl) {
              window.location.replace(request.response.RedirectUrl);
            } else if (request.response.URL) {
              window.location.replace(request.response.URL);
            } else {
              changePaymentStatus({
                text: t('OPERATIONS.PAYMENT_SUCCESS_TEXT')
              });
            }
          }
        } else {
          notifyApp(request.error, NOTIFY.ERROR);
        }
      } catch (error) {
        notifyApp(error);
      } finally {
        toggleLoader();
        component.option('disabled', false);
      }
    } else {
      // focus first broken field
      result.brokenRules[0].validator.focus();
    }
  };

  render() {
    const {account, t, isShowLoader, selectedMethod} = this.props;
    const {
      amount, toCurrencyAmount, fee, card, cardHolder,
      expire, cvvCode, currencyState, allowedPaymentParams,
    } = this.state;
    const amountValue = amount ? amount : fee ? fee.Amount : null;
    const toCurrencyAmountValue = toCurrencyAmount ? toCurrencyAmount : fee ? fee.Amount : null;

    return (
      <div className={'income-fields'}>
        <Loader
          isShowLoader={isShowLoader}
        />
        <div className={'operations-row'}>
          <div className={'operations-row-inner'}>
            <div className={'operations-title'}>{t('OPERATIONS_PAGE.RIGHT_BLOCK_REPLENISH.REPLENISHMENT_AMOUNT')}</div>
            <div className={'operations-fields-wrapper'}>
              {selectedMethod.ForeignPaymentSystemTypeGroupID === 2 || selectedMethod.ForeignPaymentSystemTypeGroupID === 3 ? (
                <CurrencyAmount
                  amountChanged={this.amountChanged}
                  currencyChanged={this.currencyChanged}
                  value={amountValue}
                  fee={fee}
                  account={account}
                  currencyState={currencyState}
                />
              ) : (
                <div className={'operations-fieldset'}>
                  <div className={'operations-field'}>
                    <div
                      className="dx-field-label">{`${t('OPERATIONS_PAGE.RIGHT_BLOCK_REPLENISH.AMOUNT_PAYABLE')}*`}</div>
                    <div className="dx-field-value field-value-relative">
									<span className={classNames('field-value-currency', {
                    'field-value-currency-display': amount < 0,
                  })}>
										{account.CurrencySymbol}
									</span>
                      <NumberBox
                        className={'operation-input'}
                        value={amountValue}
                        name={'amount'}
                        stylingMode={'outlined'}
                        onValueChanged={this.amountChanged}
                        hoverStateEnabled={false}
                      >
                        <Validator>
                          <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                          <PatternRule message={t('ERROR_MSG.DIGIT_GRATER_NULL_ONLY')} pattern={REG_EXP.AMOUNT}/>
                        </Validator>
                      </NumberBox>
                    </div>
                  </div>
                </div>
              )}

              <div className={'operations-fieldset'}>
                <div className={'operations-field'}>
                  <div className="dx-field-label">{`${t('OPERATIONS_PAGE.RIGHT_BLOCK_REPLENISH.FEE')}*`}</div>
                  <div className="dx-field-value field-value-relative">
									<span className={classNames('field-value-currency', 'currency-read-only', {
                    'field-value-currency-display': fee.FeeAmount < 0,
                  })}>
										{account.CurrencySymbol}
									</span>
                    <NumberBox
                      className={'operation-input'}
                      value={fee.FeeAmount}
                      readOnly={true}
                      hoverStateEnabled={false}
                      stylingMode={'outlined'}
                    />
                  </div>
                </div>
              </div>
              <div className={'operations-fieldset'}>
                <div className={'operations-field'}>
                  <div
                    className="dx-field-label">{`${t('OPERATIONS_PAGE.RIGHT_BLOCK_REPLENISH.AMOUNT_RECEIVABLE')}*`}</div>
                  <div className="dx-field-value field-value-relative">
									<span className={classNames('field-value-currency', 'currency-read-only', {
                    'field-value-currency-display': toCurrencyAmountValue < 0,
                  })}>
										{account.CurrencySymbol}
									</span>
                    <NumberBox
                      readOnly
                      className={'operation-input'}
                      value={toCurrencyAmountValue}
                      hoverStateEnabled={false}
                      stylingMode={'outlined'}
                    />
                    <div className={'operations-field-addons'}>
                      {constructMinMaxString(allowedPaymentParams, currencyState.selectedCurrency.ID)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {selectedMethod.ForeignPaymentSystemTypeIsShowCardForm === OPERATION.NEED_CREDIT_FORM_ID ? (
          <div className={'operations-row credit-card-row'}>
            <div className={'anti-autocomplete'}>
              <input/>
              <input type={'password'}/>
            </div>
            <div className={'operations-row-inner'}>
              <div className={'operations-title'}>{t('OPERATIONS_PAGE.RIGHT_BLOCK_REPLENISH.SENDER')}</div>
              <div className={'operations-fields-wrapper'}>
                <div className={'operations-fieldset'}>
                  <div className={'operations-field'}>
                    <div
                      className="dx-field-label">{`${t('OPERATIONS_PAGE.RIGHT_BLOCK_REPLENISH.SENDER_CARD')}*`}
                    </div>
                    <div className={'dx-field-value field-value-relative'}>
                      <span className={'field-value-icon mdi mdi-credit-card-outline'}/>
                      <TextBox
                        className={'operation-input'}
                        value={card}
                        name={'card-number'}
                        stylingMode={'outlined'}
                        placeholder={'____ ____ ____ ____'}
                        maxLength={OPERATION.FORMATTED_CARD_NUMBER_LENGTH}
                        validationMessageMode={'always'}
                        inputAttr={{'autocomplete': 'cc-number'}}
                        onInput={({event, component}) => {
                          const {value} = event.target;
                          component.option('value', creditCardFormat(value));
                        }}
                        onFocusOut={({component}) => {
                          const {card} = this.state;

                          if (card.length < OPERATION.FORMATTED_CARD_NUMBER_LENGTH) {
                            component.option('validationError', {message: t('ERROR_MSG.WRONG_CARD_NUMBER')});
                            component.option('isValid', false);
                          }

                        }}
                        onFocusIn={({component}) => {
                          component.option('isValid', true);
                        }}
                        onValueChanged={({value}) => {
                          return this.onCardFieldChanged('card', value);
                        }}
                        hoverStateEnabled={false}
                      >
                        <Validator>
                          <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                          <PatternRule message={t('ERROR_MSG.REQUIRED')} pattern={REG_EXP.NUMBERS_WITH_SPACE_ONLY}/>
                        </Validator>
                      </TextBox>
                    </div>
                  </div>
                </div>
                <div className={'operations-fieldset'}>
                  <div className={'operations-field'}>
                    <div
                      className="dx-field-label">{`${t('OPERATIONS_PAGE.RIGHT_BLOCK_REPLENISH.SENDER_NAME')}*`}
                    </div>
                    <div className={'dx-field-value field-value-relative'}>
                      <span className={'field-value-icon mdi mdi-account-circle-outline'}/>
                      <TextBox
                        className={'operation-input'}
                        value={cardHolder}
                        name={'cardholder'}
                        stylingMode={'outlined'}
                        inputAttr={{'autocomplete': 'cc-name'}}
                        placeholder={t('OPERATIONS_PAGE.RIGHT_BLOCK_REPLENISH.CARDHOLDER_NAME')}
                        onValueChanged={({value}) => {
                          return this.onCardFieldChanged('cardHolder', value);
                        }}
                        hoverStateEnabled={false}
                      >
                        <Validator>
                          <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                          <PatternRule message={t('ERROR_MSG.WRONG_CARD_HOLDER')} pattern={REG_EXP.LATIN_ONLY}/>
                        </Validator>
                      </TextBox>
                    </div>
                  </div>
                </div>
                <div className={'operations-fieldset'}>
                  <div className={'operations-field'}>
                    <div
                      className="dx-field-label">{`${t('OPERATIONS_PAGE.RIGHT_BLOCK_REPLENISH.SENDER_CARD_EXPIRE')}*`}
                    </div>
                    <div className={'dx-field-value field-value-relative'}>
                      <span className={'field-value-icon mdi mdi-calendar-blank-outline'}/>
                      <TextBox
                        className={'operation-input'}
                        value={expire}
                        name={'expiry-date'}
                        stylingMode={'outlined'}
                        validationMessageMode={'always'}
                        placeholder={t('OPERATIONS_PAGE.RIGHT_BLOCK_REPLENISH.EXPIRY_DATE')}
                        inputAttr={{'autocomplete': 'cc-exp'}}
                        maxLength={OPERATION.EXPIRY_DATE_LENGTH}
                        onInput={({event, component}) => {
                          const {value} = event.target;
                          component.option('value', expiryDateFormat(value));
                        }}
                        onFocusOut={({component}) => {
                          const {expire} = this.state;

                          if (expire.length < OPERATION.EXPIRY_DATE_LENGTH) {
                            component.option('validationError', {message: t('ERROR_MSG.WRONG_CARD_EXPIRY')});
                            component.option('isValid', false);
                          } else if (expire.length === OPERATION.EXPIRY_DATE_LENGTH) {
                            const month = parseInt(expire.split('/')[0], 10);
                            const year = parseInt(expire.split('/')[1], 10);
                            const date = new Date();
                            const currentYear = parseInt(date.getFullYear().toString().substring(2), 10);
                            let isValidMonth = month !== 0 && month <= 12;

                            if (isValidMonth && year === currentYear) {
                              isValidMonth = date.getMonth() < month;
                            }

                            const isValidYear = year >= currentYear;

                            component.option('validationError', {message: t('ERROR_MSG.WRONG_CARD_EXPIRY')});
                            component.option('isValid', isValidMonth && isValidYear);
                          }

                        }}
                        onFocusIn={({component}) => {
                          component.option('isValid', true);
                        }}
                        onValueChanged={({value}) => {
                          return this.onCardFieldChanged('expire', value);
                        }}
                        hoverStateEnabled={false}
                      >
                        <Validator>
                          <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                        </Validator>
                      </TextBox>
                    </div>
                  </div>
                </div>
                <div className={'operations-fieldset'}>
                  <div className={'operations-field'}>
                    <div
                      className="dx-field-label">{`${t('OPERATIONS_PAGE.RIGHT_BLOCK_REPLENISH.SENDER_CARD_CVV')}*`}
                    </div>
                    <div className={'dx-field-value field-value-relative'}>
                      <span className={'field-value-icon mdi mdi-lock-outline'}/>
                      <TextBox
                        className={'operation-input text-security'}
                        value={cvvCode}
                        name={'cvv'}
                        maxLength={3}
                        stylingMode={'outlined'}
                        inputAttr={{'autocomplete': 'off'}}
                        placeholder={'CVV2/CVC2/CAV2'}
                        onValueChanged={({value}) => {
                          return this.onCardFieldChanged('cvvCode', value);
                        }}
                        hoverStateEnabled={false}
                      >
                        <Validator>
                          <RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
                          <PatternRule message={t('ERROR_MSG.WRONG_CARD_CODE')} pattern={REG_EXP.THREE_NUMBERS_ONLY}/>
                        </Validator>
                      </TextBox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className={'operations-buttons'}>
          <div className={'buttons-wrapper'}>
            <Button
              width={120}
              text={t('OPERATIONS_PAGE.RIGHT_BLOCK_REPLENISH.CANCEL')}
              type={'normal'}
              stylingMode={'contained'}
              onClick={this.props.clickCancel}
            />
            <Button
              width={120}
              text={t('OPERATIONS_PAGE.RIGHT_BLOCK_REPLENISH.CONTINUE')}
              type={'normal'}
              stylingMode={'contained'}
              onClick={this.externalTransaction}
            />
          </div>
        </div>
      </div>
    );
  }

  onCardFieldChanged = (fieldName, value) => {
    this.setState({
      [fieldName]: value,
    });
  }
}

export default withTranslation()(IncomeFields);
