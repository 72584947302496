import React, {useState} from 'react';
import RoundButton from 'components/buttons/round-button/round-button';
import {useTranslation} from "react-i18next";
import FormInput from 'components/inputs/form-input/form-input';
import validationEngine from 'devextreme/ui/validation_engine';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';

const RecoveryInitForm = (props) => {
  const {t} = useTranslation();
  const {initCallback} = props;
  const [loginValue, setLoginValue] = useState('');

  const sendRecoveryRequest = async () => {
    const result = validationEngine.validateGroup('recoveryGroup');

    if (result.isValid) {
      const request = await apiRequest({
        operation: 'Login/RestorePasswordInit',
        data: {
          Params: {
            Login: loginValue
          }
        }
      });

      if (request.data.ResponseCode === '000') {
        initCallback(request.data.Response);
      } else {
        notifyApp(request.data.ResponseText);
      }
    }
  }

  return (
    <div className={'password-recovery-component'}>
      <div className={'component-subtitle'}>{t('PASSWORD_RECOVERY_SUBTITLE')}</div>
      <FormInput
        type={'text'}
        label={t('PASSWORD_RECOVERY_EMAIL_PLACEHOLDER')}
        onValueChanged={({value}) => {
          setLoginValue(value);
        }}
        validation={{
          group: 'recoveryGroup',
          rules: [
            {type: 'required', message: t('ERROR_MSG.REQUIRED')},
          ]
        }}
      />
      <RoundButton
        name={'recovery-recover-password'}
        text={t('PASSWORD_RECOVERY_BUTTON')}
        icon={null}
        onClick={sendRecoveryRequest}
        customClass={'size14 new-button'}
      />
    </div>
  );
};

export default RecoveryInitForm;
