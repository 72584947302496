import React, {useState} from "react";
import {RoundInput} from 'components/inputs/round-input';

const useRoundInput = ({
                         type,
                         figures = false,
                         name,
                         placeholder,
                         initValue = '',
                         height = 40,
                         customClass,
                         validator = null,
                       }) => {
  const [value, setValue] = useState(initValue);
  const input = <RoundInput
    figures={figures}
    name={name}
    placeholder={placeholder}
    text={value}
    onValueChanged={({value}) => setValue(value)}
    mode={type}
    height={height}
    customClass={customClass}
  >
    {validator}
  </RoundInput>;
  return [value, input];
};

export default useRoundInput;
