import React, {useEffect, useState} from 'react';
import CopyButton from 'components/buttons/copy-button/copy-button';
import {apiRequest} from 'services/async';
import {vars} from 'utils/variables';
import Loader from 'components/loader/loader';
import classNames from 'classnames';
import './banxe-requisites.scss';

const {SUCCESS_API_CODE} = vars;

const fetchDetails = async (accountId, directionTypeID) => {
  const request = await apiRequest({
    operation: 'Banxe/GetAccountBankDetails',
    data: {
      Params: {
        AccountID: accountId,
        DirectionTypeID: directionTypeID,
      }
    },
  });

  if (request.data.ResponseCode === SUCCESS_API_CODE) {
    return request.data.Response['AccountBankDetails'];
  }

  return null;
}

const fetchRequisites = async (accountId) => {
  const SEPA_DIRECTION_TYPE_ID = 1;
  const SWIFT_DIRECTION_TYPE_ID = 2;

  await new Promise(resolve => setTimeout(resolve, 500));

  const sepaRequest = fetchDetails(accountId, SEPA_DIRECTION_TYPE_ID);
  const swiftRequest = fetchDetails(accountId, SWIFT_DIRECTION_TYPE_ID);

  const [sepaData, swiftData] = await Promise.all([
    sepaRequest,
    swiftRequest,
  ]);

  return {
    sepa: sepaData,
    swift: swiftData,
  };
};


const RequisitesInner = ({items, title}) => {
  return (
    <div className={'animated-page'}>
      <span className={'req-title'}>{title}</span>
      <div className={'requisites'}>
        {items.map(({Name, Value}, index) => {
          return (
            <div key={index} className={'req-row'}>
              <span className={'req-name'}>{Name}</span>
              <div className={'req-value'}>
                <span>{Value}</span>
                <CopyButton value={Value}/>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const BanxeRequisites = ({account}) => {
  const [requisites, setRequisites] = useState(null);

  useEffect(() => {
    fetchRequisites(account.ID).then((response) => {
      setRequisites(response);
    });

    return () => {
      setRequisites(null);
    }
  }, [account]);

  return (
    <div className={classNames('req-wrapper')}>
      <Loader isShowLoader={!requisites}/>
      {requisites ? (
        <div className={'req-inner'}>
          {requisites.sepa ? (<RequisitesInner title={'SEPA'} items={requisites.sepa}/>) : null}
          {requisites.sepa && requisites.swift ? (<div className={'divider'}/>) : null}
          {requisites.swift ? (<RequisitesInner title={'SWIFT'} items={requisites.swift}/>) : null}
        </div>
      ) : null}
    </div>
  );
}

export default BanxeRequisites;