import React, {useEffect, useState} from 'react';
import {LoadIndicator} from 'devextreme-react/load-indicator';
import ScrollView from 'devextreme-react/scroll-view';
import {Popup} from 'devextreme-react/popup';
import {notifyApp} from 'utils/notifyWrapper';
import {apiRequest} from 'services/async';
import {vars} from 'utils/variables';
import S3Fields from './s3-fields';
import WallettoFields from './walletto-fields';
import './payment-detail.scss';

const {SUCCESS_API_CODE, MAIN: {ACCOUNTS: {SHARPAY_ID, BANXE_ID}}} = vars;

const PaymentDetail = ({t, accountType, visible, onHiding, detailID}) => {
  const [rowData, setRowData] = useState(null);

  const getParseDetails = (accountType) => {
    if (accountType === SHARPAY_ID || accountType === BANXE_ID) {
      return {objectName: 'Payment', idFieldName: 'PaymentID'}
    }
    return {objectName: 'WallettoPayment', idFieldName: 'WallettoPaymentID'}
  }

  const prepareDetailsData = (accountType, detailID) => {
    const {objectName, idFieldName} = getParseDetails(accountType);

    return {
      operation: `${objectName}/GetDetail`,
      data: {
        Params: {
          [idFieldName]: detailID,
        }
      }
    };
  }

  const loadDetails = async () => {
    const detailsData = prepareDetailsData(accountType, detailID);
    const request = await apiRequest(detailsData);

    if (request.data.ResponseCode === SUCCESS_API_CODE) {
      return request.data.Response;
    } else {
      throw new Error(request.data.ResponseText);
    }
  }

  useEffect(() => {
    if (detailID) {
      loadDetails().then((details) => {
        setRowData(details);
      }).catch((errorText) => {
        notifyApp(errorText);
      });
    }
  }, [detailID]);

  return (
    <Popup
      showTitle={false}
      visible={visible}
      container={'#root'}
      onHiding={onHiding}
      maxWidth={450}
      height={'90%'}
      maxHeight={'90%'}
      closeOnOutsideClick
      shadingColor={'rgba(255, 255, 255, 0)'}
      position={{
        my: 'right',
        at: 'right',
        of: '#root',
        collision: 'fit',
        boundaryOffset: {x: 150, y: 10},
        offset: {y: 10},
      }}
    >
      <div className={'payment-detail-wrapper'}>
        {visible ? (
          <div className={'payment-detail-wrapper-inner'}>
            <div
              className={'payment-detail-close'}
              onClick={onHiding}
            >
              <i className={'mdi mdi-close-circle-outline'}/>
            </div>
            <div className={'payment-detail-title'}>{t('MAIN.PAYMENT_DETAIL.TITLE')}</div>
            {rowData ? (
                <div className={'payment-detail-body'}>
                  <ScrollView
                    height='100%'
                    scrollByContent
                    scrollByThumb
                  >
                    {(accountType === SHARPAY_ID) || (accountType === BANXE_ID) ? (
                        <S3Fields rowData={rowData} t={t}/>
                      ) :
                      <WallettoFields rowData={rowData} t={t}/>
                    }
                  </ScrollView>
                </div>
              ) :
              <div className={'load-wrapper'}>
                <LoadIndicator className={'vision-loader'} height={50} width={50}/>
              </div>
            }
          </div>
        ) : null}
      </div>
    </Popup>
  );
}

export default PaymentDetail;