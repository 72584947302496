import React from 'react';
import TextBox from 'devextreme-react/text-box';
import {getCardLogo} from 'utils/functions';
import {useTranslation} from 'react-i18next';

const RecipientMaskFieldTemplate = ({data}) => {
  const {t} = useTranslation();

	if (!data) {
		return <TextBox readOnly={true} placeholder={t('COMMON.SELECT_BOX')}/>
	}

  const {
    ForeignAccountCode,
  } = data;

	return (
		<div className={'recipient-item-field'}>
      <div className={'item-field-inner'}>
        <div className={'image-wrapper'}>
          <img src={getCardLogo(ForeignAccountCode)} alt={'ForeignAccountCode'}/>
        </div>
      </div>
      <TextBox
        className={'regular-mask-value'}
        value={ForeignAccountCode}
        readOnly={true}
			/>
		</div>
	);
};

export default RecipientMaskFieldTemplate;


