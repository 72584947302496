import React from 'react';
import RoundButton from 'components/buttons/round-button/round-button';
import {useTranslation} from 'react-i18next';

const RecoverySuccessForm = (props) => {
	const {handleClose} = props;
	const {t} = useTranslation();

	return (
		<div className={'password-recovery-component '}>
			<div className={'component-subtitle'}>{t('PASSWORD_RECOVERY_CHANGE_SUCCESS')}</div>
			<RoundButton
				name={'recovery-ok'}
				text={t('PASSWORD_RECOVERY_SUCCESS_BUTTON')}
				icon={null}
				onClick={handleClose}
				customClass={'size14 new-button'}
			/>
		</div>
	);
};

export default RecoverySuccessForm;
