import React from 'react';
import AccountWidget from './account-widget';
import {vars} from 'utils/variables';
import SharpayDetails from 'pages/main/account-details/sharpay/sharpay-details';
import NewWallettoDetails from 'pages/main/account-details/walletto/new-walletto-details';
import NewIbanDetails from '../../pages/main/account-details/iban/new-iban-details';

const {
  MAIN: {ACCOUNTS: {WALLETTO_ID,SHARPAY_IBAN_ID,SHARPAY_ID}},
} = vars;

const getDetailWidget = (accountData) => {
  switch (accountData.paymentSystemTypeId) {
    case WALLETTO_ID:
      return (<NewWallettoDetails accountData={accountData}/>);
    case SHARPAY_IBAN_ID:
      return <NewIbanDetails accountData={accountData}/>;
    case SHARPAY_ID:
    default:
      return <SharpayDetails accountData={accountData}/>;
  }
};

const AccountDetail = ({coords: {left}, onDetailClick, accountData}) => {
  const style = {
    position: 'absolute',
    top: 118,
    left: Math.round(left - 304),
  }

  return (
    <div className={'account-detail-wrapper'} onClick={() => {
      onDetailClick(null);
    }}>
      <div
        style={style}
        className={'account-detail'}
        onClick={(event) => {
          if (event.type === 'click') {
            event.stopPropagation();
          }
        }}
      >
        <div className={'account-detail-inner'}>
          <div className={'inner-top'}>
            <div className={'close-cursor'} onClick={() => {
              onDetailClick(null);
            }}></div>
          </div>
          {getDetailWidget(accountData)}
        </div>
      </div>
    </div>
  );
}

export default AccountDetail;