import React from 'react';

export default props => {
	return (
		<span className={'settings-section_button__group'}>
			<span className={'settings-section_button__save'}
						onClick={props.saveButtonHandler}>
				<i className={'mdi mdi-checkbox-marked-circle'}/>
			</span>
			<span className={'settings-section_button__close'}
						onClick={props.closeButtonHandler}>
				<i className={'mdi mdi-close-circle'}/>
			</span>
		</span>
	);
};