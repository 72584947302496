import React from 'react';
import {ReactSVG} from 'react-svg';
import {SelectBox} from 'devextreme-react';

export default function
  PopupSelectBox(props) {
  const {
    items,
    placeholder = 'Select...',
    displayExpr,
    disabled = false,
    height = 50,
    valueExpr,
    value,
    changeHandler,
    enterKeyHandler,
    id,
    children,
  } = props;

  const componentProps = {
    height: height,
    dataSource: items,
    placeholder: placeholder,
    displayExpr: displayExpr,
    disabled: disabled,
    valueExpr: valueExpr,
    value: value,
    onValueChanged: changeHandler,
    onEnterKey: enterKeyHandler,
    hoverStateEnabled: false,
  }

  if (id) {
    componentProps.inputAttr = {id: id};
  }

  return (
    <div className={'flexi-popup-select_item'}>
      <ReactSVG src={'./images/figures.svg'} className={'flexi-popup-select_item__icon'}/>
      <SelectBox
        {...componentProps}
      >
        {children}
      </SelectBox>
    </div>
  );
};

PopupSelectBox.defaultProps = {
  valueExpr: 'ID',
  displayExpr: 'Name',
};
