import React, {useEffect, useState} from 'react';
import {HashRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {hideOtpPopup, showOtpPopup} from './services/app/appActions';
import {Authorized} from './layouts';
import OtpPopup from './components/popup/otp-popup';
import {getUserRoutes} from './utils/functions';
import {connect} from 'react-redux';
import {routes} from './app-routes';
import {fetchCurrencies} from 'services/currency/currencyActions';
import NewLogin from './pages/new-login/new-login';
import NewRegister from './pages/new-register/new-register';
import classNames from 'classnames';

import 'devextreme/dist/css/dx.common.css';
import './assets/dx.material.custom-scheme.css';
import './App.scss';
import './dx-styles.scss';
import {Header} from './components';

const App = ({otpPopupState, hideOtpPopup, initFetchCurrencies, currencies}) => {
  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem('user'));
  const [needIcons, setNeedIcons] = useState();
  const enabledRoutes = getUserRoutes(routes);

  const logIn = (userName) => {
    localStorage.setItem('user', userName);
    setLoggedIn(true);
  }

  useEffect(() => {
    if (loggedIn && !currencies) {
      console.log('Fetching currencies');
      initFetchCurrencies();
    }
  }, [loggedIn, currencies, initFetchCurrencies]);

  useEffect(() => {
    setNeedIcons(window.innerWidth < 1400);
  }, []);

  return (
    <Router>
      <div id={loggedIn ? '' : 'login-page'} className={classNames('app', {
        unauthorized: !loggedIn,
      })}>
        {otpPopupState.visible ? (
          <OtpPopup
            {...otpPopupState}
            handleClose={hideOtpPopup}
          />
        ) : null}
        <div className={'powered-by'}></div>
        {loggedIn ? (
          <Header
            needIcons={needIcons}
            menuItems={enabledRoutes}
            className={'layout-header'}
            menuToggleEnabled
            title={'Facilitator Devextreme'}
          />
        ) : (
          <div className={'adaptive-block-left'}>
            <img src="/images/branding.svg" alt="branding"/>
          </div>
        )}
        <Switch>
          <Route path="/login" render={() => (
            loggedIn ? <Redirect to="/main"/> : <NewLogin onLoginClick={logIn}/>
          )}/>
          <Route path="/registration" render={() => (
            loggedIn ? <Redirect to="/main"/> : <NewRegister onLoginClick={logIn}/>
          )}/>
          {enabledRoutes.map(route => (
            <Route key={route.id} path={route.path} exact={route.exact} render={() => (
              loggedIn ? <Authorized menuItems={enabledRoutes} component={route.component}/> : <Redirect to="/login"/>
            )}/>
          ))}
          <Route path="/">
            {loggedIn ? <Redirect to="/main"/> : <Redirect to="/login"/>}
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

const mapStateToProps = state => {
  return {
    otpPopupState: state.app,
    currencies: state.currency.currencies,
  }
};

const mapDispatchToProps = (dispatch) => ({
  showOtpPopup: () => dispatch(showOtpPopup()),
  hideOtpPopup: () => dispatch(hideOtpPopup()),
  initFetchCurrencies: () => dispatch(fetchCurrencies()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
