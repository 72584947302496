import React, {useEffect, useState} from 'react';
import {LoadIndicator} from 'devextreme-react/load-indicator';
import {notifyApp} from 'utils/notifyWrapper';
import {apiRequest} from 'services/async';
import {dateToFormat, truncateString} from 'utils/functions';
import {dateFormats} from 'utils/variables';
import {customizeTransactionStatus, customizeStatus} from 'utils/customize-table-cell';

const TransactionDetails = ({
                              t,
                              activeRow,
                              visible,
                              onHiding,
                            }) => {

  const [details, setDetails] = useState(null);

  useEffect(() => {
    if (activeRow) {
      requestDetails().then((result) => {
        setDetails(result);
      }).catch((error) => {
        notifyApp(error);
      });
    }
  }, [activeRow]);

  useEffect(() => {
    if (details && details.length === 1) {
      toggleSubDetails(details[0].ID);
    }
  }, [details]);

  const requestDetails = async () => {
    const request = await apiRequest({
      operation: 'MerchantOrderPayment/List',
      data: {
        Columns: [
          'ID', 'MerchantOrderID', 'PaymentSystemTypeID', 'PaymentSystemTypeName', 'PaymentTypeID', 'PaymentTypeName',
          'PaymentStatusID', 'PaymentStatusName', 'InsDate', 'ForeignAccountCode', 'ForeignClientName',
          'ForeignCurrencyID', 'ForeignAmount', 'ForeignCurrencyCode', 'ForeignCurrencySymbol',
          'ForeignCurrencyViewPrecision'
        ],
        Filters: {
          MerchantOrderID: activeRow.MerchantOrderID,
        },
      },
    });

    if (request.data.ResponseCode === '000') {
      const remappedResponse = request.data.Response.MerchantOrderPayment.map((item) => {
        return {
          ID: item.ID,
          PaymentAmount: `${valueWithPrecision(item.ForeignAmount, item.ForeignCurrencyViewPrecision)} ${item.ForeignCurrencyCode}`,
          InsDate: dateToFormat(item.InsDate, dateFormats.viewDateTimeFormat),
          PaymentStatusName: item.PaymentStatusName,
          PaymentStatusID: item.PaymentStatusID,
          PaymentSystemTypeName: item.PaymentSystemTypeName,
          ForeignAccountCode: item.ForeignAccountCode,
          ForeignClientName: item.ForeignClientName,
        };
      });

      return remappedResponse;
    } else {
      throw new Error(request.data.ResponseText);
    }
  }

  const [subDetails, setSubDetails] = useState(null);

  useEffect(() => {
    console.log(subDetails);
  }, [subDetails]);

  const toggleSubDetails = (id) => {
    const current = details.find((item) => item.ID === id);
    current.ForeignAccountCode = truncateString(current.ForeignAccountCode);

    if (subDetails) {
      if (subDetails[current.ID]) {
        setSubDetails({
          ...subDetails,
          [id]: null,
        });
      } else {
        setSubDetails({
          ...subDetails,
          [id]: current,
        });
      }

    } else {
      setSubDetails({
        [id]: current,
      });
    }
  }

  const getLabel = (key) => {
    const labels = {
      ForeignAccountCode: 'CARD_ACCOUNT',
      ForeignClientName: 'HOLDER',
      InsDate: 'PAYMENT_DATE',
      PaymentAmount: 'PAYMENT_AMOUNT',
      PaymentStatusName: 'PAYMENT_STATUS',
      PaymentSystemTypeName: 'PAYMENT_METHOD',
    }

    return t('TRANSACTIONS.' + labels[key]);
  }

  const getClassName = (id) => {
    const chevron = subDetails && subDetails[id] ? 'mdi-chevron-up' : 'mdi-chevron-down';
    return 'mdi group-id ' + chevron;
  }

  const getStatusValue = (value, id) => {
    const data = details.find((item) => item.ID === id);
    return customizeStatus({value: value, data: data})
  }

  const getDetails = () => {
    return (
      <>
        {details.map((item, index) => {
          return (
            <div
              key={`item-${index}`}
              className={'item item-with-content'}
              onClick={() => {
                toggleSubDetails(item.ID);
              }}
            >
              <span className={'item-label'}>{t('TRANSACTIONS.PAYMENT_ID')}</span>
              <span className={'item-value flexi'}>
                <span>{item.ID}</span>
                <i className={getClassName(item.ID)}></i>
              </span>
              {subDetails && subDetails[item.ID] ? (
                <div className={'sub-item'}>
                  {Object.keys(subDetails[item.ID]).map((key) => {

                    if (key === 'ID' || key === 'PaymentStatusID') return null;

                    return (
                      <div key={key} className={'sub-item-row'}>
                        <span>{getLabel(key)}</span>
                        <div>
                          {key === 'PaymentStatusName' ?
                            getStatusValue(subDetails[item.ID][key], item.ID) :
                            subDetails[item.ID][key]
                          }
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          );
        })}
      </>
    );
  }

  const valueWithPrecision = (value, precision) => {
    return value.toFixed(precision);
  }

  return visible ? (
    <div onClick={onHiding} className={'detail-widget animated-popup'}>
      <div onClick={(event) => {
        event.stopPropagation();
      }} className={'detail-widget-inner popup-show'}>
        <div className={'detail-content'}>
          <div className={'detail-header'}>
            <span className={'header-title'}>{`${t('TRANSACTIONS.ORDER_DETAILS')} ${activeRow.MerchantOrderID}`}</span>
            <i onClick={onHiding} className={'mdi mdi-close close-black'}/>
          </div>
          <div className={'detail-fields-wrapper'}>
            <div className={'item'}>
              <span className={'item-label'}>{t('TRANSACTIONS.PAID')}</span>
              <span className={'item-value'}>
                {`${valueWithPrecision(activeRow.PaymentAmount, activeRow.CurrencyViewPrecision)} ${activeRow.ForeignCurrencyCode}`}
              </span>
            </div>
            <div className={'item'}>
              <span className={'item-label'}>{t('TRANSACTIONS.FEE')}</span>
              <span className={'item-value'}>
                {`${valueWithPrecision(activeRow.Fee, activeRow.CurrencyViewPrecision)} ${activeRow.ForeignCurrencyCode}`}
              </span>
            </div>
            <div className={'item'}>
              <span className={'item-label'}>{t('TRANSACTIONS.STATUS')}</span>
              <span className={'item-value'}>
                {customizeTransactionStatus({value: activeRow.Status, data: activeRow})}
              </span>
            </div>
            <div className={'item'}>
              <span className={'item-label'}>{t('TRANSACTIONS.HOLD')}</span>
              <span className={'item-value'}>
                {valueWithPrecision(activeRow.Hold, activeRow.CurrencyViewPrecision)}
              </span>
            </div>
            <div className={'item'}>
              <span className={'item-label'}>{t('TRANSACTIONS.RECEIVABLE')}</span>
              <span className={'item-value'}>
                {`${valueWithPrecision(activeRow.Receivable, activeRow.CurrencyViewPrecision)} ${activeRow.ForeignCurrencyCode}`}
              </span>
            </div>
            <div className={'item'}>
              <span className={'item-label'}>{t('TRANSACTIONS.CURRENCY_RATE')}</span>
              <span className={'item-value'}>{activeRow.CurrencyRate}</span>
            </div>

            {details ? (
              getDetails()
            ) : (
              <div className={'load-wrapper'}>
                <LoadIndicator className={'vision-loader'} height={50} width={50}/>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default TransactionDetails;