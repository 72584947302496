import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'devextreme-react';
import classNames from 'classnames';

import './styles.scss';

function RoundButton({disabled, name = '', icon, text, onClick, customClass, width, height, id}) {
  const elementAttr = {};

  if (name) {
    Object.assign(elementAttr, {name: name});
  }

  if (id) {
    Object.assign(elementAttr, {id: id});
  }

  return (
    <div className={'round-button-wrapper'}>
      <Button
        elementAttr={elementAttr}
        tabIndex={2}
        width={width}
        height={height}
        className={classNames('round-button-wrapper_button', customClass)}
        text={text}
        icon={icon}
        stylingMode={'contained'}
        onClick={async ({event, component}) => {
          component.option('disabled', true);
          await onClick(event);
          component.option('disabled', false);
        }}
      />
    </div>
  );
}

export default RoundButton;

RoundButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  customClass: PropTypes.string,
};

RoundButton.defaultProps = {
  customClass: '',
  disabled: false,
  width: 'auto',
  height: 40,
};
