import {actionTypes} from './actions';

const initialState = {
	paymentTypeId: 0,
	account: {ID: null},
	isBlockedRightForm: false,
	isShowLoader: false,
	status: 'OPERATIONS.RIGHT_START_TEXT',
};

export const operationsReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case actionTypes.MAKE_OPERATIONS:
			return {
				...state,
				paymentTypeId: payload.paymentTypeId,
				account: payload.account,
				isBlockedRightForm: false,
				isShowLoader: false,
			};
		case actionTypes.BLOCK_RIGHT_FORM:
			return {
				...state,
				isBlockedRightForm: true,
			};
		case actionTypes.TOGGLE_BLOCK_RIGHT_FORM:
			return {
				...state,
				isBlockedRightForm: !state.isBlockedRightForm,
			};
		case actionTypes.CHANGE_PAYMENT_STATUS:
			return {
				...initialState,
				status: payload.text,
			};
		case actionTypes.TOGGLE_LOADER:
			const isShowLoader = payload ? false : !state.isShowLoader;
			return {
				...state,
				isShowLoader: isShowLoader,
			};
		case actionTypes.CLEAR_OPERATIONS:
			return initialState;
		default:
			return state;
	}
};
