import React from 'react';
import '../settings.scss';
import {TextBox} from 'devextreme-react';
import {userProfileGetList} from 'services/async';
import withButtons from '../withButtons';
import {withTranslation} from 'react-i18next';
import {vars} from 'utils/variables';
import {splitDateTimeToDate} from 'utils/functions';
import {notifyApp} from 'utils/notifyWrapper';
import {hideOtpPopup, showOtpPopup} from 'services/app/appActions';
import {connect} from 'react-redux';

const mapDispatchToProps = (dispatch) => {
	return {
		showOtpPopup: (payload) => dispatch(showOtpPopup(payload)),
		hideOtpPopup: () => dispatch(hideOtpPopup()),
	};
}

const ButtonTextBox = connect(null, mapDispatchToProps)(withButtons(TextBox));

class Profile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userProfile: {
				BirthCountry: '',
				BirthDate: '',
				Email: '',
				LivingCity: '',
				LivingCountry: '',
				LivingPostalIndex: '',
				LivingStreet: '',
				Name: '',
				PayoutConfirmType: '',
				Phone: '',
				Surname: '',
			}
		}
	}

	componentDidMount() {
		this.updateState().catch((error) => {
			notifyApp(error);
		});
	}

	updateState = async () => {
		this.getUserProfile().then((newState) => {
			this.setState(newState);
		}).catch((error) => {
			notifyApp(error);
		});
	}

	getUserProfile = async () => {
		const request = await userProfileGetList();

		if (request.isSuccess) {
			return {
				userProfile: request.response.UserProfile[0]
			};
		} else {
			throw new Error(request.error);
		}
	}

	render() {
		const {PROFILE} = vars;
		const {t} = this.props;
		const {userProfile} = this.state;

		return (
			<div className={'settings-wrapper'}>
				<div className={'settings-header'}>
					<div className={'settings-header_inner'}>
						<div className={'settings-header_text'}>
							<i className={'mdi mdi-account-circle-outline'}/>
							<span>{t('SETTINGS.LEFT_BLOCK.PROFILE.TITLE')}</span>
						</div>
						{userProfile.VmVerificationStatusID === PROFILE.VERIFICATED_STATUS && (
							<div className={'settings-header_text'}>
								<i className={'settings-status_icon'}/>
								{t('SETTINGS.RIGHT_BLOCK.PROFILE.VERIFIED')}
							</div>
						)}
					</div>
				</div>
				<div className="settings-splitter"/>
				<div className={'settings-fields'}>
					<div className={'settings-fields_column'}>
						<span className={'settings-section_name'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.PERSONAL_DETAILS')}</span>
						<div className={'settings-section_inputs'}>
							<div className={'settings-section_edit'}>
								<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.FIRST_NAME')}</span>
								<TextBox readOnly value={userProfile.Name}/>
							</div>
							<div className={'settings-section_edit'}>
								<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.LAST_NAME')}</span>
								<TextBox readOnly value={userProfile.Surname}/>
							</div>
							<div className={'settings-section_edit'}>
								<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.DATE_OF_BIRTH')}</span>
								<TextBox readOnly value={splitDateTimeToDate(userProfile.BirthDate)}/>
							</div>
							<div className={'settings-section_edit'}>
								<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.PLACE_OF_BIRTH')}</span>
								<TextBox readOnly value={userProfile.BirthCountry}/>
							</div>
							<div className={'settings-section_edit'}>
								<ButtonTextBox
									name={'email'}
									placeholder={t('SETTINGS.RIGHT_BLOCK.PROFILE.EMAIL')}
									value={userProfile.Email}
									updateState={this.updateState}
								/>
							</div>
						</div>
					</div>
					<div className={'settings-fields_column'}>
						<span className={'settings-section_name'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.ADDRESS')}</span>
						<div className={'settings-section_inputs'}>
							<div className={'settings-section_edit'}>
								<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.STREET')}</span>
								<TextBox readOnly value={userProfile.LivingStreet}/>
							</div>
							<div className={'settings-section_edit'}>
								<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.CITY')}</span>
								<TextBox readOnly value={userProfile.LivingCity}/>
							</div>
							<div className={'settings-section_edit'}>
								<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.COUNTRY')}</span>
								<TextBox readOnly value={userProfile.LivingCountry}/>
							</div>
							<div className={'settings-section_edit'}>
								<span className={'settings-section_label'}>{t('SETTINGS.RIGHT_BLOCK.PROFILE.ZIP_CODE')}</span>
								<TextBox readOnly value={userProfile.LivingPostalIndex}/>
							</div>
							<div className={'settings-section_edit'}>
								<ButtonTextBox
									name={'phone'}
									placeholder={t('SETTINGS.RIGHT_BLOCK.PROFILE.PHONE')}
									value={userProfile.Phone}
									updateState={this.updateState}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(Profile);
