import React, {useState} from 'react';
import GearIcon from '../svg/gear-icon';
import {useTranslation} from 'react-i18next';
import ArrowIcon from '../svg/arrow-icon';
import EyeIcon from '../svg/eye-icon';
import CardSettings from '../svg/card-settings';
import CardActivation from '../svg/card-activation';
import {apiRequest} from 'services/async';
import {vars} from 'utils/variables';
import {notifyApp} from 'utils/notifyWrapper';
import CardActivationSuccess from 'pages/main/account-details/walletto/card-activation-success';
import WallettoDetailsSettingsFields from 'pages/main/account-details/walletto/walletto-details-settings-fields';
import CardActivationFields from 'pages/main/account-details/walletto/card-activation-fields';
import {LoadIndicator} from 'devextreme-react/load-indicator';
import AppPopup from '../popup/app-popup';
import LockIcon from '../svg/lock-icon';

const {SUCCESS_API_CODE, MAIN: {WALLETTO_NOT_ACTIVATED_ID}} = vars;
const hiddenCVV = '***';
const pos = {
  'my': 'top',
  'at': 'top',
  'of': '#acc-toolbar',
  collision: 'fit',
  boundaryOffset: '20 40',
};
const cont = '#root';

const CardWidget = ({data}) => {
  const {ID, CardNumber, Expiry, WallettoCardStatusID, IsLock} = data;
  const {t} = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [cardNumber, setCardNumber] = useState(CardNumber);
  const [cvv, setCvv] = useState(hiddenCVV);
  const [isShowCardSettingsMenu, setIsShowCardSettingsMenu] = useState(false);
  const [isShowActivate, setIsShowActivate] = useState(false);
  const [showSuccess, setShowSuccess] = useState(null);
  const [isLoadingCvv, setIsLoadingCvv] = useState(false);
  const [isLoadingCardNumber, setIsLoadingCardNumber] = useState(false);
  const [isCardNumberVisible, setIsCardNumberVisible] = useState(false);
  const [isCvvVisible, setIsCvvVisible] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const getFullCardNumber = async () => {
    if (isCardNumberVisible) {
      setCardNumber(CardNumber);
      setIsCardNumberVisible(false);
    } else {
      setIsLoadingCardNumber(true);
      try {
        const response = await fetchData('GetCardNumber', 'CardNumber');
        setCardNumber(response);
        setIsCardNumberVisible(true);
        setIsCvvVisible(false);
        setCvv(hiddenCVV);
      } catch (error) {
        notifyApp(error);
      } finally {
        setIsLoadingCardNumber(false);
      }
    }
  }

  const getCvvCode = async () => {
    if (isCvvVisible) {
      setCvv(hiddenCVV);
      setIsCvvVisible(false);
    } else {
      setIsLoadingCvv(true);
      try {
        const response = await fetchData('GetCvv', 'Cvv');
        setCvv(response);
        setIsCvvVisible(true);
        setIsCardNumberVisible(false);
        setCardNumber(CardNumber);
      } catch (error) {
        notifyApp(error);
      } finally {
        setIsLoadingCvv(false);
      }
    }
  }

  const fetchData = async (operation, fieldName) => {
    const request = await apiRequest({
      operation: `WallettoCard/${operation}`,
      data: {
        Params: {
          WallettoCardID: ID,
        }
      }
    });

    if (request.data['ResponseCode'] === SUCCESS_API_CODE) {
      return request.data['Response'][fieldName];
    } else {
      throw new Error(request.data['ResponseText']);
    }
  }

  const handleClose = (options) => {
    setIsShowCardSettingsMenu(false);
    setIsShowActivate(false);
    setShowSuccess(options && options.setPinResultID ? options : null);
  };

  const renderIcon = () => {
    return isShowCardSettingsMenu ? (<CardSettings/>) : isShowActivate ? (<CardActivation/>) : null;
  }

  return (
    <div className={'card-widget'}>
      <div className={'card-row'}>
        <div className={'card-row-group'}>
          {IsLock ? (
            <LockIcon/>
          ) : null}
          <span className={'card-settings'} onClick={() => {
            setIsShowCardSettingsMenu(!isShowCardSettingsMenu);
          }}>
            <GearIcon/>
          </span>
          <span>{t('MAIN.ACCOUNT_DETAIL_WIDGET.CARD')}</span>
        </div>
        <div className={'card-row-group'}>
          <div className={'same-height'} onClick={getFullCardNumber}>
            {isLoadingCardNumber ? (
              <LoadIndicator height={16} width={16}/>
            ) : (
              <EyeIcon open={cardNumber === CardNumber}/>
            )}
          </div>
          <span>{cardNumber}</span>
          <span className={`chevron ${isExpanded ? 'up' : 'down'}`} onClick={toggleExpand}>
            <ArrowIcon/>
          </span>
        </div>
      </div>
      <div className={`collapsable-row ${isExpanded ? 'expanded' : ''}`}>
        <div className={'row-delimiter'}></div>
        {WallettoCardStatusID === WALLETTO_NOT_ACTIVATED_ID ? (
          <div className={'tile-acc-bold'}>
            <a onClick={(e) => {
              e.preventDefault();
              setIsShowActivate(!isShowActivate);
            }} href="#">{t('MAIN.ACCOUNT_DETAIL_WIDGET.ACTIVATE_CARD')}</a>
          </div>
        ) : (
          <div className={'card-row'}>
            <div className={'card-row-bottom-group'}>
              <span>Expiry</span>
              <span>{Expiry}</span>
            </div>
            <div className={'group-delimiter'}></div>
            <div className={'card-row-bottom-group'}>
              <span>CVV</span>
              <div className={'card-row-group'}>
                <span className={'same-height'}>{cvv}</span>
                <div className={'same-height'} onClick={getCvvCode}>
                  {isLoadingCvv ? (
                    <LoadIndicator id={'cvv'} className={'vision-loader'} height={16} width={16}/>
                  ) : (
                    <EyeIcon open={cvv === hiddenCVV}/>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <AppPopup
        visible={showSuccess || isShowCardSettingsMenu || isShowActivate}
        container={cont}
        title={isShowCardSettingsMenu ?
          t('MAIN.ACCOUNT_DETAIL_WIDGET.CARD_SETTINGS') :
          t('MAIN.ACCOUNT_DETAIL_WIDGET.CARD_ACTIVATION')
        }
        handleClose={handleClose}
        maxWidth={500}
        position={pos}
        icon={renderIcon()}
      >
        {showSuccess ? (
            <CardActivationSuccess
              options={showSuccess}
            />
          ) :
          isShowCardSettingsMenu ? (
            <WallettoDetailsSettingsFields
              handleClose={handleClose}
              cardId={ID}
            />
          ) : isShowActivate && (
            <CardActivationFields
              handleClose={handleClose}
              cardId={ID}
            />
          )}
      </AppPopup>
    </div>
  );
}

export default CardWidget;