import React from 'react';
import {TextBox} from 'devextreme-react';
import {useTranslation} from 'react-i18next';
import {getGroupIcon} from 'utils/functions';

const FieldTemplate = ({data}) => {
	const {t} = useTranslation();

	if (!data) {
		return (
			<TextBox disabled defaultValue={t('COMMON.SELECT_BOX')}/>
		);
	}

	const {
		ForeignPaymentSystemTypeImageUrl,
		ForeignPaymentSystemTypeGroupID,
		ForeignPaymentSystemTypeName,
	} = data;

	return (
		<div className={'template-item-field'}>
			<div className={'image-wrapper'}>
				{ForeignPaymentSystemTypeImageUrl ? (
					<img src={ForeignPaymentSystemTypeImageUrl} alt={'icon'}/>
				): (
					<i className={getGroupIcon(ForeignPaymentSystemTypeGroupID)}></i>
				)}
			</div>
			<TextBox
				readOnly
				className={'item-wrapper'}
				value={ForeignPaymentSystemTypeName}
			/>
		</div>
	);
};

export default FieldTemplate;


