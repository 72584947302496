import React, {useEffect, useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames/bind';
import {copyTextToClipboard} from 'utils/functions';
import {userProfileGetList} from 'services/async';
import './invite.scss';

const Invite = () => {
	const {t} = useTranslation();
	const [userProfile, setUserProfile] = useState(null);
	const [isCopying, setIsCopying] = useState(null);
	const inviteRef = useRef(null);

	useEffect(() => {
		if (isCopying) {
			hideHint().catch((e) => {
				console.error(e);
			});
		}
	}, [isCopying]);

	useEffect(() => {
		if (!userProfile) {
			userProfileGetList().then((response) => {
				if (response.isSuccess) {
					setUserProfile(response.responseObj);
				}
			}).catch((error) => {
				console.error(error);
			})
		}
	}, [userProfile]);

	const copyUrl = () => {
		setIsCopying('invite');
		const text = inviteRef.current.textContent;
		copyTextToClipboard(text);
	}

	const hideHint = async () => {
		const delay = new Promise((resolve => setTimeout(resolve, 2000)));
		await delay;
		setIsCopying(null);
	};

	const getReturnUrl = () => {
		let returnUrl = '';
		if (userProfile) {
			returnUrl = window.location.protocol + '//' + window.location.host + userProfile.ReferralUrlPath;
		}
		return returnUrl;
	}

	const getCountText = () => {
		let count = 0;

		if (userProfile) {
			count = userProfile.ReferralCount;
		}

		return t('SETTINGS.RIGHT_BLOCK.INVITE.REFERRAL_COUNT') + count;
	}

	return <div className={'settings-wrapper invite-wrapper'} id={'invite-wrapper'}>
		<div className={'settings-header'}>
			<div className={'settings-header_inner'}>
				<div className={'settings-header_text'}>
					<i className={'mdi mdi-bank'}/>
					<span>{t('SETTINGS.LEFT_BLOCK.INVITE.TITLE')}</span>
				</div>
			</div>
		</div>
		<div className="settings-splitter"/>
		<div className={'invite-inner'}>
			<div className={'invite-field'}>
				<div className={'invite-label'}>{t('SETTINGS.RIGHT_BLOCK.INVITE.TITLE')}</div>
				<div className={'invite-input invite-text'}>
					<i onClick={copyUrl} className={'invite-copy'}>
										<span className={classNames('invite-hint', {
											'copy-hint-visible': isCopying === 'invite',
										})}>{t('COMMON.COPIED')}</span>
					</i>
					<div ref={inviteRef} className={'input-text'}>
						{getReturnUrl()}
					</div>
				</div>
				<div className={'invite-label'}>
					{getCountText()}
				</div>
			</div>
		</div>
	</div>;
};

export default Invite;