import React from 'react';
import {camelToUpperSnakeCase, truncateString} from 'utils/functions';
import CopyButton from 'components/buttons/copy-button/copy-button';

const S3Fields = ({t, rowData}) => {
  rowData.InsDate = rowData.InsDate.replace('T', ' ');

  const {
    Amount, CurrencyCode, FeeAmount, FeePayerTypeCode, ForeignAmount, ForeignClientName, ForeignAccountCode,
    ForeignCurrencyCode, CurrencyViewPrecision, ForeignCurrencyViewPrecision, CurrencyRate, PaymentSystemTypeID,
    Description, CryptoParams,
  } = rowData;

  const simpleFields = [
    'InsDate',
    'PaymentTypeName',
    'PaymentStatusName',
  ];

  let conditionFields = [];

  if (rowData.PaymentSystemTypeID === 15) {
    conditionFields.push('CryptoParams.BlockchainCode');
  }

  if (rowData.PaymentSystemTypeID === 16) {
    conditionFields = conditionFields.concat([
      'ForeignClientCode',
      'ForeignClientAddress',
      'ForeignClientCountry',
      'ForeignClientBank',
      'ForeignClientBankSwift',
      'ForeignClientBankAddress',
      'ForeignClientBankCountry',
      'ForeignClientCorrBank',
      'ForeignClientCorrBankSwift',
      'ForeignClientCorrBankAddress',
      'ForeignClientCorrBankCountry',
      'ForeignClientCorrBankAccount',
    ]);
  }

  if (rowData.PaymentSystemTypeID === 21) {
    conditionFields = conditionFields.concat([
      'BanxeParams.ForeignAddress',
      'BanxeParams.ForeignBankBicOrSwift',
      'BanxeParams.ForeignBankSortCode',
    ]);
  }

  let complexFields;
  const commonFields = {};

  // https://wiki.macc.com.ua/maccsystems/pages/viewpage.action?pageId=60623336
  if ([2, 4, 6, 8, 10].includes(rowData.PaymentTypeID)) {

    complexFields = {
      Amount: ForeignAmount.toFixed(ForeignCurrencyViewPrecision) + " " + ForeignCurrencyCode,
      ToReceive: (Amount + FeeAmount).toFixed(CurrencyViewPrecision) + " " + CurrencyCode,
      Fee: (FeePayerTypeCode == null ? '' : FeePayerTypeCode) + " " + FeeAmount.toFixed(CurrencyViewPrecision) + " " + CurrencyCode,
      ToEnroll: Amount.toFixed(CurrencyViewPrecision) + " " + CurrencyCode,
    };
  } else {
    complexFields = {
      Amount: Amount.toFixed(CurrencyViewPrecision) + " " + CurrencyCode,
      Fee: (FeePayerTypeCode == null ? '' : FeePayerTypeCode) + " " + FeeAmount.toFixed(CurrencyViewPrecision) + " " + CurrencyCode,
      ToReceive: (Amount - FeeAmount).toFixed(CurrencyViewPrecision) + " " + CurrencyCode,
      ToTransfer: ForeignAmount.toFixed(ForeignCurrencyViewPrecision) + " " + ForeignCurrencyCode,
    };
  }

  if (CurrencyRate !== 1) {
    commonFields.Rate = "1 " + CurrencyCode + " = " + CurrencyRate + " " + ForeignCurrencyCode;
  }

  commonFields.TransactionId = rowData.ID;

  const complexKeys = Object.keys(complexFields);
  const commonKeys = Object.keys(commonFields);

  return (
    <div className={'payment-detail-inner'}>
      {simpleFields.map((key) => {
        return (
          <div
            key={key}
            className={'payment-detail-item'}
          >
            <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.${camelToUpperSnakeCase(key)}`)}</span>
            <span className={'item-value'}>{rowData[key]}</span>
          </div>
        );
      })}
      <div className={'payment-detail-item'}>
        <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.FOREIGN_CLIENT_NAME`)}</span>
        <div className={'item-value with-copy-button'}>
          <span className={'item-value'} title={ForeignClientName}>{ForeignClientName}</span>
          <CopyButton value={ForeignClientName}/>
        </div>
      </div>
      <div className={'payment-detail-item'}>
        <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.FOREIGN_ACCOUNT_CODE`)}</span>
        <div className={'item-value with-copy-button'}>
          <span className={'item-value'} title={ForeignAccountCode}>{truncateString(ForeignAccountCode)}</span>
          <CopyButton value={ForeignAccountCode}/>
        </div>
      </div>
      {conditionFields.map((key) => {

        const [outerKey, innerKey] = key.split('.');

        if (innerKey) {
          return rowData[outerKey][innerKey] ? (
            <div
              key={key}
              className={'payment-detail-item'}
            >
              <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.${camelToUpperSnakeCase(innerKey)}`)}</span>
              <span className={'item-value'}>{rowData[outerKey][innerKey]}</span>
            </div>
          ) : null;
        } else {
          return (
            <div
              key={key}
              className={'payment-detail-item'}
            >
              <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.${camelToUpperSnakeCase(key)}`)}</span>
              <span className={'item-value'}>{rowData[key]}</span>
            </div>
          );
        }
      })}
      <div className={'payment-detail-item'}>
        <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.DESCRIPTION`)}</span>
        <span className={'item-value'} title={Description}>{Description}</span>
      </div>
      {complexKeys.map((key) => {
        return (
          <div
            key={key}
            className={'payment-detail-item'}
          >
            <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.${camelToUpperSnakeCase(key)}`)}</span>
            <span className={'item-value'}>{complexFields[key]}</span>
          </div>
        );
      })}
      {commonKeys.map((key) => {
        return (
          <div
            key={key}
            className={'payment-detail-item'}
          >
            <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.${camelToUpperSnakeCase(key)}`)}</span>
            <span className={'item-value'}>{commonFields[key]}</span>
          </div>
        );
      })}

      {PaymentSystemTypeID === 15 && CryptoParams && CryptoParams.Txid ? (
        <div
          className={'payment-detail-item'}
        >
          <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.BLOCKCHAIN_ID`)}</span>
          <div className={'item-value with-copy-button'}>
            <span className={'item-value'} title={CryptoParams.Txid}>{truncateString(CryptoParams.Txid)}</span>
            <CopyButton value={CryptoParams.Txid}/>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default S3Fields;