import React from 'react';
import TextBox from 'devextreme-react/text-box';
import {useTranslation} from 'react-i18next';
import {accountIcon} from 'data/accounts';

const RecipientFieldTemplate = ({data}) => {
	const {t} = useTranslation();

	if (!data) {
		return <TextBox readOnly={true} placeholder={t('COMMON.SELECT_BOX')}/>
	}

  const {
    PaymentSystemTypeID,
    CurrencyCode,
    CurrencyImageUrl,
    AccountNumber,
    CardNumber,
  } = data;


  let src;

  if (PaymentSystemTypeID === 0) {
    src = CurrencyImageUrl ? CurrencyImageUrl : './images/icons/global.svg';
  } else {
    src = (accountIcon[PaymentSystemTypeID] && accountIcon[PaymentSystemTypeID].icon) ?
      accountIcon[PaymentSystemTypeID].icon :
      '';
  }

	return (
		<div className={'recipient-item-field'}>
      <div className={'item-field-inner'}>
        {src ? (
          <div className={'image-wrapper'}>
            <img src={src} alt={'PaymentSystemTypeName'}/>
          </div>
        ) : null}
        <span className={'item-currency'}>{CurrencyCode}</span>
      </div>
			<TextBox
        className={src ? 'regular-value' : 'custom-value'}
				value={PaymentSystemTypeID === 11 ? CardNumber : AccountNumber}
        readOnly={true}
			/>
		</div>
	);
};

export default RecipientFieldTemplate;


