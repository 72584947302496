import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {notifyApp} from 'utils/notifyWrapper';
import RoundButton from 'components/buttons/round-button/round-button';
import './otp-input-stateful.scss';

const OtpInputStateful = ({
                            onEnterKey = () => {
                            },
                            withFocusedState = false,
                            withResend = true,
                            resendText = 'COMMON.RESEND',
                            placeholder = 'OTP',
                            icon = 'mdi-shield',
                            validationMessage = 'ERROR_MSG.REQUIRED',
                            onClosePopup = () => {
                            },
                            onSubmit,
                            onResend,
                          }) => {
  const {t} = useTranslation();
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [otp, setOtp] = useState('');
  const [isDisabledResend, setIsDisabledResend] = useState(true);

  const componentClass = {
    'otp-component': true,
    'with-focused-state': withFocusedState,
    'with-resend': withResend,
    'otp-component-focused': isFocused,
    'invalid': !isValid,
  };

  useEffect(() => {
    const focusTimerId = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 700);

    return () => {
      clearTimeout(focusTimerId);
    }
  }, []);

  useEffect(() => {
    if (isDisabledResend) {
      const timerId = setTimeout(() => {
        setIsDisabledResend(false);
      }, 60000);

      return () => {
        clearTimeout(timerId);
      }
    }
  }, [isDisabledResend]);

  return (
    <div className={'otp-input-stateful'}>
      <div className={'round-input-wrapper'}>
        <div className={'round-input-wrapper_input'}>
          <div className={classNames(componentClass)}>
            <div className={'left-pocket'}>
              <i className={classNames('mdi', icon)}/>
            </div>
            <div className={'otp-input-wrapper'}>
              <input
                ref={inputRef}
                type={'text'}
                placeholder={placeholder}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    onEnterKey();
                  }
                }}
                onFocus={() => {
                  setIsFocused(true);
                  setIsValid(true);
                }}
                onBlur={() => {
                  setIsFocused(false);
                }}
                value={otp}
                onChange={({target: {value}}) => {
                  setOtp(value.replace(/\D/g, '').substring(0, 6));
                }}
              />
            </div>
            <div className={'right-pocket'}>
              <button
                disabled={isDisabledResend}
                onClick={async (event) => {
                  event.target.disabled = true;

                  setOtp('');

                  try {
                    await onResend();
                  } catch (error) {
                    notifyApp(error);
                  } finally {
                    setIsDisabledResend(true);
                  }
                }}
                className={'resend-otp-button'}
              >
                {t(resendText)}
              </button>
              <div className={'validation-sign'}/>
            </div>
            <div className={'validation-message'}>
              {t(validationMessage)}
            </div>
          </div>
        </div>
      </div>
      <div className={'button-wrapper'}>
        <RoundButton
          text={t('COMMON.CANCEL')}
          icon={''}
          onClick={onClosePopup}
          customClass={'size14'}
          width={235}
        />
        <RoundButton
          text={t('COMMON.CONFIRM')}
          icon={''}
          onClick={async () => {
            try {
              if (otp.length === 6) {
                await onSubmit(otp);
              } else {
                setIsValid(false);
              }
            } catch (error) {
              notifyApp(error);
            }
          }}
          customClass={'blue size14'}
          width={235}
        />
      </div>
    </div>
  );
}

export default OtpInputStateful;