import React, {useEffect, useRef, useState} from 'react';
import ItemWidget from './item-widget';

const TransactionGroup = ({selectedId, items, onItemClick, onItemDetailClick, isDetailOpen}) => {
  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('wheel', (e) => {
        e.preventDefault();
        container.scrollLeft += e.deltaY;
      }, {passive: false});
    }
  }, []);

  return (
    <div className={'horizontal-bar-widget'}>
      <div
        className='bar-widget'
        ref={scrollContainerRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <div className={'account-inner'}>
          {items.map((item) => {

            const className = item.ID === selectedId ? 'account-item selected' : 'account-item';

            return (
              <div className={className} key={item.ID} onClick={() => {
                if (item.ID !== selectedId) {
                  onItemClick({selectedItemId: item.ID});
                }
              }}>
                <ItemWidget data={item} onItemDetailClick={onItemDetailClick} isDetailOpen={isDetailOpen}/>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TransactionGroup;
