export const actionTypes = {
	SHOW_OTP_POPUP: 'app/SHOW_OTP_POPUP',
	HIDE_OTP_POPUP: 'app/HIDE_OTP_POPUP',
};


export const showOtpPopup = (payload) => ({
	type: actionTypes.SHOW_OTP_POPUP,
	payload: payload,
});
export const hideOtpPopup = () => ({
	type: actionTypes.HIDE_OTP_POPUP,
});