import React, {useEffect, useState} from 'react';
import PopupSelectBox from '../popup-selectbox';
import {createNewWallettoAccount} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {useTranslation} from 'react-i18next';
import {Button, CheckBox} from 'devextreme-react';
import Validator, {CompareRule} from 'devextreme-react/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import './wallettoAccountFields.scss';

const IbanAccountFields = ({
                             paymentSystemId, successHandler, showOtpPopup,
                             handleClose, userProfile, paymentSystemMatrix,
                           }) => {
  const [agreement, setAgreement] = useState(false);
  const [wallettoAccountType, setWallettoAccountType] = useState({
    ID: null,
    PaymentSystemSubTypeName: null,
    CurrencyID: null,
    CurrencyName: null,
  });

  useEffect(() => {
    if (paymentSystemMatrix.length === 1) {
      setWallettoAccountType(paymentSystemMatrix[0]);
    }
  }, [paymentSystemMatrix]);

  const initIbanWallettoAccount = async ({component}) => {
    const result = validationEngine.validateGroup('iban');

    if (result && result.isValid) {
      component.option('disabled', true);

      const requestData = {
        PaymentSystemTypeID: paymentSystemId,
        WallettoAccountTypeID: wallettoAccountType.PaymentSystemSubTypeID,
        PayGateTokenID: wallettoAccountType.PayGateTokenID,
      };

      try {
        const request = await createNewWallettoAccount(requestData);

        if (request.isSuccess) {
          showOtpPopup({
            onResend: async () => {
              delete requestData.OtpCode;

              createNewWallettoAccount(requestData).then((response) => {
                if (response.error) {
                  notifyApp(response.error);
                }
              }).catch((error) => {
                notifyApp(error);
              });
            },
            onSubmit: async (otpCode) => {
              requestData.OtpCode = otpCode;

              try {
                const confirmRequest = await createNewWallettoAccount(requestData);

                if (confirmRequest.isSuccess) {
                  successHandler();
                } else {
                  notifyApp(confirmRequest.error);
                }
              } catch (error) {
                notifyApp(error);
              }
            },
            sendChannelId: request.response.SendChannelID,
            infoText: request.response.InfoText,
          });
        } else {
          notifyApp(request.error);
        }
      } catch (error) {
        notifyApp(error);
      } finally {
        setTimeout(() => {
          component.option('disabled', false);
        }, 500);
      }
    }
  };

  const handleAgreement = ({value}) => {
    setAgreement(value);
  };

  const agreementCompare = () => {
    return true;
  };

  const {t} = useTranslation();

  const onCardTypeChanged = ({value}) => {
    setWallettoAccountType(paymentSystemMatrix.find((item) => item.ID === value));
  };

  return (
    <div className={'walletto-fields'}>
      <div>
        {paymentSystemMatrix.length > 1 ? (
          <PopupSelectBox
            placeholder={t('MAIN.ADD_ACCOUNT.ACCOUNT_SUBTYPE')}
            items={paymentSystemMatrix}
            displayExpr={'PaymentSystemSubTypeName'}
            value={wallettoAccountType.ID}
            changeHandler={onCardTypeChanged}
          />
        ) : null}
        <PopupSelectBox
          placeholder={t('MAIN.ADD_ACCOUNT.CURRENCY')}
          items={paymentSystemMatrix}
          displayExpr={'CurrencyName'}
          valueExpr={'CurrencyID'}
          value={wallettoAccountType.CurrencyID}
          disabled={true}
        >
          <Validator validationGroup={'iban'}/>
        </PopupSelectBox>
        <div className={'dx-field add-walletto-checkbox'}>
          <CheckBox
            name={'registration-agreement-checkbox'}
            id={'agreement-checkbox-text'}
            validationMessageMode={'always'}
            defaultValue={false}
            value={agreement}
            text={''}
            onValueChanged={handleAgreement}
          >
            <Validator validationGroup={'registrationGroup'}>
              <CompareRule message=""
                           comparisonTarget={agreementCompare}/>
            </Validator>
          </CheckBox>
          <div>
            <span>{t('MAIN.ADD_ACCOUNT.CONFIRM_CHECKBOX_PRE')}</span>
            <a
              className={'terms-link'}
              target={'_blank'}
              href={userProfile.VisaCardTermsUrl}
            >
              {t('MAIN.ADD_ACCOUNT.CONFIRM_CHECKBOX_LINK')}
            </a>
            <span>{t('MAIN.ADD_ACCOUNT.CONFIRM_CHECKBOX_POST')}</span>
          </div>
        </div>
        <div className={'popup-buttons'}>
          <Button
            id={'popup-buttons_add'}
            width={'50%'}
            height={40}
            text={t('MAIN.ADD_ACCOUNT.ADD')}
            type={'default'}
            stylingMode={'contained'}
            disabled={!agreement}
            onClick={initIbanWallettoAccount}
          />
          <Button
            id={'popup-buttons_cancel'}
            width={'50%'}
            height={40}
            text={t('MAIN.ADD_ACCOUNT.CANCEL')}
            type={'default'}
            stylingMode={'outlined'}
            onClick={handleClose}
          />
        </div>
      </div>
    </div>
  );
}

export default IbanAccountFields;
