import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import './success-status-screen.scss';

const SuccessStatusScreen = (props) => {
	const {text} = props;
	const {t} = useTranslation();

	useEffect(() => {
		const timerID = setTimeout(() => {
			const el = document.getElementById('animation');
			const success = document.getElementById('success');
			const textElement = document.getElementById('status-text');

			el.style.opacity = '0';
			success.style.opacity = '1';
			textElement.textContent = t(text);
		}, 1500);

		return () => {
			clearTimeout(timerID);
		};
	}, [])

	return (
		<div className={'success-wrapper'}>
			<div className={'animation-wrapper'}>
				<object
					height={150}
					id={'animation'}
					type={'image/svg+xml'}
					data={'./images/sharpay/animated-circles.svg'}
				>Success operation</object>
				<img height={150} id={'success'} src={'./images/sharpay/success-green.svg'} alt={'success-operation'}/>
			</div>
			<div>
					<span id={'status-text'} className={'blank-block-text'}>
						{t('OPERATIONS.PAYMENT_PROCESSING_TEXT')}
					</span>
			</div>
		</div>
	);
};

export default SuccessStatusScreen;
