import {languages} from 'utils/variables';
import classNames from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';

const LanguageSwitcher = () => {
  const {i18n} = useTranslation();

  return (
    <div className={'language-switcher'}>
      {languages.map((language) => {
        const currentLanguage = i18n.language.split('-')[0];
        const langClass = {
          'language-item': true,
          'selected': currentLanguage === language.name,
        };

        return (
          <div
            className={classNames(langClass)}
            key={language.name}
            onClick={() => {
              if (i18n.language !== language.name) {
                i18n.changeLanguage(language.name).then(() => {
                  if (typeof HelpCrunch === 'function') {
                    // eslint-disable-next-line no-undef
                    HelpCrunch('setPhraseList', language.name);
                  }
                }).catch((error) => {
                  console.error(error);
                });
              }
            }}
          >
            <img
              src={language.imgSrc}
              alt={language.name}
            />
            <span>{language.name.toUpperCase()}</span>
          </div>
        );
      })}
    </div>
  );
}

export default LanguageSwitcher;