import React, {useEffect, useRef, useState} from 'react';
import Loader from 'components/loader/loader';
import {Button, NumberBox, SelectBox, TextBox, Validator} from 'devextreme-react';
import {CustomRule, PatternRule, RequiredRule} from 'devextreme-react/validator';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {vars} from 'utils/variables';
import {
	apiRequest,
	calculateFee,
	getAllowedPaymentParams,
	makeTransaction,
	paymentConfirm,
	resendPaymentOtp
} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import validationEngine from "devextreme/ui/validation_engine";
import ItemTemplate from 'components/operations/crypto/crypto-currency-templates/item-template';
import FieldTemplate from 'components/operations/crypto/crypto-currency-templates/field-template';
import CurrencyInput from 'components/inputs/currency-input/currency-input';
import {constructMinMaxString} from 'utils/functions';

const {SUCCESS_API_CODE, REG_EXP, PAYMENT, OPERATION, NOTIFY} = vars;

const textInputProps = {
	className: 'operation-input',
	stylingMode: 'outlined',
	hoverStateEnabled: false,
};

const CryptoPayoutFieldsHook = (props) => {
	const {
		isShowLoader,
		paymentMethodValueName,
		paymentMethodValueId,
		account,
		isBlockedRightForm,
		clickCancel,
		changePaymentStatus,
		showOtpPopup,
		hideOtpPopup,
	} = props;
	const {t} = useTranslation();
	const [cryptoCurrency, setCryptoCurrency] = useState([]);
	const [selectedCryptoCurrency, setSelectedCryptoCurrency] = useState(null);

	const [amount, setAmount] = useState(null);
	const [fee, setFee] = useState(null);
	const [totalAmount, setTotalAmount] = useState(null);

	const [foreignAccountCode, setForeignAccountCode] = useState('');
	const [memoTag, setMemoTag] = useState('');
	const [description, setDescription] = useState('');

	const amountRef = useRef(null);
	const totalRef = useRef(null);

	const getCryptoCurrency = async () => {
		const request = await apiRequest({
			operation: 'CryptoPayment/GetCryptoCurrency',
			data: {
				Params: {
					AccountID: account.ID, // - ID выбранного счета
					PaymentTypeID: PAYMENT.PAYMENT_TYPE_PAYOUT, // - ID выбранного типа операции (1- Перевод, 2 Пополнение)
					ForeignPaymentSystemTypeID: paymentMethodValueId, // - ID выбранной платежной системы (15-Crypto)
				},
			},
		});

		if (request.data.ResponseCode === SUCCESS_API_CODE) {
			return Promise.resolve(request.data.Response.CryptoCurrency);
		} else {
			return Promise.reject(request.data.ResponseText);
		}
	};

	const [allowedPaymentParams, setAllowedPaymentParams] = useState(null);

	const requestMinMaxForCrypto = async () => {
		const request = await getAllowedPaymentParams({
				AccountID: account.ID,
				PaymentTypeID: PAYMENT.PAYMENT_TYPE_PAYOUT,
				ForeignPaymentSystemTypeID: paymentMethodValueId,
				ForeignAccountCode: foreignAccountCode,
			}
		);

		if (request.isSuccess) {
			return request.response;
		} else {
			throw new Error(request.error);
		}
	}

	useEffect(() => {
		if (foreignAccountCode) {
			requestMinMaxForCrypto().then((result) => {
				setAllowedPaymentParams(result);
			}).catch((error) => {
				notifyApp(error);
			});
		}
	}, [foreignAccountCode]);

	useEffect(() => {
		if (cryptoCurrency.length === 0) {
			getCryptoCurrency().then(response => {
				console.group('resolve CryptoCurrency');
				console.log(response);
				console.groupEnd();

				const cryptoCurrencies = [];

				response.forEach((item, index) => {
					if (item.DisplayCurrencyRate) {
						cryptoCurrencies.push({
							...item,
							UniqID: `${item.BlockchainID}-${index}`,
							AccountCurrencySymbol: account.CurrencySymbol,
						});
					}
				});

				if (cryptoCurrencies.length > 0) {
					setCryptoCurrency(cryptoCurrencies);
				}
			}).catch((error) => {
				notifyApp(error);
				console.log(error);
			});

		}
	}, [cryptoCurrency]);

	useEffect(() => {
		if (amount) {
			calculateFeeAmount({amount: amount}).then((summary) => {
				totalRef.current.block = true;
				setFee(summary.FeeAmount);
				setTotalAmount(summary.ToCurrencyAmount);
			}).catch(error => {
				notifyApp(error);
			}).finally(() => {
				console.group('cryptoCurrency effect');
				console.log(amount);
				console.groupEnd();
			});
		}
	}, [selectedCryptoCurrency]);

	useEffect(() => {
		if (amount) {
			if (amountRef.current.block) {
				amountRef.current.block = false;
			} else {
				calculateFeeAmount({amount: amount}).then((summary) => {
					totalRef.current.block = true;
					setFee(summary.FeeAmount);
					setTotalAmount(summary.ToCurrencyAmount);
				}).catch(error => {
					notifyApp(error);
				}).finally(() => {
					console.group('amount effect');
					console.log(amount);
					console.groupEnd();
				});
			}
		}
	}, [amount]);

	useEffect(() => {
		if (totalAmount) {
			if (totalRef.current.block) {
				totalRef.current.block = false;
			} else {
				calculateFeeAmount({totalAmount: totalAmount}).then((summary) => {
					amountRef.current.block = true;
					setFee(summary.FeeAmount);
					setAmount(summary.Amount);
				}).catch(error => {
					notifyApp(error);
				}).finally(() => {
					console.group('totalAmount effect');
					console.log(totalAmount);
					console.groupEnd();
				});
			}
		}
	}, [totalAmount]);

	const calculateFeeAmount = async ({amount = null, totalAmount = null}) => {
		console.group('calculateFeeAmount');
		console.log(amount);
		console.groupEnd();
		if (foreignAccountCode) {
			const summaryCalc = await calculateFee({
				amount: amount, // field how much total
				currency: selectedCryptoCurrency.CurrencyID, // ForeignCurrencyID - crypto currency
				toCurrencyAmount: totalAmount, // field how much total
				accountId: account.ID, // from account object
				foreignAccountCode: foreignAccountCode, // field to whom // Address
				paymentSystemTypeID: paymentMethodValueId,
				paymentTypeID: PAYMENT.PAYMENT_TYPE_PAYOUT, // payout or income
			});

			if (summaryCalc.isSuccess) {
				return Promise.resolve(summaryCalc.response);
			} else {
				return Promise.reject(summaryCalc.error);
			}
		} else {
			return Promise.reject('Не выбран получатель');
		}
	};

	const checkBalance = ({value}) => {
		return account.Balance >= value;
	};

	const onContinue = async ({component}) => {
		const result = await validationEngine.validateGroup('gr1');

		if (result.isValid) {
			component.option('disabled', true);

			let transactionData = {
				PaymentSystemTypeID: paymentMethodValueId,
				AccountID: account.ID,
				Amount: amount,
				ForeignCurrencyID: selectedCryptoCurrency.CurrencyID,
				Description: description,
				ForeignAccountCode: foreignAccountCode,
				ForeignClientName: memoTag,
				BlockchainID: selectedCryptoCurrency.BlockchainID,
				operation: OPERATION.PAYMENT_PAYOUT
			};

			try {
				const request = await makeTransaction(transactionData);

				if (request.isSuccess) {
					if (request.response.IsCheckOtp === OPERATION.IS_CHECK_OTP_CODE) {
						showOtpPopup({
							onSubmit: async (otpCode) => {
								const confirmRequest = await paymentConfirm({
									PaymentID: request.response.ID,
									Code: otpCode,
								});

								if (confirmRequest.isSuccess) {
									// done
									hideOtpPopup();
									changePaymentStatus({
										text: t('OPERATIONS.PAYMENT_SUCCESS_TEXT')
									});
								} else {
									notifyApp(confirmRequest.error);
								}
							},
							onResend: async () => {
								const resendRequest = await resendPaymentOtp({
									PaymentID: request.response.ID,
								});

								if (resendRequest.isSuccess) {
									notifyApp(t('COMMON.SENT'), NOTIFY.SUCCESS);
								} else {
									notifyApp(resendRequest.error);
								}
							},
							sendChannelId: request.response.SendChannelID,
							infoText: request.response.InfoText,
						});
					} else {
						changePaymentStatus({
							text: t('OPERATIONS.PAYMENT_SUCCESS_TEXT')
						});
					}
				} else {
					notifyApp(request.error);
				}
			} catch (error) {
				notifyApp(error);
			} finally {
				component.option('disabled', false);
			}
		} else {
			// focus first broken field
			result.brokenRules[0].validator.focus();
		}
	};

	return (
		<div className={'payout-fields'}>
			<Loader
				isShowLoader={isShowLoader}
			/>
			<div className={'operations-row'}>
				<div className={'operations-row-inner'}>
					<div className={'operations-subtitle'}>
						{`${t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.TRANSFER_TO')} ${paymentMethodValueName}`}
					</div>
					<div className={'operations-title'}>{t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.DETAILS')}</div>
					<div className={'operations-fields-wrapper'}>
						<div className={'operations-fieldset'}>
							<div className={'operations-field'}>
								<div className="dx-field-label">{`${t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.CRYPTO')}*`}</div>
								<div className="dx-field-value">
									<SelectBox
										placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.CHOOSER')}
										readOnly={isBlockedRightForm}
										dataSource={cryptoCurrency}
										valueExpr={'UniqID'}
										onValueChanged={({value}) => {
											const selectedItem = cryptoCurrency.find(item => item.UniqID === value);
											setSelectedCryptoCurrency(selectedItem);
										}}
										stylingMode={'outlined'}
										itemComponent={ItemTemplate}
										fieldComponent={FieldTemplate}
									>
										<Validator validationGroup={'gr1'}>
											<RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
										</Validator>
									</SelectBox>
								</div>
							</div>
						</div>

						<div className={'operations-fieldset'}>
							<div className={'operations-field'}>
								<div className="dx-field-label">Address*</div>
								<div className="dx-field-value">
									<TextBox
										{...textInputProps}
										readOnly={isBlockedRightForm}
										value={foreignAccountCode}
										onValueChanged={({value}) => {
											setForeignAccountCode(value);
										}}
									>
										<Validator validationGroup={'gr1'}>
											<RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
											{selectedCryptoCurrency && selectedCryptoCurrency['AddressValidationRegexp'] && (
												<PatternRule
													message={t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.CRYPTO_REG_EXP_MESSAGE')}
													pattern={selectedCryptoCurrency['AddressValidationRegexp']}
												/>
											)}
										</Validator>
									</TextBox>
								</div>
							</div>
						</div>

						{selectedCryptoCurrency && selectedCryptoCurrency['IsHasMemo'] ? (
							<div className={'operations-fieldset'}>
								<div className={'operations-field'}>
									<div className="dx-field-label">Memo / Destination tag</div>
									<div className="dx-field-value">
										<TextBox
											{...textInputProps}
											readOnly={isBlockedRightForm}
											value={memoTag}
											onValueChanged={({value}) => {
												setMemoTag(value);
											}}
										/>
									</div>
								</div>
							</div>
						) : null}

					</div>
				</div>
			</div>

			<div className={'operations-row'}>
				<div className={'operations-row-inner'}>
					<div className={'operations-title'}>{t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.TRANSFER_AMOUNT')}</div>
					<div className={'operations-fields-wrapper'}>
						<div className={'operations-fieldset'}>
							<div className={'operations-field'}>
								<div className="dx-field-label">{`${t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.AMOUNT_PAYABLE')}*`}</div>
								<div className="dx-field-value field-value-relative">
									<span className={classNames('field-value-currency', {
										'currency-read-only': isBlockedRightForm,
									})}>
										{account.CurrencySymbol}
									</span>
									<NumberBox
										step={0}
										{...textInputProps}
										ref={amountRef}
										name={'amount'}
										value={amount}
										onValueChanged={({value}) => {
											setAmount(value);
										}}
										readOnly={isBlockedRightForm}
									>
										<Validator validationGroup={'gr1'}>
											<RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
											<PatternRule message={t('ERROR_MSG.DIGIT_GRATER_NULL_ONLY')} pattern={REG_EXP.AMOUNT}/>
											<CustomRule
												message={t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.INSUFFICIENT_BALANCE')}
												validationCallback={checkBalance}
											/>
										</Validator>
									</NumberBox>
									{(allowedPaymentParams && selectedCryptoCurrency) ? (
										<div className={'operations-field-addons'}>
											{constructMinMaxString(allowedPaymentParams, selectedCryptoCurrency.CurrencyID)}
										</div>
									) : null}
								</div>
							</div>
						</div>
						<div className={'operations-fieldset'}>
							<div className={'operations-field'}>
								<div className="dx-field-label">{t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.FEE')}</div>
								<div className="dx-field-value field-value-relative">
									<span className={'field-value-currency currency-read-only'}>
										{account.CurrencySymbol}
									</span>
									<NumberBox
										{...textInputProps}
										value={fee}
										readOnly={true}
									/>
								</div>
							</div>
						</div>
						<CurrencyInput
							label={`${t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.AMOUNT_RECEIVABLE')}*`}
							currency={selectedCryptoCurrency && selectedCryptoCurrency.Symbol}
							inputProps={{
								ref: totalRef,
								name: 'total',
								value: totalAmount,
								onValueChanged: ({value}) => {
									setTotalAmount(value);
								},
								readOnly: isBlockedRightForm,
							}}
						>
							<Validator validationGroup={'gr1'}>
								<RequiredRule message={t('ERROR_MSG.REQUIRED')}/>
								<PatternRule message={t('ERROR_MSG.DIGIT_GRATER_NULL_ONLY')} pattern={REG_EXP.AMOUNT}/>
							</Validator>
						</CurrencyInput>
						{(selectedCryptoCurrency && selectedCryptoCurrency.Symbol) ? (
							<div className={'operations-fieldset'}>
								<div className={'operations-field-full operations-field'}>
									<div className="operations-field-label dx-field-label">
										{t('TRANSACTIONS.NETWORK_FEE')}
									</div>
									<div className="dx-field-value">
										<TextBox
											{...textInputProps}
											name={'network-fee'}
											value={`${selectedCryptoCurrency.Symbol} ~${selectedCryptoCurrency.WithdrawFeeAmountConst}`}
											readOnly
										/>
									</div>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>

			<div className={'operations-row'}>
				<div className={'operations-row-inner'}>
					<div className={'operations-fields-wrapper'}>
						<div className={'operations-fieldset'}>
							<div className={'operations-field details-field'}>
								<div className="dx-field-label">{`${t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.DESCRIPTION')}`}</div>
								<div className="dx-field-value dx-field-value-last">
									<TextBox
										{...textInputProps}
										placeholder={t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.DESCRIPTION_FIELD')}
										readOnly={isBlockedRightForm}
										name={'description'}
										value={description}
										onValueChanged={({value}) => {
											setDescription(value);
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className={'operations-buttons'}>
				<div className={'buttons-wrapper'}>
					<Button
						width={120}
						text={t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.CANCEL')}
						type={'normal'}
						stylingMode={'contained'}
						onClick={clickCancel}
					/>
					<Button
						width={120}
						text={t('OPERATIONS_PAGE.RIGHT_BLOCK_TRANSFER.CONTINUE')}
						type={'normal'}
						stylingMode={'contained'}
						onClick={onContinue}
					/>
				</div>
			</div>
		</div>
	);
};

export default CryptoPayoutFieldsHook;
