import React from 'react';
import {accountIcon} from 'data/accounts';

const RecipientItemTemplate = ({data}) => {
  const {
    PaymentSystemTypeID,
    CurrencyCode,
    CurrencyImageUrl,
    AccountNumber,
    CardNumber,
  } = data;

  let src;

  if (PaymentSystemTypeID === 0) {
    src = CurrencyImageUrl ? CurrencyImageUrl : './images/icons/global.svg';
  } else {
    src = (accountIcon[PaymentSystemTypeID] && accountIcon[PaymentSystemTypeID].icon) ?
      accountIcon[PaymentSystemTypeID].icon :
      '';
  }

  return (
    <div className={'select-template-item recipient-template-item'}>
      {src ? (
        <div className={'image-wrapper'}>
          <img src={src} alt={'PaymentSystemTypeName'}/>
        </div>
      ) : null}
      <div className={'item-wrapper'}>
        {CurrencyCode ? (<span className={'item-currency'}>{CurrencyCode}</span>) : null}
        <span>{PaymentSystemTypeID === 11 ? CardNumber : AccountNumber}</span>
      </div>
    </div>
  );
};

export default RecipientItemTemplate;


