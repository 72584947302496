import React, {useEffect, useRef, useState} from 'react';
import {Popover, Position} from 'devextreme-react/popover';
import {useTranslation} from 'react-i18next';
import {getUserFromStorage} from 'utils/functions';
import {changeActiveScreen} from 'services/settings/actions';
import {useHistory} from 'react-router-dom';
import {apiRequest, logOut} from 'services/async';
import {vars} from 'utils/variables';
import {notifyApp} from 'utils/notifyWrapper';
import './user-panel.scss';

const {SETTINGS_SCREENS, SUCCESS_API_CODE} = vars;

const UserPanel = () => {
  const [userName, setUserName] = useState('');
  const {t} = useTranslation();
  const history = useHistory();
  const menuRef = useRef(null);

  const goToProfile = () => {
    const destinationId = 'settings';
    changeActiveScreen({
      screen: SETTINGS_SCREENS.PROFILE,
    });
    history.push(`/${destinationId}`);
  };

  const [userMenuItems, setUserMenuItems] = useState([]);

  useEffect(() => {
    setUserName(getUserFromStorage());

    loadUserProfile().then((userProfile) => {
      const {FAQUrl, AboutUsUrl, GtmTermsUrl, FlexTermsUrl, McDebitTermsUrl, VisaCardTermsUrl} = userProfile;

      setUserMenuItems([
          {
            text: 'COMMON.ABOUT',
            href: AboutUsUrl,
          },
          {
            text: 'COMMON.FAQ',
            href: FAQUrl,
          },
          {
            text: 'COMMON.TERMS_GTM',
            href: GtmTermsUrl,
          },
          {
            text: 'COMMON.TERMS_FLEX',
            href: FlexTermsUrl,
          },
          {
            text: 'COMMON.TERMS_MC',
            href: McDebitTermsUrl,
          },
          {
            text: 'COMMON.VISA_TERMS',
            href: VisaCardTermsUrl,
          },
        ]
      );
    }).catch((error) => {
      notifyApp(error.message);
    });
  }, []);

  const loadUserProfile = async () => {
    const objectName = 'UserProfile';
    const request = await apiRequest({
        operation: `${objectName}/List`,
      }
    );

    if (request.data.ResponseCode === SUCCESS_API_CODE) {
      return request.data.Response[objectName][0];
    } else {
      throw new Error(request.data.ResponseText);
    }
  }

  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <div className={'user-name'}>
          {userName}
        </div>
        <i className={'user-name-icon mdi mdi-menu-down'}/>
      </div>

      <Popover
        ref={menuRef}
        target={'.user-button'}
        showEvent={'dxclick'}
        closeOnOutsideClick={true}
        container={'.header-menu-wrapper'}
      >
        <Position
          offset={'-6 -8'}
          my={'top right'}
          at={'bottom right'}
        />

        <div className={'user-panel-menu-item border-bottom'}>
          <div className={'user-panel-item-wrapper'}>
            <i className={'user-panel-item-icon dx-icon dx-icon-user'}/>
            <span className={'user-panel-item item-with-icon'} onClick={() => {
              menuRef.current.instance.hide();
              goToProfile();
            }}>
								{t('TOPBAR.USER_PANEL.PROFILE')}
							</span>
          </div>
        </div>

        <div>
          {userMenuItems.map(({text, href}, key) => {
            return (
              <div
                key={`user-panel-menu-item-${key}`}
                className={'user-panel-menu-item'}
              >
                <div
                  className={'user-panel-item-wrapper'}
                  onClick={() => {
                    menuRef.current.instance.hide();
                  }}
                >
                  <a className={'user-panel-item'} href={href} target={'_blank'}>{t(text)}</a>
                </div>
              </div>
            );
          })}
        </div>

        <div className={'user-panel-menu-item border-top'}>
          <div className={'user-panel-item-wrapper'}>
            <i className={'user-panel-item-icon dx-icon dx-icon-runner'}/>
            <span className={'user-panel-item item-with-icon'} onClick={logOut}>
								{t('TOPBAR.USER_PANEL.LOGOUT')}
							</span>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default UserPanel;
