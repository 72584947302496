import React from 'react';
import {useTranslation} from "react-i18next";
import {vars} from 'utils/variables';

const {
	MAIN: {
		SET_PIN_ID: {
			NOT_REQUESTED,
			SUCCESS,
			FAILED,
		}
	}
} = vars;


const CardActivationSuccess = (props) => {
	const {t} = useTranslation();
	const {options: {setPinResultID, text}} = props;
	let additionalText = '';

	const getText = (setPinResultID, text) => {
		let result = '';

		switch (setPinResultID) {
			case NOT_REQUESTED:
				result = 'Pin-код не установлен. Установить его можно позже в настройках';
				break;
			case SUCCESS:
				result = 'Для активации Pin-кода необходимо выполнить проверку баланса в банкомате';
				break;
			case FAILED:
				result = `Ошибка установки Pin-кода: "${text}"`;
				additionalText = 'Повторить попытку установки можно в настройках';
				break;
			default:
				result = '';
		}

		return result;
	};

	return (
		<div style={{'text-align': 'center'}} className={'activation-text'}>
			<p>{t('MAIN.CARD_ACTIVATION.SUCCESS')}</p>
			<p>{t('MAIN.CARD_ACTIVATION.SUCCESS_CONDITION')}</p>
			<p>{getText(setPinResultID, text)}</p>
			<p>{additionalText}</p>
		</div>
	);
};

export default CardActivationSuccess;
