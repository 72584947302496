export const category = [
	{
		icon: 'mdi mdi-account-circle-outline',
		component: 'Profile',
	},
	{
		icon: 'mdi mdi-bank',
		component: 'LegalInformation',
	},
/*
	{
		icon: 'mdi mdi-bell-outline',
		component: 'Notifications',
	},
*/

	{
		icon: 'mdi mdi-shield-check-outline',
		component: 'Security',
	},

/*
	{
		icon: 'mdi mdi-cart-plus',
		component: 'SciApi',
	},
*/

/*
	{
		icon: 'mdi mdi-key-outline',
		component: 'Connections',
	},
*/

	{
		icon: 'mdi mdi-folder-account-outline',
		component: 'Verification',
	},

	{
		icon: 'mdi mdi-account-multiple',
		component: 'Invite',
	},

];
