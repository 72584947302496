import React from 'react';
import {getGroupIcon} from 'utils/functions';

const ItemTemplate = ({data}) => {
	if (!data) {
		return null;
	}

	const {
		ForeignPaymentSystemTypeImageUrl,
		ForeignPaymentSystemTypeGroupID,
		ForeignPaymentSystemTypeName,
	} = data;

	return (
		<div className={'select-template-item'}>
			<div className={'image-wrapper'}>
				{ForeignPaymentSystemTypeImageUrl ? (
					<img src={ForeignPaymentSystemTypeImageUrl} alt={'icon'}/>
				): (
					<i className={getGroupIcon(ForeignPaymentSystemTypeGroupID)}></i>
				)}
			</div>
			<div className={'item-wrapper'}>
				{ForeignPaymentSystemTypeName}
			</div>
		</div>
	);
};

export default ItemTemplate;


