import React from 'react';
import {SelectBox, TextBox, Validator} from 'devextreme-react';
import {specialPaymentMethodValueIds, vars} from 'utils/variables';
import {PatternRule} from 'devextreme-react/validator';
import classNames from 'classnames';
import propTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const {REG_EXP, OPERATION: {INTERNAL_TRANSFERS_CODE}} = vars;

const CurrencySelectBox = props => {
  const {
    items,
    fromCurrency,
    toCurrency,
    toCurrencyAmount,
    fee: {ToCurrencyAmount, CurrencyRate},
    fieldDisabled = false,
    currencyHandler,
    onValueChanged,
    paymentMethodValueId,
    selectedMethod,
  } = props;

  const {t} = useTranslation();

  const selectBoxClass = {
    "operation-selectbox-currency": true,
    "dx-state-readonly": fieldDisabled
  };
  const toCurrencyLocal = toCurrency ? toCurrency : {
    Code: 'EUR',
    CurrencyTypeID: 1,
    ID: 978,
    Symbol: '€',
  }

  const foreignCurrencySymbol = toCurrencyLocal.Symbol;
  const currencyRate = typeof CurrencyRate === 'number' && CurrencyRate.toFixed(5);

  return (
    <div className={classNames(selectBoxClass)}>
      <div className={'operation-selectbox_input'}>
        <div className={'operation-selectbox_left operation-selectbox_left__read_only'}>
          <div className={'operation-total-amount currency-read-only'}>
            {`${foreignCurrencySymbol}`}
            <TextBox
              readOnly
              width={'100%'}
              height={40}
              className={'operation-total-amount-input'}
              name={'toCurrencyAmount'}
              value={toCurrencyAmount ? toCurrencyAmount : ToCurrencyAmount}
              onValueChanged={onValueChanged}
            >
              <Validator validationGroup={'gr1'}>
                <PatternRule message={t('ERROR_MSG.WRONG_AMOUNT')} pattern={REG_EXP.AMOUNT}/>
              </Validator>
            </TextBox>
          </div>
          {fromCurrency.Symbol === toCurrencyLocal.Symbol ? null : currencyRate > 0 ? (
            <div className={'operation-fee-detail'}>
              {`${fromCurrency.Symbol} 1.00 = ${foreignCurrencySymbol} ${currencyRate}`}
            </div>
          ) : null}
        </div>
      </div>
      {(items && items.length > 0 && !specialPaymentMethodValueIds.includes(paymentMethodValueId)) ? (
        <div className={'operation-selectbox-wrapper'}>
				<span className={'operation-selectbox-currency_icon'}>
					{foreignCurrencySymbol}
				</span>
          <SelectBox
            placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.CHOOSER')}
            disabled={selectedMethod.ForeignPaymentSystemTypeID === INTERNAL_TRANSFERS_CODE}
            className={'operation-selectbox'}
            height={40}
            items={items}
            displayExpr={'Code'}
            valueExpr={'ID'}
            value={toCurrencyLocal.ID}
            onValueChanged={currencyHandler}
          />
        </div>
      ) : null}
    </div>
  );
};

CurrencySelectBox.propTypes = {
  items: propTypes.array,
  toCurrency: propTypes.shape({
    Symbol: propTypes.string,
    ID: propTypes.number,
    Code: propTypes.string,
  }),
  fee: propTypes.shape({
    ToCurrencyAmount: propTypes.number,
    CurrencyRate: propTypes.number,
    TotalAmount: propTypes.number,
    FeeAmount: propTypes.number,
  }),
  fieldDisabled: propTypes.bool,
  currencyDisabled: propTypes.bool,
  currencyHandler: propTypes.func
};

CurrencySelectBox.defaultProps = {
  items: [
    {
      Code: 'EUR',
      ID: 978,
    },
  ],
  currency: {
    Symbol: '€',
    ID: 978,
    Code: 'EUR',
  },
  fee: {
    ToCurrencyAmount: 0,
    CurrencyRate: 0
  },
  fieldDisabled: false,
  currencyDisabled: false,
  currencyHandler: (e) => {
    console.log(e);
  }
};

export default CurrencySelectBox;
