import React from 'react';
import {changePaymentStatus, clearOperation, toggleLoader} from 'services/operations/actions';
import PayoutFields from 'components/operations/payout-fields/payout-fields';
import IncomeFields from 'components/operations/income-fields/income-fields';
import ServiceFields from 'components/operations/service-fields/service-fields';
import CryptoIncomeFieldsHook from 'components/operations/crypto/crypto-income-fields/crypto-income-fields-hook';
import CryptoPayoutFieldsHook from 'components/operations/crypto/crypto-payout-fields/crypto-payout-fields-hook';
import {hideOtpPopup, showOtpPopup} from 'services/app/appActions';
import SwiftFields from '../swift/swift-fields';
import {checkIsCardKindTransaction} from 'utils/functions';
import {vars} from 'utils/variables';
import {connect} from 'react-redux';

const {OPERATION: {CRYPTO_KIND, SWIFT_PAYMENT_TYPE}, MAIN: {ACCOUNTS: {BANXE_ID, SHARPAY_ID}}} = vars;

const getRecipientAccountsList = (accountList, paymentMethodValueId, selectedAccountCode, account) => {
  const res = accountList.filter((item) => {
    const sameCurrency = item.CurrencyID === account.CurrencyID;
    const samePaymentType = item.PaymentSystemTypeID === paymentMethodValueId;
    const notSelectedAccount = item.Code !== selectedAccountCode;

    if (paymentMethodValueId === BANXE_ID) {
      if (account.PaymentSystemTypeID === SHARPAY_ID) {
        return samePaymentType && notSelectedAccount;
      } else {
        return sameCurrency && samePaymentType && notSelectedAccount;
      }
    } else {
      return samePaymentType && notSelectedAccount;
    }
  });
  return res;
}

const Fields = props => {
  const {PAYMENT} = vars;
  const {
    selectedAccountCode,
    accountList,
    paymentMethodValueId,
    paymentMethodValueName,
    paymentTypeId,
    account,
    clearOperation,
    blockRightForm,
    changePaymentStatus,
    isBlockedRightForm,
    toggleLoader,
    isShowLoader,
    selectedMethod,
    showOtpPopup,
    hideOtpPopup,
  } = props;

  const {ForeignPaymentSystemTypeID, ForeignPaymentSystemTypeKindID} = selectedMethod;

  if (ForeignPaymentSystemTypeKindID === CRYPTO_KIND) {
    if (paymentTypeId === PAYMENT.PAYMENT_TYPE_PAYOUT) {
      return <CryptoPayoutFieldsHook
        account={account}
        showOtpPopup={showOtpPopup}
        hideOtpPopup={hideOtpPopup}
        paymentMethodValueId={paymentMethodValueId}
        paymentMethodValueName={paymentMethodValueName}
        clickCancel={clearOperation}
        blockRightForm={blockRightForm}
        isBlockedRightForm={isBlockedRightForm}
        changePaymentStatus={changePaymentStatus}
        toggleLoader={toggleLoader}
        isShowLoader={isShowLoader}
      />;
    } else if (paymentTypeId === PAYMENT.PAYMENT_TYPE_INCOME) {
      return <CryptoIncomeFieldsHook
        account={account}
        paymentMethodValueId={paymentMethodValueId}
        paymentMethodValueName={paymentMethodValueName}
        clickCancel={clearOperation}
        toggleLoader={toggleLoader}
        isShowLoader={isShowLoader}
      />;
    }
  } else if (ForeignPaymentSystemTypeID === SWIFT_PAYMENT_TYPE && paymentTypeId === PAYMENT.PAYMENT_TYPE_PAYOUT) {
    return (
      <SwiftFields
        account={account}
        showOtpPopup={showOtpPopup}
        hideOtpPopup={hideOtpPopup}
        paymentMethodValueId={paymentMethodValueId}
        paymentMethodValueName={paymentMethodValueName}
        clickCancel={clearOperation}
        isBlockedRightForm={isBlockedRightForm}
        changePaymentStatus={changePaymentStatus}
        isShowLoader={isShowLoader}
      />
    );
  }

  switch (paymentTypeId) {
    case PAYMENT.PAYMENT_TYPE_PAYOUT:
      return (
        <PayoutFields
          account={account}
          recipientAccountsList={getRecipientAccountsList(accountList, paymentMethodValueId, selectedAccountCode, account)}
          showOtpPopup={showOtpPopup}
          hideOtpPopup={hideOtpPopup}
          paymentMethodValueId={paymentMethodValueId}
          paymentMethodValueName={paymentMethodValueName}
          clickCancel={clearOperation}
          isBlockedRightForm={isBlockedRightForm}
          changePaymentStatus={changePaymentStatus}
          isShowLoader={isShowLoader}
          selectedMethod={selectedMethod}
          isCardKindTransaction={checkIsCardKindTransaction(selectedMethod)}
        />
      );
    case PAYMENT.PAYMENT_TYPE_INCOME:
      return (
        <IncomeFields
          selectedMethod={selectedMethod}
          account={account}
          paymentMethodValueId={paymentMethodValueId}
          paymentMethodValueName={paymentMethodValueName}
          clickCancel={clearOperation}
          toggleLoader={toggleLoader}
          isShowLoader={isShowLoader}
          changePaymentStatus={changePaymentStatus}
        />
      );
    case PAYMENT.PAYMENT_SERVICE:
      return (
        <ServiceFields
          selectedMethod={selectedMethod}
          account={account}
          showOtpPopup={showOtpPopup}
          hideOtpPopup={hideOtpPopup}
          paymentMethodValueId={paymentMethodValueId}
          paymentMethodValueName={paymentMethodValueName}
          clickCancel={clearOperation}
          toggleLoader={toggleLoader}
          isShowLoader={isShowLoader}
          changePaymentStatus={changePaymentStatus}
        />
      );
    default:
      return (
        <div>Unknown Payment Type</div>
      );
  }
};

const mapStateToProps = state => {
  return {
    paymentTypeId: state.operations.paymentTypeId,
    account: state.operations.account,
    isBlockedRightForm: state.operations.isBlockedRightForm,
    isShowLoader: state.operations.isShowLoader,
  }
};

const mapDispatchToProps = (dispatch) => ({
  clearOperation: () => dispatch(clearOperation()),
  toggleLoader: (payload) => dispatch(toggleLoader(payload)),
  changePaymentStatus: (payload) => dispatch(changePaymentStatus(payload)),
  showOtpPopup: (payload) => dispatch(showOtpPopup(payload)),
  hideOtpPopup: () => dispatch(hideOtpPopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fields);
