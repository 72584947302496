export const getSharpayPayments = (accountID) => ({
	key: 'ID',
	object: 'Payment',
	defFilter: {
		'AccountID': accountID,
		'||': [
			{'PaymentTypeID': {'!=': 1}},
			{'PaymentStatusID': {'!=': 1}}
		]
	},
	fieldsSettings: {
		InsDate:  {type: 'dateTime'},
		ID:  {type: 'number'},
		ForeignAccountCode:  {type: 'string'},
		PaymentTypeName:  {type: 'string'},
		PaymentSystemTypeName:  {type: 'string'},
		Description:  {type: 'string'},
		PaymentStatusName:  {type: 'string'},
		Amount:  {type: 'number'},
		FeeAmount:  {type: 'number'},
		ForeignAmount:  {type: 'number'},
	},
	modifier: (result) => {
		let newArr = [];
		result.forEach((item) => {
			newArr.push(Object.assign({}, exportCommonPaymentOrder, item));
		});

		return newArr;
	},
});

export const getWallettoPayments = (accountID) => ({
	key: 'ID',
	object: 'WallettoPayment',
	defFilter: {
		'AccountID': accountID,
	},
	sorts: [
		'-ExternalCreateDate'
	],
	fieldsSettings: {
		ExternalCreateDate:  {type: 'dateTime'},
		ID:  {type: 'number'},
	},
	modifier: (result) => {
		let newArr = [];
		result.forEach((item) => {
			newArr.push(Object.assign({}, exportWallettoCommonPaymentOrder, item));
		});

		return newArr;
	},
});

export const getTransactions = (merchantApiProfileID) => ({
	key: 'ID',
	object: 'Transaction',
	fieldsSettings: {
		MerchantOrderID: {type: 'number'},
		Amount: {type: 'number'},
	},
	defFilter: {
		'MerchantApiProfileID': merchantApiProfileID,
    'StatusID': {
      '!=': 1
    },
	},
	sorts: [
		'-SortDate'
	],
  modifier: (result) => {
    let newArr = [];
    result.forEach((item, index) => {
      item.ID = index;
      newArr.push(item);
    });

    return newArr;
  },
});

export const exportCommonPaymentOrder = {
	ID: 'MAIN.PAYMENT_HISTORY_TABLE.COLUMNS.ID',
	InsDate: 'MAIN.PAYMENT_HISTORY_TABLE.COLUMNS.INS_DATE',
  ApproveDate: 'MAIN.PAYMENT_HISTORY_TABLE.COLUMNS.APPROVE_DATE',
	PaymentTypeName: 'MAIN.PAYMENT_HISTORY_TABLE.COLUMNS.PAYMENT_TYPE_NAME',
	ViewDescription: 'MAIN.PAYMENT_HISTORY_TABLE.COLUMNS.VIEW_DESCRIPTION',
	Amount: 'MAIN.PAYMENT_HISTORY_TABLE.COLUMNS.AMOUNT',
	CurrencyCode: 'MAIN.PAYMENT_HISTORY_TABLE.COLUMNS.CURRENCY_CODE',
	PaymentStatusName: 'MAIN.PAYMENT_HISTORY_TABLE.COLUMNS.PAYMENT_STATUS_NAME',
};

export const exportWallettoCommonPaymentOrder = {
	ID: 'MAIN.PAYMENT_HISTORY_TABLE.COLUMNS.ID',
	InsDate: 'MAIN.PAYMENT_HISTORY_TABLE.COLUMNS.INS_DATE',
  ApproveDate: 'MAIN.PAYMENT_HISTORY_TABLE.COLUMNS.APPROVE_DATE',
	PaymentTypeName: 'MAIN.PAYMENT_HISTORY_TABLE.COLUMNS.PAYMENT_TYPE_NAME',
	ViewDescription: 'MAIN.PAYMENT_HISTORY_TABLE.COLUMNS.VIEW_DESCRIPTION',
	Amount: 'MAIN.PAYMENT_HISTORY_TABLE.COLUMNS.AMOUNT',
	AccountCurrencyCode: 'MAIN.PAYMENT_HISTORY_TABLE.COLUMNS.CURRENCY_CODE',
	PaymentStatusName: 'MAIN.PAYMENT_HISTORY_TABLE.COLUMNS.PAYMENT_STATUS_NAME',
};