import React from 'react';
import {getCardLogo} from 'utils/functions';
import TextBox from 'devextreme-react/text-box';
import {useTranslation} from 'react-i18next';

const RecipientMaskItemTemplate = ({data}) => {
  const {t} = useTranslation();

  if (!data) {
    return <TextBox readOnly={true} placeholder={t('COMMON.SELECT_BOX')}/>
  }

  const {
    ForeignAccountCode,
  } = data;

  return (
    <div className={'select-template-item recipient-template-item'}>
      <div className={'image-wrapper'}>
        <img src={getCardLogo(ForeignAccountCode)} alt={'ForeignAccountCode'}/>
      </div>
      <div className={'item-wrapper'}>
        <span className={'recipient-template-item-value'}>{ForeignAccountCode}</span>
      </div>
    </div>
  );
};

export default RecipientMaskItemTemplate;


