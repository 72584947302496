import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import {vars} from 'utils/variables';
import {makeOperation} from 'services/operations/actions';
import {Button} from 'devextreme-react/button';
import CardWidget from 'components/account/card-widget';

const {
  OPERATION,
  PAYMENT,
  MAIN: {WALLETTO_NOT_ACTIVATED_ID},
} = vars;

const NewIbanDetails = ({accountData, makeOperation}) => {
  const {account} = accountData;
  const {t} = useTranslation();
  const history = useHistory();
  const {AccountNumber, WallettoParams, AccountStatusID, NestedList} = account;
  const {BankName, BankSwiftCode, BankAddress} = WallettoParams ?? {};

  const onAccountDetailButtonClick = (type) => {
    const payload = {
      paymentTypeId: type,
      account: account,
    };

    makeOperation(payload);
    history.push(OPERATION.PATH);
  };

  return (
    <div>
      <div className={'tile-acc-wrap'}>
        <div className={'tile-text tile-text-detail'}>
          <div className={'tile-acc-bold tile-acc-label'}>{t('MAIN.ACCOUNT_DETAIL_WIDGET.ACCOUNT_NUMBER')}</div>
          <div className={'tile-acc-num'}>{AccountNumber}</div>
        </div>
      </div>
      {BankName && (
        <div className={'tile-acc-wrap'}>
          <div className={'tile-text tile-text-detail'}>
            <div className={'tile-acc-bold tile-acc-label'}>{t('MAIN.ACCOUNT_DETAIL_WIDGET.BANK')}</div>
            <div className={'tile-acc-num'}>{BankName}</div>
          </div>
        </div>
      )}
      {BankSwiftCode && (
        <div className={'tile-acc-wrap'}>
          <div className={'tile-text tile-text-detail'}>
            <div className={'tile-acc-bold tile-acc-label'}>{t('MAIN.ACCOUNT_DETAIL_WIDGET.SWIFT')}</div>
            <div className={'tile-acc-num'}>{BankSwiftCode}</div>
          </div>
        </div>
      )}
      {BankAddress && (
        <div className={'tile-acc-wrap'}>
          <div className={'tile-text tile-text-detail'}>
            <div className={'tile-acc-bold tile-acc-label'}>{t('MAIN.ACCOUNT_DETAIL_WIDGET.ADDRESS')}</div>
            <div className={'tile-acc-num'}>{BankAddress}</div>
          </div>
        </div>
      )}

      <div className={'card-item-wrapper'}>
        <div className={'card-item-inner'}>
          {NestedList.map((card, index) => {
            return (
              <div key={card.ID}>
                <CardWidget data={card}/>
              </div>);
          })}
        </div>
      </div>

      <div className={'buttons-container'}>
        <Button
          disabled={AccountStatusID === WALLETTO_NOT_ACTIVATED_ID}
          height={60}
          text={t('MAIN.ACCOUNT_DETAIL_WIDGET.PAYOUT')}
          stylingMode={'text'}
          className={'blue'}
          focusStateEnabled={false}
          onClick={() => {
            onAccountDetailButtonClick(PAYMENT.PAYMENT_TYPE_PAYOUT);
          }}
        />
        <div className={'divider'}/>
        <Button
          disabled={AccountStatusID === WALLETTO_NOT_ACTIVATED_ID}
          height={60}
          text={t('MAIN.ACCOUNT_DETAIL_WIDGET.INCOME')}
          stylingMode={'text'}
          className={'green'}
          focusStateEnabled={false}
          onClick={() => {
            onAccountDetailButtonClick(PAYMENT.PAYMENT_TYPE_INCOME);
          }}
        />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  makeOperation: (payload) => dispatch(makeOperation(payload)),
});

export default connect(null, mapDispatchToProps)(NewIbanDetails);