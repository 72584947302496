import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {userProfileGetList} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';

import './registration-plug.scss';

const RegistrationPlug = () => {
	const {t} = useTranslation();
  const [isSharpay] = useState(process.env.REACT_APP_BRAND === 's3');
	const [userProfile, setUserProfile] = useState({
		AppleStoreUrl: '',
		PlayStoreUrl: '',
	});

	const loadUserProfile = async () => {
		const request = await userProfileGetList();

		if (request.isSuccess) {
			return Promise.resolve(request.response.UserProfile[0]);
		} else {
			throw new Error(request.error);
		}
	}

	useEffect(() => {
		loadUserProfile().then((profile) => {
			setUserProfile(profile);
		}).catch((errorText) => {
			notifyApp(errorText);
		});
	}, []);

	return (
		<div className={'mobile-plug-wrapper'}>
			<div>
        {isSharpay ? (
          <img width="120" src="/images/sharpay/SP_80_80_3.svg" alt="site_logo"/>
        ) : null}
			</div>
			<div className={'mobile-plug-text'}>
				{t('COMMON.MOBILE_TEXT')}
			</div>
			<div className={'mobile-plug-app'}>
				<a href={isSharpay ? userProfile.AppleStoreUrl : '#'}>
					<img src='/images/icons/Download_on_the_App_Store_Badge.svg' alt='Download_on_the_App_Store_Badge'/>
				</a>
				<a href={isSharpay ? userProfile.PlayStoreUrl : '#'}>
					<img src='/images/icons/Google_Play_Store_badge_EN.svg' alt='Google_Play_Store_badge'/>
				</a>
			</div>
		</div>
	);
}

export default RegistrationPlug;
