import React, {useState} from 'react';
import {RoundInput} from 'components/inputs/round-input';
import {useTranslation} from "react-i18next";
import {PatternRule, RequiredRule, CompareRule, Validator} from "devextreme-react/validator";
import {creditCardFormat, fromCreditCardFormat} from 'utils/functions';
import {vars} from 'utils/variables';
import {RoundButton} from 'components/buttons/round-button';
import validationEngine from "devextreme/ui/validation_engine";
import {apiRequest} from "../../../../services/async";
import {notifyApp} from "../../../../utils/notifyWrapper";

import './card-activation-fields.scss';

const {
	REG_EXP: {NUMBERS_WITH_SPACE_ONLY},
	SUCCESS_API_CODE,
} = vars;


const CardActivationFields = (props) => {
	const {t} = useTranslation();
	const {cardId, handleClose} = props;
	const [creditCard, setCreditCard] = useState('');
	const [secureCode, setSecureCode] = useState('');

	const secureComparison = () => {
		return secureCode;
	};

	return (
		<div className={'activation-wrapper'}>
			<RoundInput
				name={'card-input'}
				onInput={({event: {target: {value}}}) => {
					setCreditCard(value);
				}}
				placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.ACCOUNT_CARD')}
				text={creditCardFormat(creditCard)}
				height={50}
			>
				<Validator validationGroup={'fields'}>
					<RequiredRule/>
					<PatternRule
						message={t('ERROR_MSG.DIGIT_ONLY')}
						pattern={NUMBERS_WITH_SPACE_ONLY}
					/>
				</Validator>
			</RoundInput>
			<RoundInput
				name={'3d-input'}
				placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.3D_SECURE')}
				text={secureCode}
				onValueChanged={({value}) => {
					setSecureCode(value);
				}}
				height={50}
				mode={'password'}
				isNewPassword={true}
			>
				<Validator validationGroup={'fields'}>
					<RequiredRule/>
				</Validator>
			</RoundInput>
			<RoundInput
				name={'3d-input-confirm'}
				placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.3D_SECURE_CONFIRM')}
				height={50}
				mode={'password'}
			>
				<Validator validationGroup={'fields'}>
					<CompareRule
						message="3D secure values do not match"
						comparisonTarget={secureComparison}
					/>
				</Validator>
			</RoundInput>
			<div className={'activation-buttons'}>
				<RoundButton
					name={'activate-card-button'}
					text={t('MAIN.ACCOUNT_DETAIL_WIDGET.ACTIVATE_BUTTON')}
					icon={null}
					onClick={async () => {
            const result = validationEngine.validateGroup('fields');
						if (result.isValid) {
							const response = await apiRequest({
								operation: 'WallettoCard/Activate',
								data: {
									Params: {
                    WallettoCardID: cardId,
										CardNumber: fromCreditCardFormat(creditCard),	//	String	+	Номер карты
										StaticPassword: secureCode,	//	String	+	Пароль для 3D Secure
									}
								}
							});

							if (response.data.ResponseCode === SUCCESS_API_CODE) {
								let setPinResultID;
								let text = '';

								handleClose({
									setPinResultID: setPinResultID,
									text: text
								});
							} else {
								notifyApp(response.data.ResponseText);
							}
						}
					}}
					customClass={'blue size14'}
					width={250}
				/>
			</div>
		</div>
	);
};

export default CardActivationFields;
