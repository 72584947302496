import React from 'react';
import {TextBox} from 'devextreme-react';
import '../settings.scss';
import './security.scss';
import Validator, {CompareRule, PatternRule, RequiredRule} from 'devextreme-react/validator';
import validationEngine from 'devextreme/ui/validation_engine'
import {userChangePassword} from 'services/async';
import {hideOtpPopup, showOtpPopup} from 'services/app/appActions';
import {connect} from 'react-redux';
import {vars} from 'utils/variables';
import {notifyApp} from 'utils/notifyWrapper';
import {RoundButton} from 'components/buttons/round-button';
import {sha512} from 'utils/functions';
import {withTranslation} from 'react-i18next';

const initialState = {
	currentPassword: null,
	newPassword: null,
	confirmPassword: null,
};

class Security extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
	}

	onPasswordEnterHandler = (button, {component}) => {
		this.setState({
			[button]: component.option('value'),
		}, () => {
			this.saveNewPassword();
		});
	};

	onCurrentPasswordChange = ({value}) => {
		this.setState({
			currentPassword: value,
		});
	};

	onNewPasswordChange = ({value}) => {
		this.setState({
			newPassword: value,
		});
	};

	onConfirmPasswordChange = ({value}) => {
		this.setState({
			confirmPassword: value,
		});
	};

	passwordComparison = () => {
		return this.state.newPassword;
	};

	saveNewPassword = async () => {
		const {NOTIFY} = vars;
		const result = validationEngine.validateGroup('userPasswordGroup');
		const {currentPassword, newPassword} = this.state;

		if (result.isValid) {
			const hashedCurrentPassword = await sha512(currentPassword);
			const hashedNewPassword = await sha512(newPassword);

			if (hashedCurrentPassword && hashedNewPassword) {
				await this.execChangeOperation(hashedCurrentPassword, hashedNewPassword);
			} else {
				notifyApp('Client WebAPI error', NOTIFY.ERROR);
			}
		}
	};

	clearForm = () => {
		this.setState(initialState);
	}

	async execChangeOperation(currentPassword, newPassword) {
		const {t, showOtpPopup, hideOtpPopup} = this.props;
		const {NOTIFY} = vars;
		const request = await userChangePassword({
			CurrentPassword: currentPassword,
			NewPassword: newPassword,
		});

		if (request.isSuccess) {
			showOtpPopup({
				onResend: async () => {
					const resendRequest = await userChangePassword({
						CurrentPassword: currentPassword,
						NewPassword: newPassword,
					});

					if (resendRequest.isSuccess) {
						notifyApp(t('COMMON.SENT'), NOTIFY.SUCCESS);
					} else {
						notifyApp(resendRequest.error);
					}
				},
				onSubmit: async (otpCode) => {
					const confirmRequest = await userChangePassword({
						CurrentPassword: currentPassword,
						NewPassword: newPassword,
						OtpCode: otpCode,
					});

					if (confirmRequest.isSuccess) {
						await this.clearForm();
						validationEngine.resetGroup('userPasswordGroup');
						notifyApp(t('PASSWORD_RECOVERY_CHANGE_SUCCESS'), NOTIFY.SUCCESS);
						hideOtpPopup();
					} else {
						notifyApp(confirmRequest.error);
					}
				},
				sendChannelId: request.response.SendChannelID,
				infoText: request.response.InfoText,
			});
		} else {
			notifyApp(request.error, NOTIFY.ERROR);
		}
	}

	render() {
		const {t} = this.props;
		const {currentPassword, newPassword, confirmPassword} = this.state;
		const {REG_EXP} = vars;

		return (
			<div className={'settings-wrapper'}>
				<div className={'settings-header'}>
					<div className={'settings-header_inner'}>
						<div className={'settings-header_text'}>
							<i className={'mdi mdi-shield-check-outline'}/>
							<span>{t('SETTINGS.LEFT_BLOCK.SECURITY.TITLE')}</span>
						</div>
					</div>
				</div>
				<div className="settings-splitter"/>
				<div className={'settings-fields'}>
					<div className={'settings-fields_column'}>
						<span
							className={'settings-section_name security-section_name '}>{t('SETTINGS.RIGHT_BLOCK.SECURITY.CHANGE_PASSWORD')}</span>
						<div className={'settings-section_inputs security-input'}>
							<div className={'settings-section_edit'}>
								<span
									className={'settings-section_label settings-section_placeholder'}>{t('SETTINGS.RIGHT_BLOCK.SECURITY.CURRENT_PASSWORD')}</span>
								<TextBox
									value={currentPassword}
									tabIndex={1}
									onValueChanged={this.onCurrentPasswordChange}
									onEnterKey={this.onPasswordEnterHandler.bind(this, 'currentPassword')}
									mode={'password'}
								>
									<Validator validationGroup={'userPasswordGroup'}>
										<PatternRule
											message={t('ERROR_MSG.WRONG_SYMBOLS')}
											pattern={REG_EXP.PASSWORD_PATTERN}
										/>
										<RequiredRule message={'Current password field is required'}/>
									</Validator>
								</TextBox>
							</div>
							<div className={'settings-section_edit'}>
								<span
									className={'settings-section_label settings-section_placeholder'}>{t('SETTINGS.RIGHT_BLOCK.SECURITY.NEW_PASSWORD')}</span>
								<TextBox
									tabIndex={1}
									value={newPassword}
									onValueChanged={this.onNewPasswordChange}
									onEnterKey={this.onPasswordEnterHandler.bind(this, 'newPassword')}
									mode={'password'}
								>
									<Validator validationGroup={'userPasswordGroup'}>
										<PatternRule
											message={t('ERROR_MSG.WRONG_SYMBOLS')}
											pattern={REG_EXP.PASSWORD_PATTERN}
										/>
										<RequiredRule message={'new password field is required'}/>
										<CompareRule message="New password and Confirm Password do not match"
																 comparisonTarget={this.passwordComparison}/>
									</Validator>
								</TextBox>
							</div>
							<div className={'settings-section_edit'}>
								<span
									className={'settings-section_label settings-section_placeholder'}>{t('SETTINGS.RIGHT_BLOCK.SECURITY.CONFIRM_PASSWORD')}</span>
								<TextBox
									tabIndex={1}
									value={confirmPassword}
									onValueChanged={this.onConfirmPasswordChange}
									onEnterKey={this.onPasswordEnterHandler.bind(this, 'confirmPassword')}
									mode={'password'}
								>
									<Validator validationGroup={'userPasswordGroup'}>
										<RequiredRule message={'Confirm Password is required'}/>
										<CompareRule message="Password and Confirm Password do not match"
																 comparisonTarget={this.passwordComparison}/>
									</Validator>
								</TextBox>
							</div>
							<div>
								<RoundButton
									text={t('SETTINGS.RIGHT_BLOCK.SECURITY.SAVE')}
									icon="mdi mdi-check-circle-outline"
									onClick={this.saveNewPassword}
									customClass={'green'}
								/>
							</div>
						</div>
					</div>
					<div className={'settings-fields_column'}/>
					{/*<div className={'settings-fields_column'}>
						<div className={'settings-fields_column_wrapper'}>
							<RoundButton
								text={t('SETTINGS.RIGHT_BLOCK.SECURITY.SAVE')}
								icon="mdi mdi-check-circle-outline"
								onClick={this.saveNewPassword}
								customClass={'green'}
							/>
						</div>

					</div>*/}
				</div>
				{/*<div className="settings-splitter"/>*/}
				{/*{userProfile ? (
					<ConfirmationMethod activeMethod={userProfile.PayoutConfirmType} updateActiveMethod={this.updateActiveMethod}/>
				) : null}*/}
			</div>
		);
	}
}

const WithTranslationSecurity = withTranslation()(Security);

const mapDispatchToProps = (dispatch) => {
	return {
		showOtpPopup: (payload) => dispatch(showOtpPopup(payload)),
		hideOtpPopup: () => dispatch(hideOtpPopup()),
	}
}

export default connect(null, mapDispatchToProps)(WithTranslationSecurity);
