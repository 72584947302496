import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {HashRouter} from 'react-router-dom';
import InitLoader from './components/loader/init-loader';
import App from './App';
import store from './store';
import './services/i18n';
import './polyfills';
import './index.css';

const APP_HASH = process.env.REACT_APP_HASH;

if (typeof localStorage.APP_HASH === 'undefined' || localStorage.APP_HASH === null) {
	localStorage.setItem('APP_HASH', APP_HASH);
}

if (localStorage.APP_HASH !== APP_HASH) {
	localStorage.clear();
}

ReactDOM.render(
	<React.Suspense fallback={<InitLoader/>}>
		<HashRouter>
			<Provider store={store}>
				<App/>
			</Provider>
		</HashRouter>
	</React.Suspense>,
	document.getElementById('root')
);

