import React from 'react';
import {Button, TextBox} from 'devextreme-react';
import Validator, {RequiredRule} from 'devextreme-react/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import {apiRequest, getApplicationSettings, logInOtpRequest, logInRequest} from 'services/async';
import {Link} from 'react-router-dom';
import {notifyApp} from 'utils/notifyWrapper';
import RecoveryPopupComponent from 'components/popup/recovery-popup';
import PasswordRecovery from 'components/popup/login/passwordRecovery';
import {vars} from 'utils/variables';
import {sha512} from 'utils/functions';
import {withTranslation} from 'react-i18next';
import Loader from 'components/loader/loader';
import OtpInput from 'components/inputs/otp-input/otp-input';

const {SUCCESS_API_CODE, NOTIFY} = vars;

class Login extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			login: '',
			password: '',
			otp: '',
			isOtpValid: true,
			isOtp: false,
			isShowLoader: false,
			visible: false,
			optResendIsLoad: false,
		};
	}

	disableOTP = () => {
		this.setState({
			otp: '',
			optResendIsLoad: true,
		}, this.resendOTP);
	};

	resendOTP = async () => {
		const {t} = this.props;
		const message = t('COMMON.SENT');
		notifyApp(t('COMMON.RESEND_OTP_NOTIFY'), NOTIFY.SUCCESS);
		const resendOtp = await apiRequest({
			operation: 'Login/ResendOTP',
			data: {}
		});
		const {data} = resendOtp;

		if (data && data.ResponseCode === SUCCESS_API_CODE) {
			notifyApp(message, NOTIFY.SUCCESS);
		} else {
			notifyApp(data.ResponseText);
		}

		setTimeout(() => {
			this.setState({
				optResendIsLoad: false,
			});
		}, 3000);
	};

	loginOtpFields = (otp) => {
		const {t} = this.props;
		const {optResendIsLoad, isOtpValid} = this.state;

		return (
			<>
				<div className={'dx-field'}>
					<OtpInput
						withFocusedState
						value={otp}
						placeholder={t('COMMON.OTP_OR_SMS')}
						onValueChanged={this.onOtpChanged}
						onEnterKey={this.validateFields}
						isValid={isOtpValid}
						validationMessage={t('ERROR_MSG.REQUIRED')}
						resendCallback={this.disableOTP}
						resendText={t('COMMON.RESEND_OTP')}
						isDisabledResend={optResendIsLoad}
					/>
				</div>
				<div className={'dx-field custom-login'}>
					<Button
						elementAttr={{name: 'login-otp-submit'}}
						type={'default'}
						text={t('COMMON.SEND')}
						onClick={this.validateFields}
						width={'100%'}
					/>
				</div>
			</>
		);
	};

	handleClose = () => {
		this.setState({
			visible: false,
		});
	};

	handleClick = (e) => {
		e.preventDefault();
		this.setState({
			visible: true,
		});
	};

	setSendChannelID = (channelId) => {
		this.setState({
			sendChannelID: channelId,
		});
	}

	loginFields = (password) => {
		const {t} = this.props;
		const {sendChannelID} = this.state;

		return (
			<>
				<div className={'dx-field'}>
					<i className={'mdi mdi-lock-outline login-page-icon'}/>
					<TextBox
						name={'login-password'}
						height={50}
						mode={'password'}
						value={password}
						onValueChanged={this.onPasswordChanged}
						placeholder={t('COMMON.LOGIN_PASS_PLACEHOLDER')}
						width={'100%'}
						onEnterKey={this.validateFields}
					>
						<Validator validationGroup={'fieldsGroup'}>
							<RequiredRule message={'Password is required'}/>
						</Validator>
					</TextBox>
				</div>
				<div className={'dx-field forgot-pass-link'}>
					<Link
						name={'login-recovery-link'}
						to={'/recovery'}
						onClick={this.handleClick}
					>
						{t('COMMON.FORGOT_PASSWORD')}
					</Link>
				</div>
				<div className={'dx-field custom-login'}>
					<Button
						elementAttr={{name: 'login-submit'}}
						type={'default'}
						text={t("COMMON.LOGIN")}
						onClick={this.validateFields}
						width={'100%'}
					/>
				</div>
				<RecoveryPopupComponent
					visible={this.state.visible}
					container={document.getElementById('login-page')}
					title={t('PASSWORD_RECOVERY_TITLE')}
					maxWidth={650}
					handleClose={this.handleClose}
					channelId={sendChannelID}
				>
					<PasswordRecovery
						handleClose={this.handleClose}
						clearState={!this.state.visible}
						setSendChannelID={this.setSendChannelID}
					/>
				</RecoveryPopupComponent>
			</>
		);
	};

	sendLoginRequest = async () => {
		const {NOTIFY, AUTH_TYPE} = vars;
		const {login, password} = this.state;
		const {setInfo, logIn} = this.props;

		const hashedPassword = await sha512(password);

		if (hashedPassword) {
			const request = await logInRequest(login, hashedPassword);

			if (request.isSuccess) {
				if (parseInt(request.authType) === AUTH_TYPE.SIMPLE) {
					// get settings
					const settingsResponse = await getApplicationSettings();

					if (settingsResponse.isSuccess) {
						window.localStorage.setItem('userTypeID', settingsResponse.userTypeID);
						logIn(settingsResponse.userName, request.response);
					} else {
						notifyApp(settingsResponse.error, NOTIFY.ERROR);
					}
				} else {
					this.setState({
						isOtp: true,
					}, request.infoText ? () => {
						setInfo(request.response);
					} : null);
				}
			} else {
				notifyApp(request.error, NOTIFY.ERROR);
			}
		} else {
			notifyApp('Client WebAPI error', NOTIFY.ERROR);
		}
	};

	async sendLoginOtp() {
		const {NOTIFY} = vars;
		const {otp} = this.state;

		const optResponse = await logInOtpRequest(otp);

		if (optResponse.isSuccess) {
			const settingsResponse = await getApplicationSettings();

			if (settingsResponse.isSuccess) {
				window.localStorage.setItem('userTypeID', settingsResponse.userTypeID);
			} else {
				notifyApp(settingsResponse.error, NOTIFY.ERROR);
			}

			this.props.logIn(settingsResponse.userName);
		} else {
			notifyApp(optResponse.error, NOTIFY.ERROR);
		}
	}

	onLoginChanged = (e) => {
		this.setState({
			login: e.value
		});
	};

	onOtpChanged = ({value}) => {
		this.setState({
			otp: value,
			isOtpValid: !!value,
		});
	};

	onPasswordChanged = (e) => {
		this.setState({
			password: e.value
		});
	};

	validateFields = async ({component}) => {
		const {isOtp, otp, isOtpValid} = this.state;
		const result = validationEngine.validateGroup('fieldsGroup');

		if (result.isValid) {
			component.option('disabled', true);

			try {
				if (isOtp) {
					if (otp && isOtpValid) {
						await this.sendLoginOtp();
					} else {
						this.setState({
							isOtpValid: false,
						});
					}
				} else {
					await this.sendLoginRequest();
				}
			} catch (error) {
				notifyApp(error);
			} finally {
				component.option('disabled', false);
			}
		}
	};

	render() {
		const {login, password, otp, isOtp, isShowLoader} = this.state;
		const {t} = this.props;

		return (
			<>
				{/*<Loader
					isShowLoader={isShowLoader}
				/>*/}
				<div
					id={'login-page-'}
					className={'dx-field'}
				>
					<i className={'mdi mdi-account-circle login-page-icon'}/>
					<TextBox
						name={'login-email-or-mobile'}
						height={50}
						value={login}
						readOnly={isOtp}
						onValueChanged={this.onLoginChanged}
						placeholder={t('COMMON.LOGIN_ACCOUNT_PLACEHOLDER')}
						width={'100%'}
						onEnterKey={this.validateFields}
					>
						<Validator validationGroup={'fieldsGroup'}>
							<RequiredRule message={'Login is required'}/>
						</Validator>
					</TextBox>
				</div>
				{isOtp ? this.loginOtpFields(otp) : this.loginFields(password)}
			</>
		);
	}
}

export default withTranslation()(Login);
