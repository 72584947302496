import React from 'react';
import DataGrid, {Column, Scrolling, Sorting} from 'devextreme-react/data-grid';
import {accountIcon} from 'data/accounts';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {ConvertButton, RechargeButton, ServicesButton} from './button';
import {vars} from 'utils/variables';

const {PAYMENT, ACCOUNT_LOCK_ID, MAIN: {ACCOUNTS}} = vars;

const flexiPayCellTemplate = (cellElement, {
  data: {
    PaymentSystemTypeID,
    PaymentSystemTypeName,
    IsLock,
    CurrencyCode,
    CurrencySymbol,
    CurrencyImageUrl,
    AccountNumber,
    CardNumber,
  }
}) => {
  const lockIcon = IsLock === ACCOUNT_LOCK_ID ?
    `<div class='card-lock'><i class='mdi mdi-lock-outline'></i></div>` :
    '';

  let icon;

  if (PaymentSystemTypeID === 0) {
    icon = `<img src="${CurrencyImageUrl ? CurrencyImageUrl : './images/icons/global.svg'}" alt="${PaymentSystemTypeName}" width="30px"/>`;
  } else {
    icon = (accountIcon[PaymentSystemTypeID] && accountIcon[PaymentSystemTypeID].icon) ?
      `<img src="${accountIcon[PaymentSystemTypeID].icon}" alt="${PaymentSystemTypeName}" width="30px"/>` :
      '';
  }

  cellElement.innerHTML = `
	<div class="oper-payment-type-column">
		${lockIcon}
		${icon}
		<div class="oper-payment-type-column_account">
			<div class="oper-payment-type-column_account__code">${PaymentSystemTypeID === ACCOUNTS.WALLETTO_ID ? CardNumber : AccountNumber}</div>
			<div class="oper-payment-type-column_account__name">
				<div class="name">${PaymentSystemTypeName}</div>
				<div class="account-currency">${CurrencyCode} ${CurrencySymbol}</div>
			</div>
		</div>		
	</div>
	`;
  return cellElement;
};

const onRowPrepared = (e) => {
  e.rowElement.style.height = '70px';
};

const balanceCalCellValue = (rowData) => {
  return `${rowData.Balance.toFixed(rowData.CurrencyViewPrecision)}`;
};

const AccountDataGrid = (props) => {
  const {t} = useTranslation();
  const transfer = t('OPERATIONS_PAGE.LEFT_BLOCK.TRANSFER');
  const replenish = t('OPERATIONS_PAGE.LEFT_BLOCK.REPLENISH');
  const services = t('OPERATIONS_PAGE.LEFT_BLOCK.SERVICES');
  const {accountList, account, paymentTypeId, operationButtonHandler} = props;

  const buttons = [
    {
      cssClass: classNames('operations-grid-button convert-button'),
      hint: transfer,
      text: transfer,
      template: ConvertButton,
      onClick: (e) => {
        operationButtonHandler(PAYMENT.PAYMENT_TYPE_PAYOUT, e.row.data);
      },
    },
    {
      cssClass: 'operations-grid-button recharge-button',
      hint: replenish,
      text: replenish,
      template: RechargeButton,
      onClick: (e) => {
        operationButtonHandler(PAYMENT.PAYMENT_TYPE_INCOME, e.row.data);
      },
    },
    {
      cssClass: 'operations-grid-button services-button',
      hint: services,
      text: services,
      template: ServicesButton,
      onClick: (e) => {
        operationButtonHandler(PAYMENT.PAYMENT_SERVICE, e.row.data);
      },
    },
  ];

  return (
    <DataGrid
      id={'grid-operations'}
      dataSource={accountList}
      hoverStateEnabled={false}
      showBorders={false}
      focusedRowEnabled={false}
      height={'100%'}
      width={'100%'}
      onRowPrepared={onRowPrepared}
    >
      <Sorting mode="none"/>
      <Scrolling mode="infinite"/>
      <Column
        alignment={'center'}
        allowSorting={false}
        caption={t('OPERATIONS_PAGE.LEFT_BLOCK.ACCOUNT_NUMBER')}
        //так рендер идет исинхронно и оно не может посчитать ширину правильно
        // cellRender={FlexiPayCell}
        cellTemplate={flexiPayCellTemplate}
        //width={235}
        hidingPriority={2}
        // headerCellRender={this.operationGroupDropdown}
      />
      {/*<Column
				alignment={'center'}
				allowSorting={false}
				dataField={'Code'}
				caption={t('OPERATIONS_PAGE.LEFT_BLOCK.ACCOUNT_NUMBER')}
				hidingPriority={4}
			/>*/}
      <Column
        alignment={'right'}
        allowSorting={false}
        dataField={'Balance'}
        caption={t('OPERATIONS_PAGE.LEFT_BLOCK.BALANCE')}
        hidingPriority={1}
        width={120}
        calculateCellValue={balanceCalCellValue}
      />
      <Column
        width={364}
        alignment={'center'}
        caption={t('OPERATIONS_PAGE.LEFT_BLOCK.TRANSACTION_TYPE')}
        type={'buttons'}
        accountId={account && account.ID}
        paymentTypeId={paymentTypeId}
        buttons={buttons}
        hidingPriority={3}
      />
    </DataGrid>
  );
};

export default AccountDataGrid;
