import {vars} from 'utils/variables';
import {actionTypes} from './actions';

const {SETTINGS_SCREENS} = vars;

const initialState = {
	activeScreen: SETTINGS_SCREENS.PROFILE,
  isBanxe: false,
};

export const settingsReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case actionTypes.CHANGE_ACTIVE_SCREEN:
			return {
				...state,
				activeScreen: payload.screen,
        isBanxe: !!payload.isBanxe,
			};
		default:
			return state;
	}
};
