import React, {useEffect, useState} from 'react';
import Button from 'devextreme-react/button';
import {NavLink, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import UserPanel from '../user-panel/user-panel';
import {SelectBox} from 'devextreme-react';
import ElementWithFlag from './elementWithFlag';
import {ReactSVG} from 'react-svg';
import {languages, vars} from 'utils/variables';

import './new-header.scss';

const {SITE_NAME} = vars;

const Header = (props) => {
	const location = useLocation();
	const {t, i18n} = useTranslation();
	const activePageId = location.pathname.split('/')[1];
	const {needIcons, menuItems} = props;
	const [prevLanguage, setPrevLanguage] = useState(i18n.language.split('-')[0]);
	const [nextLanguage, setNextLanguage] = useState(i18n.language.split('-')[0]);

	useEffect(() => {
		if (prevLanguage !== nextLanguage) {
			i18n.changeLanguage(nextLanguage).then(() => {
				window.location.reload();

				/*const confirmDialog = custom({
					showTitle: false,
					messageHtml: t('DIALOG.RELOAD_PAGE'),
					buttons: [
						{
							text: t('DIALOG.YES'),
							onClick: () => true,
						},
						{
							text: t('DIALOG.NO'),
							onClick: () => false,
						},
					],
				});

				confirmDialog.show().then((dialogResult) => {
					if (dialogResult) {
						window.location.reload();
					}
				});*/
			}).catch((error) => {
				console.error(error);
			}).finally(() => {
				setPrevLanguage(nextLanguage);
			});
		}
	}, [nextLanguage]);

  console.warn('test');

	return (
		<header className={'header-component'}>
			<div className={'header-menu-wrapper'}>
				<div className={'box left-content'}>
					{process.env.REACT_APP_BRAND === 's3' ? (
						<a
							className={'top-logo-wrapper'}
							href="/"
						>
							<ReactSVG
								src={'./images/Logo-Horisontal.svg'}
								beforeInjection={svg => {
									svg.classList.add('svg-class-name');
									svg.setAttribute('style', 'width: 80px');
								}}
								fallback={() => <span>{SITE_NAME}</span>}
								renumerateIRIElements={false}
								wrapper="span"
								className="wrapper-class-name"
							/>
						</a>
					) : null}
				</div>
				<div className={'box center-content'}>
					<div className={'top-menu-wrapper'}>
						{menuItems.map((item) => {
							return (
								<NavLink
									key={item.id}
									id={item.id}
									activeClassName={item.id === activePageId ? 'selected' : ''}
									to={item.url}
								>
									{needIcons ? (<i/>) : t(`TOPBAR.MENU.${item.translationKey}`)}
								</NavLink>
							);
						})}
					</div>
				</div>
				<div className={'box language-content'}>
					<SelectBox
						placeholder={t('MAIN.ACCOUNT_DETAIL_WIDGET.CHOOSER')}
						dataSource={languages}
						displayExpr={'name'}
						valueExpr={'name'}
						value={nextLanguage}
						width={86}
						height={30}
						onValueChanged={({value}) => {
							setNextLanguage(value);
						}}
						itemRender={ElementWithFlag}
						fieldRender={ElementWithFlag}
					/>
				</div>
				<div className={'box right-content'}>
					<Button
						className={'user-button authorization'}
						focusStateEnabled={false}
						activeStateEnabled={false}
						hoverStateEnabled={false}
						stylingMode={'text'}
					>
						<UserPanel/>
					</Button>
				</div>
			</div>
		</header>
	);
};

export default Header;
