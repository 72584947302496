import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from 'devextreme-react/button';

const NewWallettoDetails = ({accountData}) => {
  const {t} = useTranslation();

  return (
    <div>
      <div className={'tile-acc-wrap'}>
        <div className={'tile-text tile-text-detail'}>
          <div className={'tile-acc-bold tile-acc-label'}>{t('MAIN.ACCOUNT_DETAIL_WIDGET.ACCOUNT_CARD')}</div>
          <div className={'tile-acc-num'}>{accountData.cardNumber}</div>
        </div>
      </div>
      <div className={'walletto-card-wrap'}>
        <div className={'walletto-card-item'}>
          <div className={'tile-acc-bold tile-acc-label'}>{t('MAIN.ACCOUNT_DETAIL_WIDGET.EXPIRY')}</div>
          <div className={'tile-acc-num'}>{accountData.expiry}</div>
        </div>
        <div className={'walletto-card-item'}>
          <div className={'tile-acc-bold tile-acc-label'}>CVV</div>
          <div className={'tile-acc-num'}>***</div>
        </div>
      </div>
      <div className={'buttons-container'}>
        <Button
          disabled={true}
          height={60}
          text={t('MAIN.ACCOUNT_DETAIL_WIDGET.PAYOUT')}
          stylingMode={'text'}
          className={'blue'}
          focusStateEnabled={false}
        />
        <div className="divider"/>
        <Button
          disabled={true}
          height={60}
          text={t('MAIN.ACCOUNT_DETAIL_WIDGET.INCOME')}
          stylingMode={'text'}
          className={'green'}
          focusStateEnabled={false}
        />
      </div>
    </div>
  );
}

export default NewWallettoDetails;