import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {category} from 'data/settings-category';
import CategoryList from 'components/settings/category-list';
import ScreenMaker from 'components/settings/screen-maker';
import {activeScreenSelector, isBanxe} from 'services/settings/selectors';
import {vars} from 'utils/variables';
import {changeActiveScreen} from 'services/settings/actions';
import './settings.scss';

const {SETTINGS_SCREENS} = vars;

class Settings extends React.PureComponent {

  componentDidMount() {
    // load category
  }

  componentWillUnmount() {
    this.props.changeActiveScreen({
      screen: SETTINGS_SCREENS.PROFILE,
    });
  }

  onItemClick = (component) => {
    this.props.changeActiveScreen({
      screen: component,
    });
  };

  render() {
    const {activeScreen, isBanxe} = this.props;

    return (
      <div
        id={'settings-wrapper'}
        className={'animated-page'}
      >
        <div
          className={'settings-block'}
          id={'settings-category'}
        >
          <CategoryList
            active={activeScreen}
            category={category}
            onItemClick={this.onItemClick}
          />
        </div>
        <div
          className={'settings-block'}
          id={'settings-fields'}
        >
          <ScreenMaker
            activeScreen={activeScreen}
            isBanxe={isBanxe}
          />
        </div>
      </div>
    );
  }
}

Settings.propTypes = {
  activeScreen: PropTypes.oneOf(Object.values(SETTINGS_SCREENS)),
  changeActiveScreen: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  activeScreen: activeScreenSelector(state),
  isBanxe: isBanxe(state),
});

const mapDispatchToProps = (dispatch) => ({
  changeActiveScreen: (screen) => dispatch(changeActiveScreen(screen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
